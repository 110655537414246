/* eslint-disable camelcase */
import { List, Map } from "immutable"
import { BULK_INSERT } from "./helpers"

const PRODUCTS_REPLACE_PRODUCT = "PRODUCTS_REPLACE_PRODUCT"
export const PRODUCTS_SAVE_PRODUCT = "PRODUCTS_SAVE_PRODUCT"
export const PRODUCTS_DELETE_PRODUCT = "PRODUCTS_DELETE_PRODUCT"
export const PRODUCTS_START_LOADING = "PRODUCTS_START_LOADING"
export const PRODUCTS_LOADING_SUCCESS = "PRODUCTS_LOADING_SUCCESS"
export const PRODUCTS_LOADING_FAIL = "PRODUCTS_LOADING_FAIL"

export function productsStartLoading() {
  return {
    type: PRODUCTS_START_LOADING,
  }
}

export function productsLoadingSuccess() {
  return {
    type: PRODUCTS_LOADING_SUCCESS,
  }
}

export function productsLoadingFail(error) {
  return {
    type: PRODUCTS_LOADING_FAIL,
    error,
  }
}

export const productsSaveProduct = p => ({
  type: PRODUCTS_SAVE_PRODUCT,
  id: p.get("id"),
  name: p.get("name"),
})

export function productsDeleteProduct(product_id) {
  return {
    type: PRODUCTS_DELETE_PRODUCT,
    product_id,
  }
}

const productsReplaceProduct = (p) => {
  return ({
    type: PRODUCTS_REPLACE_PRODUCT,
    id: p.id,
    name: p.name,
  })
}


const initialProductState = Map({
  id: 0,
  name: "",
})

const product = (state = initialProductState, action) => {
  switch (action.type) {
    case PRODUCTS_SAVE_PRODUCT:
      return state.merge({
        name: action.name,
      })
    case PRODUCTS_REPLACE_PRODUCT:
      return state.merge({
        id: action.id,
        name: action.name,
      })
    default:
      return state
  }
}

const initialProductsState = Map({
  ids: List(),
})

const products = (state = initialProductsState, action) => {
  switch (action.type) {
    case BULK_INSERT:
      if (action.products) {
        let s = state
        action.products.forEach((p) => {
          const ids = s.get("ids")
          s = s.merge({
            [p.id]: product(s.get(p.id), productsReplaceProduct(p)),
            ids: ids.indexOf(p.id) === -1 ? ids.push(p.id) : ids,
          })
        })
        return s
      }
      return state

    case PRODUCTS_DELETE_PRODUCT: {
      const ids = state.get("ids")
      return state.delete(action.product_id)
        .merge({
          ids: ids.delete(ids.indexOf(action.product_id)),
        })
    }
    case PRODUCTS_SAVE_PRODUCT:
    case PRODUCTS_REPLACE_PRODUCT: {
      const ids = state.get("ids")
      const s = state.get(action.id)
      return state.merge({
        [action.id]: product(s, action),
        ids: ids.indexOf(action.id) === -1 ? ids.push(action.id) : ids,
      })
    }
    default:
      return state
  }
}

export default products
