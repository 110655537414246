/* eslint-disable camelcase,prefer-destructuring */
import { startConfiguration } from "pusher-redux"
import { Map } from "immutable"
import rg4js from "raygun4js"
import { createSelector } from "reselect"
import router from "../../config/router"
import { getProfileData, login } from "../../api/api"
import { usersReplace } from "./users"
import { addCompanies } from "./companies"
import {
  trackLogin as segmentTrackLogin,
  trackLogout as segmentTrackLogout,
  identify as segmentIdentify
} from "../../providers/segment"

import {
  REFRESH_ALL,
  STORE_API_USER,
  STORE_API_TOKEN,
  STORE_API_ROLES,
  STORE_API_FRANCHISE_ID,
  STORE_AUTH_COMPANY_ID,
  STORE_AUTH_USERID,
  ACCOUNT_DATA,
} from "../../config/constants"

const AUTH_IN_PROGRESS = "AUTH_IN_PROGRESS"
const AUTH_SUCCESS = "AUTH_SUCCESS"
const AUTH_RESTORE_SUCCESS = "AUTH_RESTORE_SUCCESS"
export const AUTH_FAILURE = "AUTH_FAILURE"
export const AUTH_LOGOUT = "AUTH_LOGOUT"
export const AUTH_FAILURE_CHECK = "AUTH_FAILURE_CHECK"
export const AUTH_SET_COMPANY_ID = "AUTH_SET_COMPANY_ID"
export const AUTH_SET_USERID = "AUTH_SET_USERID"

export const SEND_AUTH_CODE_REQUEST = "SEND_AUTH_CODE_REQUEST"
export const SEND_AUTH_CODE_FAILURE = "SEND_AUTH_CODE_FAILURE"
export const SEND_AUTH_CODE_SUCCESS = "SEND_AUTH_CODE_SUCCESS"

export const REGISTER_NEW_USER_REQUEST = "REGISTER_NEW_USER_REQUEST"
export const REGISTER_NEW_USER_FAILURE = "REGISTER_NEW_USER_FAILURE"
export const REGISTER_NEW_USER_SUCCESS = "REGISTER_NEW_USER_SUCCESS"

/* These 2 actions are used for storing account data in the cookie */
/* They were created because we asked for all the account data but we send a code to verify email */
/* when that code is submitted we get the data from the cookie to create & confirm account */
export const COOKIE_PERSIST_ACCOUNT_DATA = "COOKIE_PERSIST_ACCOUNT_DATA"
export const COOKIE_CLEAR_ACCOUNT_DATA = "COOKIE_CLEAR_ACCOUNT_DATA"

export const UPDATE_PASSWORD = "AUTH_UPDATE_PASSWORD"
export const UPDATE_PASSWORD_SUCCESS = "AUTH_UPDATE_PASSWORD_SUCCESS"
export const UPDATE_PASSWORD_FAILURE = "AUTH_UPDATE_FAILURE"

export const FORGOT_PASSWORD = "AUTH_FORGOT_PASSWORD"
export const FORGOT_PASSWORD_SUCCESS = "AUTH_FORGOT_PASSWORD_SUCCESS"
export const FORGOT_PASSWORD_FAILURE = "AUTH_FORGOT_FAILURE"

export const authSetUserId = (id) => (dispatch) => {
  const { localStorage } = window
  localStorage.setItem(STORE_AUTH_USERID, id)
  segmentIdentify(id)
  dispatch({
    type: AUTH_SET_USERID,
    id,
  })
}


export const authSetCompanyId = id => (dispatch) => {
  const { localStorage } = window
  localStorage.setItem(STORE_AUTH_COMPANY_ID, id)
  dispatch({
    type: AUTH_SET_COMPANY_ID,
    id,
  })
}

export function getCredentials() {
  const localStorage = window.localStorage
  return {
    token: localStorage.getItem(STORE_API_TOKEN),
    franchise_id: localStorage.getItem(STORE_API_FRANCHISE_ID),
  }
}

export function authLogout() {
  // remove credentials from local storage
  const localStorage = window.localStorage
  localStorage.removeItem(STORE_API_TOKEN)
  localStorage.removeItem(STORE_API_USER)
  localStorage.removeItem(STORE_API_FRANCHISE_ID)
  localStorage.removeItem(STORE_API_ROLES)
  localStorage.removeItem(STORE_AUTH_USERID)

  segmentTrackLogout()

  return {
    type: AUTH_LOGOUT,
  }
}

export function authInProgress() {
  return {
    type: AUTH_IN_PROGRESS,
  }
}

export function authFail(error) {
  const localStorage = window.localStorage
  localStorage.removeItem(STORE_API_TOKEN)
  localStorage.removeItem(STORE_API_USER)
  localStorage.removeItem(STORE_API_FRANCHISE_ID)
  localStorage.removeItem(STORE_API_ROLES)
  localStorage.removeItem(STORE_AUTH_USERID)

  return {
    type: AUTH_FAILURE,
    error,
  }
}

function authSuccess(type, apiUser, authId, franchiseId, authToken, apiRoles) {
  // save credentials to local storage
  const { localStorage } = window
  localStorage.setItem(STORE_API_TOKEN, authToken)
  localStorage.setItem(STORE_API_USER, apiUser)
  localStorage.setItem(STORE_AUTH_USERID, authId)
  localStorage.setItem(STORE_API_FRANCHISE_ID, franchiseId)
  localStorage.setItem(STORE_API_ROLES, apiRoles)

  // pusher
  startConfiguration({
    auth: {
      params: {
        api_user: apiUser,
      },
    },
  })

  return {
    type,
    apiUser,
    authId,
    franchiseId,
    authToken,
    apiRoles
  }
}

const setRaygunUser = (user) => {
  if (process.env.NODE_ENV === "production") {
    rg4js("setUser", {
      identifier: user.email,
      isAnonymous: false,
      email: user.email,
      fullName: `${user.first_name} ${user.last_name}`,
      firstName: user.first_name,
      uuid: user.id.toString(),
    })
  }
}


/**
 * @param companies array
 * @returns companyId saved in the cookies STORE_AUTH_COMPANY_ID
 */
export const getCompanyIdInCookie = () => {
  const { localStorage } = window
  let companyId
  const cookiesCompanyId = localStorage.getItem(STORE_AUTH_COMPANY_ID)
  // sometimes in the cookies the string 'null' gets saved, so we choose the first company in the list if thats the case.
  if (cookiesCompanyId && cookiesCompanyId !== "null" && cookiesCompanyId !== "0") {
    // we restore here the previously selected company
    companyId = localStorage.getItem(STORE_AUTH_COMPANY_ID)
  }
  return companyId
}

export function authAttempt(email, password, franchiseId, googleRecaptchaToken) {
  return (dispatch) => {
    dispatch(authInProgress())
    return login(email, password, franchiseId, googleRecaptchaToken)
      .then((data) => {
        if (data.data.success) {
          dispatch(authSetUserId(data.data.id))

          getProfileData({
            franchise_id: franchiseId,
            token: data.data.token,
          })
            .then(({ userData, companiesData }) => {
              /* Fresh login so reset the selected company */
              const { localStorage } = window

              localStorage.removeItem(STORE_AUTH_COMPANY_ID)

              const user = userData.data
              const companies = companiesData.data

              dispatch(usersReplace(user.id, user.email, user.first_name, user.last_name, user.phone_number, user.vip === "1"))
              addCompanies(dispatch, companies)

              setRaygunUser(user)

              dispatch(authSuccess(AUTH_SUCCESS, email, data.data.id, franchiseId, data.data.token, data.data.roles))
              segmentTrackLogin()

              dispatch({ type: REFRESH_ALL })
            })
        } else if (data.data.password_reset_required) {
          // request failed and needs a pass reset
          const { unique_key } = data.data
          router.navigate(`/reset-password/${unique_key}`)
        }
      })
      .catch((error) => {
        dispatch(authFail(error.response.data.message))
      })
  }
}

export function authRestore(apiUser, authId, franchiseId, authToken, apiRoles) {
  return (dispatch) => {
    dispatch(authSetUserId(authId))

    return getProfileData({
      franchise_id: franchiseId,
      token: authToken,
    }).then(({ userData, companiesData }) => {
      const user = userData.data
      const companies = companiesData.data

      dispatch(usersReplace(user.id, user.email, user.first_name, user.last_name, user.phone_number))
      addCompanies(dispatch, companies)

      // Reset selected company if it's present
      const { localStorage } = window

      if (localStorage.getItem(STORE_AUTH_COMPANY_ID) !== null) {
        dispatch(authSetCompanyId(localStorage.getItem(STORE_AUTH_COMPANY_ID)))
      }

      setRaygunUser(user)

      dispatch(authSuccess(AUTH_RESTORE_SUCCESS, apiUser, authId, franchiseId, authToken, apiRoles))
      dispatch({ type: REFRESH_ALL })
    }).catch(() => {
      dispatch(authFail(""))
    })
  }
}

export const sendAuthCodeRequest = ({ email, showNotification }) => ({
  type: SEND_AUTH_CODE_REQUEST,
  email,
  showNotification,
})
export const sendAuthCodeSuccess = () => ({
  type: SEND_AUTH_CODE_SUCCESS,
})
export const sendAuthCodeFailure = error => ({
  type: SEND_AUTH_CODE_FAILURE,
  error,
})

export const registerNewUser = ({
  email, firstName, lastName, password, phone, authCode
}) => ({
  type: REGISTER_NEW_USER_REQUEST,
  email,
  firstName,
  lastName,
  password,
  phone,
  authCode,
})

export const registerNewUserSuccess = () => ({
  type: REGISTER_NEW_USER_SUCCESS,
})
export const registerNewUserFailure = error => ({
  type: REGISTER_NEW_USER_FAILURE,
  error,
})

export const persistAccountDataInCookie = data => ({
  type: COOKIE_PERSIST_ACCOUNT_DATA,
  data,
})
export const clearAccountDataInCookie = () => ({
  type: COOKIE_CLEAR_ACCOUNT_DATA,
})

export const updatePassword = (token, password, passwordAgain) => ({
  type: UPDATE_PASSWORD,
  payload: { password, password_again: passwordAgain, unique_key: token },
})
export const updatePasswordSuccess = () => ({
  type: UPDATE_PASSWORD_SUCCESS,
})
export const updatePasswordFailure = error => ({
  type: UPDATE_PASSWORD_FAILURE,
  payload: { error },
})

export const forgotPassword = (email, franchiseId, googleRecaptchaToken) => ({
  type: FORGOT_PASSWORD,
  payload: { email, franchise_id: franchiseId, google_recaptcha_token: googleRecaptchaToken },
})
export const forgotPasswordSuccess = () => ({
  type: FORGOT_PASSWORD_SUCCESS,
})
export const forgotPasswordFailure = error => ({
  type: FORGOT_PASSWORD_FAILURE,
  payload: { error },
})
export const authFailureCheck = response => ({
  type: AUTH_FAILURE_CHECK,
  payload: { response },
})

const initialAuthState = Map({
  authUserId: 0,
  franchiseId: null,
  authToken: false,
  authCompanyId: null,
  isLoginDialogOpen: false,
  isAuthInProgress: false,
  sendingAuthCode: false,
  authCodeSent: false,
  authCodeError: null,
  error: "",
  apiUser: "",
  passwordUpdated: false,
  updatePasswordError: null,
  isForgotPasswordSent: false,
  forgotPasswordError: null,
})

export default function auth(state = initialAuthState, action) {
  switch (action.type) {
    case AUTH_SET_USERID:
      return state.merge({
        authUserId: parseInt(action.id, 10),
      })
    case AUTH_SET_COMPANY_ID:
      return state.merge({
        authCompanyId: action.id,
      })
    case AUTH_LOGOUT:
      return state.merge({
        // authUserId: 0,
        authToken: false,
        authCompanyId: "",
        authUserId: 0,
        apiUser: "",
      })
    case AUTH_IN_PROGRESS:
      return state.merge({
        isAuthInProgress: true,
      })
    case AUTH_FAILURE:
      return state.merge({
        isAuthInProgress: false,
        authToken: false,
        authCompanyId: "",
        authUserId: 0,
        apiUser: "",
        error: action.error,
      })
    case AUTH_SUCCESS:
    case AUTH_RESTORE_SUCCESS:
      return state.merge({
        isAuthInProgress: false,
        authToken: action.authToken,
        apiUser: action.apiUser,
        franchiseId: action.franchiseId,
      })
    case SEND_AUTH_CODE_REQUEST:
      return state
        .set("sendingAuthCode", true)
        .set("authCodeSent", false)
        .set("authCodeError", null)
    case SEND_AUTH_CODE_FAILURE:
      return state
        .set("sendingAuthCode", false)
        .set("authCodeSent", false)
        .set("authCodeError", action.error && action.error.message)
    case SEND_AUTH_CODE_SUCCESS:
      return state
        .set("sendingAuthCode", false)
        .set("authCodeSent", true)
        .set("authCodeError", null)
    case COOKIE_PERSIST_ACCOUNT_DATA: {
      const { localStorage } = window
      const { data } = action
      localStorage.setItem(ACCOUNT_DATA, JSON.stringify(data))
      return state
    }
    case COOKIE_CLEAR_ACCOUNT_DATA: {
      const { localStorage } = window
      localStorage.removeItem(ACCOUNT_DATA)
      return state
    }
    case REGISTER_NEW_USER_FAILURE: {
      return state.set("error", action.error)
    }
    case UPDATE_PASSWORD: {
      return state
        .set("passwordUpdated", false)
        .set("updatePasswordError", null)
    }
    case UPDATE_PASSWORD_FAILURE: {
      return state
        .set("passwordUpdated", false)
        .set("updatePasswordError", action.payload.error ? action.payload.error.message : "Unknown error")
    }
    case UPDATE_PASSWORD_SUCCESS: {
      return state
        .set("passwordUpdated", true)
        .set("updatePasswordError", null)
    }
    case FORGOT_PASSWORD: {
      return state
        .set("forgotPasswordSent", false)
        .set("forgotPasswordEror", null)
    }
    case FORGOT_PASSWORD_FAILURE: {
      return state
        .set("forgotPasswordSent", false)
        .set("forgotPasswordError", action.payload.error ? action.payload.error : "Unknown error")
    }
    case FORGOT_PASSWORD_SUCCESS: {
      return state
        .set("forgotPasswordSent", true)
        .set("forgotPasswordError", null)
    }
    default:
      return state
  }
}

// selectors
const getAuth = state => state.get("auth")
export const isAuthInProgress = state => state.getIn(["auth", "isAuthInProgress"])
export const getAuthError = state => state.getIn(["auth", "error"])
export const isAuthenticated = state => state.getIn(["auth", "authToken"]) !== false

export const hasSavedAuthentication = () => {
// eslint-disable-next-line prefer-destructuring
  const localStorage = window.localStorage
  const key = localStorage.getItem(STORE_API_TOKEN)
  return key && key.length > 0
}

export const getRoles = () => {
  const localStorage = window.localStorage
  const roles = localStorage.getItem(STORE_API_ROLES)
  return roles ? roles.split(",") : []
}

export const hasRole = (role) => {
  const roles = getRoles()
  return roles.indexOf(role) !== -1
}

export const getAuthApiUser = state => state.getIn(["auth", "apiUser"])
export const getAuthUserId = state => state.getIn(["auth", "authUserId"])
export const getSelectedCompanyId = (state) => {
  const selectedCompanyId = state.getIn(["auth", "authCompanyId"])
  if (selectedCompanyId && selectedCompanyId !== "null") {
    return selectedCompanyId
  }
  return null
}

export const getAuthCodeSent = state => state.getIn(["auth", "authCodeSent"])
export const getAuthCodeError = state => state.getIn(["auth", "authCodeError"])
export const isSendingAuthCode = state => state.getIn(["auth", "sendingAuthCode"])

export const getAccountDataInCookie = () => {
  const { localStorage } = window
  const accountData = localStorage.getItem(ACCOUNT_DATA)
  if (accountData && accountData.length > 0) {
    return JSON.parse(accountData)
  }
  return null
}

export const isPasswordUpdated = createSelector(
  getAuth,
  authState => authState.get("passwordUpdated"),
)
export const getPasswordUpdateError = createSelector(
  getAuth,
  authState => authState.get("updatePasswordError"),
)

export const isForgotPasswordSent = createSelector(
  getAuth,
  authState => authState.get("forgotPasswordSent"),
)
export const getForgotPasswordError = createSelector(
  getAuth,
  authState => authState.get("forgotPasswordError"),
)
