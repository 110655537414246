import React from "react"
import Label from "./Label"
import InputError from "./InputError"
import styled from "styled-components"
import { InputWrapper } from "./TextInput"
import PropTypes from "prop-types"

const ThemedCheckboxInput = styled.input`
  position: absolute;
  visibility: hidden;
  & + i {
    
    border: 1px solid ${({ theme }) => theme.borderColor};
    background: ${({ theme }) => theme.primaryContrastColor};
    border-radius: 40px;
    width: 43px;
    padding: 2px;
    float: left;
    transition: all .25s;
    
    &:after {
        content: ' ';
        background-color: ${({ theme }) => theme.labelDarkColor};
        float: left;
        width: 18px;
        height: 18px;
        border-radius: inherit;
        transition: inherit;
    }
  }

  &:checked + i {
    background-color: ${({ theme }) => theme.primaryLighterColor};
    border-color: ${({ theme }) => theme.primaryLighterColor};
    
    &:after {
        background-color: ${({ theme }) => theme.primaryColor};
        margin-left: 60%;
    }
  }
`

const Checkbox = ({errors = {}, name, value, label, onClick = null, ...rest }) => (
  <>
    <InputWrapper>
      <div>
        <ThemedCheckboxInput id={name} type="checkbox" checked={value} {...rest} />
        <i onClick={(e) => e?.target?.previousElementSibling?.click()}></i>
        {label && <Label htmlFor={name}>{label}</Label>}
      </div>
      {errors[name] && <div><InputError>{errors[name]}</InputError></div>}
    </InputWrapper>
  </>
)

Checkbox.propTypes = {
  errors: PropTypes.object,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  name: PropTypes.string,
  value: PropTypes.bool.isRequired,
}

export default Checkbox