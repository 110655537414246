import React from "react"
import PropTypes from "prop-types"
import { components } from "react-select"
import { ChevronIcon } from "../../Icons/Icons"

export const DownChevron = innerProps => (
  <components.DropdownIndicator {...innerProps} aria-label="Dropdown">
    <ChevronIcon />
  </components.DropdownIndicator>
)
DownChevron.propTypes = { innerProps: PropTypes.object.isRequired }

export const reactSelectStyleUtils = theme => ({
  control: base => ({
    ...base,
    boxShadow: "none",
    border: "none",
    background: "transparent",
  }),
  indicatorSeparator: () => ({ display: "none" }),
  dropdownIndicator: base => ({
    ...base,
    color: `${theme.primaryColor}`,
    fontSize: "18px",
    width: "30px",
    boxSizing: "content-box",
    paddingRight: "5px"
  }),
})
