import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import ImmutablePropTypes from "react-immutable-proptypes"
import moment from "moment-timezone"

import { formatToMomentTimezone } from "../../timeFormat"
import PaginationContainer from "../Pagination/PaginationContainer"
import BookingsList from "../../components/BookingsList/BookingsList"
import ListWrapper from "../../components/Wrappers/ListWrapper"
import MobileNavigation from "../../components/Home/MobileNavigation"
import FillerWrapper from "../../components/Wrappers/FillerWrapper"
import { LoadingText } from "../../components/Loading"
import {
  bookingsSetSelectedBookingId,
  getCurrentPageBookings,
  getBookingsLoading,
  refreshBookings, BOOKING_HISTORY_TYPE_PENDING, BOOKING_HISTORY_TYPE_COMPLETE,
} from "../../redux/modules/bookings"
import { changePage } from "../../redux/modules/pagination"
import BookingSearchForm from "../../forms/Search/BookingSearchForm"
import { getSelectedCompany } from "../../redux/modules/companies"

import {
  MAX_ITEMS_PER_PAGE
} from "../../sagas/sagas"
import { useNavigate, useParams } from "react-router-dom"
import { trackSetSelectedBookingId as segmentTrackSetSelectedBookingId } from "../../providers/segment"

const BookingsListContainer = ({ bookings = undefined, users, services, employees, companies, bookingsLoading, changeBookingsPage, selectedCompany = null, selectBooking, refreshBookings }) => {

  const [bookingSearchTimer, setBookingSearchTimer] = useState(null);

  const params = useParams()

  const [bookingSearchValues, setBookingSearchValues] = useState({
    user: ''
  })

  const navigate = useNavigate();

  useEffect(() => {
    if (bookingSearchValues) {
      changeBookingsPage(0)

      const search = {
        history: (params.state ?? 'pending') === 'pending' ? BOOKING_HISTORY_TYPE_PENDING : BOOKING_HISTORY_TYPE_COMPLETE,
        company: selectedCompany ? selectedCompany.get('id') : 0,
        user: bookingSearchValues.user,
        offset: 0,
        page: 0,
      }

      debouncedFetchBookings(search)
    }
  }, [bookingSearchValues, selectedCompany, params])

  const title = "Bookings"

  if (!bookings || !companies || companies.get("ids").count() === 0) {
    return null
  }

  const debouncedFetchBookings = (search) => {

    const delay = bookingSearchTimer ? 500 : 0

    if (bookingSearchTimer) {
      clearTimeout(bookingSearchTimer)
    }

    const timer = setTimeout(() => {
      refreshBookings(search.history, search.company, search.user, MAX_ITEMS_PER_PAGE, search.offset, search.page)
    }, delay)

    setBookingSearchTimer(timer)
  };
  const onClick = (item) => {
    segmentTrackSetSelectedBookingId(item.id)
    selectBooking(item.id)
    navigate('/bookings/detail')
  }

  const getTableItems = (bookings) => {
    /* Array to hold table items */
    const items = []

    /* Temp var to hold last date when iterating over bookings */
    let lastDate

    bookings.forEach((booking, index) => {
      const user = users.get(booking.get("userId"))
      const customer = user ? `${user.get("firstName")} ${user.get("lastName")}` : ""
      const time = booking.get("appointmentTime")
      const serviceId = booking.get("serviceId")
      const employeeId = booking.get("employeeId")
      const description = services.getIn([serviceId, "serviceDescription", "name"])

      const service = (employeeId) ? `${description} with ${employees.getIn([employeeId, "name"])}` : description

      const companyId = booking.get("companyId")
      const company = companies.get(companyId)
      const companyName = company.get("name")

      const timeZone = company.get("timezone")

      const cancelledType = booking.get("cancelledType")

      const saved = booking.get("saved")

      /* Is last booking on that given day? */
      const last = false
      const currentDate = formatToMomentTimezone(time, "YYYY-MM-DD", timeZone)

      /* If first row, or a new day, insert date row ahead of booking */
      if (index === 0 || currentDate !== lastDate) {
        /* If it's a new day it's implied that the last row was the last of the previous day */
        if (index > 0) {
          items[items.length - 1].last = true
        }

        /* Generate timestamp at start of day to ensure correct ordering by react-virtualized Table */
        const startOfDayTimestamp = moment.tz(currentDate, timeZone).startOf("day").valueOf()

        items.push({
          rowType: "date",
          time: startOfDayTimestamp,
          timeZone,
        })
      }

      /* Update last date for next loop */
      lastDate = currentDate

      items.push({
        rowType: "service",
        id: booking.get("id"),
        user: `${user.get("firstName")} ${user.get("lastName")}`,
        type: booking.get("type"),
        customer,
        time,
        service,
        company: companyName,
        cancelledType,
        saved,
        todo: booking.get("todo"),
        last,
        timeZone,
      })
    })

    return items
  }

  const items = getTableItems(bookings)
  const hasItems = items.length > 0

  const onPageChange = (page) => debouncedFetchBookings({
    history: bookingSearchValues.history,
    company: selectedCompany ? selectedCompany.get("id") : 0,
    user: bookingSearchValues.user,
    offset: page * MAX_ITEMS_PER_PAGE,
    page,
  })

  return (
    <React.Fragment>
      <MobileNavigation title={title} />
      <ListWrapper title={title}>
        <BookingSearchForm bookings={bookings} handleChange={setBookingSearchValues} />

        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <PaginationContainer listName="bookings" itemsPerPage={MAX_ITEMS_PER_PAGE} handleChange={onPageChange} />
        </div>

        {!bookingsLoading && hasItems && (<BookingsList items={items} onClick={onClick} />)}
        {!bookingsLoading && !hasItems && (
          <FillerWrapper style={{ padding: 0 }}>
            <div>No bookings</div>
          </FillerWrapper>
        )}
        {bookingsLoading && (
          <div style={{ marginTop: 40, textAlign: "center" }}>
            <LoadingText />
          </div>
        )}
      </ListWrapper>
    </React.Fragment>
  )
}

BookingsListContainer.propTypes = {
  bookings: ImmutablePropTypes.list,
  users: ImmutablePropTypes.map.isRequired,
  services: ImmutablePropTypes.map.isRequired,
  employees: ImmutablePropTypes.map.isRequired,
  selectBooking: PropTypes.func.isRequired,
  companies: ImmutablePropTypes.map.isRequired,
  bookingSearchFormValues: ImmutablePropTypes.map,
  refreshBookings: PropTypes.func.isRequired,
  changeBookingsPage: PropTypes.func.isRequired,
  bookingsLoading: PropTypes.bool.isRequired,
  selectedCompany: ImmutablePropTypes.map,
}

const mapDispatchToProps = dispatch => ({
  selectBooking: id => dispatch(bookingsSetSelectedBookingId(id)),
  refreshBookings: (history, company, user, limit, offset, page) => dispatch(refreshBookings(history, company, user, limit, offset, page)),
  changeBookingsPage: currentPage => dispatch(changePage("bookings", currentPage)),
})

const mapStateToProps = state => ({
  bookings: getCurrentPageBookings(state),
  bookingsLoading: getBookingsLoading(state),
  users: state.get("users"),
  services: state.get("services"),
  employees: state.get("employees"),
  companies: state.get("companies"),
  selectedCompany: getSelectedCompany(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(BookingsListContainer)
