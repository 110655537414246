import styled from "styled-components"
import { device } from "../Responsive/Responsive"

export const TwoColumnFormRow = styled.div`
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: stretch;
  border: solid 1px ${({ theme }) => theme.borderColor};
  border-radius: 10px;
  border-top-left-radius: ${({ $noTitle }) => $noTitle ? "10px" : "0px"};
  >:nth-child(1) {
    border-right: solid 1px ${({ theme }) => theme.borderColor};
  }
  @media ${device.mobile} {
    flex-direction: column;
  }
`
