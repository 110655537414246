import React from "react"
import ImmutablePropTypes from "react-immutable-proptypes"
import styled from "styled-components"

import { device } from "../Responsive/Responsive"
import TermsChapter from "../Terms/TermsChapter"

const Title = styled.div`
    font-family: 'Museo-Sans','sans-serif';
    font-weight: 500;
    font-size: 17px;
    color #575757;
    margin-bottom: 47px;

    @media ${device.mobile} {
        margin-bottom: 18px;
    }
`

/*
* Renders what in prismic are usually long text documents, with titles, subtitles and chapters.
* Currently used for Privacy Policy page and Terms and Conditions page.
*/
const LegalContent = ({ content = null }) => (
  content
    ? (
      <React.Fragment>
        <Title style={{ marginBottom: "4px" }}>{content.get("title")}</Title>
        {content.get("body").map(section => (
          <TermsChapter
            key={section.get("id")}
            title={section.getIn(["primary", "title"])}
            items={section.get("items")}
          />
        ))}
      </React.Fragment>
    ) : null
)

LegalContent.propTypes = {
  content: ImmutablePropTypes.map,
}
export default LegalContent
