import React from "react"
import styled from "styled-components"

const Image = styled.img`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  object-fit: cover;
`

const NoImage = styled.div`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  background-color: ${({ theme }) => theme.borderColor};
`

const ServiceImage = ({ width, height, src}) => {
  if(!src) {
    return <NoImage width={width} height={height} />
  }
  return  <Image width={width} height={height} src={src} alt="" />
}

export default ServiceImage