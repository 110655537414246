import React from "react"
import { CookiesProvider } from "react-cookie"
import { IntercomProvider } from "react-use-intercom"
import GoogleRecaptcha from "./google-recaptcha"
import SegmentProvider from "./segment"
import ReduxProvider from './redux'
import PrismicProvider from './prismic'
import ThemeProvider from './theme'
import RouterProvider from './router'

export default () => (
  <GoogleRecaptcha>
    <ReduxProvider>
      <SegmentProvider>
        <PrismicProvider>
          <CookiesProvider defaultSetOptions={{ path: '/' }}>
            <IntercomProvider appId='fccsgukf'>
              <ThemeProvider>
                <RouterProvider />
              </ThemeProvider>
            </IntercomProvider>
          </CookiesProvider>
        </PrismicProvider>
      </SegmentProvider>
    </ReduxProvider>
  </GoogleRecaptcha>
);
