/* eslint-disable react/prefer-stateless-function */
import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import ImmutablePropTypes from "react-immutable-proptypes"
import styled from "styled-components"

import moment from "moment-timezone"
import { Navigate, useNavigate } from "react-router-dom"
import BookingDetail from "../../components/BookingDetail/BookingDetail"
import {
  bookingsRescheduleBooking,
  bookingsCancelBooking,
  getSelectedBooking,
  BOOKING_CANCELLED_TYPE_AVAILABLE,
  BOOKING_CANCELLED_TYPE_NOSHOW,
  BOOKING_CANCELLED_TYPE_COMPANY,
} from "../../redux/modules/bookings"
import BookingEditForm from "../../forms/BookingEditForm/BookingEditForm"
import FormWrapper from "../../components/Wrappers/FormWrapper"
import MobileNavigation from "../../components/Home/MobileNavigation"
import { openConfirm } from "../../redux/modules/notifications"
import { Button } from "../../components/Form"
import DashboardCard from "../../components/Wrappers/DashboardCard"
import { getAllEmployeesMap } from "../../redux/modules/employees"
import { device } from "../../components/Responsive/Responsive"

const CancelButton = styled(Button)`
    float: right;
`

const CancelButtonContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;

    @media ${device.mobile} {
        flex-direction: column;
    }
`
const BookingEditContainer = ({ companies, booking = undefined, onRescheduleBooking, showConfirm, users, services, products, employees }) => {
  const getInitialValues = () => {
    const appointmentTime = booking.get("appointmentTime")

    return {
      date: appointmentTime,
      time: appointmentTime,
      employeeId: booking.get("employeeId").toString(),
    }
  }

  const navigate = useNavigate();

  const onReschedule = ({ employeeId, date }) => {
    onRescheduleBooking(booking.get("id"), date, getCompanyTimeZone(), employeeId);
    navigate('/bookings');
  }

  const onCancelBooking = (id, cancelledType) => [
    bookingsCancelBooking(id, cancelledType),
    () => navigate('/bookings'),
  ]

  const onNoShow = () => {
    showConfirm(
      null,
      "Are you sure you want to mark this booking as a no-show?",
      onCancelBooking(booking.get("id"), BOOKING_CANCELLED_TYPE_NOSHOW)
    )
  }

  const onCancel = () => {
    showConfirm(
      null,
      "Are you sure you want to cancel this booking?",
      onCancelBooking(
        booking.get("id"),
        BOOKING_CANCELLED_TYPE_COMPANY
      )
    )
  }

  const getBookingCompany = () => {
    return companies.get(booking.get("companyId"))
  }

  const allowCancel = () => getBookingCompany().get("allowCancel") && booking.get("cancelledType") === BOOKING_CANCELLED_TYPE_AVAILABLE;
  const getBookingUser = () => users.get(booking.get("userId"));
  const isFuture = () => moment(booking.get("appointmentTime")).isAfter()
  const getCompanyTimeZone = () => getBookingCompany().get("timezone");
  const getAppointmentTime = () => booking.get("appointmentTime");

  const getEmployeesByService = () =>  booking.get("employeeIds")
      .filter((id) => employees.has(id))
      .map((employeeId) => employees.get(employeeId).toJS())
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1
        }
        if (a.name > b.name) {
          return 1
        }
        return 0
      });

  const getBookingDetail = () => {
    const user = getBookingUser()
    const service = services.get(booking.get("serviceId"))
    const company = getBookingCompany()

    const productNamesByBooking = []

    booking.get("productIds")
      .forEach((id) => {
        products.forEach((p) => {
          if (p.get("id") === id) {
            productNamesByBooking.push(p.get("name"))
          }
        })
      })

    // Get employee name if any was selected
    let employeeName
    const employeeId = booking.get("employeeId")
    if (employeeId) {
      const employee = employees.get(employeeId)
      if (employee) {
        employeeName = employee.get("name")
      }
    }

    return (
      <BookingDetail
        customer={{
          name: `${user.get("firstName")} ${user.get("lastName")}`,
          isVip: user.get("isVip"),
          phoneNumber: user.get("phoneNumber"),
          email: user.get("email"),
        }}
        serviceDescription={service.getIn(["serviceDescription", "name"])}
        servicePrice={`${booking.get("currency").symbol}${booking.get("price")
          .toFixed(2)}`}
        company={company.toJS()}
        products={productNamesByBooking}
        notes={booking.get("notes")}
        employeeName={employeeName}
        appointmentTime={booking.get("appointmentTime")}
      />
    )
  }

  const title = "Booking Details";

  return (
    !booking
      ? <Navigate to={`${process.env.PUBLIC_URL || "/bookings"}`} />
    : <>
      <MobileNavigation title={title} backLinkOnly />
      <FormWrapper
        title={`${title} for ${getBookingUser().get("firstName")} ${getBookingUser().get("lastName")}`}
        backLinkLabel="Back to Bookings"
      >
        <DashboardCard actions={
          isFuture && (
            <CancelButtonContainer>
              {allowCancel() && (
                <CancelButton outlined onClick={onCancel}>
                  Cancel Booking
                </CancelButton>
              )}
              <div style={{ flex: 1 }} />
              <CancelButton outlined onClick={onNoShow}>
                No-Show
              </CancelButton>
            </CancelButtonContainer>
          )
        }
        >
          {getBookingDetail()}
        </DashboardCard>
      </FormWrapper>

      {isFuture && (
        <FormWrapper title="Reschedule Booking">
          {booking.get("cancelledType") === BOOKING_CANCELLED_TYPE_AVAILABLE
            ? (
              <BookingEditForm
                user={getBookingUser()}
                employees={getEmployeesByService()}
                onSubmit={onReschedule}
                appointmentTime={getAppointmentTime()}
                employeeId={booking.get("employeeId").toString()}
                allowCancel={allowCancel()}
                timeZone={getCompanyTimeZone()}
                bookingId={booking.get('id')}
              />
            ) : (
              <DashboardCard>
                <p>You are not allowed to reschedule cancelled bookings.</p>
              </DashboardCard>
            )
          }

        </FormWrapper>
      )}
    </>
  )
}

BookingEditContainer.propTypes = {
  booking: ImmutablePropTypes.map,
  users: ImmutablePropTypes.map.isRequired,
  onRescheduleBooking: PropTypes.func.isRequired,
  showConfirm: PropTypes.func.isRequired,
  services: ImmutablePropTypes.map.isRequired,
  employees: ImmutablePropTypes.map.isRequired,
  products: ImmutablePropTypes.map.isRequired,
  companies: ImmutablePropTypes.map.isRequired,
}

const mapDispatchToProps = dispatch => ({
  onRescheduleBooking: (id, appointmentTime, companyTimezone, employeeId) => dispatch(bookingsRescheduleBooking(id, appointmentTime, companyTimezone, parseInt(employeeId))),
  showConfirm: (title, message, action) => dispatch(openConfirm(title, message, action)),
})

const mapStateToProps = state => ({
  booking: getSelectedBooking(state),
  companies: state.get("companies"),
  employees: getAllEmployeesMap(state),
  services: state.get("services"),
  products: state.get("products"),
  users: state.get("users"),
})

export default connect(mapStateToProps, mapDispatchToProps)(BookingEditContainer)
