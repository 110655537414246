import React from "react"
import ImmutablePropTypes from "react-immutable-proptypes"
import { connect } from "react-redux"

import { getCompanyTerms } from "../../redux/modules/invite"
import LegalContent from "../../components/Legals/LegalContent"

const HelpTermsPage = ({ companyTerms = null }) => {
  const companyTermsData = companyTerms ? companyTerms.get("data") : null

  return <LegalContent content={companyTermsData} />
}

HelpTermsPage.propTypes = {
  companyTerms: ImmutablePropTypes.map,
}

const mapStateToProps = state => ({
  companyTerms: getCompanyTerms(state),
})

export default connect(mapStateToProps)(HelpTermsPage)
