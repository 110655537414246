import { DayPicker } from "react-day-picker"
import React from "react"

const StyledDayPicker = (props) => {
  const css = `
    .rdp {
        --rdp-accent-color: var(--flossie-pink);
        --rdp-accent-color-dark: var(--flossie-pink-dark);
        --rdp-background-color-dark: var(--flossie-pink-uber-dark);
        --rdp-background-color: var(--flossie-pink-light);
    }
  `

  return <>
    <style>{css}</style>
    <DayPicker {...props} />
  </>
}

export default StyledDayPicker