import avedaTheme from "./aveda"
import defaultTheme from "./default"

const themes = {
  default: defaultTheme,

  "staging.salon.aveda.network": avedaTheme,
  "salon.aveda.network": avedaTheme,

  "salon.avedapbf.com": avedaTheme,
  "salon-staging.avedapbf.com": avedaTheme,

}

const hasCustomTheme = (typeof themes[process.env.DOMAIN] !== "undefined")

const currentTheme = hasCustomTheme ? { ...defaultTheme, ...themes[process.env.DOMAIN] } : defaultTheme

export default currentTheme
