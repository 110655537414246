import React from "react"
import PropTypes from "prop-types"
import {
  employeesExternalData, getAllEmployeesExternalData, getCompanyEmployees, isEmployeesLoading, isLoadingEmployeesExternalData
} from "../../redux/modules/employees"
import { SearchableItemsSelect } from "../../components/Form"

/*
* Searchable selector of employees.
* @args type: 'internal' | 'external'. When 'external' shows only employees from external data. 'internal'
shows the employees saved in our database.
* @args filterIds: Array with employee ids to filter out.
*/
const EmployeeSelect = ({ type = 'default', companyId, ...rest }) => {
  switch (type) {
    case "external":
      return (
        <SearchableItemsSelect
          fetchItemsAction={() => getAllEmployeesExternalData(companyId)}
          itemsSelector={state => employeesExternalData(state, companyId)}
          loadingSelector={state => isLoadingEmployeesExternalData(state)}
          formatItem={e => ({ value: e.get("id"), label: `${e.get("first_name")} ${e.get("last_name")}` })}
          {...rest}
        />
      )
    case "internal":
      return (
        <SearchableItemsSelect
          itemsSelector={state => getCompanyEmployees(state)}
          loadingSelector={state => isEmployeesLoading(state)}
          formatItem={e => ({ value: e.get("id"), label: `${e.get("firstName")} ${e.get("lastName")} - ${e.get("role")}` })}
          {...rest}
        />
      )
    default:
      return null
  }
}

EmployeeSelect.propTypes = {
  type: PropTypes.oneOf(["external", "internal"]),
  companyId: PropTypes.string.isRequired,
}

export default EmployeeSelect
