import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { device, ResponsiveDefault } from "../Responsive/Responsive"
import { ListDoneIcon, ListTodoIcon } from "../Icons/Icons"

/* Individual list styles */
const Bookings = styled.div`

    @media ${device.mobile} {
        height: calc(100% - 180px);
    }
    @media ${device.tablet}, ${device.desktop} {
        height: calc(100% - 80px);
    }
`
const BookingsRow = styled.div`
    background-color: #ffffff;
    position: relative;
    cursor: pointer;
`

const Services = styled.div`

    @media ${device.mobile} {
        height: calc(100vh - 200px);
    }
    @media ${device.tablet} {
        height: 480px;
    }
    @media ${device.desktop} {
        height: 480px;
    }

    border-top: 1px solid #ececec;
`
const ServicesRow = styled.div`
    background-color: #ffffff;
    position: relative;
    cursor: pointer;
`


/* Remove/adjust padding on mobile devices */
const ListBase = styled.div`
    @media ${device.mobile} {
        padding: 0px;
        padding-top: 20px;
    }
    @media ${device.tablet}, ${device.desktop} {
        min-width: 720px;
        padding: 20px;
    }

    height: calc(100% - 40px);
    display: block;
`

export const ListTitleContainer = styled.div`
    margin: 0;
    height: 38px;

    @media ${device.mobile} {
        display: none;
    }
`

export const ListTitleText = styled.div`

    padding: 0;

    @media ${device.mobile} {
        padding: 0px 12px;
    }
`


const CustomerCell = styled.div`
    font-family: 'museo-sans',sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: inherit;
`
const ServiceCell = styled.div`
    font-family: 'museo-sans',sans-serif;
    font-weight: 300;
    font-size: 16px;
    color: inherit;

    @media ${device.mobile} {
        max-width: 260px;
    }
`


/* Tick icon for todo/done */
function TodoCell(props) {
  const { todo, style } = props
  if (typeof todo !== "undefined" && todo === false) {
    return (<ListDoneIcon style={style} />)
  }
  return (<ListTodoIcon style={style} />)
}
TodoCell.propTypes = {
  todo: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  style: PropTypes.object,
}

export default class ListWrapper extends React.Component {
  /* Wrap root level lists in a common adaptive component */
  render() {
    const { children, title } = this.props

    return (
      <ListBase>

        <div style={{
          display: "flex",
          flexFlow: "column",
          alignItems: "stretch",
          height: "100%",
        }}
        >

          <ResponsiveDefault>
            <div>
              <ListTitleContainer>
                <ListTitleText className="MainTitle">{title}</ListTitleText>
              </ListTitleContainer>
            </div>
          </ResponsiveDefault>

          <div style={{
            flex: "1",
            overflow: "hidden",
          }}
          >
            {children}
          </div>

        </div>
      </ListBase>
    )
  }
}
ListWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
}

export {
  Bookings,
  BookingsRow,
  Services,
  ServicesRow,
  TodoCell,
  CustomerCell,
  ServiceCell,
}
