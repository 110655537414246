import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const AuthenticationParent = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    font-family: sans-serif;
    font-size: 32px;
    color: #f067a6;
    opacity: .4;
    transform: translate(-50%, -50%);
    text-align: center;
    justify-content: center;
`
const AuthenticationMessage = styled.small`
    font-size: 16px;
    display: block;
    margin-top: 8px;
`


const Authenticate = ({ hasError, error }) => {
  const message = (hasError) ? error : "Logging in"
  return (
    <AuthenticationParent>
      FLOSSIE
      <AuthenticationMessage>{message}</AuthenticationMessage>
      { hasError
            && <AuthenticationMessage>Redirecting back to login</AuthenticationMessage>
        }
    </AuthenticationParent>
  )
}

Authenticate.propTypes = {
  hasError: PropTypes.bool,
  error: PropTypes.string,
}

export default Authenticate
