import React from "react"
import styled from "styled-components"

import { device } from "../Responsive/Responsive"

const Container = styled.div`
  display: flex;
  flex-direction: row;
  @media ${device.mobile} {
    flex-direction: column;
  }
`

const SideBar = styled.div`
  padding: 0 1rem;
  position: sticky;
  flex: 1;
  /* border-right: 1px solid ${({ theme }) => theme.borderColor }; */
  @media ${device.mobile} {
    margin-bottom: 2rem;
    padding: 0;
  }
`

const ScrollableContent = styled.div`
  padding-left: 2rem;
  position: relative;
  flex: 3;
  @media ${device.mobile} {
    padding-left: 0;
  }
`

const SidebarWithContent = ({ sidebarContent, children, style = {} }) => {
  return (
    <Container style={style}>
      <SideBar>
        {sidebarContent}
      </SideBar>
      <ScrollableContent>
        {children}
      </ScrollableContent>
    </Container>
  )
}

export default SidebarWithContent
