import React from "react"
import PropTypes from "prop-types"
import TextareaAutosize from "react-textarea-autosize"

const TextareaAutosizeRedux = ({ input: { value, onChange }, className, placeholder= "" }) => {
  return (
    <TextareaAutosize className={className} placeholder={placeholder} value={value} onChange={onChange} />
  )
}

TextareaAutosizeRedux.propTypes = {
  input: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
}

export default TextareaAutosizeRedux
