import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import { authLogout, isAuthenticated } from "../../redux/modules/auth"
import { LoginToolbar } from "../../components"

class LoginToolbarContainer extends React.Component {
    clickHandler = () => {
      const { isAuthed, dispatch } = this.props
      if (isAuthed) {
        dispatch(authLogout())
      }
    }

    render() {
      const { isAuthed } = this.props
      return (
        <LoginToolbar isAuthed={isAuthed} clickHandler={this.clickHandler} />
      )
    }
}

LoginToolbarContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isAuthed: PropTypes.bool.isRequired,
}

function mapStateToProps(state) {
  return {
    isAuthed: isAuthenticated(state),
  }
}

export default connect(mapStateToProps)(LoginToolbarContainer)
