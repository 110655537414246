export const BULK_INSERT = "BULK_INSERT"

export const bulkInsert = data => ({
  type: BULK_INSERT,
  ...data,
})

export const purge = (state) => {
  const prevIds = state.get("prevIds")
  if (prevIds) {
    const ids = state.get("ids")
    const deletedIds = state.get("prevIds")
      .filter(id => ids.indexOf(id) === -1)
    return state.deleteAll(deletedIds)
      .merge({
        prevIds: null,
      })
  }
  return state
}
