import styled, { css } from "styled-components"

import { device } from "../../components/Responsive/Responsive"

export const Row = styled.div`
  margin: 0
  ${props => {
    switch (props.gutter) {
      case "none":
        return 0
      case "narrow":
        return -device.small / 4
      case "normal":
      default:
        return -device.large / 2
    }
  }}px;
  display: flex;
  ${props => props.spaceBetween && "align-items: space-between;"}
  ${props => props.centered && "align-items: center;"}
  ${props => props.stretched && "align-items: stretch;"}

  @media ${device.medium} {
    margin: 0
    ${props => {
    switch (props.gutter) {
      case "none":
        return 0
      case "narrow":
        return -device.small / 4
      case "normal":
      default:
        return -device.medium / 2
    }
  }}px;
  }

  @media ${device.small} {
    margin: 0
    ${props => {
    switch (props.gutter) {
      case "none":
        return 0
      case "narrow":
        return -device.small / 4
      case "normal":
      default:
        return -device.small / 2
    }
  }}px;
  }
`

export const Col = styled.div`
  flex: ${props => (props.dontGrow ? "0 0" : "1 0")};
  padding: 0
    ${props => {
    switch (props.gutter) {
      case "none":
        return 0
      case "narrow":
        return device.small / 4
      case "normal":
      default:
        return device.large / 2
    }
  }}px;
  position: relative;

  @media ${device.medium} {
    padding: 0
    ${props => {
    switch (props.gutter) {
      case "none":
        return 0
      case "narrow":
        return device.small / 4
      case "normal":
      default:
        return device.medium / 2
    }
  }}px;
  }

  @media ${device.small} {
    ${props => !props.dontGrow && "width: 100%;"}
    padding: 0
    ${props => {
    switch (props.gutter) {
      case "none":
        return 0
      case "narrow":
        return device.small / 4
      case "normal":
      default:
        return device.small / 2
    }
  }}px;
  }
`

export const IntroRow = styled(Row)`
  @media ${device.small} {
    flex-wrap: wrap;
  }
`

export const IntroCol = styled(Col)`
  ${props => props.hideOnDesktop && "display: none;"}
  padding: ${({ gutterNarrow }) => (gutterNarrow ? "5px" : "25px")};
  @media ${device.small} {
    ${props => props.hideOnMobile && "display: none;"}
    ${props => props.hideOnDesktop && "display: block;"}
    padding: 0;
    flex: 0 100%;
    margin-bottom: ${props => props.theme.parentPadding};

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

// const IntroImage = styled(Img)`
export const IntroImage = styled.img`
  width: 100%;
  display: block;
  margin-bottom: -1px;
`

export const IntroWrapper = styled.div`
  max-width: 920px;
  text-align: ${({ centered }) => (centered ? "center" : "inherit")};
  margin: ${({ centered }) => (centered ? "0 auto" : "0")};
  p {
    line-height: 1.8;
  }
  strong {
    font-weight: lighter;
    color: ${({ theme }) => theme.primaryColor} !important;
  }
`

export const TopHeader = styled.div`
  color: ${props => props.theme.primaryColor};
  text-transform: uppercase;
  letter-spacing: 0.2rem;

  @media ${device.medium} {
  }

  @media ${device.small} {
  }
`

const H1smaller = css`
  font-size: 19px;
  line-height: 1.1;
  margin: 0 0 20px;

  @media ${device.medium} {
    font-size: 16px;
  }

  @media ${device.small} {
    font-size: 13px;
    line-height: 1.2;
  }
`

const H1secondary = css`
  font-size: 17px;
  line-height: 1.1;
  margin: 0 0 20px;

  @media ${device.medium} {
    font-size: 14px;
  }

  @media ${device.small} {
    font-size: 11px;
    line-height: 1.2;
  }
`

export const IntroHeadline = styled.div`
  ${({ secondary }) => (secondary ? H1secondary : H1smaller)}
`

export const MaxWidthWrapper = styled.div`
  margin: 0 auto;
  padding: 0 60px;
  max-width: 1200px;
  @media ${device.medium} {
    padding: 0 30px;
  }
`

export const Section = styled.div`
  background: ${props => props.background || "transparent"};
  p {
    font-size: 18px;
    font-weight: lighter;
    color: ${({ theme }) => theme.headerLinkColor};
  }
  @media ${device.small} {
    text-align: center;
  }
`

export const SecondarySection = styled(Section)`
  background: ${props => props.theme.secondaryColor};
`

export const Spacer = styled.div`
  height: ${props => (props.small ? props.theme.parentPadding : props.theme.parentPaddingX2)};

  @media ${device.medium} {
    height: ${props => (props.small
    ? props.theme.parentPadding / 2
    : props.theme.parentPadding)}px;
  }

  @media ${device.small} {
    height: ${props => (props.small
    ? props.theme.parentPadding / 4
    : props.theme.parentPadding)}px;
  }
`

export const SectionSpacer = styled.div`
  padding-top: 2rem;

  @media ${device.medium} {
    padding-top: 1rem;
  }
`
