import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import ImmutablePropTypes from "react-immutable-proptypes"
import CompanySelect from "../../components/Company/CompanySelect"
import { authSetCompanyId } from "../../redux/modules/auth"
import { getSelectedCompany } from "../../redux/modules/companies"

const CompanySelectContainer = ({
  style = {}, onCompanySelected, onCompanyChange = null, companies = undefined, selectedCompany = null
}) => {
  const handleCompanySelected = (option) => {
    onCompanySelected(option.value)
    if (onCompanyChange) {
      onCompanyChange(option.value)
    }
  }

  const ids = companies.get("ids")
  const companyArray = ids.size ? ids.map(id => companies.get(id))
    .toJS() : []
  return (
    <div style={{ ...style }}>
      <CompanySelect
        onCompanySelected={handleCompanySelected}
        companies={companyArray}
        selectedCompany={selectedCompany}
      />
    </div>
  )
}

CompanySelectContainer.propTypes = {
  onCompanySelected: PropTypes.func.isRequired,
  companies: ImmutablePropTypes.map,
  selectedCompany: ImmutablePropTypes.map,
  style: PropTypes.object,
  onCompanyChange: PropTypes.func, // parent can pass this function to call back when a new option is selected
}

function mapDispatchToProps(dispatch) {
  return ({
    onCompanySelected: companyId => dispatch(authSetCompanyId(companyId)),
  })
}

function mapStateToProps(state) {
  return {
    companies: state.get("companies"),
    selectedCompany: getSelectedCompany(state),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanySelectContainer)
