import React from "react"
import PropTypes from "prop-types"

import {
  areExternalPackagesLoading,
  areExternalServicesLoading,
  getAllExternalPackages,
  getAllExternalServices,
  getAllServiceDescriptions,
  getCompanyServices,
  getExternalPackages,
  getExternalServicesGroupedByCategory,
  getServiceDescriptionsGroupedByCategory,
  isServicesDescriptionsLoading,
} from "../../redux/modules/services"
import { SearchableItemsSelect } from "../../components/Form"
import Label from "../../components/Form/Label"

/**
 * Searchable Select Box for inner package services, externalServices, serviceDescriptions and externalPackages.
 */
const ServiceSelect = ({ type, companyId, ...rest }) => {
  const renderInnerServiceSelectBox = () => {
    const innerServiceItemsSelector = (state) => {
      return getCompanyServices(companyId, state)
    }

    return (
      <SearchableItemsSelect
        {...rest}
        label={<Label>Add services to this group</Label>}
        name="serviceSelectedToAddToPackage"
        itemsSelector={innerServiceItemsSelector}
        formatItem={service => ({ value: service.get("id"), label: service.getIn(["serviceDescription", "name"]) })}
      />
    )
  }

  const renderExternalServiceSelectBox = () => (
    <SearchableItemsSelect
      {...rest}
      grouped
      label="Service"
      fetchItemsAction={() => getAllExternalServices(companyId)}
      itemsSelector={state => getExternalServicesGroupedByCategory(state)}
      loadingSelector={state => areExternalServicesLoading(state)}
    />
  )

  const renderExternalPackageBox = () => (
    <SearchableItemsSelect
      {...rest}
      label="Package"
      fetchItemsAction={() => getAllExternalPackages(companyId)}
      itemsSelector={state => getExternalPackages(state)}
      loadingSelector={state => areExternalPackagesLoading(state)}
    />
  )

  const renderServiceDescriptionsBox = () => (
    <SearchableItemsSelect
      {...rest}
      fetchItemsAction={() => getAllServiceDescriptions(companyId)}
      itemsSelector={state => getServiceDescriptionsGroupedByCategory(state)}
      grouped
      loadingSelector={state => isServicesDescriptionsLoading(state)}
    />
  )

  switch (type) {
    case "innerPackageServices":
      return renderInnerServiceSelectBox()
    case "externalService":
      return renderExternalServiceSelectBox()
    case "externalPackage":
      return renderExternalPackageBox()
    case "serviceDescriptions":
      return renderServiceDescriptionsBox()
    default:
      console.error(`No Searchable select implemented for type: ${type}`)
      return <></>
  }
}

ServiceSelect.propTypes = {
  type: PropTypes.oneOf(["innerPackageServices", "externalService", "externalPackage", "serviceDescriptions"]).isRequired,
  companyId: PropTypes.string.isRequired,
}

export default ServiceSelect
