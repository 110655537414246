import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import SearchInput from "../../components/Faqs/SearchInput"
import { TopicLink, QuestionLink } from "../../components/Faqs/FaqLink"
import FaqsContentHoc from "../../hocs/FaqsContentHoc"
import { Link, useParams } from "react-router-dom"

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const TopicBox = styled.div`
  max-width: 400px;
  width: 100%;
  text-align: left;
  padding: 15px;
  a {
    color: ${props => props.theme.headerBg};
    text-decoration: none;
    &:hover {
      color: ${props => props.theme.linkColor};
    }
  }
`

const Title = styled.h1`
  text-align: center;
  flex: 1;
  margin: 0px 0px 20px 0px;
  font-weight: normal;
`

const FaqsHome = ({
  faqTopics = []
}) => {

  const takeFaqsOfTopic = (topic, amount) => {
    const topicFaqs = topic.faqs
    if (topicFaqs.length > amount) {
      return topicFaqs.slice(0, amount)
    }
    return topicFaqs
  }

  return (
    <div>
      <Title>How Can We Help You?</Title>
      <SearchInput />
      <Container>
        <h3 style={{ textAlign: "center", width: "100%", fontWeight: "normal" }}>
          You can also browse the topics below to find what you are looking for.
        </h3>
        {faqTopics.map(topic => (
          <TopicBox key={topic.slug}>
            <TopicLink topic={topic} size="h2" />
            {takeFaqsOfTopic(topic, 3).map(faq => (
              <QuestionLink faq={faq} key={faq.id} topic={topic} size="h4" />
            ))}
            <Link to={`/help/faqs/${topic.slug}`}>View all questions</Link>
          </TopicBox>
        ))}
      </Container>
    </div>
  )
}

FaqsHome.propTypes = {
  faqTopics: PropTypes.array,
  navigateTo: PropTypes.func.isRequired, // from HOC
}

export default FaqsContentHoc(FaqsHome)
