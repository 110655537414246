import React, { useState } from "react"
import DateRangePicker from "@wojtekmaj/react-daterange-picker"
import { connect } from "react-redux"
import styled from "styled-components"
import PropTypes from "prop-types"

import { device } from "../../components/Responsive/Responsive"

import {
  statisticsRefresh,
  statisticsUpdateRange,
  statisticsUpdateCompanyName,
  getStatisticsRange,
  getStatisticsCompanyName,
  getStatisticsCompanies,
  statisticsDownload,
  STATISTICS_KEY_COMPANY,
  STATISTICS_KEY_FRANCHISE,
} from "../../redux/modules/statistics"
import { getSelectedCompanyId } from "../../redux/modules/auth"

import { ChevronIcon } from "../../components/Icons/Icons"
import StyledSelectField from "../../components/Form/StyledSelectField"

const StatsControl = styled.div`
  padding: 20px;
  display: flex;

  @media ${device.mobile} {
    flex-direction: column;
  }
`

const StatsRange = styled.ul`
  padding: 0;
  margin: 0;
  text-align: right
`

const StatsDate = styled.li`
  display: inline;
  list-style-type: none;
  color: #ef609f;
  padding: 0px 10px;
  cursor: pointer;
  font-size: 15px;
  height: 42px;
  line-height: 42px;

  &:first-child {
    padding: 0px 10px 0px 0px;
  }

  &:last-child {
    padding: 0px 0px 0px 10px;
  }
`

const StatsDownload = styled.span`
  color: #ef609f;
  cursor: pointer;
  font-size: 15px;
  height: 42px;
  line-height: 42px;

  &:hover {
    text-decoration: underline;
  }

  @media ${device.mobile} {
    text-align: right;
  }
`

const StatsDownloadIcon = styled(ChevronIcon)`
  width: 10px;
  height: 10px;
  margin-left: 8px;
`

const StatsCompanyNameSelect = styled(StyledSelectField)`
  width: 280px;
  margin-right: 10px;

  @media ${device.mobile} {
    width: 100%;
    margin-right: 0px;
  }
`

const StatsForm = ({companies, onCompanyNameChange, onRangeSelected, range = null, selectedCompanyId = null, statisticsDownload, statisticsRefresh, type}) => {

  // Handle company keyword search (franchise only)
  const [companyName, setCompanyName] = useState(null)

  const handleCompanyNameChange = (name) => {
    if (!name) {
      return;
    }

    setCompanyName(name)
    onCompanyNameChange(name)

    statisticsRefresh(STATISTICS_KEY_FRANCHISE, range, null, name)
  }

  const [dateRange, setDateRange] = useState([null, null])

  // Take range, pass it to range picker and back to redux (both types)
  const handleDateRangeChange = (range) => {
    setDateRange(range)
    onRangeSelected(range)

    statisticsRefresh(type, range, selectedCompanyId, companyName)
  }

  // Handle company changes (company only)
  React.useEffect(() => {
    statisticsRefresh(STATISTICS_KEY_COMPANY, dateRange, selectedCompanyId, null)
  }, [selectedCompanyId])

  // Begin download of CSV (franchise only)
  const handleDownload = () => {
    statisticsDownload(STATISTICS_KEY_FRANCHISE, range)
  }

  // Default range for range picker
  const defaultFromDate = new Date()
  defaultFromDate.setDate(defaultFromDate.getDate() - 7)

  const defaultToDate = new Date()

  // Is a date range currently set?
  const hasDateRange = dateRange && dateRange[0] && dateRange[1]

  // Format companies array into array of options digestable by React select
  const options = companies.toJS().map(c => { return { label: c.name, value: c.name } })

  return (
    <React.Fragment>
      <div>
        <StatsControl>
          {type === STATISTICS_KEY_FRANCHISE && (
          <StatsDownload onClick={() => handleDownload()} role="button">
            Download CSV
            <StatsDownloadIcon />
          </StatsDownload>
          )}
          <div style={{ flex: 1 }} />

          {type === STATISTICS_KEY_FRANCHISE && (
            <StatsCompanyNameSelect
              name='companyName'
              value={companyName ?? ''}
              onChange={handleCompanyNameChange}
              placeholder="Enter salon name..."
              options={options}
            />

          )}
          <StatsRange>
            {!hasDateRange ? (
              <StatsDate onClick={() => { handleDateRangeChange([defaultFromDate, defaultToDate]) }}>
                All Time
                <StatsDownloadIcon />
              </StatsDate>
            ) : (
              <li style={{ height: 42, display: "flex" }}>
                <DateRangePicker rangeDivider=" to " onChange={handleDateRangeChange} value={dateRange} />
              </li>
            )}
          </StatsRange>

        </StatsControl>

      </div>

    </React.Fragment>

  )
}

StatsForm.propTypes = {
  onRangeSelected: PropTypes.func.isRequired,
  selectedCompanyId: PropTypes.string,
  onCompanyNameChange: PropTypes.func.isRequired,
  statisticsRefresh: PropTypes.func.isRequired,
  statisticsDownload: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  range: PropTypes.array,
  companies: PropTypes.object,
}

const mapStateToProps = (state, ownProps) => ({
  range: getStatisticsRange(ownProps.type, state),
  companies: getStatisticsCompanies(ownProps.type, state),
  companyName: getStatisticsCompanyName(ownProps.type, state),
  selectedCompanyId: getSelectedCompanyId(state),
})

function mapDispatchToProps(dispatch, ownProps) {
  return ({
    onRangeSelected: range => dispatch(statisticsUpdateRange(ownProps.type, range)),
    onCompanyNameChange: name => dispatch(statisticsUpdateCompanyName(ownProps.type, name)),
    statisticsRefresh: (key, dateRange, companyId, companyName) => dispatch(statisticsRefresh(key, dateRange, companyId, companyName)),
    statisticsDownload: (key, range) => dispatch(statisticsDownload(key, range)),
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(StatsForm)
