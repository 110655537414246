import React, { useEffect, useState } from "react"
import DayPicker from "../StyledDayPicker"
import PropTypes from "prop-types"
import moment from "moment-timezone"

const DatePicker = ({ disabled = false, onChange = () => {}, form, field: { value }, ...props }) => {
  const selectedDay = moment(value, "YYYY-MM-DD").toDate();

  const handleDayClick = (day, modifiers, evt) => {
    if (!disabled && !evt.target.classList.contains("DayPicker-Day--disabled")) {
      onChange(moment(day).format("YYYY-MM-DD"));
    }
  }

  return null !== selectedDay && (
    <div>
      <DayPicker
        onDayClick={handleDayClick}
        selected={selectedDay}
        {...props}
      />
    </div>
  )
}

DatePicker.propTypes = {
  field: PropTypes.shape({
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func,
  }),
  disabled: PropTypes.bool,
}

export default DatePicker