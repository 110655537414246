import React from 'react'
import styled from 'styled-components'

const Badge = styled.div`
  background: #343434;
  font-size: 15px;
  border-radius: 5px;
  font-weight: bold;
  padding: 5px 8px;
  opacity: 65%;
  color: white;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  div + div {
    margin-left: 5px;
  }
`

const LiveCircle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.primaryColor};
`

const ServiceStatusBadge = ({ live }) => {
  if(live) {
    return <Badge><div><LiveCircle /></div><div>Live</div></Badge>
  }
  return <Badge>Not Bookable</Badge>
}

export default ServiceStatusBadge
