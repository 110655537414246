import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { device } from "../Responsive/Responsive"

const Section = styled.div`
    width: 50%;
    flex: 1;
    min-width: 400px;
    min-height: 174px;
    box-sizing: border-box;
    background-color: ${({ theme, $darkBg }) => $darkBg ? theme.borderColor : ""};
    @media ${device.mobile} {
      min-width: 100%;
    }
    @media ${device.desktop} {
      max-width: 60%;
    }
`

const Content = styled.div`
  padding: ${({ theme, $noPadding }) => $noPadding ? 0 : theme.parentPadding}; 
`

const Title = styled.span`
  background-color: ${({ theme }) => theme.labelDarkColor};
  color: ${({ theme }) => theme.primaryContrastColor};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 3px 8px;
  display: block;
  height: 18px;
  line-height: 18px;
  transform: translateY(-24px);
  float: left;
`

const InnerFormSection = ({ title, children, darkBg, noPadding }) => (
  <Section $darkBg={darkBg}>
    {title && <Title>{title}</Title>}
    <Content $noPadding={noPadding}>{children}</Content>
  </Section>
)

InnerFormSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  darkBg: PropTypes.bool,
  noPadding: PropTypes.bool,
}

export default InnerFormSection
