import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { device, ResponsiveDefault } from "../Responsive/Responsive"
import { ListTitleContainer, ListTitleText } from "./ListWrapper"
import { CupOfTeaIcon } from "../Icons/Icons"

const CupOfTea = styled(CupOfTeaIcon)`
  fill: #f067a6;
  flex-grow: 0;
  flex-shrink: 0;
`

const Message = styled.div`
    min-height: 20px;
    padding: 20px 20px 20px 0px;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex: 1;
    background-color: #ffffff;
    color: #575757;
    font-weight: 300;
    font-size: 1.2em;
    cursor: pointer;
    outline: 0;

    @media ${device.mobile} {
        border-top: 1px solid #ececec;
        border-bottom: 1px solid #ececec;
    }
    @media ${device.tablet}, ${device.desktop}  {
        border: 1px solid #ececec;
    }
`

const Parent = styled.div`
    @media ${device.tablet}, ${device.desktop}  {
        padding: 20px;
    }

    @media ${device.mobile} {
        padding: 20px 0px;
    }
`

const FillerWrapper = ({
                         children,
                         title = null,
                         style = {},
                         className = "",
                       }) => {
  return (
    <Parent className={className} style={{ ...style }}>
      {title
        && (
          <ResponsiveDefault>
            <div>
              <ListTitleContainer>
                <ListTitleText className="MainTitle">{title}</ListTitleText>
              </ListTitleContainer>
            </div>
          </ResponsiveDefault>
        )
      }

      <Message>
        <CupOfTea style={{ marginLeft: "20px" }} />
        <div style={{ display: "inline-block", marginLeft: "20px" }}>
          {children}
        </div>
      </Message>
    </Parent>
  )
}

FillerWrapper.propTypes = {
  title: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export default FillerWrapper
