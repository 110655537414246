import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { device } from "../Responsive/Responsive"
import PaddingContent from "./Padding"

const Container = styled.div`
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px #ececec;
    padding: 2rem;
    color: #575757;
    position: relative;

    @media ${device.mobile} {
        margin-top: 0px;
        padding: 16px;
        padding-top: 32px;
        margin-bottom: 32px;
    }

    @media ${device.tablet}, ${device.desktop} {
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;
    }
`

/*
* This is the wrapper of the main part of the screen when user is logged in.
* Usually below tab menu navigation, and next to the left side menu.
*/
const MainContent = ({ children }) => (
  <PaddingContent>
    <Container>
      {children}
    </Container>
  </PaddingContent>
)

MainContent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
}

export default MainContent
