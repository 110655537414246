import React from "react"
import PropTypes from "prop-types"
import styled, { createGlobalStyle } from "styled-components"
import { FullscreenWrapper } from "../../../components/Wrappers"
import SignUpForm from "../SignUpForm"
import Intro from "./Intro"
import Info from "./Info"
import Benefits from "./Benefits"
import HowItWorks from "./HowItWorks"
import { IntroCol, IntroRow, MaxWidthWrapper, SecondarySection } from "../StyledComponents"
import Footer from "./Footer"

const FranchiseStyles = createGlobalStyle`
  ${({ theme }) => theme.franchiseGlobalStyles};
`

const AvedaSignupPageContainer = () => {
  const primaryColor = '#000'
  const cookiePrivacyUrl = 'aveda/cookie-policy'
  const termsUrl = 'aveda/terms'
  const accountManagerPlaceholder = 'Your Salon Development Manager'

  return (
    <FullscreenWrapper>
      <FranchiseStyles />
      <Intro />
      <SignUpForm
        accountManagerPlaceholder={accountManagerPlaceholder}
        buttonBackgroundColour={primaryColor}
        termsUrl={termsUrl}
        title='Sign up now'
        headline='Get Started!'
      />
      <Info />
      <Benefits />
      <HowItWorks />
      <Footer cookiePrivacyUrl={cookiePrivacyUrl} termsUrl={termsUrl} />
    </FullscreenWrapper>
  )
}

export default AvedaSignupPageContainer
