import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { device } from "../Responsive/Responsive"

import { TopicLink } from "./FaqLink"
import { useNavigate } from "react-router-dom"

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`

const SideBar = styled.div`
  display: block;
  padding: 0 30px;
  border-left: solid 2px #ddd;
  margin-left: 15px;
  min-width: 95px;
  h4 {
    font-weight: 400;
  }
  @media ${device.mobile} {
    display: none;
  }
`

const ContainerWithSideBar = ({ children, topics = [] }) => {
  return (
  <Container>
    {children}
    <SideBar>
      <h3>FAQ Topics</h3>
      { topics.map((topic) => <TopicLink topic={topic} key={topic.slug} size="h4" />) }
    </SideBar>
  </Container>
)
}

ContainerWithSideBar.propTypes = {
  children: PropTypes.element.isRequired,
  topics: PropTypes.array,
}

export default ContainerWithSideBar
