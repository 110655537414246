import React from "react"
import ImmutablePropTypes from "react-immutable-proptypes"
import { connect } from "react-redux"

import { getCompanyPrivacyPolicy } from "../../redux/modules/invite"
import LegalContent from "../../components/Legals/LegalContent"

const HelpPrivacyPage = ({ companyPrivacyPolicy = null }) => {
  const companyPrivacyPolicyData = companyPrivacyPolicy ? companyPrivacyPolicy.get("data") : null

  return <LegalContent content={companyPrivacyPolicyData} />
}

HelpPrivacyPage.propTypes = {
  companyPrivacyPolicy: ImmutablePropTypes.map,
}

const mapStateToProps = state => ({
  companyPrivacyPolicy: getCompanyPrivacyPolicy(state),
})

export default connect(mapStateToProps)(HelpPrivacyPage)
