import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import Label from "./Label"
import InputError from "./InputError"
import Tooltip from "../Help/Tooltip"
import { ChevronIcon } from "../Icons/Icons"

const ThemedSelectParent = styled.div`
  position: relative;
  display: block;
  flex: 1;
  ${ChevronIcon} {
    position: absolute;
    top: 12px;
    right: 15px;
  }
`

const ThemedSelect = styled.select`
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  background: white;
  border-radius: 25px;
  color: ${({ theme }) => theme.labelDarkColor};
  font-weight: 700;
  padding: 10px 40px 10px 15px !important;
  width: 100%;

  font-size: 15px;
  font-family: 'Museo-Sans','sans-serif';
  outline: none;
  border: 1px solid ${({ theme }) => theme.borderColor};
  box-sizing: border-box;
`

const SelectWrapper = styled.div`
  label {
    display: block;
    margin-bottom: 10px;
    font-size: 10px;
  }
`

const defaultChildren = [<option />, <option value="default">Default</option>]

const SelectBox = ({children = defaultChildren, defaultValue = undefined, errors, label, name, tooltipId = null, tooltipContent = null, ...rest}) => {
  const select = (
    <ThemedSelectParent>
      <ThemedSelect name={name} {...rest}>
        {children}
      </ThemedSelect>
      <ChevronIcon />
    </ThemedSelectParent>
  )

  return (
    <div>
      {label && <Label htmlFor={name}>{label}</Label>}
      <div style={ label ? { marginTop: "16px" } : {}}>
        <div style={{ display: "flex" }}>
            {
              label ?
                  <SelectWrapper>
                    <Label htmlFor={name}>{label}</Label>
                    <div>
                      {select}
                    </div>
                  </SelectWrapper>
                : select
            }
          {tooltipId && tooltipContent && <Tooltip id={tooltipId}>{tooltipContent}</Tooltip>}
        </div>
        {errors[name] && <div><InputError>{errors[name]}</InputError></div>}
      </div>
    </div>
  )
}


SelectBox.propTypes = {
  children: PropTypes.array,
  defaultValue: PropTypes.node,
  id: PropTypes.string,
  label: PropTypes.string,
  tooltipContent: PropTypes.element,
  tooltipId: PropTypes.string,
}

export default SelectBox
