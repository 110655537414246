import PropTypes from "prop-types"
import Immutable from "immutable"
import React, { Children } from "react"
import styled from "styled-components"
import ImmutablePropTypes from "react-immutable-proptypes"
import { Field } from "formik"

import {
  Button,
  Checkbox,
  FormRow,
  InnerFormSection,
  InlineError, RadioButton,
} from "../../components/Form"
import ServiceSelect from "./ServiceSelect"
import { TwoColumnFormRow } from "../../components/Form/TwoColumnsFormRow"
import { H3 } from "../../components/Typography"
import Tooltip from "../../components/Help/Tooltip"
import Label from "../../components/Form/Label"
import Badge from "../../components/Form/Badge"
import { Formik } from "formik"
import StyledSelectField from "../../components/Form/StyledSelectField"
import { components } from "react-select"

const PackageServicesWrapper = styled.div`
  display: flex;;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;
`

export const EXISTING_PACKAGE = "0"
export const NEW_PACKAGE = "-1"


const ServiceForm = ({
                       changeFormField,
                       innerServices = Immutable.List(),
                       initialValues,
                       loading,
                       onSubmit,
                       selectedCompany,
                       serviceDescriptionId = null,
                       serviceDescriptions = Immutable.Map(),
                       serviceName,
                       serviceImage
                     }) => {

  if (!selectedCompany) return null

  const packageTypeOptions = [
    // { label: 'How do you want to create this package?', value: ''},
    { label: 'Link to an existing package', value: EXISTING_PACKAGE},
    { label: 'Create my own package', value: NEW_PACKAGE},
  ]

  return (
    <Formik
      initialValues={{
        visible: initialValues?.visible,
        externalType: initialValues?.externalType,
        externalServiceId: initialValues?.externalId,
        externalPackageId: initialValues?.externalPackage,
        externalCategoryId: initialValues?.externalCategoryId,
        externalName: initialValues?.externalName,
        packageType: initialValues?.packageType,
        innerPackageServices: initialValues?.servicePackageId ?? [],
    }}
      onSubmit={onSubmit}
    >{({ values, handleSubmit, setFieldValue, errors }) => {

      const renderServiceFields = (
        <FormRow $labelTop fullWidth>
          <Field
            as={ServiceSelect}
            name="externalServiceId"
            type="externalService"
            companyId={selectedCompany.get("id")}
            onChange={(selectedItem) => {
              setFieldValue('externalServiceId', selectedItem.get('id'));
              // Clear any residual, package specific, values
              setFieldValue('externalName', null);
              setFieldValue('externalCategoryId', null);
              setFieldValue('externalPackageId', null);
            }}
          />
        </FormRow>
      )

      const InnerPackageServiceValueContainer = ({ children, ...props }) => {
        const { ValueContainer, Placeholder } = components;

        return (
          <>
            { props?.selectProps?.placeholder && (
              <Placeholder {...props} isFocused={props.isFocused}>
                {props?.selectProps?.placeholder}
              </Placeholder>
            )}
            <ValueContainer {...props}>

              {Children.map(children, child =>
                child && child.type !== Placeholder ? child : null
              )}
            </ValueContainer>
          </>
        )
      }

      const renderPackageFields = (
        <>
          <FormRow fullWidth $width="auto">
            <Field
              as={StyledSelectField}
              label="Service Group Type"
              name="packageType"
              options={packageTypeOptions}
              placeholder={ values.packageType ? '' : 'How do you want to create this package?'}
              onChange={(value) => setFieldValue('packageType', value)}
            />
          </FormRow>
          {values.packageType === EXISTING_PACKAGE && (
            <FormRow fullWidth $width="auto">
              <Field
                as={ServiceSelect}
                name="externalServiceId"
                type="externalPackage"
                companyId={selectedCompany.get("id")}
                // onChange={onExternalServiceChange}
                onChange={(selectedItem) => {
                  setFieldValue('externalServiceId', selectedItem.get('id'));
                  setFieldValue('externalName', selectedItem.get('name'));
                  setFieldValue('externalCategoryId', selectedItem.get('category')?.id);
                }}
              />
            </FormRow>
          )}
          {values.packageType === NEW_PACKAGE && (
            <>
              <FormRow fullWidth $width="auto">
                <Field
                  as={ServiceSelect}
                  name="innerPackageServices"
                  type="innerPackageServices"
                  onChange={(items) => setFieldValue('innerPackageServices', items.map((item) => item.get('id')))}
                  isMulti
                  companyId={selectedCompany.get("id")}
                  components={{
                    ValueContainer: InnerPackageServiceValueContainer
                  }}
                  placeholder={values.innerPackageServices?.length > 0 ? `${values.innerPackageServices?.length} Selected` : ''}
                />
              </FormRow>
              <FormRow fullWidth>
                <PackageServicesWrapper>
                  {innerServices.map(service => (
                    <Badge
                      key={service.get("id")}
                      onRemove={() => changeFormField("servicePackageId", innerServices.filter(s => s.get("id") !== service.get("id")).map(s => s.get("id")))}
                    >
                      {service.getIn(["serviceDescription", "name"])}
                    </Badge>
                  )).toJS()}
                </PackageServicesWrapper>
              </FormRow>
            </>)}
        </>
      )

      return (

      <TwoColumnFormRow>
        <InnerFormSection title="Aveda Service" darkBg>
          <H3>{serviceName}</H3>
          <FormRow $width="auto">
            <Field
              as={Checkbox}
              label="Visible"
              name="visible"
              errors={errors}
            />
          </FormRow>
          <FormRow fullWidth $align="flex-start">
            {serviceImage && <img style={{ maxWidth: 240 }} src={serviceImage} alt="" />}
            {(serviceDescriptionId && serviceDescriptions && serviceDescriptions.has(serviceDescriptionId)) && (
              <div style={{ padding: "1rem", paddingTop: "0", width: "100%" }}>{serviceDescriptions.get(serviceDescriptionId).get("about")}</div>
            )}
          </FormRow>
        </InnerFormSection>
        <InnerFormSection title="Your Salon Service">
          <FormRow>
            <H3>Select the service from your online booking list below</H3>
          </FormRow>
          <FormRow $width="auto" $smallMarginTop>
            <Label>Service or Service Group?</Label>
            <Tooltip id="tooltip-service-type">
              <p>
                Select
                {" "}
                <span>Service</span>
                {" "}
                if it is single service (a cut, blow dry, colour or treatment)
              </p>
              <p>
                Select
                {" "}
                <span>Service Group</span>
                {" "}
                if it will be a package made up of single services (eg colour, toner and cut).
              </p>
            </Tooltip>
          </FormRow>
          <FormRow>
            <Field
              component={RadioButton}
              type="radio"
              name="externalType"
              label="Service"
              value="service"
            />
            <Field
              component={RadioButton}
              type="radio"
              name="externalType"
              label="Service Group"
              value="package"
            />
          </FormRow>
          { values.externalType === "package" && renderPackageFields }
          { values.externalType === "service" && renderServiceFields }
          <FormRow />
          <FormRow fullWidth $width="auto">
            <div style={{ textAlign: "right"}}><Button disabled={loading} onClick={handleSubmit} type="submit">Save Service</Button></div>
          </FormRow>
        </InnerFormSection>
      </TwoColumnFormRow>
    )}}
    </Formik>
  )
}

ServiceForm.propTypes = {
  selectedCompany: ImmutablePropTypes.map.isRequired,
  serviceDescriptions: ImmutablePropTypes.map,
  serviceDescriptionId: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  serviceId: PropTypes.string, // id of the service currently being editted or null when creating a new one. (provided by parent component)
  innerServices: ImmutablePropTypes.list,
  employees: ImmutablePropTypes.map,
  errorMessage: PropTypes.string,
  defaultPricePlaceholder: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  serviceName: PropTypes.string,
  serviceImage: PropTypes.string
}

const validate = (values) => {
  const errors = {}
  if (!values.has("externalId") || values.get("externalId") === "0") {
    errors.externalId = "This field is required"
  }
  if (!values.has("serviceDescription") || !values.hasIn(["serviceDescription", "id"])) {
    errors.serviceDescription = { id: "This field is required" }
  }

  return errors
}

export default ServiceForm
