/* eslint-disable no-unused-vars,prefer-destructuring,import/no-extraneous-dependencies */
import "regenerator-runtime/runtime"
import {
  all, call, put, select, takeEvery, takeLatest
} from "redux-saga/effects"
import rg4js from "raygun4js"
import { subscribe } from "pusher-redux"
import moment from "moment-timezone"
import {
  REFRESH_ALL,
  REFRESH_BOOKINGS,
  REFRESH_STATISTICS,
  REFRESH_EMPLOYEES,
  REFRESH_SERVICES,
  REFRESH_INBOX,
  API_ROLE_COMPANY,
} from "../config/constants"
import { PUSHER_EVENT } from "../redux/modules/pusher"
import router from "../config/router"

import {
  apiCompanies,
  apiCreateContact,
  apiCreateEmployee,
  apiDeleteContact,
  apiDeleteEmployee,
  ApiException,
  apiGetBookings,
  apiGetCompanyServices,
  apiGetCompanyStats,
  apiGetFranchiseStats,
  apiGetFranchiseStatsCsv,
  apiGetEmployees,
  apiGetEmployeesExternalData,
  apiRescheduleBooking,
  apiSaveCompany,
  apiSaveCompanyHours,
  apiSaveContact,
  apiSaveEmployee,
  apiSavePreference,
  apiCreateService,
  apiSaveService,
  apiDeleteService,
  apiSendReport,
  apiCancelBooking,
  apiGetInvite,
  apiRegisterInvite,
  apiSendAuthCode,
  apiAuthRegister,
  apiAcceptInviteTerms,
  apiInviteSoftwareCheck,
  apiInviteComplete,
  apiUpdatePassword,
  apiForgotPassword,
  apiRefreshInbox,
  apiGetServiceDescriptions,
  apiGetExternalServices,
  apiGetExternalPackages,
  apiSubmitCompanyApprovalRequest,
} from "../api/api"
import {
  SEND_AUTH_CODE_REQUEST,
  AUTH_SET_COMPANY_ID,
  REGISTER_NEW_USER_REQUEST,
  UPDATE_PASSWORD,
  FORGOT_PASSWORD,
  AUTH_FAILURE,
  AUTH_FAILURE_CHECK,
  getCredentials,
  getSelectedCompanyId,
  getRoles,
  authAttempt,
  sendAuthCodeFailure,
  sendAuthCodeSuccess,
  clearAccountDataInCookie,
  registerNewUserFailure,
  registerNewUserSuccess,
  updatePasswordSuccess,
  updatePasswordFailure,
  authRestore,
  authLogout,
  authFailureCheck,
  forgotPasswordSuccess,
  forgotPasswordFailure,
} from "../redux/modules/auth"

import {
  BOOKINGS_RESCHEDULE_BOOKING,
  BOOKINGS_CANCEL_BOOKING,
  bookingsLoadingFail,
  bookingsLoadingSuccess,
  bookingsStartLoading,
  bookingsLocalRescheduleSelectedBooking,
  bookingsLocalSaveSelectedBooking,
  bookingsLocalCancelSelectedBooking,
  bookingsRescheduleBookingFail,
  bookingsRescheduleBookingStart,
  bookingsRescheduleBookingSuccess,
  bookingsCancelBookingFail,
  bookingsCancelBookingStart,
  bookingsCancelBookingSuccess,
  // fetchAllBookingsSuccess,
  // fetchAllBookingsFailure,
} from "../redux/modules/bookings"
import {
  SERVICES_SAVE_SERVICE,
  SERVICES_DELETE_SERVICE,
  saveServiceFail,
  saveServiceSuccess,
  servicesDeleteServiceFail,
  servicesDeleteServiceSuccess,
  servicesLoadingFail,
  servicesLoadingSuccess,
  servicesStartLoading,
  GET_SERVICES_DESCRIPTIONS,
  GET_SERVICES_DESCRIPTIONS_SUCCESS,
  GET_SERVICES_DESCRIPTIONS_FAIL,
  GET_EXTERNAL_SERVICES,
  GET_EXTERNAL_SERVICES_SUCCESS,
  GET_EXTERNAL_SERVICES_FAIL,
  GET_EXTERNAL_PACKAGES,
  GET_EXTERNAL_PACKAGES_SUCCESS,
  GET_EXTERNAL_PACKAGES_FAIL,
  getAllServicesSlugMap,
  getServiceSuccess,
  singleServiceStartLoading,
  singleServiceLoadingSuccess,
} from "../redux/modules/services"
import {
  CONTACT_DELETE,
  CONTACTS_SAVE_CONTACT,
  CONTACT_EDIT,
  CONTACT_ADD_NEW,
  contactsAdd,
  contactsSaveStart,
  contactsSaveSuccess,
  contactsSaveFail,
  contactsDeleteContactFail,
  contactsDeleteContactStart,
  contactsDeleteContactSuccess,
  selectContactForEditing,
  resetContactEditForm, CONTACT_EDIT_RESET,
} from "../redux/modules/contacts"
import {
  COMPANIES_SAVE_COMPANY,
  COMPANIES_SAVE_COMPANY_HOURS,
  companiesSaveCompanyFail,
  companiesSaveCompanyStart,
  companiesSaveCompanySuccess,
  replaceCompany,
  getCompanyIds,
  SUBMIT_COMPANY_APPROVAL_REQUEST,
  submitCompanyApprovalSuccess,
  submitCompanyApprovalFailure,
} from "../redux/modules/companies"
import {
  statisticsReplace,
  statisticsLoadingFail,
  statisticsLoadingSuccess,
  statisticsStartLoading,
  statisticsRefresh,
  STATISTICS_DOWNLOAD,
  STATISTICS_KEY_COMPANY,
  STATISTICS_KEY_FRANCHISE,
} from "../redux/modules/statistics"
import {
  PREFERENCES_SAVE_PREFERENCE,
  PREFERENCES_SEND_REPORT,
  preferencesSavePreferenceFail,
  preferencesSavePreferenceStart,
  preferencesSavePreferenceSuccess,
} from "../redux/modules/preferences"
import {
  EMPLOYEES_DELETE_EMPLOYEE,
  EMPLOYEES_DELETE_FAIL,
  EMPLOYEES_SAVE_EMPLOYEE,
  employeesLoadingFail,
  employeesLoadingSuccess,
  employeesStartLoading,
  EMPLOYEES_SAVE_SUCCESS,
  EMPLOYEES_SAVE_FAIL,
  GET_EMPLOYEES_EXTERNAL_DATA,
  GET_EMPLOYEES_EXTERNAL_DATA_SUCCESS,
  GET_EMPLOYEES_EXTERNAL_DATA_FAIL,
  getAllEmployeesMap,
} from "../redux/modules/employees"

import { apiActive } from "../redux/modules/api"

import { showModal, showToast } from "../containers/Notifications/NotificationsContainer"
import { showDesktop } from "../redux/modules/notifications"
import { bulkInsert } from "../redux/modules/helpers"

import {
  ACCEPT_INVITE_REQUEST,
  GET_INVITE_REQUEST,
  ACCEPTED_INVITE_TERMS_REQUEST,
  INVITE_SOFTWARE_CHECK_REQUEST,
  SOFTWARE_CHECK_RESPONSE,
  getInviteSuccess,
  getInviteFailure,
  registerInviteSuccess,
  registerInviteFailure,
  acceptInviteTermsSuccess,
  acceptInviteTermsFailure,
  inviteSoftwareCheckFailure,
  inviteSoftwareCheckSuccess,
} from "../redux/modules/invite"

import {
  refreshInboxSuccess,
  refreshInboxFailure,
} from "../redux/modules/inbox"

export const MAX_ITEMS_PER_PAGE = 10

const logError = (error) => {
  if (process.env.NODE_ENV === "production") {
    rg4js("send", {
      error,
    })
  } else {
    console.log(JSON.stringify(error))
  }
}

// Contents of booking list search form
const getBookingSearchValues = (state) => state.getIn(["form", "bookingSearch", "values"])

function bookingSearchPayload(selectedCompanyId, bookingSearchValues) {
  return {
    company: selectedCompanyId,
    history: bookingSearchValues ? bookingSearchValues.get("history") : 0,
    user: bookingSearchValues ? bookingSearchValues.get("user") : "",
    offset: 0,
    page: 0,
    limit: MAX_ITEMS_PER_PAGE,
  }
}

function* refreshAll(action) {
  const roles = getRoles()

  if (roles.indexOf(API_ROLE_COMPANY) !== -1) {
    yield put({ type: REFRESH_INBOX })
    yield put({ type: REFRESH_SERVICES })
    yield put({ type: REFRESH_EMPLOYEES })

    const selectedCompanyId = yield select(getSelectedCompanyId)
    const bookingSearchValues = yield select(getBookingSearchValues)

    yield put({ type: REFRESH_BOOKINGS, payload: bookingSearchPayload(selectedCompanyId, bookingSearchValues) })
  }
}

function* pusherEvent(action) {
  const { event, data } = action

  const companyIds = yield select(getCompanyIds)

  /* Ignore any pusher messages for a company we're not an admin for */
  if (!companyIds.contains(data.company_id)) {
    return
  }

  if (event === "company-notification") {
    const message = data.message
    yield put(showDesktop(Date.now()
      .toString(), "Flossie", message))
  } else if (event === "client-refresh") {
    const { items } = data
    for (let i = 0; i < items.length; i += 1) {
      const selectedCompanyId = yield select(getSelectedCompanyId)

      // If the updated company is selected, or all companies are selected, updated company level data
      const refreshCompanyData = (data.company_id === selectedCompanyId || selectedCompanyId === "0" || !selectedCompanyId)

      if (items[i] === "bookings" && refreshCompanyData) {
        yield put({ type: REFRESH_INBOX })

        const bookingSearchValues = yield select(getBookingSearchValues)
        yield put({ type: REFRESH_BOOKINGS, payload: bookingSearchPayload(selectedCompanyId, bookingSearchValues) })
      }

      if (items[i] === "statistics") {
        if (refreshCompanyData) {
          yield put(statisticsRefresh(STATISTICS_KEY_COMPANY, null, selectedCompanyId))
        }

        // Franchise view includes all companies so always update
        yield put(statisticsRefresh(STATISTICS_KEY_FRANCHISE))
      }
    }
  }
}

function* handleAuthFailure(action) {
  const { payload, type } = action

  // Either auth has failed during initial load or some random API response has failed and been sent here to be checked
  if (type === AUTH_FAILURE || (payload.response && payload.response.status && payload.response.status === 401)) {
    yield put(authLogout())
    yield call(router.navigate, '/login')
  }
}

function* refreshEmployees(action, params = {}) {
  const credentials = getCredentials()
  try {
    yield put(apiActive(true))
    yield put(employeesStartLoading())

    const companyParam = {}

    const companyId = yield select(getSelectedCompanyId)
    if (companyId && companyId !== "0") {
      companyParam.company_id = companyId
    }

    const data = yield call(apiGetEmployees, credentials, { ...companyParam, ...params })
    if (Array.isArray(data.data)) {
      const d = { employees: [] }
      for (let i = 0; i < data.data.length; i += 1) {
        const employee = data.data[i]
        employee.company_slug = employee.company.slug
        employee.company_id = employee.company.id
        employee.service_ids = []
        if (employee.services) {
          for (let j = 0; j < employee.services.length; j += 1) {
            employee.service_ids.push(employee.services[j].id)
          }
        }
        d.employees.push(employee)
      }
      yield put(bulkInsert(d))
    }

    yield put(employeesLoadingSuccess())
  } catch (error) {
    yield put(employeesLoadingFail(error.message))
    logError(error)
    yield put(authFailureCheck(error.response))
  } finally {
    yield put(apiActive(false))
  }
}

function parseBookings(bookingArray) {
  const result = {
    bookings: [],
    services: [],
    users: [],
    employees: [],
    products: [],
  }
  for (let i = 0; i < bookingArray.length; i += 1) {
    const booking = bookingArray[i]
    result.bookings.push(booking)
    result.users.push(booking.user_profile)
    result.services.push(Object.assign(booking.service, { company: booking.company, pricing: { default: { price: booking.price_paid } } }))

    if (booking.service.employees) {
      for (let j = 0; j < booking.service.employees.length; j += 1) {
        const employee = booking.service.employees[j]
        employee.company_slug = booking.company.slug
        employee.company_id = booking.company.id
        result.employees.push(employee)
      }
    }

    if (booking.products) {
      for (let j = 0; j < booking.products.length; j += 1) {
        const product = booking.products[j].product
        result.products.push(product)
      }
    }
  }
  return result
}


function* refreshInbox() {
  const credentials = getCredentials()
  try {
    yield put(apiActive(true))

    const params = {}

    const companyId = yield select(getSelectedCompanyId)
    if (companyId && companyId !== "0") {
      params.company_id = companyId
    }
    const response = yield call(apiRefreshInbox, credentials, params)
    if (response.data && response.data.success) {
      yield put(refreshInboxSuccess(response.data))
    }
  } catch (error) {
    yield put(refreshInboxFailure(error.message))
    logError(error)
    yield put(authFailureCheck(error.response))
  } finally {
    yield put(apiActive(false))
  }
}

function* refreshBookings(action) {
  const {
    payload: {
      history = 0, company = 0, user = "", limit, offset, page
    }
  } = action
  const credentials = getCredentials()
  try {
    yield put(apiActive(true))
    yield put(bookingsStartLoading())

    const params = { history, limit, offset }

    if (company > 0) {
      params.company_id = company
    }

    if (user.length > 0) {
      params.user = user
    }

    const response = yield call(apiGetBookings, credentials, params)

    if (response.status === 200) {
      const result = parseBookings(response.data.bookings)

      // we do bulk insert of everything but bookings.
      yield put(bulkInsert(result))

      const { bookings } = result

      // booking data is paginated in the store
      yield put(bookingsLoadingSuccess(bookings, page, response.data.count, limit))
    }
  } catch (error) {
    yield put(bookingsLoadingFail(error.message))
    logError(error)
    yield put(authFailureCheck(error.response))
  } finally {
    yield put(apiActive(false))
  }
}

/*
function* refreshBookings() {
    const start = Date.now()
    const credentials = getCredentials()
    try {
        yield put(apiActive(true))
        yield put(bookingsStartLoading())
        const data = yield call(apiGetBookings, credentials)
        if (Array.isArray(data.data)) {
            yield put(bulkInsert(parseBookings(data.data)))
        }
        yield put(bookingsLoadingSuccess())
    } catch (error) {
        yield put(bookingsLoadingFail(error.message))
        logError(error)
    } finally {
        console.log(`bookings loaded in  ${moment().valueOf() - moment(start).valueOf()} ms`)
        yield put(apiActive(false))
    }
}
*/

function* refreshStatistics(action) {
  const credentials = getCredentials()

  // Company or franchise stats?
  const { key, range } = action
  try {
    yield put(apiActive(true))
    yield put(statisticsStartLoading(key))

    const apiMethod = (key === STATISTICS_KEY_COMPANY) ? apiGetCompanyStats : apiGetFranchiseStats

    let params = {}

    // Exploit toISOString() to get YYYY-MM-DD from Date()
    if (range && range[0] && range[1]) {
      params = dateRangeToParams(range)
    }

    if (key === STATISTICS_KEY_FRANCHISE && action.companyName) {
      params.company_name = action.companyName
    }

    if (key === STATISTICS_KEY_COMPANY && action.companyId) {
      params.company_id = action.companyId
    }

    const data = yield call(apiMethod, credentials, params)

    yield put(statisticsReplace(key, data.data.currency, data.data.values, data.data.companies))

    yield put(statisticsLoadingSuccess(key))
  } catch (error) {
    yield put(statisticsLoadingFail(key, error.message))
    logError(error)
    yield put(authFailureCheck(error.response))
  } finally {
    yield put(apiActive(false))
  }
}


function* refreshServices() {
  const credentials = getCredentials()
  try {
    yield put(apiActive(true))
    yield put(servicesStartLoading())
    const companyId = yield select(getSelectedCompanyId)
    if (companyId && companyId !== "0") {
      const d = {
        services: [],
      }
      const data = yield call(apiGetCompanyServices, credentials, {
        company_id: companyId,
      })

      if (data) {
        const services = data.data
        if (Array.isArray(services)) {
          for (let i = 0; i < services.length; i += 1) {
            d.services.push(services[i])
          }
        }
      }
      yield put(bulkInsert(d))
      yield put(servicesLoadingSuccess())
    }
  } catch (error) {
    yield put(servicesLoadingFail(error.message))
    logError(error)
    yield put(authFailureCheck(error.response))
  } finally {
    yield put(apiActive(false))
  }
}

function* refreshSingleService({ serviceId }) {
  const credentials = getCredentials()
  try {
    yield put(apiActive(true))
    yield put(singleServiceStartLoading())
    const companyId = yield select(getSelectedCompanyId)
    if (companyId && companyId !== "0") {
      // To get a single service with all data needed we need to get all services for the company
      // Passing the serviceId as param will ensure only 1 result is returned
      const data = yield call(apiGetCompanyServices, credentials, {
        company_id: companyId,
        service_id: serviceId,
      })
      if(data && data.data && data.data.length === 1) {
        const service = data.data[0]
        yield put(getServiceSuccess(service))
        yield put(singleServiceLoadingSuccess())
      } else {
        throw new Error("Service not found")
      }
    } else {
      throw new Error("Company not selected")
    }
  } catch (error) {
    logError(error)
    yield put(authFailureCheck(error.response))
    throw error
  } finally {
    yield put(apiActive(false))
  }
}

function getErrorMessage(error) {
  let errorMessage = "unknown error"
  if (error) {
    if (error.message) {
      errorMessage = error.message
    } else {
      errorMessage = JSON.stringify(error)
    }
  }
}

function* cancelBooking(action) {
  const credentials = getCredentials()
  try {
    yield put(apiActive(true))
    yield put(bookingsCancelBookingStart())
    yield put(bookingsLocalCancelSelectedBooking())

    const result = yield call(apiCancelBooking, credentials, { id: action.id, type: action.cancelledType })

    if (result) {
      if (result.status === 412) {
        const message = result.data.message
        yield put(showModal(message))
        throw new ApiException(message)
      } else {
        yield put(bookingsCancelBookingSuccess())
        yield put(showToast("Booking Canceled"))
      }
    } else {
      throw new ApiException("Unknown Error")
    }
  } catch (error) {
    yield put(bookingsCancelBookingFail(getErrorMessage(error)))
    logError(error)
    yield put(authFailureCheck(error.response))
    // yield put({ type: REFRESH_BOOKINGS }) // undo local save
  } finally {
    yield put(apiActive(false))
  }
}

function* rescheduleBooking(action) {
  const credentials = getCredentials()
  const { type, ...params } = action
  try {
    yield put(apiActive(true))
    yield put(bookingsRescheduleBookingStart())
    yield put(bookingsLocalRescheduleSelectedBooking(params.appointmentTime))

    // Only send an employeeId if a stylist has been selected
    const apiParams = Object.fromEntries(Object.entries({
      appointment_time: moment(params.appointmentTime).tz(params.companyTimezone).format(),
      employee_id: params.employeeId,
      id: params.id,
    }).filter(([key, value]) => key !== 'employee_id' || parseInt(value) !== 0))

    const result = yield call(apiRescheduleBooking, credentials, apiParams)
    if (result) {
      if (result.status === 412) {
        const message = result.data.message
        yield put(showModal(message))
        throw new ApiException(message)
      } else {
        yield put(bookingsRescheduleBookingSuccess(params))
        yield put(showToast("Booking Rescheduled"))
      }
    } else {
      throw new ApiException("Unknown Error")
    }
  } catch (error) {
    yield put(bookingsRescheduleBookingFail(getErrorMessage(error)))
    logError(error)
    yield put(authFailureCheck(error.response))
    // yield put({ type: REFRESH_BOOKINGS }) // undo local reschedule
  } finally {
    yield put(apiActive(false))
  }
}

function* saveService(action) {
  const credentials = getCredentials()
  const { type, ...service } = action
  try {
    let result
    yield put(apiActive(true))
    if (action.id) {
      // update service
      result = yield call(apiSaveService, credentials, service)
    } else {
      // new service
      result = yield call(apiCreateService, credentials, service)
    }
    if (result && result.data.success) {
      const { id: serviceId } = result.data

      yield call(refreshSingleService, { serviceId })
      yield put(saveServiceSuccess())
      yield put(showToast("Service saved"))

    } else if (result && !result.data.success) {
      yield put(saveServiceFail({ error: result.data.message }))
    } else {
      throw new ApiException("Unknown Error")
    }
  } catch (error) {
    let message
    if (error.message) {
      message = error.message
    }
    if (error.response && error.response.data) {
      message = error.response.data.message
    }
    yield put(saveServiceFail(message))
    logError(message)
    yield put(authFailureCheck(error.response))
  } finally {
    yield put(apiActive(false))
  }
}

function* deleteService(action) {
  const credentials = getCredentials()
  const { id } = action
  try {
    yield put(apiActive(true))
    const result = yield call(apiDeleteService, credentials, { id })
    if (result) {
      yield put(servicesDeleteServiceSuccess(id))
      yield put(showToast("Service Removed"))
    } else {
      throw new ApiException("Unknown Error")
    }
  } catch (error) {
    yield put(servicesDeleteServiceFail(error.message ? error.message : JSON.stringify(error)))
    logError(error)
    yield put(authFailureCheck(error.response))
  } finally {
    yield put(apiActive(false))
  }
}

function* fetchServiceDescriptions(action) {
  const credentials = getCredentials()
  const { type, ...params } = action
  try {
    yield put(apiActive(true))
    const result = yield call(apiGetServiceDescriptions, credentials, params)
    if (result) {
      if (result.status === 412) {
        const message = result.data.message
        yield put(showModal(message))
        throw new ApiException(message)
      } else {
        yield put(bulkInsert({ serviceDescriptions: result.data }))
        yield put({ type: GET_SERVICES_DESCRIPTIONS_SUCCESS })
      }
    } else {
      yield put({ type: GET_SERVICES_DESCRIPTIONS_FAIL })
      throw new ApiException("Unknown Error")
    }
  } catch (error) {
    yield put({ type: GET_SERVICES_DESCRIPTIONS_FAIL, error })
    logError(error)
    yield put(authFailureCheck(error.response))
  } finally {
    yield put(apiActive(false))
  }
}

function* fetchExternalServices(action) {
  const credentials = getCredentials()
  const { type, ...params } = action
  try {
    yield put(apiActive(true))
    const result = yield call(apiGetExternalServices, credentials, params)
    if (result) {
      if (result.status === 412) {
        const message = result.data.message
        yield put(showModal(message))
        throw new ApiException(message)
      } else {
        yield put(bulkInsert({ externalServices: result.data }))
        yield put({ type: GET_EXTERNAL_SERVICES_SUCCESS })
      }
    } else {
      yield put({ type: GET_EXTERNAL_SERVICES_FAIL })
      throw new ApiException("Unknown Error")
    }
  } catch (error) {
    yield put({ type: GET_EXTERNAL_SERVICES_FAIL, error })
    logError(error)
    yield put(authFailureCheck(error.response))
  } finally {
    yield put(apiActive(false))
  }
}

function* fetchExternalPackages(action) {
  const credentials = getCredentials()
  const { type, ...params } = action
  try {
    yield put(apiActive(true))
    const result = yield call(apiGetExternalPackages, credentials, params)
    if (result) {
      if (result.status === 412) {
        const message = result.data.message
        yield put(showModal(message))
        throw new ApiException(message)
      } else {
        yield put(bulkInsert({ externalPackages: result.data }))
        yield put({ type: GET_EXTERNAL_PACKAGES_SUCCESS })
      }
    } else {
      yield put({ type: GET_EXTERNAL_PACKAGES_FAIL })
      throw new ApiException("Unknown Error")
    }
  } catch (error) {
    yield put({ type: GET_EXTERNAL_PACKAGES_FAIL, error })
    logError(error)
    yield put(authFailureCheck(error.response))
  } finally {
    yield put(apiActive(false))
  }
}

function* deleteContact(action) {
  const credentials = getCredentials()
  const { type, ...params } = action
  try {
    yield put(apiActive(true))
    yield put(contactsDeleteContactStart())
    let result = yield call(apiDeleteContact, credentials, params)
    if (result) {
      if (result.status === 412) {
        const message = result.data.message
        yield put(showModal(message))
        throw new ApiException(message)
      } else {
        yield put(contactsDeleteContactSuccess())
        result = yield call(apiCompanies, credentials, false)
        if (result) {
          const company = result.data.filter(s => s.id === action.company_id)[0]
          for (let i = 0; i < company.company_contacts.length; i += 1) {
            const contact = company.company_contacts[i]
            yield put(contactsAdd(company.id, contact))
          }
        }
        yield put(showToast("Contact Deleted"))
      }
    } else {
      throw new ApiException("Unknown Error")
    }
  } catch (error) {
    yield put(contactsDeleteContactFail(getErrorMessage(error)))
    logError(error)
    yield put(authFailureCheck(error.response))
  } finally {
    yield put(apiActive(false))
  }
}

function* deleteEmployee(action) {
  const credentials = getCredentials()
  const { type, ...params } = action
  try {
    yield put(apiActive(true))
    const result = yield call(apiDeleteEmployee, credentials, params)
    if (result) {
      if (result.status === 412) {
        const message = result.data.message
        yield put(showModal(message))
        throw new ApiException(message)
      } else {
        yield put(showToast("Team Member Removed"))

        /* Update services too to reflect automatic unassignment from services on delete */
        yield call(refreshServices)
      }
    } else {
      throw new ApiException("Unknown Error")
    }
  } catch (error) {
    yield put({ type: EMPLOYEES_DELETE_FAIL, error: getErrorMessage(error) })
    logError(error)
    yield put(authFailureCheck(error.response))
  } finally {
    yield put(apiActive(false))
  }
}

function* saveContact(action) {
  const credentials = getCredentials()
  const { type, ...params } = action
  try {
    yield put(apiActive(true))
    yield put(contactsSaveStart())

    let result

    let errorMessage = ""

    const normalizeApiFieldName = fieldName => {
      const returnString = fieldName.replace(/([A-Z])/g, s => ` ${s.toLowerCase()}`)

      return returnString[0].toUpperCase() + returnString.substring(1)
    }

    try {
      if (params.id) {
        // Update to an existing contact
        result = yield call(apiSaveContact, credentials, params)
      } else {
        // Create new contact
        result = yield call(apiCreateContact, credentials, params)
      }
    } catch (error) {
      if (error.response && error.response.status && error.response.status === 400) {
        //Try to derive the reason for the error
        if (error.response.data && error.response.data.message) {
          const matchResult = /\[(.+)\]: (.+)/g.exec(error.response.data.message)
          if (matchResult.length === 3) {
            const fieldName = normalizeApiFieldName(matchResult[1])
            const fieldMessage = matchResult[2]
              .replace(/^((Contact email|This value)\s)/, "")
              .replace(/^Invalid/g, "needs to be a valid")

            errorMessage = `${fieldName} ${fieldMessage}`
          }
        }
      }
    }

    if (result && result.status === 412) {
      errorMessage = result.data.message
    }

    if (errorMessage.length > 0) {
      yield put(showModal(errorMessage))
      throw new ApiException(errorMessage)
    } else if (result) {
      if (params.id) {
        // Contact updated
        yield put(contactsAdd(params.company_id, params))
        yield put(contactsSaveSuccess())
        yield put(resetContactEditForm())
        yield put(showToast("Contacts Updated"))
      } else {
        // New contact created
        result = yield call(apiCompanies, credentials, false)

        if (result) {
          const company = result.data.filter(s => s.id === action.company_id)[0]
          for (let i = 0; i < company.company_contacts.length; i += 1) {
            const contact = company.company_contacts[i]
            yield put(contactsAdd(company.id, contact))
          }
        }
        yield put(contactsSaveSuccess())
        yield put(resetContactEditForm())
        yield put(showToast("Contact Added"))
      }
    } else {
      yield put(contactsSaveFail("Unknown Error"))
      throw new ApiException("Unknown Error")
    }
  } catch (error) {
    logError(error)
    yield put(contactsSaveFail(error))
    yield put(authFailureCheck(error.response))
  } finally {
    yield put(apiActive(false))
  }
}

function* saveEmployee(action) {
  const credentials = getCredentials()
  const { type, ...params } = action
  try {
    yield put(apiActive(true))
    let result
    if (params.id) {
      // Edit empoyee
      result = yield call(apiSaveEmployee, credentials, params)
    } else {
      // Create employee
      result = yield call(apiCreateEmployee, credentials, params)
    }

    if (result) {
      if (result.status === 412) {
        const message = result.data.message
        yield put(showModal(message))
        yield put({ type: EMPLOYEES_SAVE_FAIL })
        throw new ApiException(message)
      } else {
        yield put({ type: EMPLOYEES_SAVE_SUCCESS })

        yield call(router.navigate, '/settings/employees')

        yield call(refreshEmployees, { company_id: params.company_id })
        yield put(showToast("Team Updated"))

        // if (result.data.id) {
        // we just saved a new employee. Redirect to edit using new id.
        // yield put(push(`/settings/${params.company_id}/employee/${result.data.id}`))
        // }
      }
    } else {
      yield put({ type: EMPLOYEES_SAVE_FAIL, error: "Unknown Error" })
      throw new ApiException("Unknown Error")
    }
  } catch (error) {
    let message
    if (error.message) {
      message = error.message
    }
    if (error.response && error.response.data) {
      message = error.response.data.message
    }
    yield put({ type: EMPLOYEES_SAVE_FAIL, error: message })
    logError(error)
    yield put(authFailureCheck(error.response))
  } finally {
    yield put(apiActive(false))
  }
}

function* fetchEmployeesExternalData(action) {
  const credentials = getCredentials()
  const { type, ...params } = action
  try {
    yield put(apiActive(true))
    const result = yield call(apiGetEmployeesExternalData, credentials, params)
    if (result) {
      if (result.status === 412) {
        const message = result.data.message
        yield put(showModal(message))
        throw new ApiException(message)
      } else {
        yield put(bulkInsert({ employeesExternalData: result.data, companyId: params.company_id }))
        yield put({ type: GET_EMPLOYEES_EXTERNAL_DATA_SUCCESS })
      }
    } else {
      yield put({ type: GET_EMPLOYEES_EXTERNAL_DATA_FAIL })
      throw new ApiException("Unknown Error")
    }
  } catch (error) {
    yield put({ type: GET_EMPLOYEES_EXTERNAL_DATA_FAIL, error })
    logError(error)
    yield put(authFailureCheck(error.response))
  } finally {
    yield put(apiActive(false))
  }
}

function* saveCompany(action) {
  const credentials = getCredentials()
  const { type, ...params } = action
  try {
    yield put(apiActive(true))
    yield put(companiesSaveCompanyStart())
    let result = yield call(apiSaveCompany, credentials, params)
    if (result) {
      yield put(companiesSaveCompanySuccess())
      result = yield call(apiCompanies, credentials, false)
      if (result) {
        if (result.status === 412) {
          const message = result.data.message
          yield put(showModal(message))
          throw new ApiException(message)
        } else {
          const company = result.data.filter(s => s.id === action.id)[0]
          yield put(replaceCompany(company))
          yield put(showToast("Salon Profile Updated"))
        }
      }
    } else {
      throw new ApiException("Unknown Error")
    }
  } catch (error) {
    yield put(companiesSaveCompanyFail(getErrorMessage(error)))
    logError(error)
    yield put(authFailureCheck(error.response))
  } finally {
    yield put(apiActive(false))
  }
}

function* saveCompanyHours(action) {
  const credentials = getCredentials()
  const { type, ...params } = action
  try {
    yield put(apiActive(true))
    yield put(companiesSaveCompanyStart())

    // Save company hours
    const days = params.hours.map(h => ({
      day: h.get("dow"),
      open: h.get("open_minute"),
      close: h.get("close_minute"),
      is_open: h.get("is_open"),
    })).toJS()
    const resultHours = yield call(apiSaveCompanyHours, credentials, { id: params.id, days })

    if (resultHours && resultHours.status === 412) {
      const message = resultHours.data.message
      yield put(showModal(message))
      throw new ApiException(message)
    } else {
      yield put(companiesSaveCompanySuccess())
      yield put(showToast("Salon Hours Updated"))
    }
  } catch (error) {
    yield put(companiesSaveCompanyFail(getErrorMessage(error)))
    logError(error)
    yield put(authFailureCheck(error.response))
  } finally {
    yield put(apiActive(false))
  }
}

function* savePreference(action) {
  const credentials = getCredentials()
  const { type, ...params } = action
  try {
    yield put(apiActive(true))
    yield put(preferencesSavePreferenceStart())
    const result = yield call(apiSavePreference, credentials, params)
    if (result) {
      if (result.status === 412) {
        const message = result.data.message
        yield put(showModal(message))
        throw new ApiException(message)
      } else {
        yield put(preferencesSavePreferenceSuccess())
        yield put(showToast("Preference Saved"))
      }
    } else {
      throw new ApiException("Unknown Error")
    }
  } catch (error) {
    yield put(preferencesSavePreferenceFail(getErrorMessage(error)))
    logError(error)
    yield put(authFailureCheck(error.response))
  } finally {
    yield put(apiActive(false))
  }
}

function* sendReport(action) {
  const credentials = getCredentials()
  const { type, ...params } = action
  try {
    yield put(apiActive(true))
    const result = yield call(apiSendReport, credentials, params)
    if (result) {
      if (result.status === 412) {
        const message = result.data.message
        yield put(showModal(message))
        throw new ApiException(message)
      } else {
        yield put(showToast("Report Sent"))
      }
    } else {
      throw new ApiException("Unknown Error")
    }
  } catch (error) {
    yield put(preferencesSavePreferenceFail(getErrorMessage(error)))
    logError(error)
    yield put(authFailureCheck(error.response))
  } finally {
    yield put(apiActive(false))
  }
}

function* getInvite(action) {
  const { type, ...params } = action
  yield put(apiActive(true))
  try {
    let credentials = getCredentials()
    if (!credentials || !credentials.token) {
      credentials = null
    }
    const result = yield call(apiGetInvite, credentials, params)
    if (result) {
      yield put(getInviteSuccess(result))
    } else {
      throw new ApiException("Unknown Error")
    }
  } catch (error) {
    yield put(getInviteFailure(error.message ? error.message : JSON.stringify(error)))
    logError(error)
    yield put(authFailureCheck(error.response))
  } finally {
    yield put(apiActive(false))
  }
}

function* registerNewUser(action) {
  const {
    type, email, firstName, lastName, password, phone, authCode
  } = action
  yield put(apiActive(true))
  try {
    /* If invited user doesn't exists we do a signup */
    const result = yield call(apiAuthRegister, {
      first_name: firstName,
      last_name: lastName,
      phone_number: phone,
      username: email,
      password,
      code: authCode,
    })
    if (!result || !result.data.success) {
      throw new Error(result ? result.data.message : "Unknown Error")
    } else {
      yield put(registerNewUserSuccess())
      /* Now we do a login attempt */
      yield put(authAttempt(email, password))
      /* We used the account data we had in the cookie, we clear it now */
      yield put(clearAccountDataInCookie())
    }
  } catch (error) {
    let message
    if (error.message) {
      message = error.message
    }
    if (error.response && error.response.data) {
      message = error.response.data.message
    }
    yield put(registerNewUserFailure(message || JSON.stringify(error)))
    logError(error)
  } finally {
    yield put(apiActive(false))
  }
}

function* acceptInvite(action) {
  const { code } = action
  yield put(apiActive(true))
  try {
    const credentials = getCredentials()
    if (credentials && credentials.token) {
      /* Once user is logged in we accept/register the invite */
      const result = yield call(apiRegisterInvite, credentials, { code })
      if (result && result.status === 200) {
        yield put(registerInviteSuccess())
        /* fetch the latest invite data */
        yield getInvite({ code })
      } else {
        throw new ApiException("Unknown Error")
      }
    } else {
      throw new ApiException("You need to log in to accept an invite")
    }
  } catch (error) {
    let message
    if (error.message) {
      message = error.message
    }
    if (error.response && error.response.data) {
      message = error.response.data.message
    }
    yield put(registerInviteFailure(message || JSON.stringify(error)))
    logError(error)
    yield put(authFailureCheck(error.response))
  } finally {
    yield put(apiActive(false))
  }
}

function* acceptedInviteTerms(action) {
  const { type, code, ...params } = action
  yield put(apiActive(true))
  const credentials = getCredentials()
  try {
    const result = yield call(apiAcceptInviteTerms, credentials, { code, ...params })
    if (result && result.data.success) {
      yield put(acceptInviteTermsSuccess(result.data))
    } else {
      throw new ApiException("Unknown Error")
    }
    yield getInvite({ code }) // we get the updated invite
    yield call(router.navigate, `/invite/${code}/connect`)
  } catch (error) {
    let message = "Error Accepting terms"
    if (error.response && error.response.data) {
      message = error.response.data.message
    }
    yield put(acceptInviteTermsFailure(message))
    logError(error)
    yield put(authFailureCheck(error.response))
  } finally {
    yield put(apiActive(false))
  }
}

function randomString26() {
  const length = 26
  const chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
  let result = ""
  for (let i = length; i > 0; i -= 1) result += chars[Math.floor(Math.random() * chars.length)]
  return result
}

function* inviteSoftwareCheck(action) {
  const {
    type,
    code, // invite code
    softwareSlug,
    externalId,
    externalUsername,
    externalPassword,
  } = action
  yield put(apiActive(true))
  const credentials = getCredentials()
  try {
    const channelToken = randomString26()
    const pusherChannelName = `check-${channelToken}`
    const params = {
      code, // invite code
      token: channelToken,
      softwareSlug,
      externalId,
      externalUsername,
      externalPassword,
    }
    /* We subscribe to the pusher channel */
    /* when SOFTWARE_CHECK_RESPONSE is dispacthed we analyza the result there */
    yield call(subscribe, pusherChannelName, "client-end-invite-check", SOFTWARE_CHECK_RESPONSE)

    const result = yield call(apiInviteSoftwareCheck, credentials, params)
    if (!result || !result.data.success) {
      throw new ApiException(result.data && result.data.message)
    }
  } catch (error) {
    let message = "Error Connecting Software"
    if (error.response && error.response.data) {
      message = error.response.data.message
    }
    yield put(inviteSoftwareCheckFailure(JSON.stringify(message)))
    logError(error)
    yield put(authFailureCheck(error.response))
  } finally {
    yield put(apiActive(false))
  }
}

function* sendAuthCode(action) {
  const { type, email, showNotification } = action
  yield put(apiActive(true))
  try {
    const result = yield call(apiSendAuthCode, { email })
    if (result && result.data.success) {
      yield put(sendAuthCodeSuccess())
      if (showNotification) {
        yield put(showToast(`Code sent to ${email}`))
      }
    } else {
      throw new ApiException(result ? result.data.message : "Unknown Error")
    }
  } catch (error) {
    yield put(sendAuthCodeFailure(error))
    logError(error)
  } finally {
    yield put(apiActive(false))
  }
}

function* softwareCheckResponse(action) {
  const { data } = action
  yield put(apiActive(true))
  try {
    if (data && data.success) {
      /* If success = true means the software was configured correctly */
      const credentials = getCredentials()
      const result = yield call(apiInviteComplete, credentials, { code: data.code })
      if (result && result.data.success) {
        yield put(inviteSoftwareCheckSuccess(data))
        yield put(showToast("Your booking software is now connected with Flossie"))
        /* fetch the latest invite data */
        yield getInvite({ code: data.code })
      } else {
        yield put(inviteSoftwareCheckFailure({ error: result.data && result.data.message }))
      }
    } else {
      yield put(inviteSoftwareCheckFailure(data))
    }
  } catch (e) {
    yield put(inviteSoftwareCheckFailure(e))
  } finally {
    yield put(apiActive(false))
  }
}

function* updatePassword(action) {
  yield put(apiActive(true))

  try {
    const result = yield call(apiUpdatePassword, { ...action.payload })

    if (result && result.data.success) {
      yield put(updatePasswordSuccess())
      yield put(showToast("Password has been updated"))
      yield put(authRestore(result.data.username, result.data.id, result.data.franchise_id, result.data.token, result.data.roles))
    } else {
      throw new ApiException(result ? result.data.message : "Unknown Error")
    }
  } catch (error) {
    yield put(updatePasswordFailure(error))
    logError(error)
  } finally {
    yield put(apiActive(false))
  }
}

function* forgotPassword(action) {
  yield put(apiActive(true))

  try {
    const result = yield call(apiForgotPassword, { ...action.payload })
    if (result && result.data.success) {
      yield put(forgotPasswordSuccess())
      yield put(showToast(`Email has been sent to ${action.payload.email}`))
    } else {
      throw new ApiException(result ? result.data.message : "Unknown Error")
    }
  } catch (error) {
    yield put(forgotPasswordFailure(error.message))
    logError(error)
  } finally {
    yield put(apiActive(false))
  }
}

function* submitCompanyForApproval(action) {
  const credentials = getCredentials()
  const { type, ...params } = action
  try {
    yield put(apiActive(true))

    // Validate company profile has the min data for request approval
    // For now we check if they have at least 1 employee and 1 service
    const employees = yield select(getAllEmployeesMap)
    const services = yield select(getAllServicesSlugMap)

    if (!employees.valueSeq().find(e => e.get("companyId") === params.company_id) || !services.valueSeq().find(s => s.get("companyId") === params.company_id)) {
      const error = "Please create at least 1 service and 1 employee."
      yield put(showToast(error))
      yield put(submitCompanyApprovalFailure(error))
      return
    }

    const result = yield call(apiSubmitCompanyApprovalRequest, credentials, params)
    if (result) {
      if (result.status === 412) {
        const message = result.data.message
        yield put(showModal(message))
        throw new ApiException(message)
      } else {
        yield put(submitCompanyApprovalSuccess(params))
        // no toast
      }
    } else {
      throw new ApiException("Unknown Error")
    }
  } catch (error) {
    yield put(showModal(getErrorMessage(error)))
    logError(error)
    yield put(submitCompanyApprovalFailure(error.response))
  } finally {
    yield put(apiActive(false))
  }
}

// Exploit toISOString() to get YYYY-MM-DD from Date()
function dateRangeToParams(range) {
  const params = {}

  const offset = range[0].getTimezoneOffset()
  const fromDate = new Date(range[0].getTime() - (offset * 60 * 1000))
  const toDate = new Date(range[1].getTime() - (offset * 60 * 1000))

  params.from = fromDate.toISOString().split("T")[0]
  params.to = toDate.toISOString().split("T")[0]

  return params
}

function* statisticsDownload(action) {
  yield put(apiActive(true))
  const credentials = getCredentials()

  try {
    let params = {}

    if (action.range && action.range[0] && action.range[1]) {
      params = dateRangeToParams(action.range)
    }

    if (action.companyName) {
      params.company_name = action.companyName
    }

    const result = yield call(apiGetFranchiseStatsCsv, credentials, params)
  } catch (error) {
    logError(error)
  } finally {
    yield put(apiActive(false))
  }
}


function* watchRefreshAll() {
  yield takeEvery(REFRESH_ALL, refreshAll)
}

function* watchPusherEvent() {
  yield takeEvery(PUSHER_EVENT, pusherEvent)
}

function* watchRefreshEmployees() {
  yield takeLatest(REFRESH_EMPLOYEES, refreshEmployees)
}

function* watchRefreshBookings() {
  yield takeLatest(REFRESH_BOOKINGS, refreshBookings)
}

function* watchRefreshStatistics() {
  yield takeEvery(REFRESH_STATISTICS, refreshStatistics)
}

function* watchRefreshServices() {
  yield takeLatest(REFRESH_SERVICES, refreshServices)
}

function* watchSaveService() {
  yield takeLatest(SERVICES_SAVE_SERVICE, saveService)
}

function* watchDeleteService() {
  yield takeLatest(SERVICES_DELETE_SERVICE, deleteService)
}

function* watchDeleteContact() {
  yield takeEvery(CONTACT_DELETE, deleteContact)
}

function* watchSaveContact() {
  yield takeLatest(CONTACTS_SAVE_CONTACT, saveContact)
}

function* watchSaveEmployee() {
  yield takeLatest(EMPLOYEES_SAVE_EMPLOYEE, saveEmployee)
}

function* watchDeleteEmployee() {
  yield takeEvery(EMPLOYEES_DELETE_EMPLOYEE, deleteEmployee)
}

function* watchSaveCompany() {
  yield takeLatest(COMPANIES_SAVE_COMPANY, saveCompany)
}

function* watchSubmitCompanyApprovalRequest() {
  yield takeLatest(SUBMIT_COMPANY_APPROVAL_REQUEST, submitCompanyForApproval)
}

function* watchSaveCompanyHours() {
  yield takeLatest(COMPANIES_SAVE_COMPANY_HOURS, saveCompanyHours)
}

function* watchCompanySelected() {
  yield takeLatest(AUTH_SET_COMPANY_ID, refreshAll)
}

function* watchSavePreference() {
  yield takeEvery(PREFERENCES_SAVE_PREFERENCE, savePreference)
}

function* watchSendReport() {
  yield takeLatest(PREFERENCES_SEND_REPORT, sendReport)
}

function* watchRescheduleBooking() {
  yield takeLatest(BOOKINGS_RESCHEDULE_BOOKING, rescheduleBooking)
}

function* watchCancelBooking() {
  yield takeLatest(BOOKINGS_CANCEL_BOOKING, cancelBooking)
}

function* watchGetInvite() {
  yield takeLatest(GET_INVITE_REQUEST, getInvite)
}

function* watchAcceptInvite() {
  yield takeLatest(ACCEPT_INVITE_REQUEST, acceptInvite)
}

function* watchAcceptInviteTerms() {
  yield takeLatest(ACCEPTED_INVITE_TERMS_REQUEST, acceptedInviteTerms)
}

function* watchInviteSoftwareCheck() {
  yield takeLatest(INVITE_SOFTWARE_CHECK_REQUEST, inviteSoftwareCheck)
}

function* watchSendAuthCode() {
  yield takeLatest(SEND_AUTH_CODE_REQUEST, sendAuthCode)
}

function* watchRegisterNewUser() {
  yield takeLatest(REGISTER_NEW_USER_REQUEST, registerNewUser)
}

function* watchSoftwareCheckResponse() {
  yield takeEvery(SOFTWARE_CHECK_RESPONSE, softwareCheckResponse)
}

function* watchResetPassword() {
  yield takeLatest(UPDATE_PASSWORD, updatePassword)
}

function* watchForgotPassword() {
  yield takeLatest(FORGOT_PASSWORD, forgotPassword)
}

function* watchRefreshInbox() {
  yield takeLatest(REFRESH_INBOX, refreshInbox)
}

function* watchAuthFailureCheck() {
  yield takeLatest(AUTH_FAILURE_CHECK, handleAuthFailure)
}

function* watchAuthFailure() {
  yield takeLatest(AUTH_FAILURE, handleAuthFailure)
}

function* watchGetEmployeesExternalData() {
  yield takeLatest(GET_EMPLOYEES_EXTERNAL_DATA, fetchEmployeesExternalData)
}

function* watchGetServiceDescriptions() {
  yield takeLatest(GET_SERVICES_DESCRIPTIONS, fetchServiceDescriptions)
}

function* watchGetExternalServices() {
  yield takeLatest(GET_EXTERNAL_SERVICES, fetchExternalServices)
}

function* watchGetExternalPackages() {
  yield takeLatest(GET_EXTERNAL_PACKAGES, fetchExternalPackages)
}

function* watchEditContact() {
  yield takeLatest(CONTACT_EDIT, action => put(selectContactForEditing(action.payload.id)))
}

function* watchAddNewContact() {
  yield takeLatest(CONTACT_ADD_NEW, () => put(resetContactEditForm()))
}

function* watchStatisticsDownload() {
  yield takeLatest(STATISTICS_DOWNLOAD, statisticsDownload)
}

export default function* rootSaga() {
  yield all([
    watchRefreshAll(),
    watchPusherEvent(),
    watchRefreshEmployees(),
    watchRefreshBookings(),
    watchRefreshStatistics(),
    watchRefreshServices(),
    watchSaveService(),
    watchDeleteService(),
    watchDeleteContact(),
    watchSaveContact(),
    watchSaveEmployee(),
    watchDeleteEmployee(),
    watchSaveCompany(),
    watchSaveCompanyHours(),
    watchCompanySelected(),
    watchSavePreference(),
    watchSendReport(),
    watchRescheduleBooking(),
    watchCancelBooking(),
    watchGetInvite(),
    watchAcceptInvite(),
    watchAcceptInviteTerms(),
    watchInviteSoftwareCheck(),
    watchSendAuthCode(),
    watchRegisterNewUser(),
    watchSoftwareCheckResponse(),
    watchResetPassword(),
    watchForgotPassword(),
    watchRefreshInbox(),
    watchAuthFailureCheck(),
    watchAuthFailure(),
    watchGetEmployeesExternalData(),
    watchGetServiceDescriptions(),
    watchGetExternalServices(),
    watchGetExternalPackages(),
    watchSubmitCompanyApprovalRequest(),
    watchEditContact(),
    watchAddNewContact(),
    watchStatisticsDownload(),
  ])
}
