import React from "react"
import Immutable from "immutable"
import ImmutablePropTypes from "react-immutable-proptypes"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import {
  isEmployeesLoading, getEmployeeError, getAllEmployeesMap
} from "../../redux/modules/employees"
import EmployeeForm from "../../forms/Employee/EmployeeForm"

import { getAllServices, isServicesLoading } from "../../redux/modules/services"
import FormWrapper from "../../components/Wrappers/FormWrapper"
import { getSelectedCompany } from "../../redux/modules/companies"
import Tabs from "../../components/Navigation/Tabs"
import NavigateTab from "../../components/Navigation/Tabs/NavigateTab"
import ServicesList from "../../components/Services/ServicesList"
import { useLocation, useNavigate, useParams } from "react-router-dom"

const EmployeeFormContainer = ({
  employeesLoading,
  allServices = Immutable.Map(),
  servicesLoading,
  selectedCompany = null,
  allEmployees = Immutable.Map(),
  browser,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { employeeSlug, companySlug } = useParams();

  if (employeesLoading) {
    return null;
  }

  const employee = allEmployees.find(
    (eachEmployee) => eachEmployee.get('slug') === employeeSlug
  )

  // Employee slug was set but the employee couldn't be found, go back
  if (employeeSlug && (!employee || !employee.get('externalId'))) {
    navigate('/settings/employees');
  }

  const title = 'Configure Stylist'

  const newEmployeePathname = `/settings/employees/${companySlug}`
  const employeeDetailsPathname = `/settings/employees/${companySlug}/${employeeSlug}/details`
  const employeeServicesPathname = `/settings/employees/${companySlug}/${employeeSlug}/services`

  const renderEmployeeServices = () => {
    /* Hide if fetching services or employee has no services assigned */
    if (!allServices || !employee.has("serviceId") || employee.get("serviceId").count() < 1) return null

    const employeeServices = employee.get("serviceId")
      .filter(serviceId => allServices.has(serviceId))
      .map(serviceId => allServices.get(serviceId))

    return (
      <>
        <div style={{ marginTop: 24 }}>
          <ServicesList
            serviceLinkTarget='employees'
            services={employeeServices}
            company={selectedCompany}
            loading={servicesLoading}
          />
        </div>
      </>
    )
  }

  return (
      <FormWrapper
        title={title}
        backLinkLabel={browser.greaterThan.medium ? "Back to Stylists" : "Back"}
        backLinkPath={ location.state?.backlink ?? '/settings/employees' }
        showHelpLink
      >
        {newEmployeePathname !== location.pathname && (
          <>
            <Tabs size="sm" align="left">
              <NavigateTab key="details" to={employeeDetailsPathname}>Stylist Details</NavigateTab>
              <NavigateTab key="services" to={employeeServicesPathname}>Stylist Services</NavigateTab>
            </Tabs>

            {/* Conditionally add padding when tabs are present */}
            <div style={{ height: 30 }} />
          </>
        )}
        {location.pathname === employeeDetailsPathname || location.pathname === newEmployeePathname ? (
          <EmployeeForm
            loading={employeesLoading}
            selectedExistingEmployee={employee ? employee.toJS() : null}
            selectedCompanyId={selectedCompany.get('id')}
          />
        ) : null}
        {location.pathname === employeeServicesPathname && (
          selectedCompany && renderEmployeeServices()
        )}
      </FormWrapper>
  )
}

EmployeeFormContainer.propTypes = {
  allServices: ImmutablePropTypes.map, // all services in the redux store.
  servicesLoading: PropTypes.bool.isRequired, // true: means we are fetching services, false: we didnt start fetching or we finished fetching them.
  employeesLoading: PropTypes.bool.isRequired, // true: employees are being loaded or saved.
  selectedCompany: ImmutablePropTypes.map,
  allEmployees: ImmutablePropTypes.map,
  browser: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
  return {
    allEmployees: getAllEmployeesMap(state),
    allServices: getAllServices(state),
    employeesLoading: isEmployeesLoading(state),
    servicesLoading: isServicesLoading(state),
    errorMessage: getEmployeeError(state),
    selectedCompany: getSelectedCompany(state),
    browser: state.get("browser"),
  }
}

export default connect(mapStateToProps)(EmployeeFormContainer)
