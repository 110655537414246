import React from "react"
import PropTypes from "prop-types"
import ImmutablePropTypes from "react-immutable-proptypes"
import { connect } from "react-redux"

import { SettingsHoursForm } from "../../forms"
import { companiesSaveHours, getSelectedCompany, isSavingSelector } from "../../redux/modules/companies"
import { H1 } from "../../components/Typography"
import SidebarWithContent from "../../components/Wrappers/SidebarWithContent"
import Immutable from "immutable"
import { Navigate } from "react-router-dom"


const HoursContainer = ({ company, isSaving, saveHours }) => {
  if (!company) return <Navigate to={'/settings'} />

  /* Don't render this until all required data has been retrieved and made available in redux */
  return (!company || !company.has("hours")) ? null : (
    <SidebarWithContent
      sidebarContent={(
        <>
          <H1>
            Your Salon Hours
          </H1>
          <p>These are your currently configured opening hours.<br/><br/>You can make any changes then click save to accept.</p>
        </>
      )}
    >
      <SettingsHoursForm
        hours={ Immutable.isList(company.get("hours")) ? company.get("hours").toJS() : [] }
        onSubmit={({ hours }) => {
          saveHours(company.get("id"), Immutable.fromJS(hours))
        }}
        isSaving={isSaving}
      />
    </SidebarWithContent>
  )
}

HoursContainer.propTypes = {
  company: ImmutablePropTypes.map, // provided by parent
  saveHours: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
}

const mapStateToProps = state => ({
  isSaving: isSavingSelector(state),
  company: getSelectedCompany(state),
})

const mapDispatchToProps = dispatch => ({
  saveHours: (id, hours) => dispatch(companiesSaveHours(id, hours)),
})

export default connect(mapStateToProps, mapDispatchToProps)(HoursContainer)
