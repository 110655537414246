import React from "react"
import {
  IntroCol,
  IntroHeadline,
  IntroImage,
  IntroRow,
  MaxWidthWrapper,
  SecondarySection, Spacer,
  TopHeader
} from "../StyledComponents"
import { IntroWrapper } from "../StyledComponents"
import AnchorLink from "react-anchor-link-smooth-scroll"
import { Button } from "../../../components/Form"

export default () => {
  const imageUrl = "https://images.prismic.io/flossie-production/e1401e02-62bb-48ff-932d-a8a4b01ecfb0_Prismic+-+2441+x+2130+%281%29.png?auto=compress%2Cformat&w=800&h=800&fit=max"
  const title='Exclusive Opportunity';
  const headline='Booking appointments just got easier! Join Aveda\'s new salon appointment booking platform.';
  const description='Powered By Flossie technology';
  const button= {
    destination: '#SupForm',
    text: 'Sign up now',
    backgroundColor: '#000'
  }

  return (
    <SecondarySection>
      <MaxWidthWrapper>
        <IntroRow centered>
          <IntroCol>
            <IntroWrapper centered={false}>
              <TopHeader><h4>{title}</h4></TopHeader>
              <IntroHeadline><h1>{headline}</h1></IntroHeadline>
              <p><em>{description}</em></p>
            </IntroWrapper>
          </IntroCol>
          <IntroCol>
            <IntroImage src={imageUrl} alt="Flossie Campaign Header" />
          </IntroCol>
        </IntroRow>
        <Spacer />
      </MaxWidthWrapper>
    </SecondarySection>
  )
}
