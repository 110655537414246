import React from "react"
import Label from "./Label"
import { CopyIcon } from "../Icons/Icons"
import { ThemedTextInput } from "./TextInput"
import PropTypes from "prop-types"
import styled from "styled-components"

const CopyInput = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
  svg {
    position: absolute;
    right: 1rem;
  }
`

export const CopyTextInput = ({
                                      input, label, id, ...rest
                                    }) => {
  const [copySuccess, setCopySuccess] = React.useState(false)
  const copyToClipBoard = async () => {
    try {
      await window.navigator.clipboard.writeText(input.value)
      setCopySuccess(true)
      setTimeout(() => {
        setCopySuccess(false)
      }, 1000)
    } catch (err) {
      setCopySuccess(false)
    }
  }
  return (
    <>
      {label && <Label htmlFor={id}>{label}</Label>}
      <CopyInput>
        <ThemedTextInput id={id} disabled {...input} {...rest} />
        <CopyIcon onClick={() => copyToClipBoard()} />
        {copySuccess && <CopiedMessage>Copied</CopiedMessage>}
      </CopyInput>
    </>
  )
}

CopyTextInput.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
}

export default CopyTextInput