const PRISMIC_TYPES = {
  // These are the attributes names we populate on the all_prismic_data.json file
  // add here your fields when you fetch new data from prismic
  PARTNER_TERMS: "partner_terms_of_business",
  PRIVACY_POLICY: "privacy_policy",
  CONTACT_PAGE: "salon_dashboard_contact_page", // this is the Name field inside the "Page" type in prismic
  FAQ_TOPIC: "faq_topic",
  FAQS: "faqs",
  SUPPLIER_CAMPAIGNS: "supplier_campaigns",
  SIMPLE_PAGE: "page",
}

/* Environment specific configs */
const development = {
  API_VERSION: 13,

  FRANCHISE_IDS: [],

  PUSHER_API_KEY: "364eb85b338b56569d83",
  PUSHER_APP_CLUSTER: "ap1",
  PUSHER_AUTH_ENDPOINT: "https://staging.api.flossie.boutique/support/pusher/company/auth",

  GOOGLE_RECAPTCHA_SITE_ID: false,

  PRISMIC_SIGNUP_UID: false,

}

const demo = {
  API_VERSION: 13,

  FRANCHISE_IDS: [],

  PUSHER_API_KEY: "364eb85b338b56569d83",
  PUSHER_APP_CLUSTER: "ap1",
  PUSHER_AUTH_ENDPOINT: "https://api-demo.flossie.com/support/pusher/company/auth",

  GOOGLE_RECAPTCHA_SITE_ID: false,

  PRISMIC_SIGNUP_UID: false,
}

const test = {
  API_VERSION: 13,

  FRANCHISE_IDS: [],

  PUSHER_API_KEY: "364eb85b338b56569d83",
  PUSHER_APP_CLUSTER: "ap1",
  PUSHER_AUTH_ENDPOINT: "https://api-test.flossie.com/support/pusher/company/auth",

  GOOGLE_RECAPTCHA_SITE_ID: false,

  PRISMIC_SIGNUP_UID: false,
}

const production = {
  API_VERSION: 13,

  FRANCHISE_IDS: [],

  PUSHER_API_KEY: "99d56ac126ab1752de53",
  PUSHER_APP_CLUSTER: "ap1",
  PUSHER_AUTH_ENDPOINT: "https://api.flossie.com/support/pusher/company/auth",

  GOOGLE_RECAPTCHA_SITE_ID: false,

  PRISMIC_SIGNUP_UID: false,
}

/* Site specific configs */
const CONFIG_SALON_DEMO_POWEREDBYFLOSSIE_COM = {
  ...demo,

  FRANCHISE_IDS: [13],
  TEMPLATE: "./app/html-templates/salon-staging.flossie.html",
}

const CONFIG_SALON_AVEDAPBF_COM = {
  FRANCHISE_IDS: [11],
  TEMPLATE: "./app/html-templates/salon.avedapbf.html",
  PRISMIC_SIGNUP_UID: "aveda-sign-up",
}

const CONFIG_SALON_AVEDA_NETWORK = {
  ...CONFIG_SALON_AVEDAPBF_COM,
  PRISMIC_SIGNUP_UID: "aveda-usa-sign-up",
  FRANCHISE_IDS: [11, 12],
}

const SITE_CONFIG = {
  "aveda": CONFIG_SALON_AVEDA_NETWORK,
  "salon.aveda.network": CONFIG_SALON_AVEDA_NETWORK,
  "staging.salon.aveda.network": CONFIG_SALON_AVEDA_NETWORK,

  "salon-staging.avedapbf.com": CONFIG_SALON_AVEDAPBF_COM,
  "salon.avedapbf.com": CONFIG_SALON_AVEDAPBF_COM,

  "salon-demo.poweredbyflossie.com": CONFIG_SALON_DEMO_POWEREDBYFLOSSIE_COM,
}

let configEnvironment
if (process.env.NODE_ENV === "production") {
  configEnvironment = production
} else if (process.env.NODE_ENV === "demo") {
  configEnvironment = demo
} else if (process.env.NODE_ENV === "test") {
  configEnvironment = test
} else {
  configEnvironment = development
}

const configSite = process.env.COMPANY ? SITE_CONFIG[process.env.COMPANY] : SITE_CONFIG[process.env.DOMAIN]

const config = { ...configEnvironment, ...configSite }

// We do this here mostly to make my IDE happy. Vars defined by the Webpack DefinePlugin are introduced at build time
// so IDE's tend to say "This var is not defined". "Hard" Assigning it this way means there's only one place to clean
// it up in the future.
const FRANCHISE_LIST = (typeof DEFINE_PLUGIN_FRANCHISE_LIST !== "undefined") ? DEFINE_PLUGIN_FRANCHISE_LIST : []

module.exports = {
  // Add common config values here
  ...PRISMIC_TYPES,
  STORE_API_USER: "STORE_API_USER",
  STORE_API_KEY: "STORE_API_KEY",
  STORE_AUTH_USERID: "STORE_AUTH_USERID",
  STORE_AUTH_COMPANY_ID: "STORE_AUTH_COMPANY_ID",
  STORE_API_TOKEN: "STORE_API_TOKEN",
  STORE_API_FRANCHISE_ID: "STORE_API_FRANCHISE_ID",
  STORE_API_ROLES: "STORE_API_ROLES",

  API_BASE: `https://${process.env.FLOSSIE_API_HOSTNAME}:443`,

  API_ROLE_STATISTICS: "statistics",
  API_ROLE_COMPANY: "company",

  THUMBOR_URL: `https://${process.env.FLOSSIE_THUMBOR_HOSTNAME}`,
  THUMBOR_KEY: process.env.FLOSSIE_THUMBOR_KEY,

  REFRESH_EMPLOYEES: "REFRESH_EMPLOYEES",
  REFRESH_BOOKINGS: "REFRESH_BOOKINGS",
  REFRESH_STATISTICS: "REFRESH_STATISTICS",
  REFRESH_SERVICES: "REFRESH_SERVICES",
  REFRESH_INBOX: "REFRESH_INBOX",
  REFRESH_ALL: "REFRESH_ALL",

  ACCOUNT_DATA: "ACCOUNT_DATA",

  PRISMIC_URL: "https://flossie-production.cdn.prismic.io/api/v2",
  PRISMIC_REPO: "flossie-production",
  PRISMIC_ACCESS_TOKEN: process.env.PRISMIC_REPOSITORY_KEY,

  HELP_FAQ_TAB_TITLE: "FAQ",
  HELP_TERMS_TAB_TITLE: "Terms of use",
  HELP_POLICY_TAB_TITLE: "Privacy Policy",
  HELP_CONTACT_TAB_TITLE: "Contact",

  SHOW_COOKIE_BANNER: false,

  DEFAULT_LOCALE: "en-us",

  FRANCHISE_LIST,

  ...config,
}
