import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import styled from "styled-components"
import moment from "moment-timezone"
import Select from "react-select"

import DatePicker from "../DatePicker/DatePicker"
import TimePicker, { isValidTimepickerTime } from "../TimePicker/TimePicker"
import { Button, InlineError } from "../../components/Form"
import { device } from "../../components/Responsive/Responsive"
import { Formik, Field } from "formik"
import StyledSelectField from "../../components/Form/StyledSelectField"

const RescheduleButton = styled(Button)`
    float: right;
    @media ${device.mobile} {
        width: ${props => (props.allowCancel ? "50%" : "100%")};
    }
`

const RowLabel = styled.label`
    display: inline-block;
    font-face: 'Museo-Sans', 'sans-serif';
    color: #575757;
    font-size: 17px;
`

const BookingEditFormParent = styled.div`
    background-color: #fff;

    @media ${device.desktop}, ${device.tablet} {
        width: 730px;
        min-width: 730px;
    }
    @media ${device.mobile} {
        width: auto;
    }
`

const BookingEditFormTop = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    
    @media ${device.mobile} {
        padding: 20px 25px 0px 10px;
        border-top: 1px solid #ececec;
    }
    @media ${device.tablet}, ${device.desktop} {
        padding: 30px 36px;
        border: 1px solid #ececec;
    }
`

const DateSelectorField = styled(Field)`
    & .rdp-months {
        justify-content: center;
    }
`


const DateSelectLabel = styled(RowLabel)`
    display: block;
    color: ${({ theme }) => theme.primaryColor};
    font-weight: 500;
    margin-bottom: 30px;

    @media ${device.mobile} {
        display: block;
        font-family: 'Museo-Sans', 'sans-serif';
        font-size: 15px;
        margin-bottom: 10px;
    }
`

const DateLabel = styled(RowLabel)`
    display: block;
    font-weight: 500;
    margin-bottom: 24px;
`

const BookingEditFormBottom = styled.div`
    @media ${device.mobile} {
        padding: 0 10px 10px 25px;
        margin-top: 24px;
    }
    
    @media ${device.desktop}, ${device.tablet} {
        padding: 30px 36px;
        border: 1px solid #ececec;
        border-top: none;
    }
`

const BookingEditFormButtonParent = styled.div`
    position: relative;
    padding-top: 20px;
    height: 65px;
    background-color: #f7f7f7;

    @media ${device.tablet}, ${device.desktop} {
        width: 730px;
    }

    @media ${device.mobile} {
      padding: 20px;
    }
`

const TimeFieldParent = styled.div`
    @media ${device.tablet}, ${device.desktop} {
        width: 50%;
        float: left;
    }
`

const TimeLabel = styled.label`
    @media ${device.tablet}, ${device.desktop} {
        display: none;
    }

    @media ${device.mobile} {
        display: block;
        font-family: 'Museo-Sans', 'sans-serif';
        font-size: 15px;
        margin-bottom: 10px;
    }
`


const EmployeeFieldParent = styled.div`
    @media ${device.tablet}, ${device.desktop} {
        width: 50%;
        float: left;
    }
`

const EmployeeLabel = styled.label`
    font-family: 'Museo-Sans', 'sans-serif';
    color: #575757;
    font-size: 17px;
    font-weight: 300;
    display: block;

    @media ${device.tablet}, ${device.desktop} {
        height: 38px;
        line-height: 38px;
        float: left;
        margin-right: 10px;
    }

    @media ${device.mobile} {

        font-family: 'Museo-Sans', 'sans-serif';
        color: #575757;
        font-size: 15px;
        font-weight: 300;
        margin-bottom: 10px;
        margin-top: 24px;
    }
`

const BookingEditForm = ({ onSubmit, browser, employees, allowCancel, appointmentTime: appointmentTimeEpoch, timeZone, bookingId, ...props }) => {
  const employeeSelectorOptions = [
    {
      label: "Any employee",
      value: "0",
    },
    ...employees.map((employee) => ({ label: employee.name, value: employee.id }))
  ]

  // Default to any stylist if this stylist is no longer associated with this service
  const selectedEmployeeId = employeeSelectorOptions.find(eachOption => eachOption.value === props.employeeId) ? props.employeeId : '0'

  const getMomentDateInCompanyTimezone = (date, time) => moment(`${date} ${time}`, 'YYYY-MM-DD hh:mm A').tz(timeZone, true)

  const isFutureDate = (date, time) => getMomentDateInCompanyTimezone(date, time).isSameOrAfter(moment())

  const initialValues = {
    date: moment(appointmentTimeEpoch).tz(timeZone).format("YYYY-MM-DD"),
    time: moment(appointmentTimeEpoch).tz(timeZone).format("hh:mm A"),
    employeeId: selectedEmployeeId
  }

  return (
    <Formik
      initialValues={initialValues}
      validate={(values) => {
        const errors = {}

        if (!isValidTimepickerTime(values.time) || !moment(values.time, "hh:mm A").isValid()) {
          errors.time = 'Invalid time'
        // No point checking a valid date if we already know the time is invalid
        } else if (!isFutureDate(values.date, values.time)) {
          errors.date =  "The date must be in the future"
        }

        return errors;
      }}
      onSubmit={async (values) => {
        const submitValues = {
          employeeId: values.employeeId,
          date: getMomentDateInCompanyTimezone(values.date, values.time).valueOf(),
        }
        onSubmit(submitValues)
      }}
    >
      {({ values, errors, handleSubmit, isSubmitting,setFieldValue }) => (
        <BookingEditFormParent>
          <BookingEditFormTop>
            <DateSelectLabel>Select New Date</DateSelectLabel>
            <DateSelectorField
              name="date"
              component={DatePicker}
              defaultMonth={moment(appointmentTimeEpoch).toDate()}
              onChange={value => setFieldValue('date', value)}
              numberOfMonths={(browser.lessThan.tablet) ? 1 : 2}
            />
          </BookingEditFormTop>

          <BookingEditFormBottom>
            <DateSelectLabel>Add New Time</DateSelectLabel>
            <div>
              <TimeFieldParent>
                <TimeLabel>Time</TimeLabel>
                <Field name="time" onChange={ value => setFieldValue('time', value)} component={TimePicker} />
              </TimeFieldParent>
              <EmployeeFieldParent>
                <EmployeeLabel>With</EmployeeLabel>
                <Field
                  name="employeeId"
                  as={StyledSelectField}
                  errors={errors}
                  options={employeeSelectorOptions}
                  onChange={(value) => setFieldValue('employeeId', value)}
                />
              </EmployeeFieldParent>

              <div style={{ clear: "both" }} />
            </div>
          </BookingEditFormBottom>
          <BookingEditFormBottom>
            <DateSelectLabel>New Appointment Time</DateSelectLabel>
            <DateLabel>{ Object.keys(errors).length > 0 ? Object.values(errors)[0] : getMomentDateInCompanyTimezone(values.date, values.time).format("h:mm A [on] dddd Do MMMM YYYY") }</DateLabel>
          </BookingEditFormBottom>
          <BookingEditFormButtonParent>
            <RescheduleButton
              allowCancel={allowCancel}
              type="submit"
              onClick={handleSubmit}
              disabled={isSubmitting || Object.keys(errors).length > 0}
            >
              Reschedule
            </RescheduleButton>
          </BookingEditFormButtonParent>
        </BookingEditFormParent>
      )}
    </Formik>
  )
}

BookingEditForm.propTypes = {
  employeeId: PropTypes.string.isRequired,
  appointmentTime: PropTypes.number.isRequired,
  employees: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  allowCancel: PropTypes.bool.isRequired,
  browser: PropTypes.object.isRequired,
  timeZone: PropTypes.string.isRequired,
}

const mapStateToProps = state => ({
  browser: state.get("browser"),
})

export default connect(mapStateToProps)(BookingEditForm)
