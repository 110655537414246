import React from "react"
import PropTypes from "prop-types"
import { slide as Menu } from "react-burger-menu"
import styled, { withTheme } from "styled-components"
import { hasRole } from "../../../redux/modules/auth"
import { device } from "../../Responsive/Responsive"
import CompanySelectContainer from "../../../containers/CompanySelect/CompanySelectContainer"

import { API_ROLE_COMPANY, API_ROLE_STATISTICS } from "../../../config/constants"

import MenuItem from "./MenuItem"

const menuMobileStyles = ({ theme }) => ({
  bmBurgerButton: {
    position: "fixed",
    width: "25px",
    height: "20px",
    left: theme.parentPadding,
    top: "16px",
  },
  bmBurgerBars: {
    background: theme.headerLinkColor,
  },
  bmCrossButton: {
    height: "24px",
    width: "24px",
  },
  bmCross: {
    background: "#f067a6",
  },
  bmMenuWrap: {
    zIndex: "900",
    transition: "",
  },
  bmMenu: {
    background: "#343434",
    paddingTop: "0px",
    fontSize: "1.15em",
    marginTop: "0px",
  },
  bmMorphShape: {
    fill: "#343434",
  },
  bmItem: {
    outline: "none",
  },
  bmItemList: {
    color: "#ffffff",
    padding: "0",
    outline: "none",
  },
  bmOverlay: {
    background: "rgba(0, 0, 0, 0.3)",
  },
})


const menuDesktopStyles = ({ theme }) => ({
  bmBurgerButton: {
    position: "fixed",
    width: "36px",
    height: "25px",
    left: "36px",
    top: "36px",
  },
  bmBurgerBars: {
    background: "#373a47",
  },
  bmCrossButton: {
    height: "24px",
    width: "24px",
  },
  bmCross: {
    background: "#bdc3c7",
  },
  bmMenuWrap: {
    zIndex: "900",
    transition: "",
  },
  bmMenu: {
    background: "#343434",
    fontSize: "1.15em",
    paddingTop: theme.parentPaddingX2,
    paddingLeft: theme.parentPadding,
    paddingRight: theme.parentPadding,
    marginTop: "70px",
    height: "calc(100vh - 70px)",
  },
  bmMorphShape: {
    fill: "#343434",
  },
  bmItem: {
    outline: "none",
  },
  bmItemList: {
    color: "#ffffff",
    outline: "none",
  },
  bmOverlay: {
    background: "rgba(0, 0, 0, 0.3)",
  },
})

const MenuItemParent = styled.div`
    @media ${device.mobile} {
        padding: 16px 30px 0px 30px;
        height: calc(100% - 145px);
    }

    @media ${device.tablet}, ${device.desktop} {
        height: 100%;
    }

    min-height: 400px;
    position: relative;
`

const CompanySelectParent = styled.div`
    background-color: #fff;
    padding: 10px 24px 20px 20px;
`

const SideBarMenu = ({
  menuActive = '', theme, isOpen = false, isMobile, menuStateChanged, browser, newUnsavedBookingCount = 0, notVisibleCompanies = 0, visibleCompanies = 0
}) => {
  const menuWidth = (browser.lessThan.tablet) ? "100%" : theme.desktopSideBarWidth
  const menuIsOpen = (browser.lessThan.tablet) ? isOpen : true
  const menuOnStateChange = (browser.lessThan.tablet) ? menuStateChanged : () => {}
  const menuCustomBurgerIcon = (browser.lessThan.tablet) ? null : false
  const menuStyles = (browser.lessThan.tablet) ? menuMobileStyles({ theme }) : menuDesktopStyles({ theme })
  const menuDisableCloseOnEsc = browser.lessThan.tablet
  return (
    <Menu
      width={menuWidth}
      isOpen={menuIsOpen}
      disableCloseOnEsc={menuDisableCloseOnEsc}
      noOverlay
      customCrossIcon={false}
      customBurgerIcon={menuCustomBurgerIcon}
      styles={menuStyles}
      pageWrapId="page-wrap"
      outerContainerId="outer-container"
      onStateChange={({ isOpen }) => menuOnStateChange(isOpen)}
    >

      {hasRole(API_ROLE_COMPANY) && isMobile && (
        <CompanySelectParent>
          <CompanySelectContainer onCompanyChange={() => menuOnStateChange(false)} />
        </CompanySelectParent>
      )}

      <MenuItemParent>
        {(visibleCompanies > 0 || hasRole(API_ROLE_STATISTICS)) && (
          <MenuItem
            menuActive={menuActive}
            id="home"
            label="Dashboard"
            to="/"
          />
        )}
        {hasRole(API_ROLE_COMPANY) && (
          <>
            {visibleCompanies > 0 && (
              <>
                <MenuItem
                  menuActive={menuActive}
                  id="bookings"
                  label="Bookings"
                  hasNotification={newUnsavedBookingCount > 0}
                  to="/bookings/pending"
                />
                <MenuItem
                  menuActive={menuActive}
                  id="reports"
                  label="Reports"
                  to="/reports"
                />
              </>
            )}
            <MenuItem
              menuActive={menuActive}
              id="settings"
              label="Settings"
              to="/settings/profile"
              hasNotification={notVisibleCompanies > 0}
            />
            <MenuItem
              menuActive={menuActive}
              id="help"
              label="Help"
              to="/help/faqs"
            />
          </>
        )}

      </MenuItemParent>
    </Menu>
  )
}

SideBarMenu.propTypes = {
  browser: PropTypes.object.isRequired,
  newUnsavedBookingCount: PropTypes.number,
  menuStateChanged: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  isMobile: PropTypes.bool.isRequired,
  theme: PropTypes.object.isRequired,
  menuActive: PropTypes.string,
  notVisibleCompanies: PropTypes.number,
  visibleCompanies: PropTypes.number,
}

export default withTheme(SideBarMenu)
