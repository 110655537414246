/* eslint-disable react/prefer-stateless-function,react/no-multi-comp */

import React from "react"
import PropTypes from "prop-types"
import { Field, Formik } from "formik"
import styled from "styled-components"
import { device } from "../../components/Responsive/Responsive"
import { Button } from "../../components/Form"

const validate = () => {
  const errors = {}
  return errors
}


const SettingsHourElement = styled.div`
    display: inline-block;
    height: 42px;
    font-family: 'Museo-Sans','sans-serif';
    font-weight: 300;
    font-size: 17px;
    color #575757;
    line-height: 38px;
`

const SettingsDayElement = styled(SettingsHourElement)`
    ${({ hasErrors }) => hasErrors && `
      font-weight: bold;
      color: var(--flossie-danger);
    ` };
    
    flex: 1;

    @media ${device.mobile} {
        display: block;
        width: 100%;
    }
`

const SettingsToElement = styled(SettingsHourElement)`
    padding-left: 24px;
    padding-right: 17px;

    @media ${device.mobile} {
        padding-left: 8px;
        padding-right: 12px;
    }
`

const SettingsHoursFormParent = styled.div`
    position: relative;
    display: flex;

    @media ${device.large} {
      display: block;
    }

    /* padding: 30px 0;

    @media ${device.mobile} {
        padding: 30px 12px 57px 12px;
    } */
`

const SettingsHoursOpeningHoursParent = styled.div`

    flex: 1;

    // max-width: 480px;

    margin-right: 16px;

    @media ${device.large} {
      margin-right: 0px;
    }
`

const SettingsHoursUnavailableDatesParent = styled.div`

    flex: 1;
    margin-left: 16px;

    @media ${device.large} {
      margin-top: 32px;
      margin-left: 0px;
    }
`

const SettingsHoursSaveButton = styled(Button)`
    @media ${device.mobile} {
        width: 100%;
    }
`

const HourPickerParent = styled.div`
    position: relative;

    &:after {
        content: '';
        color: #333;
        right: 12px;
        /*Adjust for position however you want*/
        border-width: 6px 6px 3px;
        border-color: #f067a6 transparent transparent;
        border-style: solid;
        top: 20px;
        width: 0px;
        height: 0px;
        /*left line */

        position: absolute;
        pointer-events: none;
    }
`
const HourPickerSelect = styled.select`

    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    background: white;
    border-radius: 25px;
    font-weight: 100 !important;
    padding: 10px 15px !important;
    width: 120px;

    height: 42px;
    font-size: 15px;
    font-family: 'Museo-Sans','sans-serif';
    outline: none;
    border: 1px solid ${({ theme }) => theme.borderColor};
    box-sizing: border-box;
`

const dayHours = (() => {
  const dateCursor = new Date();
  dateCursor.setHours(6, 0, 0, 0);

  const dateEnd = new Date();
  dateEnd.setHours(22, 0, 0, 0);

  const hours = []
  while (dateCursor <= dateEnd) {
    hours.push(new Date(dateCursor.getTime()))
    dateCursor.setMinutes(dateCursor.getMinutes() + 30)
  }

  return hours;
})();


const minutesSinceMidnight = date => date.getHours() * 60 + date.getMinutes()
const minutesSinceMidnightToEpoch = (minutesSinceMidnight) => (new Date()).setHours(0, minutesSinceMidnight,0,0);

const getNextLaterTime = (time) => {
  const nextTime = dayHours.find((hour) => hour.valueOf() > parseInt(time));

  return nextTime ? nextTime.valueOf() : time
}

const HourPicker = ({ id, value, onChange, minTime }) => {
  const handleChange = ({ target: { value: eventValue } }) => onChange(eventValue)

  return (
    <HourPickerParent>
      <HourPickerSelect id={id} value={value} onChange={handleChange}>
        <option value="closed">Closed</option>
        {dayHours.filter((hour) => (!minTime || hour.valueOf() >= minTime)).map((hour) => {
          return (
            <option key={hour.valueOf()} value={hour.valueOf()}>
              { hour.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric' }) }
            </option>
          )
        })}
      </HourPickerSelect>
    </HourPickerParent>
  )
}
HourPicker.propTypes = {
  minTime: PropTypes.number,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
}

const OpenCloseHours = ({ label, onChange, value, errors }) => {
  const formValues = {
    open: typeof value.open === 'number' ? value.open.toString() : value.open,
    close: typeof value.close === 'number' ? value.close.toString() : value.close
  }

  const handleChange = (type, value) => {
    let v = {
      ...formValues,
      [type]: value
    }

    onChange(v)
  }

  return (
    <div style={{ marginBottom: "8px", display: "flex" }}>
      <SettingsDayElement hasErrors={ errors !== null }>
        {label}
      </SettingsDayElement>
      <SettingsHourElement>
        <HourPicker id="open" value={formValues.open} onChange={(value) => handleChange('open', value)} />
      </SettingsHourElement>
      {formValues.open !== "closed" && (
        <SettingsHourElement>
          <SettingsToElement>To</SettingsToElement>
          <SettingsHourElement>
            <HourPicker id="close" minTime={getNextLaterTime(formValues.open)} value={formValues.close} onChange={(value) => handleChange('close', value)} />
          </SettingsHourElement>
        </SettingsHourElement>
      )}
    </div>
  )
}

OpenCloseHours.propTypes = {
  value: PropTypes.shape({
    open: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    close: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
  }).isRequired,
  errors: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

const OpeningHours = ({ form: {errors}, field: { value }, onChange }) => {
  const handleHoursChanged = (dayOfWeek, changes) => {

    const currentDayValue = value.find((day) => day.dow === dayOfWeek)

    let newValue = {}

    if (currentDayValue.is_open && (changes.open === "closed" || changes.close === "closed")) {
      newValue = {
        open_minute: "0",
        close_minute: "0",
        is_open: false,
      }
    } else if (changes.open !== "closed" || changes.close !== "closed") {
      newValue = {
        open_minute: minutesSinceMidnight(new Date(parseInt(changes.open))),
        // Set close to open if the new open value is greater than the close
        close_minute: minutesSinceMidnight(new Date(parseInt(changes.close === "closed" || changes.open >= changes.close ? getNextLaterTime(changes.open) : changes.close))),
        is_open: true,
      }
    }

    onChange(value.map((day) => {
      if (day.dow === dayOfWeek) {
        return {
          ...day,
          ...newValue,
        }
      } else {
        return day;
      }
    }));
  }

  return !value ? null : (
    <div style={{
      dislay: "flex",
      flexDirection: "column",
    }}
    >
      {value.map(v => (
        <OpenCloseHours
          value={{
            open: v.open_minute === "0" ? "closed" : minutesSinceMidnightToEpoch(v.open_minute),
            close: v.close_minute === "0" ? "closed" : minutesSinceMidnightToEpoch(v.close_minute)
          }}
          index={v.dow}
          key={v.dow}
          errors={ errors[v.dow] ?? null }
          label={v.day}
          onChange={(values) => handleHoursChanged(v.dow, values)}
        />
      ))}
    </div>
  )
}

OpeningHours.propTypes = {
  field: PropTypes.shape({
    value: PropTypes.array.isRequired,
  }),
}

const SettingsHoursForm = ({ onSubmit, hours, isSaving }) => {
  return (
    <Formik
      initialValues={{
        hours
      }}
      validate={(values, props) => {
        const errors = {}

        for (let key in values.hours) {
          let thisValue = values.hours[key];
          if (
            (thisValue.open_minute === "closed" || thisValue.close_minute === "closed")
            && (thisValue.open_minute !== thisValue.close_minute)
          ) {
            errors[thisValue.dow] = 'Invalid'
          } else if (parseInt(thisValue.open_minute) > parseInt(thisValue.close_minute)) {
            errors[thisValue.dow] = 'Open time cannot come after close time'
          }
        }

        return errors
      }}
      onSubmit={onSubmit}
    >
      {({setFieldValue, handleSubmit, isSubmitting, errors}) => (
        <>
          <SettingsHoursFormParent>
            <SettingsHoursOpeningHoursParent>
              <div style={{ maxWidth: 480, marginBottom: 20 }}>
                <Field name="hours" component={OpeningHours} onChange={(value) => setFieldValue('hours', value)} />
              </div>
            </SettingsHoursOpeningHoursParent>

            <div style={{ clear: "both" }} />
          </SettingsHoursFormParent>

          <div style={{ textAlign: "right" }}>
            <SettingsHoursSaveButton disabled={isSaving || Object.keys(errors).length > 0} onClick={handleSubmit} type="submit">Save</SettingsHoursSaveButton>
          </div>
        </>
      )}
    </Formik>
  )
}

SettingsHoursForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  hours: PropTypes.array.isRequired,
  isSaving: PropTypes.bool.isRequired,
}

export default SettingsHoursForm
