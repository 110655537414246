import moment from "moment-timezone"

export const formatToMomentTimezone = (date, formatString, timezone, oldDateString) => {
  const dateString = moment(date).tz(timezone).format(formatString)
  if (typeof oldDateString !== "undefined" && oldDateString !== dateString) {
    return oldDateString
  }
  return dateString
}

export const parseMomentTimezone = (date, format, timeZone, oldVal) => {
  const d = moment(date, format).tz(timeZone).toDate()
  if (typeof oldVal !== "undefined" && JSON.stringify(d) !== JSON.stringify(oldVal)) {
    return oldVal
  }
  return d
}
