import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { TextInput, Button, InlineError } from "../../components/Form"
import { Field, Formik } from "formik"
import { getPasswordUpdateError, updatePassword } from "../../redux/modules/auth"
import { isApiActive } from "../../redux/modules/api"
import { connect } from "react-redux"

const FormRow = styled.div`
  margin-bottom: 14px;
  text-align: center;
`

const passwordMatch = (password, passwordAgain) => {
  let passMatch = false
  if (
    !password
    && !password.length
    && !passwordAgain
    && !passwordAgain.length
  ) return passMatch
  if (password === passwordAgain) passMatch = true
  return passMatch
}

const minLengthCheck = (password) => password.length >= 10;

const ResetPasswordForm = ({ id, isApiActive, onUpdatePassword, updatePasswordError }) => (
  <Formik
    validateOnChange={false}
    validateOnBlur={false}
    initialValues={{
      password: '',
      passwordAgain: '',
    }}
    validate={(values) => {
      const errors = {}
      if (!minLengthCheck(values.password)) {
        errors.global = 'Please enter a password at least 10 characters long.'
      } else if (!passwordMatch(values.password, values.passwordAgain)) {
        errors.global = 'Passwords do not match'
      }

      return errors;
    }}
    onSubmit={(values) => {
      onUpdatePassword(id, values.password, values.passwordAgain)
    }}>
    {({ handleSubmit, errors }) => (
      <>
        <div>
          <h3 style={{ fontSize: "16px", color: "#575757" }}>Reset Password</h3>
          <form
            onSubmit={handleSubmit}
          >
            <FormRow>
              <Field as={TextInput} errors={errors} name="password" type="password" placeholder="Password*" required/>
            </FormRow>
            <FormRow>
              <Field as={TextInput} errors={errors} name="passwordAgain" type="password" placeholder="Confirm Password*"
                     required/>
            </FormRow>
            <div style={{ textAlign: "right" }}>
              <Button disabled={isApiActive} size="sm" type="submit">Save</Button>
            </div>
          </form>
        </div>
        <InlineError style={{ textAlign: "left" }} error={errors.global || updatePasswordError}/>
      </>
    )}
  </Formik>
)

ResetPasswordForm.propTypes = {
  id: PropTypes.string.isRequired,
  isApiActive: PropTypes.bool.isRequired,
  onUpdatePassword: PropTypes.func.isRequired,
  updatePasswordError: PropTypes.string, // selector
}

const mapStateToProps = state => ({
  isApiActive: isApiActive(state),
  updatePasswordError: getPasswordUpdateError(state),
})

const mapDispatchToProps = dispatch => ({
  onUpdatePassword: (token, password, passwordAgain) => dispatch(updatePassword(token, password, passwordAgain)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForm)