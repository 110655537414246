import React from "react"
import { IntroCol, IntroRow, MaxWidthWrapper, SecondarySection } from "../StyledComponents"
import styled from "styled-components"
import PropTypes from "prop-types"

const FlossieLegalsRow = styled.div`
    margin-top: 2rem;
    a {
        font-weight: lighter;
        text-decoration: none;
        color: ${props => props.theme.headerLinkColor};
    }
`

const Footer = ({ cookiePrivacyUrl, termsUrl }) => (
  <IntroCol>
    <SecondarySection>
      <MaxWidthWrapper style={{ minWidth: '375px' }}>
        <IntroRow>
          <FlossieLegalsRow>
            <a href={cookiePrivacyUrl ? `/${cookiePrivacyUrl}` : "/privacy-policy"} target="_blank" rel="noopener noreferrer">Powered By Flossie Privacy Policy</a>
            {" | "}
            <a href={termsUrl ? `/${termsUrl}` : "/partner-terms"} target="_blank" rel="noopener noreferrer">Powered By Flossie Terms and Conditions</a>
          </FlossieLegalsRow>
        </IntroRow>
      </MaxWidthWrapper>
    </SecondarySection>
  </IntroCol>
)

Footer.propTypes = {
  termsUrl: PropTypes.string.isRequired,
  cookiePrivacyUrl: PropTypes.string.isRequired
}

export default Footer