import Immutable from "immutable"
import { createSelector } from "reselect"

export const REFRESH_INBOX_SUCCESS = "REFRESH_INBOX_SUCCESS"
export const REFRESH_INBOX_FAILURE = "REFRESH_INBOX_FAILURE"

// ACTIONS
export const refreshInboxSuccess = response => ({
  type: REFRESH_INBOX_SUCCESS,
  payload: { response },
})

export const refreshInboxFailure = error => ({
  type: REFRESH_INBOX_FAILURE,
  payload: { error },
})

// REDUCER
export const initialState = Immutable.fromJS({
  bookings: null,
  error: null,
})

export default function inboxReducer(state = initialState, action) {
  switch (action.type) {
    case REFRESH_INBOX_SUCCESS: {
      const { response } = action.payload
      return state.set("bookings", Immutable.fromJS(response.bookings))
    }
    case REFRESH_INBOX_FAILURE: {
      const { error } = action.payload
      return state.set("error", error)
    }
    default:
      return state
  }
}

// SELECTORS
const getInbox = state => state.get("inbox")
const getCompanies = state => state.get("companies")

export const getBookingsInbox = createSelector(
  getInbox,
  inbox => inbox.get("bookings"),
)

export const getNewBookingsInboxCount = createSelector(
  getBookingsInbox,
  bookings => bookings && parseInt(bookings.get("new"), 10),
)

export const getCompaniesPendingApprovalCount = createSelector(
  getCompanies,
  companies => {
    const ids = companies.get("ids")

    if (ids.count() < 1) {
      return 0
    }

    let count = 0

    for (let i = 0; i < ids.count(); i += 1) {
      const company = companies.get(ids.get(i))

      if (!company.get("visible") && !company.get("approvalRequested")) {
        count += 1
      }
    }

    return count
  },
)
