import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import { CenterScreenWrapper, SingleColumnPage, ContentWrapper } from "../../components/Wrappers"
import ForgotPasswordForm from "../../forms/ResetPassword/ForgotPasswordForm"
import { isForgotPasswordSent, getForgotPasswordError } from "../../redux/modules/auth"
import { InlineError } from "../../components/Form"

const ForgotPasswordContainer = ({
  forgotPasswordSent, forgotPasswordError
}) => {
  return (
    <CenterScreenWrapper>
      <SingleColumnPage>
        <ContentWrapper style={{ maxWidth: "276px" }}>
          {forgotPasswordSent ? (
            <div>
              <h3>We have sent you an email with the instructions to reset your password.</h3>
            </div>
          ) : (
            <ForgotPasswordForm />
          )}
          {forgotPasswordError && <InlineError style={{ textAlign: "center" }} error={forgotPasswordError} />}
        </ContentWrapper>
      </SingleColumnPage>
    </CenterScreenWrapper>
  )
}

ForgotPasswordContainer.propTypes = {
  forgotPasswordSent: PropTypes.bool, // selector
  forgotPasswordError: PropTypes.string, // selector
}

const mapStateToProps = state => ({
  forgotPasswordSent: isForgotPasswordSent(state),
  forgotPasswordError: getForgotPasswordError(state),
})

export default connect(mapStateToProps)(ForgotPasswordContainer)
