import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import CompanyAvatar from "./CompanyAvatar"

const StyledCompanyAvatar = styled(CompanyAvatar)`
    position: absolute;
    bottom: 1rem;
    right: 1rem;
`

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`

const CompanyGalleryImage = ({
  logoUrl = null, logoSize = 50, imageUrl, alt = ''
}) => (
  <Wrapper style={{ backgroundImage: `url(${imageUrl})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover' }}>
    {logoUrl && <StyledCompanyAvatar logoUrl={logoUrl} alt={alt} size={logoSize} />}
  </Wrapper>
)

CompanyGalleryImage.propTypes = {
  logoSize: PropTypes.number,
  logoUrl: PropTypes.string,
  imageUrl: PropTypes.string.isRequired,
  alt: PropTypes.string,
}

export default CompanyGalleryImage
