import React, { useEffect } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import styled from "styled-components"

import {
  loadAllFaqs,
  searchFaqs,
  getFaqsTopic,
  getSearchResults,
} from "../redux/modules/faqs"

const ParentDiv = styled.div`
  max-width: 980px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 80px;
  position: relative;
  padding:  15px;
  div > h1 + div {
    text-align: center;
  }
  a {
    cursor: pointer;
  }
  img, iframe {
    display: block;
    max-width: 100%;
    margin: 0 auto;
  }
`

const FaqsContentHoc = (InnerComponent) => {
  const FaqsContentWrapper = ({
    faqTopics = [], children = null, dispatchLoadAllFaqs, searchFaqs, searchResults, ...rest
  }) => {
    useEffect(() => {
      dispatchLoadAllFaqs()
    }, [])

    if (!faqTopics) return null

    return (
      <ParentDiv>
        <InnerComponent
          faqTopics={faqTopics}
          searchFaqs={searchFaqs}
          searchResults={searchResults}
          {...rest}
        >
          {children}
        </InnerComponent>
      </ParentDiv>
    )
  }
  FaqsContentWrapper.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    faqTopics: PropTypes.array,
    dispatchLoadAllFaqs: PropTypes.func.isRequired,
  }

  const mapStateToProps = state => ({
    faqTopics: getFaqsTopic(state),
    searchResults: getSearchResults(state),
  })

  const mapDispatchToProps = dispatch => ({
    dispatchLoadAllFaqs: () => dispatch(loadAllFaqs()),
    searchFaqs: query => dispatch(searchFaqs(query)),
  })

  return connect(mapStateToProps, mapDispatchToProps)(FaqsContentWrapper)
}

export default FaqsContentHoc
