import React from "react"
import Immutable from "immutable"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import ImmutablePropTypes from "react-immutable-proptypes"
import styled from "styled-components"

import ServicesList from "../../components/Services/ServicesList"
import FillerWrapper from "../../components/Wrappers/FillerWrapper"
import { isServicesLoading, companyServicesSelector, servicesDeleteService, getServiceDescriptions, getAllServiceDescriptions } from "../../redux/modules/services"
import { openConfirm } from "../../redux/modules/notifications"
import { getSelectedCompany } from "../../redux/modules/companies"
import ThemedRadioInput from "../../components/Form/ThemedRadioInput"
import SearchInput from "../../components/Form/SearchInput"
import SidebarWithContent from "../../components/Wrappers/SidebarWithContent"
import { H1 } from "../../components/Typography"
import { Navigate } from "react-router-dom"

const SearchFilter = styled.div`
  margin-bottom: 6px;
  label, input {
    cursor: pointer;
  }
`

const SearchBox = styled.div`
  margin: 2rem 0;
  font-size: 16px;
  label {
    display: block;
    margin-bottom: 10px;
    font-weight: bolder;
  }
  a {
    text-decoration: underline;
    cursor: pointer;
  }
`
const SearchHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

const NoServicesFiller = styled(FillerWrapper)`

    padding: 0;
    margin: 0;


    & > div {
      border: none;
      margin-top: 0px;
      margin-bottom: 0px;
      justify-content: center;
    }
`

const SERVICE_FILTERS = {
  ALL: "allServicesFilter",
  LIVE: "liveServicesFilter",
  NOT_BOOKABLE: "notBookableServicesFilter",
}

const ServicesContainer = ({
  company = null, services = Immutable.List(), servicesLoading, serviceDescriptions = null, dispatchGetAllServiceDescriptions
}) => {
  if (!company) return <Navigate to={'/settings'} />

  const [searchText, setSearchText] = React.useState("")
  const [filterSelected, setFilterSelected] = React.useState(SERVICE_FILTERS.ALL)
  // TODO: reinstate if further discussion merits doing so
  // const debouncedSearchText = useDebounce(searchText, 250)


  React.useEffect(() => {
    dispatchGetAllServiceDescriptions()
  }, [])

  const onChat = () => {
    Intercom("show")
  }

  const getFiller = () => (
    <React.Fragment>
      <NoServicesFiller>
        <div>You have no services available to sell! <span style={{ color: "#ef609f", cursor: "pointer", textDecoration: "underline" }} onClick={onChat}>Chat to our team</span> about getting these set up now!</div>
      </NoServicesFiller>
    </React.Fragment>
  )

  const getSearchFiller = () => (
    <React.Fragment>
      <NoServicesFiller>
        <div>Sorry, your search returned no results! <span style={{ color: "#ef609f", cursor: "pointer", textDecoration: "underline" }} onClick={onChat}>Chat to our team</span> if you need assistance!</div>
      </NoServicesFiller>
    </React.Fragment>
  )

  const matchAllWordsInString = (string, words) => words.every(word => string.toLowerCase().includes(word.toLowerCase()))

  const isSearching = (searchText && searchText.trim() !== "")
  /**
   * Filter services rows by search text
   * matches all words in either service name or category name.
   */
  let filteredServices = isSearching
    ? services.filter((service) => {
      const name = service.getIn(["serviceDescription", "name"]).toLowerCase()
      const descId = service.getIn(["serviceDescription", "id"])
      let categoryName = ""

      if (serviceDescriptions.has(descId)) {
        categoryName = serviceDescriptions.getIn([descId, "category", "name"]).toLowerCase()
      }
      const searchWords = searchText.split(" ")
      return matchAllWordsInString(name, searchWords) || matchAllWordsInString(categoryName, searchWords)
    })
    : services
  
  if(filterSelected !== SERVICE_FILTERS.ALL) {
    filteredServices = filteredServices.filter((service) => {
      const isLive = service.get("visible")
      if(filterSelected === SERVICE_FILTERS.LIVE) {
        return isLive
      } else if(filterSelected === SERVICE_FILTERS.NOT_BOOKABLE) {
        return !isLive
      }
      return false
    })
  }
  const emptyTableContent = isSearching ? getSearchFiller() : getFiller()

  const onFilterCheckboxChange = (e) => {
    const { id } = e.target
    setFilterSelected(id)
  }

  const resetSearchAndFilter = () => {
    setFilterSelected(SERVICE_FILTERS.ALL)

    setSearchText("")
    document.getElementById("searchService").value = ""
  }

  return (
    <SidebarWithContent
      sidebarContent={(
        <>
        <H1>
          Services
          {!servicesLoading && (<span>{` (${filteredServices.count()})`}</span>)}
        </H1>
          <p>This is your service menu, you can create new services by connecting your salon services to our curated list, then adding your employees or pricing.<br/><br/>You can change the pricing and employees assigned to these services at any time.</p>
          <SearchBox>
            <SearchHeader>
              <label htmlFor="searchService">Search</label>
              <a onClick={resetSearchAndFilter}>Reset</a>
            </SearchHeader>
            <SearchInput
              id="searchService"
              placeholder="By service name or category"
              onChange={(e) => { setSearchText(e.target.value) }}
            />
          </SearchBox>
          <SearchBox>
            <SearchHeader>
              <label>Filter</label>
            </SearchHeader>
            <SearchFilter><ThemedRadioInput onChange={onFilterCheckboxChange} name="servicesFilter" id={SERVICE_FILTERS.ALL} label="All Services" checked={filterSelected === SERVICE_FILTERS.ALL} /></SearchFilter>
            <SearchFilter><ThemedRadioInput onChange={onFilterCheckboxChange} name="servicesFilter" id={SERVICE_FILTERS.LIVE} label="Live" checked={filterSelected === SERVICE_FILTERS.LIVE} /></SearchFilter>
            <SearchFilter><ThemedRadioInput onChange={onFilterCheckboxChange} name="servicesFilter" id={SERVICE_FILTERS.NOT_BOOKABLE} label="Not Bookable" checked={filterSelected === SERVICE_FILTERS.NOT_BOOKABLE} /></SearchFilter>
          </SearchBox>
        </>
      )}
    >
      <ServicesList
        services={filteredServices || []}
        company={company}
        loading={servicesLoading}
        emptyTableContent={emptyTableContent}
      />
    </SidebarWithContent>
  )
}

ServicesContainer.propTypes = {
  company: ImmutablePropTypes.map, // currently selected company
  services: ImmutablePropTypes.list,
  servicesLoading: PropTypes.bool.isRequired, // true: means we are fetching services, false: we didnt start fetching or we finished fetching them.
  showConfirm: PropTypes.func.isRequired, // Action to show confirmation dialog

  // we need this to show the service about summary in each row of the service list.
  serviceDescriptions: ImmutablePropTypes.map,
  dispatchGetAllServiceDescriptions: PropTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => ({
  dispatchGetAllServiceDescriptions: () => dispatch(getAllServiceDescriptions()),
  showConfirm: (title, message, action) => dispatch(openConfirm(title, message, action)),
})

const mapStateToProps = state => ({
  services: companyServicesSelector(state),
  serviceDescriptions: getServiceDescriptions(state),
  servicesLoading: isServicesLoading(state),
  company: getSelectedCompany(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(ServicesContainer)
