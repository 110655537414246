import Immutable from "immutable"
import { createSelector } from "reselect"

import { PARTNER_TERMS, PRIVACY_POLICY } from "../../config/constants"
import PrismicContent from "../../../dist/all_prismic_data.json"

export const REGISTER_INVITE = "REGISTER_INVITE"
export const GET_INVITE_REQUEST = "GET_INVITE_REQUEST"
export const GET_INVITE_SUCCESS = "GET_INVITE_SUCCESS"
export const GET_INVITE_FAILURE = "GET_INVITE_FAILURE"
export const ACCEPT_INVITE_REQUEST = "ACCEPT_INVITE_REQUEST"
export const ACCEPT_INVITE_SUCCESS = "ACCEPT_INVITE_SUCCESS"
export const ACCEPT_INVITE_FAILURE = "ACCEPT_INVITE_FAILURE"
export const LOAD_COMPANY_TERMS = "LOAD_COMPANY_TERMS"
export const LOAD_COMPANY_PRIVACY_POLICY = "LOAD_COMPANY_PRIVACY_POLICY"
export const ACCEPTED_INVITE_TERMS_REQUEST = "ACCEPTED_INVITE_TERMS_REQUEST"
export const ACCEPTED_INVITE_TERMS_SUCCESS = "ACCEPTED_INVITE_TERMS_SUCCESS"
export const ACCEPTED_INVITE_TERMS_FAILURE = "ACCEPTED_INVITE_TERMS_FAILURE"
export const INVITE_SOFTWARE_CHECK_REQUEST = "INVITE_SOFTWARE_CHECK_REQUEST"
export const INVITE_SOFTWARE_CHECK_SUCCESS = "INVITE_SOFTWARE_CHECK_SUCCESS"
export const INVITE_SOFTWARE_CHECK_FAILURE = "INVITE_SOFTWARE_CHECK_FAILURE"
export const SOFTWARE_CHECK_RESPONSE = "SOFTWARE_CHECK_RESPONSE"

// ACTIONS
export const getInviteRequest = code => ({
  type: GET_INVITE_REQUEST,
  code,
})

export const getInviteSuccess = invite => ({
  type: GET_INVITE_SUCCESS,
  invite,
})

export const getInviteFailure = error => ({
  type: GET_INVITE_FAILURE,
  error,
})

export const registerAcceptInvite = ({ code }) => ({
  type: ACCEPT_INVITE_REQUEST,
  code,
})

export const registerInviteSuccess = result => ({
  type: ACCEPT_INVITE_SUCCESS,
  result,
})

export const registerInviteFailure = error => ({
  type: ACCEPT_INVITE_FAILURE,
  error,
})

export const acceptInviteTermsRequest = ({ code, acceptedTerms }) => ({
  type: ACCEPTED_INVITE_TERMS_REQUEST,
  code,
  terms_and_conditions: acceptedTerms,
})

export const acceptInviteTermsSuccess = result => ({
  type: ACCEPTED_INVITE_TERMS_SUCCESS,
  result,
})

export const acceptInviteTermsFailure = error => ({
  type: ACCEPTED_INVITE_TERMS_FAILURE,
  error,
})

export const inviteSoftwareCheckRequest = ({
  code, softwareSlug, externalId, externalUsername, externalPassword
}) => ({
  type: INVITE_SOFTWARE_CHECK_REQUEST,
  code,
  softwareSlug,
  externalId,
  externalUsername,
  externalPassword,
})

export const inviteSoftwareCheckSuccess = result => ({
  type: INVITE_SOFTWARE_CHECK_SUCCESS,
  result,
})

export const inviteSoftwareCheckFailure = error => ({
  type: INVITE_SOFTWARE_CHECK_FAILURE,
  error,
})

export const loadCompanyTerms = () => ({
  type: LOAD_COMPANY_TERMS,
})

export const loadCompanyPrivacyPolicy = () => ({
  type: LOAD_COMPANY_PRIVACY_POLICY,
})

// REDUCER
export const initialState = Immutable.fromJS({
  current: null,
  fetching: true,
  companyTerms: null,
  companyPrivacyPolicy: null,
  softwareConnected: false,
  connectingSoftware: false,
  sendingAuthCode: false,
  acceptingInvite: false,
  error: null,
})

const getLegalsContent = (key) => {
  if (PrismicContent && PrismicContent[key]) {
    const { results } = PrismicContent[key]
    return results.length === 1 ? results[0] : results.find(resultItem => resultItem.data.domain !== "default")
  }
  return null
}

const getCompanyTermsContent = () => getLegalsContent(PARTNER_TERMS)
const getCompanyPrivacyPolicyContent = () => getLegalsContent(PRIVACY_POLICY)


export default function inviteReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_INVITE_REQUEST":
      return state.set("fetching", true)
    case "GET_INVITE_SUCCESS":
      return state
        .set("current", Immutable.fromJS(action.invite.data))
        .set("fetching", false)
    case "GET_INVITE_FAILURE":
      return state.set("fetching", false)
    case "ACCEPT_INVITE_REQUEST":
      return state.set("acceptingInvite", true)
    case "ACCEPTED_INVITE_TERMS_FAILURE":
      return state.set("error", action.error)
    case "ACCEPT_INVITE_SUCCESS":
      return state
        .set("acceptingInvite", false)
        .remove("error")
    case "ACCEPT_INVITE_FAILURE":
      return state
        .set("acceptingInvite", false)
        .set("error", action.error)
    case LOAD_COMPANY_TERMS: {
      const prsimicBusinessTerms = getCompanyTermsContent()
      return state.merge({
        companyTerms: prsimicBusinessTerms ? Immutable.fromJS(prsimicBusinessTerms) : null,
      })
    }
    case LOAD_COMPANY_PRIVACY_POLICY: {
      const privacyPolicyContent = getCompanyPrivacyPolicyContent()
      return state.merge({
        companyPrivacyPolicy: privacyPolicyContent ? Immutable.fromJS(privacyPolicyContent) : null,
      })
    }
    case "INVITE_SOFTWARE_CHECK_REQUEST":
      return state.merge({
        softwareConnected: false,
        connectingSoftware: true,
      })
    case "INVITE_SOFTWARE_CHECK_FAILURE":
      return state.merge({
        softwareConnected: false,
        connectingSoftware: false,
        error: action.error,
      })
    case "INVITE_SOFTWARE_CHECK_SUCCESS":
      return state.merge({
        softwareConnected: true,
        connectingSoftware: false,
        error: null,
      })
    default:
      return state
  }
}

// SELECTORS
export const getInvitesData = state => state.get("invite")

export const getCurrentInvite = createSelector(
  getInvitesData,
  data => data.get("current"),
)

export const isFetchingInvite = createSelector(
  getInvitesData,
  data => data.get("fetching"),
)

export const isAcceptingInvite = createSelector(
  getInvitesData,
  data => data.get("acceptingInvite"),
)

export const getInviteError = createSelector(
  getInvitesData,
  data => data.get("error"),
)

export const getCompanyTerms = createSelector(
  getInvitesData,
  data => data.get("companyTerms"),
)

export const getCompanyPrivacyPolicy = createSelector(
  getInvitesData,
  data => data.get("companyPrivacyPolicy"),
)

export const isSoftwareConnected = createSelector(
  getInvitesData,
  data => data.get("softwareConnected"),
)

export const isConnectingSoftware = createSelector(
  getInvitesData,
  data => data.get("connectingSoftware"),
)
