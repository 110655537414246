import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"

import { device } from "../../components/Responsive/Responsive"

const InfoCardWrapper = styled.div`
  box-sizing: border-box;
  background-color: white;
  border-radius: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 10px;
  text-align: center;
  min-height: 100%;
`

const InfoCardNumber = styled.div`
  background-color: ${props => props.theme.primaryColor};
  color: white;
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 30px;
  position: absolute;
  line-height: 30px;
  top: -15px;
  font-weight: bold;
`

const InfoCardIcon = styled.img`
  width: 80%;
  @media ${device.medium} {
    width: 40%;
  }
`

const InfoCardHeading = styled.h5`
  font-size: 18px;
  margin: 0 0 4px 0;
`

const InfoCardContent = styled.p`
  margin-top: 5px;
  max-width: 420px;
  @media ${device.medium} {
    max-width: 100%;
  }
`

const InfoCardLinkStyles = css`
  font-weight: bold;
  display: block;
  margin-top: 1rem;
  text-decoration: underline;
  cursor: pointer;
  color: ${props => props.theme.headerLinkColor};
`

const InfoCardLink = styled.a`
  ${InfoCardLinkStyles}
`

const InfoCardAnchorLink = styled.a`
  ${InfoCardLinkStyles}
`

const InfoCard = ({
  count,
  icon,
  headline,
  content,
  buttonText,
  buttonDestination,
}) => (
  <InfoCardWrapper>
    {count && <InfoCardNumber>{count}</InfoCardNumber>}
    <InfoCardIcon src={icon} />
    <InfoCardHeading>{headline}</InfoCardHeading>
    <div className="small-paragraph"><InfoCardContent>{content}</InfoCardContent></div>
    {buttonText && buttonDestination && buttonDestination.startsWith("#") ? (
      <InfoCardAnchorLink href={buttonDestination}>
        {buttonText}
      </InfoCardAnchorLink>
    ) : (
      <InfoCardLink href={buttonDestination}>{buttonText}</InfoCardLink>
    )}
  </InfoCardWrapper>
)

InfoCard.propTypes = {
  count: PropTypes.number,
  icon: PropTypes.string,
  headline: PropTypes.string,
  content: PropTypes.string,
  buttonText: PropTypes.string,
  buttonDestination: PropTypes.string,
}
export default InfoCard
