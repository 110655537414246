import * as prismic from "@prismicio/client"

import { PRISMIC_REPO, PRISMIC_ACCESS_TOKEN } from "./config/constants"

const endpoint = prismic.getEndpoint(PRISMIC_REPO)

const client = prismic.createClient(endpoint, {
  // If your repo is private, add an access token
  accessToken: PRISMIC_ACCESS_TOKEN,
  routes: [
    {
      type: "supplier_campaigns",
      path: "/:uid",
    },
  ],
})

export default client
