import React from "react"
import PropTypes from "prop-types"

export default function LoginToolbar(props) {
  const { isAuthed, clickHandler } = props
  const label = isAuthed ? "Logout" : "Login"

  return (
    <div
      tabIndex={0}
      role="button"
      className="tk-museo-sans"
      onClick={evt => clickHandler(evt)}
      onKeyUp={(evt) => {
        if (evt.key === "Enter") {
          clickHandler(evt)
        }
      }}
    >
      {label}
    </div>
  )
}

LoginToolbar.propTypes = {
  isAuthed: PropTypes.bool.isRequired,
  clickHandler: PropTypes.func.isRequired,
}
