import React from "react"
import PropTypes from "prop-types"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"

import { ArrowLeftIcon } from "../Icons/Icons"

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;
`

const Header = ({ children }) => {

  const navigate = useNavigate();

  return (
    <Container>
      <a
        href="/help/faqs"
        onClick={(e) => {
          e.preventDefault()
          navigate("/help/faqs")
        }}
        style={{ position: "relative", marginRight: "15px" }}
      >
        <ArrowLeftIcon />
      </a>
      {children}
    </Container>
  )
}

Header.propTypes = {
  children: PropTypes.element.isRequired,
}

export default Header
