/* eslint-disable camelcase, no-restricted-syntax */
import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import ImmutablePropTypes from "react-immutable-proptypes"

import {
  HELP_FAQ_TAB_TITLE,
  HELP_TERMS_TAB_TITLE,
  HELP_POLICY_TAB_TITLE,
  HELP_CONTACT_TAB_TITLE,
} from "../../config/constants"
import {
  getCompanyPrivacyPolicy, getCompanyTerms, loadCompanyPrivacyPolicy, loadCompanyTerms
} from "../../redux/modules/invite"
import NavigateTab from "../../components/Navigation/Tabs/NavigateTab"
import Tabs from "../../components/Navigation/Tabs"
import MainContent from "../../components/Wrappers/MainContent"
import { Outlet, useLocation, useParams } from "react-router-dom"


const HelpMasterPage = ({ companyTerms = null, companyPrivacyPolicy = null, dispatchLoadCompanyTermsContent, dispatchLoadCompanyPrivacyPolicy, ...rest }) => {
  React.useEffect(() => {
    dispatchLoadCompanyTermsContent()
    dispatchLoadCompanyPrivacyPolicy()
  }, [])

  const getTabs = () => {
    const helpPath = "/help"
    const tabs = [{
      id: "help-faq",
      path: `${helpPath}/faqs`,
      // style: { display: showFaqs ? browser.greaterThan.mobile ? 'inline-block' : 'block' : 'none' },
      title: HELP_FAQ_TAB_TITLE,
    }]
    if (companyTerms) tabs.push({ id: "help-terms", path: `${helpPath}/terms`, title: HELP_TERMS_TAB_TITLE })
    if (companyPrivacyPolicy) tabs.push({ id: "help-privacy-policy", path: `${helpPath}/privacy-policy`, title: HELP_POLICY_TAB_TITLE })
    tabs.push({ id: "help-contact", title: HELP_CONTACT_TAB_TITLE, path: `${helpPath}/contact` })

    return tabs
  }

  return (
    <React.Fragment>
      <Tabs>
        {getTabs().map(tab => <NavigateTab key={tab.id} to={tab.path}>{tab.title}</NavigateTab>)}
      </Tabs>

      <MainContent>
        <Outlet />
      </MainContent>

    </React.Fragment>
  )
}

HelpMasterPage.propTypes = {
  companyTerms: ImmutablePropTypes.map,
  dispatchLoadCompanyTermsContent: PropTypes.func.isRequired,
  dispatchLoadCompanyPrivacyPolicy: PropTypes.func.isRequired,
  companyPrivacyPolicy: ImmutablePropTypes.map,
}

const mapStateToProps = state => ({
  companyTerms: getCompanyTerms(state),
  companyPrivacyPolicy: getCompanyPrivacyPolicy(state),
})

const mapDispatchToProps = dispatch => ({
  dispatchLoadCompanyTermsContent: () => dispatch(loadCompanyTerms()),
  dispatchLoadCompanyPrivacyPolicy: () => dispatch(loadCompanyPrivacyPolicy()),
})

export default connect(mapStateToProps, mapDispatchToProps)(HelpMasterPage)
