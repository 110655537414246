import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { device } from "../../Responsive/Responsive"
import NavigateTab from "./NavigateTab"

const Container = styled.div`
    ${({ $size }) => ($size === "sm" ? (`
      line-height: 50px;
      `) : (`
      line-height: 80px;
    `))};
    display: block;
    text-align: ${({ $align }) => $align};
    background: ${({ theme }) => theme.headerBg};
    border-bottom: solid 1px ${({ theme }) => theme.borderColor};
    white-space: nowrap;
    @media ${device.mobile} {
        cursor: grab;
        overflow: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }
    }
`

const Tabs = ({ children, size = 'lg', align = 'center', ...rest }) => (
  <Container $size={size} $align={align}>
    {children.map(navigateTab => React.cloneElement(navigateTab, { ...rest, size }))}
  </Container>
)

Tabs.propTypes = {
  children: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.oneOf([NavigateTab]),
  })).isRequired,
  size: PropTypes.oneOf(["sm", "lg"]),
  align: PropTypes.oneOf(["left", "center", "right"]),
}

export default Tabs
