import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import { Dashboard } from "../../components"
import InboxContainer from "../Inbox/InboxContainer"
import StatsContainer from "../Stats/StatsContainer"

import { STATISTICS_KEY_COMPANY, STATISTICS_KEY_FRANCHISE } from "../../redux/modules/statistics"

import { API_ROLE_STATISTICS, API_ROLE_COMPANY } from "../../config/constants"

import { hasRole } from "../../redux/modules/auth"

import { getStatistics } from "../../redux/modules/statistics"
import { getBookingsInbox } from "../../redux/modules/inbox"
import { getAmountOfVisibleCompanies } from "../../redux/modules/companies"

import { LoadingText } from "../../components/Loading"

class DashboardContainer extends React.Component {
  render() {
    const { franchiseStatistics, companyStatistics, inbox, visibleCompanies } = this.props

    const showLoading = (franchiseStatistics === null && companyStatistics === null && inbox === null)

    return (
      <Dashboard>
        {showLoading && (<LoadingText />)}
        {hasRole(API_ROLE_COMPANY) && (<InboxContainer />)}
        {hasRole(API_ROLE_COMPANY) && visibleCompanies > 0 && (<StatsContainer title="Your Salons" type={STATISTICS_KEY_COMPANY} />)}
        {hasRole(API_ROLE_STATISTICS) && (<StatsContainer title="Business Performance" type={STATISTICS_KEY_FRANCHISE} />)}
      </Dashboard>
    )
  }
}

DashboardContainer.defaultProps = {
  visibleCompanies: 0,
}

DashboardContainer.propTypes = {
  franchiseStatistics: PropTypes.object,
  companyStatistics: PropTypes.object,
  visibleCompanies: PropTypes.number,
  inbox: PropTypes.object
}

const mapStateToProps = state => ({
  franchiseStatistics: getStatistics(STATISTICS_KEY_FRANCHISE, state),
  companyStatistics: getStatistics(STATISTICS_KEY_COMPANY, state),
  visibleCompanies: getAmountOfVisibleCompanies(state),
  inbox: getBookingsInbox(state),
})


export default connect(mapStateToProps)(DashboardContainer)
