import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import ImmutablePropTypes from "react-immutable-proptypes"
import styled from "styled-components"

import { authSetCompanyId } from "../../redux/modules/auth"
import { getSelectedCompany } from "../../redux/modules/companies"
import { device } from "../../components/Responsive/Responsive"
import CompanyGalleryImage from "../../components/Company/CompanyGalleryImage"
import CompanyAvatar from "../../components/Company/CompanyAvatar"
import { H1 } from "../../components/Typography"

const Wrapper = styled.div`
    margin: 0 auto;
    display: grid;
    gap: 16px;
    grid-auto-rows: minmax(222px, auto);
    grid-template-columns: 1fr 1fr 1fr 1fr;

    @media ${device.large} {
      grid-template-columns: 1fr 1fr;
    }

    @media ${device.mobile} {
      grid-template-columns: 1fr 1fr;
    }
`

const CompanyPending = styled.div`
    position: absolute;
    top: 20px;
    left: 0;
    background-color: ${({ theme }) => theme.primaryColor};
    color: #fff;
    padding: 4px 8px;
`

const CompanyPendingWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
`

const CompanyGalleryImageWrapper = styled.div`
    width: 100%;
    height: 140px;
    max-height: 140px;
    overflow: hidden;
    position: relative;
`
// border: solid 2px ${({ theme }) => theme.primaryColor};
const CompanyCard = styled.button`
    display: block;

    padding: 0;
    margin: 0;
    background: none;
    outline: none;
    border: none;

    width: 100%;
    height: 100%;
    box-sizing: border-box;

    &:hover {
        cursor: pointer;

    }
`

const CardBody = styled.div`
    width: calc(100% - 34px);
    height: calc(100% - 172px);
    padding: 16px;
    border: solid 1px ${({ theme }) => theme.borderColor};
    border-top: none;
    text-align: left;
    font-family: 'Museo-Sans','sans-serif';
    font-weight: 300;

    h3 {
      margin-top: 0px;
      margin-bottom: 8px;
      font-weight: 300;
      font-size: 17px;
    }
`

const TileCompaniesPickerContainer = ({ onCompanySelected, companies = undefined }) => {
  const ids = companies.get("ids")

  return (
    <div>
      <H1>Select a Salon</H1>
      <Wrapper>
        {ids.count() > 0 ? ids.map((companyId) => {
          const company = companies.get(companyId)
          return (
            <CompanyCard key={`company-${companyId}`} onClick={() => onCompanySelected(company.get("id"))}>
              <CompanyGalleryImageWrapper>
                <CompanyGalleryImage
                  imageUrl={company.get("galleryImageUrl")}
                  alt={company.get("name")}
                />
                {!company.get("visible") && <CompanyPendingWrapper><CompanyPending>Approval required</CompanyPending></CompanyPendingWrapper>}
              </CompanyGalleryImageWrapper>
              <CardBody>
                <h3>{company.get("name")}</h3>
                <div style={{ display: "flex" }}>
                  <div style={{ flex: 1 }}>
                    <div>{company.get("addressOne")}</div>
                    {company.get("addressTwo") && <div>{company.get("addressTwo")}</div>}
                    {company.get("postCode") && <div>{company.get("postCode")}</div>}
                  </div>
                  <CompanyAvatar logoUrl={company.get("logoUrl")} />
                </div>
              </CardBody>
            </CompanyCard>
          )
        }) : null}
      </Wrapper>
    </div>
  )
}

TileCompaniesPickerContainer.propTypes = {
  onCompanySelected: PropTypes.func.isRequired,
  companies: ImmutablePropTypes.map,
}

function mapDispatchToProps(dispatch) {
  return ({
    onCompanySelected: companyId => dispatch(authSetCompanyId(companyId)),
  })
}

function mapStateToProps(state) {
  return {
    companies: state.get("companies"),
    selectedCompany: getSelectedCompany(state),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TileCompaniesPickerContainer)
