const THEME = {
  // Colors
  primaryColor: "#ef609f",
  primaryLighterColor: "#facfe2",
  primaryContrastColor: "#fff",
  headerBg: "#fff",
  headerLinkColor: "#575757",
  borderColor: "#ececec",
  disabledColor: "#dedede",
  errorColor: "#e74c3c",
  labelDarkColor: "#343434",
  // Images
  logo: "/images/logos/flossie.svg",
  // Layout
  parentPadding: "25px",
  parentPaddingX2: "50px",
  desktopSideBarWidth: "220px",
  maxWidthColumn: "1200px",

  franchiseGlobalStyles: "",
}

export default THEME
