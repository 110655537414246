import React from "react"
import {
  IntroCol,
  IntroHeadline,
  IntroImage,
  IntroRow, IntroWrapper,
  MaxWidthWrapper,
  Section,
  Spacer,
  TopHeader
} from "../StyledComponents"
import { Button } from "../../../components/Form"
import styled from "styled-components"

const ButtonsRow = styled.div`
  margin-top: 2rem;
  a + a {
    margin-left: 2rem;
  }
`

const SimpleLink = styled.a`
  color: black;
  text-decoration: underline;
`

export default () => {
  const headline = 'Reach new clients on Aveda.com';
  const title = 'More Appointments. More Clients.';
  const description = (
    <>
      <p>Customers can look for their prefered Aveda salon on Aveda.com and book an appointment.</p>
      <p>
        Connecting to your salon software means real-time availability. Bookings will drop directly into your calendar for seamless, stress-free scheduling.
      </p>
    </>
  );

  const imageUrl = 'https://images.prismic.io/flossie-production/db9dcfd5-5801-4fd0-8aec-9a00f1302e0f_Prismic+-+3508+x+2480.jpg?auto=compress%2Cformat&w=800&h=800&fit=max'

  const button = {
    text: 'See the live site!',
    destination: `https://${process.env.CONSUMER_SITE}/`,
    backgroundColor: '#000'
  }

  const secondaryButton = {
    text: 'Read FAQs',
    destination: 'aveda/faqs',
  }

  return (
    <Section background="white">
      <MaxWidthWrapper>
        <Spacer />
        <IntroRow centered>
          {imageUrl && (
            <IntroCol hideOnMobile>
              <IntroImage src={imageUrl} alt="Flossie Campaign Header" />
            </IntroCol>
          )}
          <IntroCol>
            <IntroWrapper>
              <TopHeader><h4>{title}</h4></TopHeader>
              <IntroHeadline><h1>{ headline }</h1></IntroHeadline>
              {description}
              {imageUrl && (
                <IntroCol hideOnDesktop>
                  <IntroImage src={imageUrl} alt="Flossie Campaign Header" />
                </IntroCol>

              )}
            </IntroWrapper>

            <ButtonsRow>
              <a href={button.destination}>
                <Button uppercase size="sm" backgroundColor={button.backgroundColor}>{button.text}</Button>
              </a>
              <SimpleLink href={secondaryButton.destination} target="_blank">
                {secondaryButton.text}
              </SimpleLink>
            </ButtonsRow>

          </IntroCol>
        </IntroRow>
      </MaxWidthWrapper>
    </Section>
  )
}
