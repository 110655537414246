import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import { device } from "../Responsive/Responsive"
import { TrendDownIcon, TrendFlatIcon, TrendUpIcon } from "../Icons/Icons"

const StatsRow = styled.div`
    color: #575757;

    @media ${device.mobile} {
      margin: 10px auto;
    }
`

const StatContainer = styled.div`
    display: inline-block;
    text-align: left;
`
const StatValue = styled.div`
    font-weight: 800;
    font-size: 40px;
    color: #ef609f;
    margin-bottom: 10px;
    letter-spacing: 1px;
`
const StatLabel = styled.div`
    font-weight: 500;
    font-size: 1.2em;
    color: #575757;
    text-align: left;
`
const StatTrend = styled.div`
    font-weight: 500;
    font-size: 1.0em;
    color: #868686;
`

export default function Stats({
  total, label, change, trend
}) {
  return (
    <StatsRow className="tk-museo-sans">
      <StatContainer>
        <StatValue>{total}</StatValue>
        <StatLabel>{label}</StatLabel>
        <div style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginTop: "4px",
        }}
        >
          {trend === "up" && <TrendUpIcon />}
          {trend === "down" && <TrendDownIcon />}
          {trend === "flat" && <TrendFlatIcon />}
          <StatTrend style={{ marginLeft: "6px" }}>{change}</StatTrend>
        </div>
      </StatContainer>
    </StatsRow>
  )
}
Stats.propTypes = {
  total: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  label: PropTypes.string.isRequired,
  change: PropTypes.string.isRequired,
  trend: PropTypes.string.isRequired,

}
