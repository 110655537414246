import React from "react"
import PropTypes from "prop-types"
import { PrismicRichText } from "@prismicio/react"
import styled from "styled-components"

import ContainerWithSideBar from "../../components/Faqs/ContainerWithSideBar"
import SearchInput from "../../components/Faqs/SearchInput"
import FaqsContentHoc from "../../hocs/FaqsContentHoc"
import { TopicLink } from "../../components/Faqs/FaqLink"
import Header from "../../components/Faqs/Header"
import { useNavigate, useParams } from "react-router-dom"

const AnswerContent = styled.div`
  font-size: 17px;
  text-align: left !important;
    
    & a {
        text-decoration: none;
        color: var(--flossie-pink);
    }
`

const Answer = ({ faqTopics }) => {

  const navigate = useNavigate()
  const params = useParams()
  const faqSlug = params.faq;

  if (!faqSlug) {
    navigate('/help/faqs');
  }

  let faqData = null;
  const faqTopic = faqTopics.find((eachFaqTopic) => {
      faqData = eachFaqTopic.faqs.find(
        (eachFaq) => eachFaq.slug === faqSlug
      );

      return !!faqData
    }
  )

  if (!faqData) {
    return null
  }

  const showEmbedVideo = faqContent => (
    faqContent.data.video ? <div style={{ width: "100%" }} dangerouslySetInnerHTML={{ __html: faqContent.data.video.html }} /> : null
  )

  return (
    <div>
      <Header><TopicLink size="h3" topic={faqTopic} suffix=" FAQs" /></Header>
      <SearchInput />
      <ContainerWithSideBar topics={faqTopics}>
        <div style={{ flex: 1 }}>
          <h1>{faqData.data.question}</h1>
          <AnswerContent>
            <PrismicRichText field={faqData.data.answer} />
          </AnswerContent>
          {showEmbedVideo(faqData)}
        </div>
      </ContainerWithSideBar>
    </div>
  )
}

Answer.propTypes = {
  faqTopics: PropTypes.array.isRequired,
}

export default FaqsContentHoc(Answer)
