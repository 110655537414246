import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const Container = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
`

const CenterScreenWrapper = ({ children }) => (
  <Container>
    {children}
  </Container>
)

CenterScreenWrapper.propTypes = {
  children: PropTypes.element.isRequired,
}
export default CenterScreenWrapper
