import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const ThemedLabel = styled.label`
  font-family: 'Museo-Sans', 'sans-serif';
  font-weight: 100;
  color: ${({ theme }) => theme.labelColor};
  font-size: 15px;
  ${({ $hasToolTip }) => $hasToolTip && `
    > div { 
      float: right;
    }
  `}
`

const Label = ({ children, ...rest }) => <ThemedLabel {...rest}>{children}</ThemedLabel>

Label.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
}

export default Label
