import React from "react"

import LoginForm from "../../forms/Login/LoginForm"
import { CenterScreenWrapper, SingleColumnPage, ContentWrapper } from "../../components/Wrappers"

function LoginContainer() {
  return (
    <CenterScreenWrapper>
      <SingleColumnPage>
        <ContentWrapper>
          <LoginForm />
        </ContentWrapper>
      </SingleColumnPage>
    </CenterScreenWrapper>
  )
}

LoginContainer.propTypes = {}

export default LoginContainer
