import React from "react"

import LoadingIcon from "./LoadingIcon"

const LoadingText = () => (
  <div style={{ marginTop: 40 }}>
    <div style={{ display: "flex" }}>
      <div style={{ flex: 1 }} />
      <LoadingIcon />
      <div style={{ lineHeight: "24px", marginLeft: 4 }}>Loading</div>
      <div style={{ flex: 1 }} />
    </div>
  </div>
)

export default LoadingText
