import Immutable from "immutable"

import { BOOKINGS_LOADING_SUCCESS } from "./bookings"

export const PAGINATION_CHANGE_PAGE = "PAGINATION_CHANGE_PAGE"

// ACTIONS
export const changePage = (listName, page) => ({
  type: PAGINATION_CHANGE_PAGE,
  payload: { listName, page },
})

// REDUCER
export const initialState = Immutable.fromJS({
  bookings: { currentPage: 0, totalPages: 0 },
})

export default function paginationReducer(state = initialState, action) {
  switch (action.type) {
    case PAGINATION_CHANGE_PAGE: {
      const { listName, page } = action.payload
      return state.mergeIn([listName], Immutable.fromJS({ currentPage: page }))
    }
    case BOOKINGS_LOADING_SUCCESS: {
      const { count, limit } = action.payload
      return state.mergeIn(["bookings"], Immutable.fromJS({ totalPages: Math.ceil((count / limit)) }))
    }
    default:
      return state
  }
}

// SELECTORS
export const getPagination = state => state.get("pagination")
