/* eslint-disable camelcase */
import { List, Map } from "immutable"
import { createSelector } from "reselect"
import { BULK_INSERT } from "./helpers"
import { AUTH_LOGOUT } from "./auth"

import PrismicContent from "../../../dist/all_prismic_data.json"

const CONTACT_ADD = "CONTACT_ADD"
const CONTACTS_DELETE_CONTACT_START = "CONTACTS_DELETE_CONTACT_START"
const CONTACTS_DELETE_CONTACT_SUCCESS = "CONTACTS_DELETE_CONTACT_SUCCESS"
const CONTACTS_DELETE_CONTACT_FAIL = "CONTACTS_DELETE_CONTACT_FAIL"
const CONTACTS_CREATE_CONTACT_START = "CONTACTS_CREATE_CONTACT_START"
const CONTACTS_CREATE_CONTACT_SUCCESS = "CONTACTS_CREATE_CONTACT_SUCCESS"
const CONTACTS_CREATE_CONTACT_FAIL = "CONTACTS_CREATE_CONTACT_FAIL"


export const CONTACT_DELETE = "CONTACT_DELETE"
export const CONTACT_CREATE = "CONTACT_CREATE"

export const CONTACT_EDIT = "CONTACT_EDIT"
export const CONTACT_ADD_NEW = "CONTACT_ADD_NEW"
export const CONTACT_EDIT_SELECT = "CONTACT_EDIT_SELECT"
export const CONTACT_EDIT_RESET = "CONTACT_EDIT_RESET"


export const CONTACTS_SAVE_CONTACT = "CONTACTS_SAVE_CONTACT"
export const contactsSaveContact = (companyId, contactValues) => ({
  type: CONTACTS_SAVE_CONTACT,
  id: !contactValues.id || contactValues.id === "0" ? undefined : contactValues.id,
  company_id: companyId,
  first_name: contactValues.firstName,
  last_name: contactValues.lastName,
  role_id: contactValues.roleId,
  phone_number: contactValues.phoneNumber,
  email: contactValues.email,
})
const CONTACTS_SAVE_CONTACT_START = "CONTACTS_SAVE_CONTACT_START"
export const contactsSaveStart = () => ({ type: CONTACTS_SAVE_CONTACT_START })
export const CONTACTS_SAVE_CONTACT_SUCCESS = "CONTACTS_SAVE_CONTACT_SUCCESS"
export const contactsSaveSuccess = () => ({ type: CONTACTS_SAVE_CONTACT_SUCCESS })
const CONTACTS_SAVE_CONTACT_FAIL = "CONTACTS_SAVE_CONTACT_FAIL"
export const contactsSaveFail = e => ({ type: CONTACTS_SAVE_CONTACT_FAIL, error: e })

export const CONTACT_ROLES = {
  0: "General",
  1: "Accounts",
  // 2: 'Business Manager',
  // 3: 'Creative Director',
  // 4: 'Director',
  // 5: 'Front of House Manager',
  // 6: 'Head Makeup Artist',
  // 7: 'Marketing',
  // 8: 'Offers',
  9: "Owner",
  // 10: 'Salon Manager',
  // 11: 'Tester',
}

export function contactsDeleteContact(company_id, id) {
  return {
    type: CONTACT_DELETE,
    company_id,
    id,
  }
}

export function contactEdit(id) {
  return {
    type: CONTACT_EDIT,
    payload: {
      id
    }
  }
}

export function contactAddNew() {
  return {
    type: CONTACT_ADD_NEW,
  }
}

export function selectContactForEditing(id) {
  return {
    type: CONTACT_EDIT_SELECT,
    payload: {
      id
    }
  }
}

export function resetContactEditForm() {
  return {
    type: CONTACT_EDIT_RESET,
  }
}

export function contactsDeleteContactStart() {
  return {
    type: CONTACTS_DELETE_CONTACT_START,
  }
}

export function contactsDeleteContactSuccess() {
  return {
    type: CONTACTS_DELETE_CONTACT_SUCCESS,
  }
}

export function contactsDeleteContactFail(error) {
  return {
    type: CONTACTS_DELETE_CONTACT_FAIL,
    error,
  }
}

export function contactsCreateContactStart() {
  return {
    type: CONTACTS_CREATE_CONTACT_START,
  }
}

export function contactsCreateContactSuccess() {
  return {
    type: CONTACTS_CREATE_CONTACT_SUCCESS,
  }
}

export function contactsCreateContactFail(error) {
  return {
    type: CONTACTS_CREATE_CONTACT_FAIL,
    error,
  }
}


const initialContactState = Map({
  id: 0,
  companyId: 0,
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  roleId: 0,
  role: "",
  isModified: false,
})

export function contactsAdd(companyId, c) {
  return {
    type: CONTACT_ADD,
    companyId,
    id: c.id,
    firstName: c.first_name,
    lastName: c.last_name,
    email: c.email,
    phoneNumber: c.phone_number,
    roleId: c.role_id,
    role: CONTACT_ROLES[c.role_id],
  }
}


function contact(state = initialContactState, action) {
  switch (action.type) {
    case CONTACTS_SAVE_CONTACT:
      return state.merge({
        firstName: action.firstName,
        lastName: action.lastName,
        email: action.email,
        phoneNumber: action.phoneNumber,
        roleId: action.roleId,
        role: CONTACT_ROLES[action.roleId],
        isModified: false,
      })
    case CONTACT_ADD:
      return state.merge({
        id: action.id,
        companyId: action.companyId,
        firstName: action.firstName,
        lastName: action.lastName,
        email: action.email,
        phoneNumber: action.phoneNumber,
        roleId: action.roleId,
        role: CONTACT_ROLES[action.roleId],
        isModified: false,
      })
    default:
      return state
  }
}

const initialContactsState = Map({
  currentContactId: 0,
  ids: List(),
  isDeleting: false,
  isSaving: false,
  error: null,
})

export default function contacts(state = initialContactsState, action) {
  switch (action.type) {
    case BULK_INSERT:
      if (action.contacts) {
        let s = state
        action.contacts.forEach((c) => {
          const ids = s.get("ids")
          s = s.merge({
            [c.id]: contact(s.get(c.id), contactsAdd(c.company_id, c)),
            ids: ids.indexOf(c.id) === -1 ? ids.push(c.id) : ids,
          })
        })
        return s
      }
      return state
    case CONTACT_ADD: {
      const ids = state.get("ids")
      const c = state.get(action.id)
      return state.merge({
        [action.id]: contact(c, action),
        ids: ids.indexOf(action.id) === -1 ? ids.push(action.id) : ids,
      })
    }
    case CONTACT_DELETE: {
      const ids = state.get("ids")
      return state.delete(action.contact_id)
        .merge({
          ids: ids.delete(ids.indexOf(action.contact_id)),
        })
    }
    case CONTACT_EDIT_SELECT:
      return state.merge({
        currentContactId: action.payload.id,
      })
    case CONTACT_EDIT_RESET:
      return state.merge({
        currentContactId: initialContactsState.get("currentContactId"),
      })
    case CONTACTS_DELETE_CONTACT_START:
      return state.merge({
        isDeleting: true,
      })
    case CONTACTS_DELETE_CONTACT_SUCCESS:
      return state.merge({
        isDeleting: false,
      })
    case CONTACTS_DELETE_CONTACT_FAIL:
      return state.merge({
        isDeleting: false,
        error: action.error,
      })
    case CONTACTS_SAVE_CONTACT_START:
      return state.merge({
        isSaving: true,
        error: null
      })
    case CONTACTS_SAVE_CONTACT_SUCCESS:
      return state.merge({
        isSaving: false,
      })
    case CONTACTS_SAVE_CONTACT_FAIL:
      return state.merge({
        isSaving: false,
        error: action.error,
      })
    case AUTH_LOGOUT:
      return initialContactsState
    default:
      return state
  }
}

const getContactsState = state => state.get("contacts")

const contactsSelector = state => state.getIn(["contacts", "ids"])
  .map(id => state.getIn(["contacts", id]))

export const companyContactsSelector = (state) => {
  const companyId = state.getIn(["auth", "authCompanyId"])
  return contactsSelector(state)
    .filter(c => c.get("companyId") === companyId)
}

export const getContactLinks = () => {
  if (PrismicContent && PrismicContent.salon_dashboard_contact_page) {
    const contactContent = PrismicContent.salon_dashboard_contact_page.results.find(result => result.data.name === "Salon_Dashboard_Contact_Page")
    if (contactContent) {
      const socialMediaLinks = contactContent.data.body.find(item => item.slice_type === "social_media_links")
      return socialMediaLinks ? socialMediaLinks.items : []
    }
  }
  return []
}

export const isSavingSelector = createSelector(
  getContactsState,
  contactsState => contactsState.get("isSaving"),
)

export const hasErrorSelector = createSelector(
  getContactsState,
  contactsState => contactsState.get("error"),
)
