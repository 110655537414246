import React from "react"
import styled from "styled-components"

import { ServiceName } from "./common"
import ServiceStatusBadge from "./StatusBadge"
import ServiceImage from "./Image"

import { device } from "../Responsive/Responsive"

const ServiceCard = styled.div`
  position: relative;
  max-width: 240px;
  width: 240px;
  padding-bottom: 12px;
  ${ServiceName} {
    margin-top: 8px;
    line-height: 1.3;
  }

  & > img {
    width: 100%;
    height: auto;
    display: block;
  }

  @media ${device.tablet}, ${device.desktop} {

    img {
      min-height: 240px
    }
  }

  @media ${device.mobile} {
    width: 100%;
    max-width: none;

    img {
      min-height: none;
    }
  }
`

const FloatingBadge = styled.div`
  position: absolute;
  top: 8px;
  left: 8px;
`

const ServiceCardView = ({ image, description, live }) => {
  return (
    <ServiceCard>
      <ServiceImage src={image} />
      <FloatingBadge><ServiceStatusBadge live={live} /></FloatingBadge>
      <div><ServiceName>{description}</ServiceName></div>
    </ServiceCard>
  )
}

export default ServiceCardView
