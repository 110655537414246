import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import SearchInput from "../../components/Faqs/SearchInput"
import ContainerWithSideBar from "../../components/Faqs/ContainerWithSideBar"
import { QuestionLink } from "../../components/Faqs/FaqLink"
import FaqsContentHoc from "../../hocs/FaqsContentHoc"
import Header from "../../components/Faqs/Header"
import { useNavigate, useParams } from "react-router-dom"

const Title = styled.h1`
  text-align: center;
  flex: 1;
`

const FaqsTopic = ({
  faqTopics
}) => {
  const navigate = useNavigate();
  const params = useParams();

  const topicParam = params.faqTopic ?? null;

  if (!topicParam) {
    navigate("/help/faqs")
  }

  const topic = faqTopics.find(t => t.slug === topicParam)
  const faqsOfTopic = topic.faqs

  if (!topic) {
    return null
  }

  return (
    <div>
      <Header><Title>{`${topic.label} FAQs`}</Title></Header>
      <SearchInput query={topicParam} />
      <ContainerWithSideBar topics={faqTopics}>
        <div>
          {faqsOfTopic && (<span>{`${faqsOfTopic.length} Questions`}</span>)}
          {faqsOfTopic && faqsOfTopic.map(f => (
            <QuestionLink key={f.id} size="h2" topic={topic} faq={f} />
          ))}
        </div>
      </ContainerWithSideBar>
    </div>
  )
}

FaqsTopic.propTypes = {
  faqTopics: PropTypes.array.isRequired,
}

export default FaqsContentHoc(FaqsTopic)
