/* eslint-disable no-undef */
import axios from "axios"

import { API_BASE, FRANCHISE_LIST } from "../config/constants"

export const ApiException = message => ({ message })

/* Set source for all requests as coming from the company application */
const SOURCE = "company"

function getEndpointUrl(endpoint) {
  return `${API_BASE}/${endpoint}`
}

export function getPusherAuthEndpointUrl() {
  return getEndpointUrl("support/pusher/auth")
}

function get(credentials, endpoint, data) {
  const d = {
    ...data,
    source: SOURCE,
  }

  const headers = {}

  if (credentials && credentials.token) {
    headers.Authorization = `Bearer ${credentials.token}`
  }

  if (credentials && credentials.franchise_id) {
    headers["X-Franchise-ID"] = credentials.franchise_id
  }

  const url = getEndpointUrl(endpoint)

  return axios({
    method: "get", params: d, url, headers
  })
}

function post(credentials, endpoint, params) {
  const postParams = {
    ...params,
    source: SOURCE,
  }

  const headers = {}

  if (credentials && credentials.token) {
    headers.Authorization = `Bearer ${credentials.token}`
  }

  if (credentials && credentials.franchise_id) {
    headers["X-Franchise-ID"] = credentials.franchise_id
  }

  return axios({
    method: "post",
    url: getEndpointUrl(endpoint),
    data: postParams,
    headers,
    validateStatus: status => (status === 200 || status === 412),
  })
}

export function preflight(username, password) {
  const data = {
    username,
    password,
    source: SOURCE,
    franchise_id: FRANCHISE_LIST.map(franchise => franchise.id),
  }

  return axios.post(getEndpointUrl("authentication/preflight"), data)
}

export function login(email, password, franchiseId, googleRecaptchaToken) {
  const data = {
    username: email,
    password,
    source: SOURCE
  }

  if (googleRecaptchaToken) {
    data.google_recaptcha_token = googleRecaptchaToken
  }

  const headers = {}
  headers["X-Franchise-ID"] = franchiseId

  return axios.post(getEndpointUrl("authentication/login"), data, { headers })
}

export function apiCompanies(credentials, includeServices) {
  return get(credentials, "user/company/all", { include_services: includeServices ? "1" : "0" })
}

function userProfile(credentials) {
  return get(credentials, "user/get", {})
}

export function getProfileData(credentials) {
  return axios.all([userProfile(credentials), apiCompanies(credentials, false)])
    .then(axios.spread((userData, companyData) => ({
      userData,
      companiesData: companyData,
    })))
}

export function apiSaveBooking(credentials, id) {
  return post(credentials, "company/booking/save", { id })
}

export function apiCancelBooking(credentials, params) {
  return post(credentials, "company/booking/cancel", params)
}

export function apiRescheduleBooking(credentials, params) {
  return post(credentials, "company/booking/reschedule", params)
}

export function apiCreateContact(credentials, params) {
  return post(credentials, "company/add/contact", params)
}

export function apiSaveContact(credentials, params) {
  return post(credentials, "company/edit/contact", params)
}

export function apiCreateEmployee(credentials, params) {
  return post(credentials, "company/employee/new", params)
}

export function apiSaveEmployee(credentials, params) {
  return post(credentials, "company/employee/edit", params)
}

export function apiDeleteContact(credentials, params) {
  return post(credentials, "company/remove/contact", params)
}

export function apiDeleteEmployee(credentials, params) {
  return post(credentials, "company/employee/delete", params)
}

export function apiDeleteAvailability(credentials, params) {
  return post(credentials, "company/availability/cancel", params)
}

export function apiSaveAvailability(credentials, params) {
  return post(credentials, "company/availability/edit", params)
}

export function apiSaveService(credentials, params) {
  return post(credentials, "company/service/edit", params)
}

export function apiCreateService(credentials, params) {
  return post(credentials, "company/service/new", params)
}

export function apiDeleteService(credentials, params) {
  return post(credentials, "company/service/delete", params)
}

export function apiGetService(credentials, params) {
  return get(credentials, "service/get", params)
}

export function apiSaveCompanyHours(credentials, params) {
  return post(credentials, "company/edit/hours", params)
}

export function apiSaveCompanyAvailability(credentials, params) {
  return post(credentials, "company/availability/set", params)
}

export function apiSaveCompany(credentials, params) {
  return post(credentials, "company/edit", params)
}

export function apiSavePreference(credentials, params) {
  return post(credentials, "user/set", params)
}

export function apiSendReport(credentials, params) {
  return post(credentials, "company/report/payment", params)
}

export function apiGetEmployees(credentials, params = {}) {
  return get(credentials, "company/employee/all", params)
}

export function apiGetEmployeesExternalData(credentials, params = {}) {
  return get(credentials, "company/employee/external", params)
}

export function apiGetBookings(credentials, params = {}) {
  return get(credentials, "company/booking/all", params)
}

export function apiGetAvailabilities(credentials, params) {
  return get(credentials, "company/availability/all", params)
}

export function apiGetAvailabilitiesDates(credentials, params) {
  return get(credentials, "company/availability/dates", params)
}

export function apiGetCompanyStats(credentials, params) {
  return get(credentials, "company/statistics", params)
}

export function apiGetFranchiseStats(credentials, params) {
  return get(credentials, "franchise/statistics", params)
}

export function apiGetFranchiseStatsCsv(credentials, params) {
  return get(credentials, "franchise/statistics/csv", params).then((response) => {
    // Cannot extract correct filename without content-disposition which requires
    // setting Access-Control-Expose-Headers: Content-Disposition on the server
    if (response.headers["content-disposition"] === "undefined") {
      console.log("Server missing Access-Control-Expose-Headers: Content-Disposition")
      return
    }

    // Extract filename and tell server to download
    const filename = response.headers["content-disposition"].replace(/.*filename="(.*)".*/, "$1")

    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", filename)
    document.body.appendChild(link)
    link.click()
  })
}

export function apiGetCompanyServices(credentials, params) {
  return get(credentials, "company/service/all", params)
}

export function apiGetServiceDescriptions(credentials, params) {
  return get(credentials, "company/service/description/all", params)
}

export function apiGetExternalServices(credentials, params) {
  return get(credentials, "company/service/external", params)
}

export function apiGetExternalPackages(credentials, params) {
  return get(credentials, "company/package/external", params)
}

export function apiSubmitCompanyApprovalRequest(credentials, params) {
  return post(credentials, "company/request-approval", params)
}

export function apiGetInvite(credentials, params) {
  return get(credentials, "invite/get", params)
}

export function apiRegisterInvite(credentials, params) {
  return post(credentials, "invite/accept", params)
}

export function apiSendAuthCode(params) {
  return post(null, "authentication/code", params)
}

export function apiAuthRegister(params) {
  return post(null, "authentication/register", params)
}

export function apiAcceptInviteTerms(credentials, params) {
  return post(credentials, "invite/terms", params)
}

export function apiInviteSoftwareCheck(credentials, params) {
  const {
    code, // invite code
    softwareSlug,
    externalId,
    externalUsername,
    externalPassword,
    token, // pusher channel name
  } = params
  return post(
    credentials,
    "invite/check",
    {
      code,
      token,
      external_provider: softwareSlug,
      external_id: externalId,
      external_username: externalUsername,
      external_password: externalPassword,
    },
  )
}

export function apiInviteComplete(credentials, params) {
  return post(credentials, "invite/complete", params)
}

export function apiUpdatePassword(params) {
  return post(null, "authentication/reset-password", { ...params, source: SOURCE })
}

export function apiForgotPassword(params) {
  // TODO: Ewww, hack. We need to clean this up once we remove the API's blanket dependency on a Franchise ID
  const credentials = {
    franchise_id: params.franchise_id,
  }

  return post(credentials, "authentication/forgot-password", { ...params, source: SOURCE })
}

export function apiRefreshInbox(credentials, params) {
  return get(credentials, "company/inbox", { ...params, source: SOURCE })
}

export function apiRegisterFranchise(params) {
  // TODO: Ewww, hack. We need to clean this up once we remove the API's blanket dependency on a Franchise ID
  const credentials = {
    franchise_id: params.franchise_id,
  }
  return post(credentials, "franchise/signup", params)
}
