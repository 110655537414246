import React, { useEffect } from "react"
import ReactDOM from "react-dom"
import PropTypes, { string } from "prop-types"
import styled from "styled-components"
import { ArrowLeftIcon } from "../Icons/Icons"
import { useNavigate } from "react-router-dom"

const BackLink = styled(ArrowLeftIcon)`
    cursor: pointer;
    float: left;
    margin-top: 13px;
    margin-left: 13px;
`

const Title = styled.div`
    float: right;
    font-face: 'Museo-Sans', 'sans-serif';
    color: #575757;
    font-size: 20px;
    font-weight: 500;
    line-height: 53px;
    margin-right: 13px;
`

const Navigation = styled.div`
    height: 53px;
    background-color: #fff;
    float: right;
`

const MobileNavigationSimple = ({ backLinkOnly = false, title }) => {
  const navigate = useNavigate();

  const navigationStyle = {
    width: (backLinkOnly) ? "100%" : "calc(100% - 48px)",
  }

  return (
    <Navigation style={navigationStyle}>
      {backLinkOnly && <BackLink onClick={() => navigate(-1)} />}
      <Title>{title}</Title>
    </Navigation>
  )
}

MobileNavigationSimple.propTypes = {
  title: PropTypes.string.isRequired,
  backLinkOnly: PropTypes.bool,
}

const MobileNavigation = ({ ...props }) => {
  const container = document.createElement('div')

  useEffect(() => {
    if (document.getElementById("header-portal")) {
      document.getElementById("header-portal").appendChild(container)
    }

    return () => {
      const header = document.getElementById("header-portal")
      if (header) {
        header.removeChild(container)
      }
    }
  }, [])

  return ReactDOM.createPortal(<MobileNavigationSimple {...props} />, container)
}

MobileNavigation.propTypes = {
  title: PropTypes.string.isRequired,
  backLinkOnly: PropTypes.bool,
}


export default MobileNavigation
