import React, { useContext } from "react"
import PropTypes from "prop-types"
import { connect, shallowEqual, useSelector } from "react-redux"
import Home from "../../components/Home/Home"
import { hasSavedAuthentication, isAuthenticated } from "../../redux/modules/auth"
import CookiesBanner from "../../components/CookiesBanner"
import { SHOW_COOKIE_BANNER } from "../../config/constants"
import {
  cookieOk, cookieDecline, areCookiesAllowed, areCookiesDeclined
} from "../../redux/modules/preferences"
import { Navigate, useLocation, useNavigate } from "react-router-dom"
import ReactGA from "react-ga4"
import { SegmentContext } from "../../providers/segment"

const ga4Settings = GA4_SETTINGS;
if (typeof ga4Settings === 'object') {
  ReactGA.initialize(ga4Settings);
}

const MasterContainer = ({ isPublic= false, children, isAuthed, dispatchCookieDecline, dispatchCookieOk, cookiesAllowed, cookiesDeclined, ...rest }) => {

  const location = useLocation()
  const segment = useContext(SegmentContext)

  React.useEffect(() => {
    if (isPublic || isAuthed) {
      ReactGA.send({ hitType: "pageview", page: location.pathname });
      segment.page({
        page: location.pathname
      })
    }
  }, [location]);

  if (!isPublic && !isAuthed) {
    const authLocation = hasSavedAuthentication() ? "/authenticate" : "/login";
    const searchParams = new URLSearchParams();
    searchParams.append('target', location.pathname);

    return <Navigate to={`${authLocation}?${searchParams}`}/>;
  }


  // const onMenuClicked = (id) => {
  //   switch (id) {
  //     case "home": {
  //       pushUrl(`${process.env.PUBLIC_URL || "/"}`)
  //       break
  //     }
  //     case "bookings": {
  //       pushUrl(`${process.env.PUBLIC_URL || "/bookings"}`)
  //       break
  //     }
  //     case "settings": {
  //       pushUrl(`${process.env.PUBLIC_URL || "/settings/profile"}`)
  //       break
  //     }
  //     case "reports": {
  //       pushUrl(`${process.env.PUBLIC_URL || "/reports"}`)
  //       break
  //     }
  //     case "help": {
  //       pushUrl(`${process.env.PUBLIC_URL || "/help/faqs"}`)
  //       break
  //     }
  //     default:
  //       break
  //   }
  // }

  return (
    <Home isPublic={isPublic} {...rest}>
      {children}
      { SHOW_COOKIE_BANNER
        && !cookiesAllowed
        && !cookiesDeclined
        && <CookiesBanner cookieOk={dispatchCookieOk} cookieDecline={dispatchCookieDecline} />
      }
    </Home>
  )
}

MasterContainer.propTypes = {
  children: PropTypes.node.isRequired,
  isAuthed: PropTypes.bool.isRequired,
  isPublic: PropTypes.bool,
  cookiesAllowed: PropTypes.bool.isRequired,
  cookiesDeclined: PropTypes.bool.isRequired,
  dispatchCookieOk: PropTypes.func.isRequired,
  dispatchCookieDecline: PropTypes.func.isRequired,
  menuActive: PropTypes.string,
}

const mapStateToProps = state => ({
  cookiesAllowed: areCookiesAllowed(state),
  cookiesDeclined: areCookiesDeclined(state),
  isAuthed: isAuthenticated(state),
})


const mapDispatchToProps = dispatch => ({
  dispatchCookieOk: () => dispatch(cookieOk()),
  dispatchCookieDecline: () => dispatch(cookieDecline()),
})

export default connect(mapStateToProps, mapDispatchToProps)(MasterContainer)
