/* eslint-disable react/no-multi-comp */
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import ImmutablePropTypes from "react-immutable-proptypes"
import { TrashIcon, EditIcon } from "../../components/Icons/Icons"
import { device } from "../../components/Responsive/Responsive"
import Button from "../../components/Form/Button"
import ContactForm from "./ContactForm"
import { H1 } from "../../components/Typography"
import { connect } from "react-redux"
import {
  companyContactsSelector,
  contactsDeleteContact,
  hasErrorSelector,
  isSavingSelector
} from "../../redux/modules/contacts"
import { openConfirm } from "../../redux/modules/notifications"
import { getSelectedCompany } from "../../redux/modules/companies"

const SettingsContactsFormParent = styled.div`
    position: relative;
    padding: 0px 0px 30px 0px;

    @media ${device.mobile} {
        padding: 0px 0px 12px 0px;
    }
`

const SettingsContactsList = styled.div`
    border: 1px solid ${({ theme }) => theme.borderColor};
    border-bottom: none;

    @media ${device.mobile} {
        border-left: none;
        border-right: none;
    }
`

const SettingsContactsHeader = styled.div`
    background-color: ${({ theme }) => theme.labelDarkColor};
    width: calc(100% - 32px);
    padding: 0px 16px;
    height: 48px;
    display: flex;
    flex-direction: row;
    line-height: 48px;
    font-family: 'Museo-Sans', 'sans-serif';
    font-size: 14px;
    color: ${({ theme }) => theme.primaryContrastColor}};

    @media ${device.mobile} {
        display: none;
    }
`

const SettingsContactsRow = styled.div`
    width: calc(100% - 32px);
    padding: 0px 16px;
    border-bottom: 1px solid ${({ theme }) => theme.borderColor};
    cursor: pointer;
    font-family: 'Museo-Sans', 'sans-serif';
    font-weight: 300;
    color: #575757;
    font-size: 15px;
    position: relative;
    display: flex;
    flex-direction: row;
    height: 65px;
    line-height: 65px;

    @media ${device.mobile} {
        background-color: #fff;
        display: block;
        width: calc(100% - 16px);
        padding: 8px;
        height: auto;
        line-height: normal;
    }
`

const SettingsContactsColumn = styled.div`
`

const SettingsContactsName = styled(SettingsContactsColumn)`
    flex: 1;
`
const SettingsContactsEmail = styled(SettingsContactsColumn)`
    width: 200px;

    @media ${device.mobile} {
        font-size: 14px;
    }
`
const SettingsContactsRole = styled(SettingsContactsColumn)`
    width: 100px;

    @media ${device.mobile} {
        font-size: 14px;
    }
`
const SettingsContactsPhone = styled(SettingsContactsColumn)`
    width: 160px;
    text-align: right;

    @media ${device.mobile} {
        font-size: 14px;
        text-align: left;
    }
`

const SettingsContactsDelete = styled(SettingsContactsColumn)`
    width: 38px;
    margin: 0 20px;
    text-align: center;

    @media ${device.mobile} {
        width: auto;
        margin: 0;
        position: absolute;
        top: 12px;
        right: 46px;
    }
`

const SettingsContactsEdit = styled(SettingsContactsColumn)`
    width: 30px;
    text-align: center;

    @media ${device.mobile} {
        width: auto;
        position: absolute;
        top: 12px;
        right: 8px;
    }

`

const SettingsContactsForm = ({ company, contacts, hasError, isSaving, deleteContact, showConfirm }) => {

  const initialFormValueTemplate = {
    id: '',
    firstName: '',
    lastName: '',
    roleId: '0',
    email: '',
    phoneNumber: '',
  }

  const [selectedContactId, setSelectedContactId] = useState('');
  const [initialFormValues, setInitialFormValues] = useState(initialFormValueTemplate);
  const [wasSaving, setWasSaving] = useState(false)

  useEffect(() => {
    if (!hasError && (isSaving === false && wasSaving === true)) {
      setSelectedContactId('')
    }

    setWasSaving(isSaving)
  }, [isSaving])

  useEffect(() => {
      const found = contacts.find((eachContact) => eachContact.get('id') === selectedContactId)
      if (found) {
        setInitialFormValues({
          id: found.get('id'),
          firstName: found.get('firstName') ?? '',
          lastName: found.get('lastName') ?? '',
          roleId: found.get('roleId') ?? 0,
          email: found.get('email') ?? '',
          phoneNumber: found.get('phoneNumber') ?? '',
        })
        // setSelectedContactDetails(found.toJS())
      } else {
        setInitialFormValues(initialFormValueTemplate)
      }
  }, [selectedContactId])

  const onDelete = (contactId) => {
    if (!contactId) {
      return;
    }
    showConfirm(null, "Are you sure you want to delete this contact?", () => deleteContact(company.get("id"), contactId))
  }

  const getContactsList = () => {
    return contacts.toJS().map(contact => (
      <SettingsContactsRow key={contact.id} onClick={() => setSelectedContactId(contact.id)}>
        <SettingsContactsName>
          <b>
            {contact.firstName}
            {" "}
            {contact.lastName}
          </b>
        </SettingsContactsName>
        <SettingsContactsRole>{contact.role}</SettingsContactsRole>
        <SettingsContactsEmail style={{ textDecoration: "underline" }}>{contact.email}</SettingsContactsEmail>
        <SettingsContactsPhone>{contact.phoneNumber}</SettingsContactsPhone>
        <SettingsContactsDelete><TrashIcon style={{ verticalAlign: "middle" }} onClick={() => { onDelete(contact.id) }} /></SettingsContactsDelete>
        <SettingsContactsEdit><EditIcon style={{ verticalAlign: "middle" }} /></SettingsContactsEdit>
      </SettingsContactsRow>
    ))
  }

  const contactsList = getContactsList()

  return (
    <SettingsContactsFormParent>
      <SettingsContactsList>
        <SettingsContactsHeader>
          <SettingsContactsName>Name</SettingsContactsName>
          <SettingsContactsRole>Role</SettingsContactsRole>
          <SettingsContactsEmail>Email</SettingsContactsEmail>
          <SettingsContactsPhone>Phone</SettingsContactsPhone>
          <SettingsContactsDelete>Delete</SettingsContactsDelete>
          <SettingsContactsEdit>Edit</SettingsContactsEdit>
        </SettingsContactsHeader>

        {contactsList}
      </SettingsContactsList>

      <div style={{ paddingBottom: "18px", marginTop: "42px", display: "flex" }}>
        <H1>{ !selectedContactId ? "Add new contact" : "Edit contact"}</H1>
        <div style={{ flex: 1 }} />
        { selectedContactId !== null && (
          <Button
            onClick={() => setSelectedContactId(null)}
          >
            Cancel
          </Button>
        )}
      </div>
      <ContactForm
        enableReinitialize
        initialValues={initialFormValues}
      />
    </SettingsContactsFormParent>
  )
}

SettingsContactsForm.propTypes = {
  contacts: ImmutablePropTypes.list,
  company: PropTypes.object.isRequired,
  showConfirm: PropTypes.func.isRequired,
  deleteContact: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  contacts: companyContactsSelector(state),
  company: getSelectedCompany(state),
  isSaving: isSavingSelector(state),
  hasError: hasErrorSelector(state),
})

const mapDispatchToProps = (dispatch) => ({
  deleteContact: (companyId, id) => dispatch(contactsDeleteContact(companyId, id)),
  showConfirm: (title, message, action) => dispatch(openConfirm(title, message, action)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsContactsForm)
