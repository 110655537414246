import styled from "styled-components"
import { device } from "../Responsive/Responsive"

export const H1 = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 25px;
  font-weight: 500;
  color: ${({ theme }) => theme.labelDarkColor};
  @media ${device.mobile} {
    font-size: 20px;
  }
`

export const H2 = styled.h2`
  margin: 0;
  padding: 0;
  font-size: 22px;
  font-weight: 500;
  color: ${({ theme }) => theme.labelDarkColor};
  @media ${device.mobile} {
    font-size: 18px;
  }
`

export const H3 = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 17px;
  font-weight: 700;
  color: ${({ theme }) => theme.labelDarkColor};
  @media ${device.mobile} {
    font-size: 15px;
  }
`