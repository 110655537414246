import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { CloseIcon } from "../../Icons/Icons"

const BadgeWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  border-radius: 15px;
  align-items: center;
  background-color: ${({ theme }) => theme.borderColor};
  font-weight: 700;
  color: ${({ theme }) => theme.labelDarkColor};
  button {
    display: inline-flex;
    padding: 0;
    margin-left: 5px;
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
  }
`

const Badge = ({ onRemove, children }) => {
  return (
    <BadgeWrapper>
      {children}
      {onRemove && <button flat onClick={onRemove}><CloseIcon /></button>}
    </BadgeWrapper>
  )
}

Badge.propTypes = {
  children: PropTypes.string.isRequired,
  onRemove: PropTypes.func,
}

export default Badge