/* eslint-disable no-unused-expressions,react/no-string-refs,func-names */
import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import ImmutablePropTypes from "react-immutable-proptypes"
import { connect } from "react-redux"
import Modal from "react-modal"
import styled from "styled-components"


import {
  closeConfirm,
  closeModal,
  getConfirmAction,
  getConfirmMessage,
  getConfirmTitle,
  getModalMessage,
  getModalTitle,
  getPendingDesktopNotifications,
  getShownDesktopNotifications,
  hideNotification,
  isConfirmOpen,
  isModalOpen,
  openModal, showDesktop,
  shownDesktop,
  showNotification,
} from "../../redux/modules/notifications"
import { isAuthenticated } from "../../redux/modules/auth"
import SystemTrayIcon from "../../../img/tray.png"
import { Button } from "../../components/Form"
import ScrollTop from "../../components/Wrappers/ScrollTop"
import IntercomContainer from "./IntercomContainer"
import { toast, ToastContainer, Bounce } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';
import ToastMessage from "../../components/Notifications/ToastMessage"
import { useNavigate } from "react-router-dom"

const confirmDialogStyle = {
  backgroundColor: "#FFFFFF",
  color: "#575757",
  minHeight: "200px",
  maxWidth: "300px",
  padding: "1rem 2rem",
  inset: "auto",
  position: "static",
  margin: "100px auto",
}

const standardDialogStyle = {
  backgroundColor: "#FFFFFF",
  color: "#575757",
  minHeight: "auto",
  maxWidth: "300px",
  padding: "1rem 2rem",
  inset: "auto",
  position: "static",
  margin: "100px auto",
}

const ConfirmButtons = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  width: 100%;
`

const ConfirmMessage = styled.div`
  font-size: 16px;
  font-weight: bold;
  max-width: 300px;
  width: 100%;
`

export function showToast (message) {
  return function (dispatch) {
    dispatch(showNotification({
      position: "bc",
      dismissible: "none",
      children: (<ToastMessage message={message} />)
    }, "success"))
  }
}

export function showModal(title, message) {
  return function (dispatch) {
    dispatch(openModal(title, message))
  }
}

const NotificationsContainer = (props) => {
  const {
    auth,
    desktopPending,
    users,
    modalIsOpen,
    hideModal,
    modalTitle = undefined,
    modalMessage = undefined,
    confirmIsOpen,
    hideConfirm,
    hideToast,
    notifications,
    confirmTitle = undefined,
    confirmMessage = undefined,
    isAuthed,
    children,
    browser,
    confirmAction =  undefined,
    dispatchOnConfirmAction,
    ...rest
  } = props

  const notify = useRef()

  const navigate = useNavigate()

  useEffect(() => {
    const n = notifications.toJS()

    if (n.length > 0) {
      n.forEach((notification) => {
        toast(notification.children, {
          autoClose: (notification.autoDismiss || 3) * 1000,
          onClose: (props) => {
            hideToast(notification.uid)
          }
        })
      })

      if (desktopPending.size > 0 && "Notification" in window) {
        setTimeout(() => {
          Notification.requestPermission().then(() => {
            desktopPending.map((notification) => {
              const desktopNotification = new Notification(notification.get("title"), {
                tag: notification.get("tag"),
                body: notification.get("body"),
                icon: SystemTrayIcon,
              })
              desktopNotification.addEventListener('show', onShowDesktopNotification)
              desktopNotification.addEventListener('click', onClickDesktopNotification)
            })
          })
        }, 5000)
      }
    }
  }, [notifications])

  const handleConfirmAction = (action) => {
    // Support an array of actions
    if (Array.isArray(action)) {
      action.map(nestedAction => handleConfirmAction(nestedAction))
    // Allow for pure functions that aren't bound for redux
    } else if (typeof action === 'function') {
      action()
    // Assume anything else is dispatchable (current default functionality)
    } else {
      dispatchOnConfirmAction(action)
    }
  }

  const onConfirm = () => {
    hideConfirm()
    handleConfirmAction(confirmAction)
  }

  // eslint-disable-next-line no-unused-vars
  const onClickDesktopNotification = (clickEvent) => {
    const { target: notification } = clickEvent;
    navigate('/');

    /* Maximise window if it's minimised */
    window.focus()

    /* Close the notification */
    notification.close()
  }

  const onShowDesktopNotification = (clickEvent) => {
    const { target: { tag } } = clickEvent
    const { onDesktopShown } = props
    onDesktopShown(tag)
  }

  const style = {
    NotificationItem: {
      DefaultStyle: {
        height: "60px",
      },

      success: {
        color: "white",
        border: "0",
        backgroundColor: "#575757",
      },
    },
  }

  const userId = auth.get("authUserId")
  const user = userId ? users.get(userId.toString()) : undefined
  const intercomUser = {
    userId,
    email: auth.get("apiUser"),
    name: user ? `${user.get("firstName")} ${user.get("lastName")}` : undefined,
  }

  const overlayStyle = {
    marginLeft: "220px",
    marginTop: "70px",
  }

  if (browser.lessThan.tablet) {
    overlayStyle.marginLeft = "0px"
    overlayStyle.marginTop = "53px"
  }

  return (
    <ScrollTop>
      <style>
        {`
            :root {
              --toastify-color-dark: #575757
            }`
        }
      </style>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick={false}
        closeButton={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        theme="dark"
        transition={Bounce}
      />
      {children}
      <div>
        <Modal
          style={{ overlay: overlayStyle, content: standardDialogStyle }}
          isOpen={modalIsOpen}
          onRequestClose={hideModal}
          contentLabel={modalTitle}
        >
          <h4>{modalTitle}</h4>
          <div>{modalMessage}</div>
        </Modal>
      </div>
      <div>
        <Modal
          style={{ overlay: overlayStyle, content: confirmDialogStyle }}
          closeButtonStyle={{ zIndex: "1" }}
          isOpen={confirmIsOpen}
          onRequestClose={hideConfirm}
          contentLabel={confirmTitle}
        >
          <div>
            <h4>{modalTitle}</h4>
            <ConfirmMessage>
              {confirmMessage}
            </ConfirmMessage>
            <ConfirmButtons>
              <Button
                size="sm"
                outlined
                role="button"
                tabIndex={0}
                onClick={hideConfirm}
                onKeyUp={(evt) => {
                  if (evt.key === "Enter" || evt.key === "n") {
                    hideConfirm()
                  }
                }}
              >
                NO
              </Button>
              <Button
                size="md"
                role="button"
                tabIndex={0}
                onClick={onConfirm}
                onKeyUp={(evt) => {
                  if (evt.key === "Enter" || evt.key === "y") {
                    onConfirm()
                  }
                }}
              >
                YES
              </Button>
            </ConfirmButtons>
          </div>
        </Modal>
      </div>
      <IntercomContainer intercomUser={intercomUser} />
    </ScrollTop>
  )
}

NotificationsContainer.propTypes = {
  browser: PropTypes.object.isRequired,
  notifications: ImmutablePropTypes.list.isRequired,
  desktopPending: ImmutablePropTypes.list.isRequired,
  desktopShown: ImmutablePropTypes.list.isRequired,
  children: PropTypes.node.isRequired,
  auth: ImmutablePropTypes.map.isRequired,
  users: ImmutablePropTypes.map.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  confirmIsOpen: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  hideConfirm: PropTypes.func.isRequired,
  hideToast: PropTypes.func.isRequired,
  onDesktopShown: PropTypes.func.isRequired,
  dispatchOnConfirmAction: PropTypes.func.isRequired,
  modalTitle: PropTypes.string,
  modalMessage: PropTypes.string,
  confirmTitle: PropTypes.string,
  confirmMessage: PropTypes.string,
  confirmAction: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.func
      ])
    )
  ]),

  isAuthed: PropTypes.bool.isRequired,
  mediaType: PropTypes.string.isRequired,
}

const mapDispatchToProps = dispatch => ({
  hideModal: () => dispatch(closeModal()),
  hideConfirm: () => dispatch(closeConfirm()),
  hideToast: uid => dispatch(hideNotification(uid)),
  onDesktopShown: tag => dispatch(shownDesktop(tag)),
  dispatchOnConfirmAction: action => dispatch(action),
})

const mapStateToProps = state => ({
  browser: state.get("browser"),
  notifications: state.getIn(["notifications", "notifications"]),
  auth: state.get("auth"),
  users: state.get("users"),
  modalTitle: getModalTitle(state),
  modalMessage: getModalMessage(state),
  modalIsOpen: isModalOpen(state),
  confirmTitle: getConfirmTitle(state),
  confirmMessage: getConfirmMessage(state),
  confirmAction: getConfirmAction(state),
  confirmIsOpen: isConfirmOpen(state),
  desktopPending: getPendingDesktopNotifications(state),
  desktopShown: getShownDesktopNotifications(state),
  isAuthed: isAuthenticated(state),
  mediaType: state.getIn(["browser", "mediaType"]),
})


export default connect(mapStateToProps, mapDispatchToProps)(NotificationsContainer)
