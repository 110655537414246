import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import { device } from "../Responsive/Responsive"

const Container = styled.div`
    @media ${device.desktop}, ${device.tablet} {
        width: 730px;
        min-width: 730px;
    }
`

const Card = styled.div`
  padding: ${({ theme }) => theme.parentPadding};
  background: ${({ theme }) => theme.primaryContrastColor};
`

const Actions = styled.div`
  margin-top: ${({ theme }) => theme.parentPadding};
  display: flex;
  justify-content: flex-end;
  >button {
    margin-left: 10px;
  }

  @media ${device.mobile} {
    padding: 0px 20px;
  }
`

const DashboardCard = ({
  children, actions = null, style, className
}) => (
  <Container className={className} style={style}>
    <Card>
      {children}
    </Card>
    {actions && <Actions>{actions}</Actions>}
  </Container>
)

DashboardCard.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
  actions: PropTypes.node,
  style: PropTypes.object,
  className: PropTypes.string,
}

export default DashboardCard
