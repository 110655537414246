import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { ThemedTextInput } from "../Form/TextInput"

import { Button } from "../Form"
import { device } from "../Responsive/Responsive"
import { useNavigate, useParams } from "react-router-dom"

const TextInput = styled(ThemedTextInput)`
    width: 100%;
    margin-right: 10px;

    @media ${device.mobile} {
      margin-bottom: 10px;
    }
`

const SearchBox = styled.form`
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
  margin-bottom: 30px;

  @media ${device.mobile} {
    flex-direction: column;
  }
`

const SearchInput = ({ query }) => {

  if (!query) {
    const params = useParams()
    query = params.query ?? ''
  }

  const [value, setQueryValue] = useState(query);
  const navigate = useNavigate();

  const handleClick = () => {
    if (value && value.trim() !== '') {
      navigate(`/help/faqs/search/${value}`);
    } else {
      navigate('/help/faqs');
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    e.stopPropagation()
    handleClick()
  }

  return (
    <SearchBox onSubmit={handleSubmit}>
      <TextInput
        placeholder="Type keywords to find answers"
        value={value}
        onChange={(event) => setQueryValue(event.target.value)}
      />
      <Button onClick={handleClick}>Search</Button>
    </SearchBox>
  )
}

SearchInput.propTypes = {
  query: PropTypes.string,
}

export default SearchInput
