/* eslint-disable no-underscore-dangle */
import React from "react"
import { createRoot } from "react-dom/client"
import rg4js from "raygun4js"
import Providers from "./providers"
import { store } from "./providers/redux"
import { delayConfiguration } from "pusher-redux"
import Modal from "react-modal"

import {
  PUSHER_API_KEY,
  PUSHER_APP_CLUSTER,
  PUSHER_AUTH_ENDPOINT,
} from "./config/constants"
import "./index.css"

// import companySelectorMiddleware from "./redux/middleware/companySelectorMiddleware"

import "react-virtualized/styles.css"

import { cookieOk, cookieDecline } from "./redux/modules/preferences"
import { group as segmentGroup } from "./providers/segment"

// raygun
if (process.env.RAYGUN_API_KEY && process.env.RAYGUN_API_KEY.length > 0) {
  rg4js("enableCrashReporting", true)
  rg4js("apiKey", process.env.RAYGUN_API_KEY)
}


if (process.env.NODE_ENV === "production") {
  rg4js("withCustomData", () => {
    const state = store.getState()
      .delete("availabilities")
      .delete("services")
      .toJS()
    return { state }
  })
}

// pusher
delayConfiguration(store, PUSHER_API_KEY, {
  cluster: PUSHER_APP_CLUSTER,
  disableStats: true,
  authEndpoint: PUSHER_AUTH_ENDPOINT,
})

// trigger segment group call on init to allocate all future events to the correct franchise ID
segmentGroup()

// user has handled cookie popup already, so send OK/DECLINE action based on cookie value
if (document.cookie.includes("cookiesok")) {
  const isCookieOk = (document.cookie.split(";").some(item => item.includes("cookiesok=true")))
  if (isCookieOk) {
    store.dispatch(cookieOk())
  } else {
    store.dispatch(cookieDecline())
  }
}

Modal.setAppElement("#root")

createRoot(document.getElementById("root")).render(<Providers />)