import React, { useEffect, useState, useCallback } from "react"
import { useDispatch } from "react-redux"
import styled from "styled-components"
import { Link, useNavigate } from "react-router-dom"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import { TextInput, SelectBox, Button } from "../../components/Form"
import { preflight } from "../../api/api"
import { GOOGLE_RECAPTCHA_SITE_ID } from "../../config/constants"
import { authAttempt } from "../../redux/modules/auth"
import { Field, Formik } from "formik"
import StyledSelectField from "../../components/Form/StyledSelectField"

const FormRow = styled.div`
  margin-bottom: 14px;
`

const LoginForm = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha()

  const [franchiseSelectOptions, setFranchiseSelectOptions] = useState({})

  const [googleRecaptchaToken, setGoogleRecaptchaToken] = useState(false)

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return
    }

    const token = await executeRecaptcha()

    setGoogleRecaptchaToken(token)
  }, [executeRecaptcha])

  if (GOOGLE_RECAPTCHA_SITE_ID) {
    // You can use useEffect to trigger the verification as soon as the component being loaded
    useEffect(() => {
      handleReCaptchaVerify()
    }, [handleReCaptchaVerify])
  }

  const doAuth = ({ email, password, franchise}) => {
    dispatch(authAttempt(email, password, franchise, googleRecaptchaToken))
    navigate('/authenticate')
  }

  const onFormSubmit = async (values) => {
    if (values.franchise) {
      doAuth(values)
    } else {
      await preflight(values.email, values.password)
        .then(response => {

          // Convert array of franchise objects into selection options
          const options = {}

          const { franchises } = response.data

          for (let i = 0; i < franchises.length; i += 1) {
            options[franchises[i].id] = franchises[i].name
          }

          if (Object.keys(options).length > 1) {
            setFranchiseSelectOptions(options)
          } else {
            // If we only matched one franchise there's no need to display the franchise select screen
            if (Object.keys(options).length > 0) {
              values.franchise = Object.keys(options)[0]
            }
            doAuth(values)
          }
        })
    }
  }

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        franchise: '',
      }}
      onSubmit={onFormSubmit}
    >
      {({ errors, handleSubmit, isSubmitting, setFieldValue }) => (
        <div>
          <h1 style={{
            textAlign: "center", fontSize: "20px", marginBottom: 30, marginTop: 0
          }}
          >
            Log in now
          </h1>
          {Object.keys(franchiseSelectOptions).length === 0
            ? (
              <>
                <FormRow>
                  <Field
                    as={TextInput}
                    name="email"
                    type="email"
                    placeholder="Email*"
                    className="LoginField"
                    errors={errors}
                  />
                </FormRow>
                <FormRow>
                  <Field
                    as={TextInput}
                    name="password"
                    type="password"
                    placeholder="Password*"
                    className="LoginField"
                    errors={errors}
                  />
                </FormRow>
              </>
            ) : (
              <>
                <FormRow>
                  <Field
                    as={StyledSelectField}
                    name="franchise"
                    placeholder="Select your region"
                    options={Object.keys(franchiseSelectOptions).map(franchiseId => ({ value: franchiseId, label: franchiseSelectOptions[franchiseId]}))}
                    onChange={(value) => setFieldValue('franchise', value)}
                  />
                </FormRow>
              </>
            )
          }
          <div style={{ textAlign: "right" }}>
            <Button disabled={isSubmitting} size="sm" type="submit" onClick={handleSubmit}>LOG IN</Button>
          </div>
          <div style={{ textAlign: "center", marginTop: "15px" }}>
            <Link href to="/forgot-password"><Button flat><small>Forgot password?</small></Button></Link>
          </div>
        </div>
    )}
    </Formik>
  )
}

export default LoginForm
