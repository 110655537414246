import React from "react"
import Immutable from "immutable"
import ImmutablePropTypes from "react-immutable-proptypes"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import styled from "styled-components"

import StyledSelectField from "./StyledSelectField"

const FieldWrapper = styled(StyledSelectField)`
    width: 100%;
`

const OuterWrapper = styled.div`
  width: 100%;

  label {
    font-size: ${({ $labelSize }) => ($labelSize === "lg" ? "20px !important" : "15px !important")};
    font-weight: ${({ $labelSize }) => ($labelSize === "lg" ? "bold" : "100")};
    margin-bottom: ${({ $labelSize }) => ($labelSize === "lg" ? "16px !important" : "10px")};
    display: block;
    color: ${({ theme }) => theme.labelDarkColor};
  }
`

/**
 * This component was built in order to be used with any object in our redux store. Services, employees, bookings.
 *
 * @param fetchItemsAction: optional: Action to dispatch in order to fetch the items for the list.
 * @param itemsSelector: Redux Selector to get the map with the items. The map is <key>: <ImmutableMap> where key usually is the id of the Items.
 * @param loadingSelector: optional Redux Selector for the loading status of our data. When true this shows a spinning icon.
 * @param formatItems: Function that converts ImmutableMap item into a plain { value, label } object for the select box.
 * Default: item => ({ value: item.get('id), label: item.get('name)})
 * @constructor
 */

const defaultFormatItemCallback = (item) => ({ value: item.get("id"), label: item.get("name") })

const SearchableItemsSelect = ({
                                 fetchItems,
                                 filterIds = [],
                                 formatItem = defaultFormatItemCallback,
                                 grouped = false,
                                 items = Immutable.Map(),
                                 loadingSelector = null,
                                 isLoading = false,
                                 onChange = null,
                                 labelSize,
                                 ...rest
}) => {
  React.useEffect(() => {
    fetchItems()
  }, [])

  const handleChange = (value) => {
    if (typeof onChange !== "function") {
      return
    }

    let values = Array.isArray(value) ? value : [value];


    // Convert the selected option into to an item
    values = values.map((value) => {
      if (grouped) {
        const groupItem = items.find(innerItem => innerItem.get("options").has(value))
        return groupItem.getIn(["options", value])
      } else {
        return items.get(value)
      }
    })

    if (!rest.isMulti) {
      if (values.length > 1) {
        throw new Error('More than one value selected for a non-multi select input')
      }

      values = values[0]
    }

    onChange(values)

    // if (typeof onItemChange === "function") {
    //   if (grouped) {
    //     const groupItem = items.find(innerItem => innerItem.get("options").has(value))
    //     onItemChange(groupItem.getIn(["options", value]))
    //   } else {
    //     onItemChange(items.get(value))
    //   }
    // }
    //
    // if (typeof rest.onChange === "function") {
    //   rest.onChange(value)
    // }
  }

  const getOptions = () => (
    items
      .valueSeq()
      .filter(i => !filterIds.includes(i.get("id")))
      .map(formatItem)
      .sortBy(item => item.label)
      .toJS()
  )

  const getGroupedOptions = () => {
    let groups = []
    if (items && items.count() > 0) {
      groups = items.valueSeq().map((group) => {
        const groupLabel = group.get("label")
        const groupOptions = group.get("options")
        if (groupOptions && groupOptions.count() > 0) {
          const formattedGroupOptions = groupOptions
            .valueSeq()
            .filter(i => !filterIds.includes(i.get("id")))
            .map(formatItem)
            .sortBy(item => item.label)
            .toJS()
          return { label: groupLabel, options: formattedGroupOptions }
        }
        return { label: groupLabel, option: [] }
      }).sortBy(item => item.label).toJS()
    }

    return groups
  }

  return (
    <OuterWrapper $labelSize={labelSize}>
      <FieldWrapper
        {...rest}
        isLoading={isLoading}
        options={grouped ? getGroupedOptions() : getOptions()}
        onChange={handleChange}
        grouped={grouped}
      />
    </OuterWrapper>
  )
}

SearchableItemsSelect.propTypes = {
  // Own props
  isLoading: PropTypes.bool,
  items: ImmutablePropTypes.map,

  // Provided by parent
  itemsSelector: PropTypes.func.isRequired,
  loadingSelector: PropTypes.func,
  fetchItems: PropTypes.func.isRequired,
  formatItem: PropTypes.func,
  onChange: PropTypes.func,
  filterIds: PropTypes.array,
  grouped: PropTypes.bool,
}

const mapStateToProps = (state, { itemsSelector, loadingSelector }) => ({
  items: itemsSelector(state),
  isLoading: loadingSelector ? loadingSelector(state) : false,
})

const mapDispatchToProps = (dispatch, { fetchItemsAction }) => ({
  fetchItems: () => (fetchItemsAction ? dispatch(fetchItemsAction()) : {}),
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchableItemsSelect)
