import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import ImmutablePropTypes from "react-immutable-proptypes"

import { device } from "../Responsive/Responsive"
import { TrashIcon, EditIcon } from "../Icons/Icons"
import Table from "../Table"

const EmployeeCell = styled.td`
  font-size: 15px;
  text-align: ${({ $centered }) => ($centered ? "center" : "left")};
  @media ${device.mobile} {
    padding: 8px !important;
  }
`

const EmployeesDetailsMobile = styled(EmployeeCell)`
    width: 100%;
    text-align: left;
`

const FormattedPrice = styled.span`
    opacity: ${({ isDefaultPrice }) => (isDefaultPrice ? "0.5" : "1")};
`

const EmployeeTable = styled.div`

  @media ${device.tablet}, ${device.desktop} {
    height: 65vh;
    overflow-y: scroll;
  }

  table th:first-child {
    border-top-left-radius: ${({ $borderTopLeftRadius }) => ($borderTopLeftRadius ? "10px" : "0")};
  }
  
  @media ${device.mobile} {
    thead {
      display: none;
    }

    tr {
      border: none;
      border-bottom: solid 1px ${({ theme }) => theme.borderColor};
    }
  
    tr:first-child {
      border: none;
      border-top: solid 1px ${({ theme }) => theme.borderColor};
      border-bottom: solid 1px ${({ theme }) => theme.borderColor};
    }
  }
`

const defaultOptions = {
  showRole: true,
  showServicesCount: true,
  allowLink: true,
  showFormattedPrice: true,
}

const defaultSortFunction = (a, b) => {
  if (a.get("name").toLowerCase() < b.get("name").toLowerCase()) {
    return -1
  }
  if (a.get("name").toLowerCase() > b.get("name").toLowerCase()) {
    return 1
  }
  return 0
}

const EmployeesList = ({
  employees = null, handleRemove = null, company, loading = false, options = {}, sort = defaultSortFunction, browser, emptyTableContent = null, borderTopLeftRadius
}) => {
  if (!employees) return <span>No Employees</span>

  const listOptions = { ...defaultOptions, ...options }
  const location = useLocation();
  const params = useParams();

  const navigate = useNavigate();
  const backlinkState = { backlink: location.pathname };

  const rows = employees.sort(sort).map((employee) => {
    const href = params.serviceSlug ?
      `/settings/services/${company.get('slug')}/${params.serviceSlug}/employees/${employee.get("slug")}`
      :`/settings/employees/${company.get('slug')}/${employee.get("slug")}/details`

    const onRemove = (e) => {
      typeof handleRemove === 'function' && handleRemove(employee.get("id"))

      e.stopPropagation()
    }
    const employeeName = employee.get("name")

    const formattedPrice = employee.get("formattedPrice")
    const isDefaultPrice = formattedPrice ? formattedPrice.includes("default") : false
    const canDelete = !employee.get("hasUpcomingBooking")

    const row = {
      id: employee.get("id"),
      href,
    }

    const EditLink = ({ children }) => <Link href={href} to={href} state={backlinkState}>{ children ?? <EditIcon />}</Link>

    if (browser.greaterThan.mobile) {
      const key = `desk-${employee.get("id")}`

      row.desktop = (
        <>
          <EmployeeCell key={`${key}-link`}>
            <b>
              {listOptions.allowLink
                ? <EditLink>{employeeName}</EditLink>
                : employeeName
              }
            </b>
          </EmployeeCell>
          {listOptions.showRole ? <EmployeeCell key={`${key}-role`}>{employee.get("role")}</EmployeeCell> : null}
          {listOptions.showFormattedPrice ? <EmployeeCell $centered key={`${key}-price`}><FormattedPrice isDefaultPrice={isDefaultPrice}>{formattedPrice}</FormattedPrice></EmployeeCell> : null}
          {listOptions.showServicesCount ? <EmployeeCell $centered key={`${key}-count`}>{(employee.get("serviceId") && employee.get("serviceId").count() > 0) ? employee.get("serviceId").count() : "-"}</EmployeeCell> : null}
          <EmployeeCell key={`${key}-visible`} $centered>{employee.get("visible") ? "Yes" : "-"}</EmployeeCell>
          {handleRemove && <EmployeeCell key={`${key}-delete`} $centered>{canDelete && <TrashIcon onClick={onRemove} />}</EmployeeCell>}
          <EmployeeCell key={`${key}-edit`} $centered><EditLink /></EmployeeCell>
        </>
      )
    } else {
      const key = `mobile-${employee.get("id")}`

      row.mobile = (
        <>
          <EmployeesDetailsMobile>
            <div style={{ display: "flex" }}>
              <div key={`${key}-details`} style={{ flex: 1 }}>
                <div key={`${key}-link`}>
                  <b>
                    {listOptions.allowLink
                      ? <Link href={href} to={href} state={{ backlink: location.pathname }}>{employeeName}</Link>
                      : employeeName
                    }
                  </b>
                </div>
                <div key={`${key}-more-details`} style={{ fontSize: 14 }}>
                  {listOptions.showRole ? <div key={`${key}-role`}>{employee.get("role")}</div> : null}
                  <div key={`${key}-visible`}>
                    Visible:
                    {employee.get("visible") ? "Yes" : "-"}
                  </div>
                  {listOptions.showServicesCount ? (
                    <div key={`${key}-count`}>
                      Services:
                      {employee.get("serviceId") ? employee.get("serviceId").count() : 0}
                    </div>
                  ) : null}
                  {listOptions.showFormattedPrice ? <FormattedPrice key={`${key}-price`} isDefaultPrice={isDefaultPrice}>{formattedPrice}</FormattedPrice> : null}
                </div>
              </div>
              {handleRemove && <div style={{ marginRight: 8 }} key={`${key}-delete`}>{canDelete && <TrashIcon onClick={onRemove} />}</div>}
              <div key={`${key}-edit`}><EditLink /></div>
            </div>
          </EmployeesDetailsMobile>
        </>
      )
    }

    return row
  }).toJS()


  const getHeaders = () => {
    const headers = []

    headers.push({ label: "Name", key: "name" })

    if (listOptions.showRole) {
      headers.push({ label: "Role", key: "role" })
    }

    if (listOptions.showServicesCount) {
      headers.push({ label: "Services", key: "services", alignment: "center" })
    }
    if (listOptions.showFormattedPrice) {
      headers.push({ label: "Price", key: "price", alignment: "center" })
    }
    headers.push({ label: "Visible", key: "visible", alignment: "center" })
    headers.push({ label: "Delete", key: "delete", alignment: "center", width: "0px" }) // for the actions column
    headers.push({ label: "Edit", key: "edit", alignment: "center" }) // for the actions column
    return headers
  }

  return (
    <EmployeeTable $borderTopLeftRadius={borderTopLeftRadius}>
      <Table
        headers={getHeaders()}
        rows={rows}
        loading={loading}
        emptyTableContent={emptyTableContent}
        onRowClick={(row) => navigate(row.href, {state: backlinkState})}
      />
    </EmployeeTable>
  )
}

EmployeesList.propTypes = {
  employees: PropTypes.oneOfType([ImmutablePropTypes.list, ImmutablePropTypes.seq]),
  company: ImmutablePropTypes.map.isRequired,
  handleRemove: PropTypes.func,
  url: PropTypes.string,
  loading: PropTypes.bool,
  options: PropTypes.object,
  sort: PropTypes.func,
  browser: PropTypes.object.isRequired,
  emptyTableContent: PropTypes.element, // Content to display post-loading if rows contains no data
  borderTopLeftRadius: PropTypes.bool,
}

const mapStateToProps = state => ({
  browser: state.get("browser"),
})

export default connect(mapStateToProps)(EmployeesList)
