import { List, Map } from "immutable"
import { BULK_INSERT } from "./helpers"

const CATEGORIES_REPLACE_ITEM = "CATEGORIES_REPLACE_ITEM"

const initialCategoryState = Map({
  id: 0,
  name: "",
})

function category(state = initialCategoryState, action) {
  switch (action.type) {
    case CATEGORIES_REPLACE_ITEM:
      return state.merge({
        id: action.id,
        name: action.name,
      })

    default:
      return state
  }
}

function categoriesReplaceCategory(c) {
  return {
    type: CATEGORIES_REPLACE_ITEM,
    id: c.id,
    name: c.name,
  }
}

const initialResponsesState = Map({
  ids: List(),
})

export default function categories(state = initialResponsesState, action) {
  switch (action.type) {
    case BULK_INSERT:
      if (action.categories) {
        let s = state
        action.categories.forEach((c) => {
          const ids = s.get("ids")
          s = s.merge({
            [c.id]: category(s.get(c.id), categoriesReplaceCategory(c)),
            ids: ids.indexOf(c.id) === -1 ? ids.push(c.id) : ids,
          })
        })
        return s
      }
      return state

    case CATEGORIES_REPLACE_ITEM: {
      const ids = state.get("ids")
      const s = state.get(action.id)
      return state.merge({
        [action.id]: category(s, action),
        ids: ids.indexOf(action.id) === -1 ? ids.push(action.id) : ids,
      })
    }
    default:
      return state
  }
}
