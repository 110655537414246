import { List, Map } from "immutable"

export const RNS_SHOW_NOTIFICATION = "RNS_SHOW_NOTIFICATION"
export const RNS_HIDE_NOTIFICATION = "RNS_HIDE_NOTIFICATION"
export const RNS_REMOVE_ALL_NOTIFICATIONS = "RNS_REMOVE_ALL_NOTIFICATIONS"
const NOTIFICATIONS_OPEN_MODAL = "NOTIFICATIONS_OPEN_MODAL"
const NOTIFICATIONS_CLOSE_MODAL = "NOTIFICATIONS_CLOSE_MODAL"
const NOTIFICATIONS_OPEN_CONFIRM = "NOTIFICATIONS_OPEN_CONFIRM"
const NOTIFICATIONS_CLOSE_CONFIRM = "NOTIFICATIONS_CLOSE_CONFIRM"
const NOTIFICATIONS_SHOW_DESKTOP = "NOTIFICATIONS_SHOW_DESKTOP"
const NOTIFICATIONS_SHOWN_DESKTOP = "NOTIFICATIONS_SHOWN_DESKTOP"


export const showDesktop = (tag, title, body) => ({
  type: NOTIFICATIONS_SHOW_DESKTOP,
  tag,
  title,
  body,
})

export const shownDesktop = tag => ({
  type: NOTIFICATIONS_SHOWN_DESKTOP,
  tag,
})

export function openModal(title, message) {
  return {
    type: NOTIFICATIONS_OPEN_MODAL,
    title,
    message,
  }
}

export function closeModal() {
  return {
    type: NOTIFICATIONS_CLOSE_MODAL,
  }
}

export function openConfirm(title, message, action) {
  return {
    type: NOTIFICATIONS_OPEN_CONFIRM,
    title,
    message,
    action,
  }
}

export function closeConfirm() {
  return {
    type: NOTIFICATIONS_CLOSE_CONFIRM,
  }
}

export function showNotification(opts = {}, level = "success") {
  return {
    type: RNS_SHOW_NOTIFICATION,
    ...opts,
    uid: opts.uid || Date.now(),
    level,
  }
}

export function hideNotification(uid) {
  return {
    type: RNS_HIDE_NOTIFICATION,
    uid,
  }
}

export function removeAll() {
  return { type: RNS_REMOVE_ALL_NOTIFICATIONS }
}


const initialNotificationsState = Map({
  notifications: List(),
  desktopPending: List(),
  desktopShown: List(),
  modalOpen: false,
  modalTitle: null,
  modalMessage: null,
  confirmOpen: false,
  confirmTitle: null,
  confirmMessage: null,
  confirmAction: null,
})

export default function notifications(state = initialNotificationsState, action = {}) {
  switch (action.type) {
    case NOTIFICATIONS_SHOW_DESKTOP: {
      const p = state.get("desktopPending")
      const { type, ...rest } = action
      return state.merge({
        desktopPending: p.push(Map({
          ...rest,
        })),
      })
    }
    case NOTIFICATIONS_SHOWN_DESKTOP: {
      const p = state.get("desktopPending")
      const s = state.get("desktopShown")
      const { tag } = action
      return state.merge({
        desktopPending: p.filter(n => n.get("tag") !== tag),
        desktopShown: s.push(Map({ tag })),
      })
    }
    case NOTIFICATIONS_OPEN_MODAL:
      return state.merge({
        modalOpen: true,
        modalTitle: action.title,
        modalMessage: action.message,
      })
    case NOTIFICATIONS_CLOSE_MODAL:
      return state.merge({
        modalOpen: false,
      })
    case NOTIFICATIONS_OPEN_CONFIRM:
      return state.merge({
        confirmOpen: true,
        confirmTitle: action.title,
        confirmMessage: action.message,
        confirmAction: action.action,
      })
    case NOTIFICATIONS_CLOSE_CONFIRM:
      return state.merge({
        confirmOpen: false,
      })
    case RNS_SHOW_NOTIFICATION: {
      const notifictions = state.get("notifications")
      const { type, ...rest } = action
      return state.merge({
        notifications: notifictions.push({
          ...rest,
          uid: action.uid,
        }),
      })
    }
    case RNS_HIDE_NOTIFICATION: {
      const ns = state.get("notifications")
      return state.merge({
        notifications: ns.filter(n => n.uid !== action.uid),
      })
    }
    case RNS_REMOVE_ALL_NOTIFICATIONS:
      return state.merge({
        notifications: List(),
      })

    default:
      return state
  }
}

export const isModalOpen = state => state.getIn(["notifications", "modalOpen"])

export const getModalTitle = state => state.getIn(["notifications", "modalTitle"])

export const getModalMessage = state => state.getIn(["notifications", "modalMessage"])

export const isConfirmOpen = state => state.getIn(["notifications", "confirmOpen"])

export const getConfirmTitle = state => state.getIn(["notifications", "confirmTitle"])

export const getConfirmMessage = state => state.getIn(["notifications", "confirmMessage"])

export const getConfirmAction = state => state.getIn(["notifications", "confirmAction"])

export const getPendingDesktopNotifications = state => state.getIn(["notifications", "desktopPending"])

export const getShownDesktopNotifications = state => state.getIn(["notifications", "desktopShown"])
