import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Link, useLocation, useNavigate } from "react-router-dom"

import { device } from "../Responsive/Responsive"
import { ArrowLeftIcon, InfoIcon } from "../Icons/Icons"
import { H1 } from "../Typography"

const Title = styled(H1)`
    @media ${device.mobile} {
        padding-left: 25px;
        padding-top: 23px;
    }
`

const Content = styled.div`

    @media ${device.mobile} {
        padding: 0px;
        margin: 0px;
    }

    @media ${device.tablet}, ${device.desktop} {
      padding: 20px;
    }
`


const FormParent = styled.div`
    padding-top: ${({ theme }) => theme.parentPadding};
`

const DetailParent = styled.div`

    padding-right: 34px;
    width: 294px;
    min-width: 294px;

    @media ${device.mobile} {
        padding: 0px;
        margin: 0px;
        width: auto;
    }
`

const BackLink = styled(Link)`
    display: flex;
    align-items: center;
    font-size: 17px;
    font-weight: 500;
    cursor: pointer;
    color: ${({ theme }) => theme.headerLinkColor};
    svg {
      margin-right: 10px; 
    }
    &:hover {
      color: ${({ theme }) => theme.primaryColor};
      svg path {
        fill: ${({ theme }) => theme.primaryColor};
      }
    }
    @media ${device.mobile} {
        padding: 20px 0px;
    }
`

const TopInnerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  @media ${device.mobile} {
      display: none;
  }
`
const HelpLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: 500;
  color: ${({ theme }) => theme.headerLinkColor};
  svg {
    margin-left: 5px;
  }
`

const FormWrapper = ({ detail = undefined, children, title = '', backLinkLabel = '', backLinkPath = null, showHelpLink = false }) => {

  const navigate = useNavigate();

  return (
    <Content>
      { (backLinkLabel.length > 0 || title.length > 0) && (
        <div>
          {backLinkLabel.length > 0 &&(
            <TopInnerHeader>
              <BackLink
                className="BackLink"
                to={backLinkPath ?? -1}
                onKeyUp={(evt) => evt.key === "Enter" && navigate(backLinkPath ?? -1)}
              >
                <ArrowLeftIcon />
                {backLinkLabel}
              </BackLink>
              {showHelpLink && <HelpLink to='/help/faqs'>Need help <InfoIcon /></HelpLink>}
            </TopInnerHeader>
          )}
          { title.length > 0
            && <Title className="MainTitle">{title}</Title>
          }
        </div>
      )}

      <FormParent>
        {detail && <DetailParent>{detail}</DetailParent>}
        {children}
      </FormParent>
    </Content>

  )
}

FormWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  detail: PropTypes.node,
  title: PropTypes.string,
  backLinkLabel: PropTypes.string,
  backLinkPath: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showHelpLink: PropTypes.bool,
}

export default FormWrapper
