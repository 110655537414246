import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Link } from "react-router-dom"

import Button from "../Form/Button"

const WrapperLink = styled(Link)`
    text-decoration: none;
`

/*
* This component looks like a button but when clicked navigates to the url specified in the prop 'to'
*/
const NavigateButton = ({ to, children, ...rest }) => (
  <WrapperLink to={to}><Button {...rest}>{children}</Button></WrapperLink>
)

NavigateButton.propTypes = {
  to: PropTypes.string.isRequired, // url to navigate to when button is clicked
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]), // text or element for the body of the button.
}

export default NavigateButton
