import PropTypes from "prop-types"
import Immutable from "immutable"
import ImmutablePropTypes from "react-immutable-proptypes"
import { connect } from "react-redux"
import React from "react"
import styled from "styled-components"
import { Link, useLocation, useParams } from "react-router-dom"

import { ListIcon, MosaicIcon } from "../Icons/Icons"
import { getServiceDescriptions, getServiceImage } from "../../redux/modules/services"
import { getSelectedCompany } from "../../redux/modules/companies"
import IconAndLabelLink from "../Form/IconAndLabelLink"
import LoadingIcon from "../Loading/LoadingIcon"
import ServiceCardView from "./CardView"
import ServiceRowView from "./RowView"
import { ServiceEditIcon, ServiceName } from "./common"

import { device } from "../Responsive/Responsive"

const ToggleViewButtons = styled.div`
  padding: 0 0 1rem 0;
  margin-bottom: 1rem;
  border-bottom: solid 1px ${({ theme }) => theme.borderColor};
  button + button {
    margin-left: 1rem;
  }
  min-width: 320px;
`

const ServicesContainer = styled.div`
  padding: 0 1rem;

  a {
    text-decoration: none !important;
  }

  @media ${device.tablet}, ${device.desktop} {
    height: 65vh;
    overflow-y: scroll;
  }

  @media ${device.mobile} {
    padding: 0;
  }
`

const ServiceCardsContainer = styled.div`
  display: grid;

  a {
    margin-bottom: 20px;
  }

  @media ${device.tablet}, ${device.desktop} {
    grid-template-columns: repeat(3, 1fr);

    a:nth-child(3n + 1) {
      justify-self: start;
    }

    a:nth-child(3n + 2) {
      justify-self: center;
      margin: 0 20px 20px 20px;
    }

    a:nth-child(3n + 3) {
      justify-self: end;
    }
  }

  @media ${device.mobile} {
    grid-template-columns: repeat(2, 1fr);

    a:nth-child(2n + 1) {
      margin-right: 8px;
    }

    a:nth-child(2n + 2) {
      justify-self: end;
      margin: 0;
      margin-left: 8px;
    }
  }
`

const ServiceLink = styled.div`
  img, ${ServiceName} {
    ${({ $highlighted }) => $highlighted ? '' : 'opacity: 50%'};
  }
  &:hover {
    ${ServiceEditIcon} {
      display: block;
    }
    ${ServiceName} {
      color: ${({ theme }) => theme.primaryColor};
    }
  }
`

const THUMBNAIL_VIEW = "thumbnail"
const LIST_VIEW = "list"

/**
 * This is a new approach to build the services list, instead of using react-virtualized we use our own plain html table 'components/Table'
 */
const ServicesList = ({ emptyTableContent, loading = false, selectedCompany, services = Immutable.List(), serviceLinkTarget = 'details' }) => {
  const [activeView, setActiveView] = React.useState(THUMBNAIL_VIEW)

  const location = useLocation();
  const params = useParams()

  const backlinkState = { backlink: location.pathname };

  const toggleView = () => {
    setActiveView(activeView === THUMBNAIL_VIEW ? LIST_VIEW : THUMBNAIL_VIEW)
  }
  
  const getServiceLink = (service, selectedCompany) => {
    const serviceSlug = service.get("slug")
    const companySlug = selectedCompany.get("slug")
    const employeeSlug = params?.employeeSlug;

    let href = `/settings/services/${companySlug}/${serviceSlug}/${serviceLinkTarget}`
    if (serviceLinkTarget === 'employees' && employeeSlug) {
      href = `${href}/${employeeSlug}`
    }
    return href;
  }

  const getServices = () => {
    const servicesViews = (services.sortBy(s => s.getIn(["serviceDescription", "name"]).toLowerCase()).map((service) => {
      const serviceDescription = service.getIn(["serviceDescription", "name"])
      const href = getServiceLink(service, selectedCompany)
      const galleryImage = getServiceImage(service)
      const live = service.get("visible")

      return (
        <Link href={href} to={href} key={service.get("id")} state={backlinkState}>
            <ServiceLink $highlighted={live}>
              {activeView === LIST_VIEW ? (
                <ServiceRowView description={serviceDescription} image={galleryImage} live={live} />
                ) : (
                <ServiceCardView description={serviceDescription} image={galleryImage} live={live} />
              )}
            </ServiceLink>
        </Link>
      )
    }).toJS())
    return servicesViews
  }

  return (
    <ServicesContainer>
      <ToggleViewButtons>
        <IconAndLabelLink active={activeView === THUMBNAIL_VIEW} icon={<MosaicIcon />} onClick={toggleView}>Thumbnail View</IconAndLabelLink>
        <IconAndLabelLink active={activeView === LIST_VIEW} icon={<ListIcon />} onClick={toggleView}>List View</IconAndLabelLink>
      </ToggleViewButtons>
      {loading ? <div style={{ textAlign: "center" }}><LoadingIcon /></div> : null}

      {!loading && services.count() === 0 ? emptyTableContent : null}

      {!loading && services.count() > 0 && (activeView === LIST_VIEW  
        ? getServices()
        : <ServiceCardsContainer>{getServices()}<div style={{ flex: 1 }}/></ServiceCardsContainer>
      )}
    </ServicesContainer>
  )
}

ServicesList.propTypes = {
  services: ImmutablePropTypes.list,
  serviceLinkTarget: PropTypes.string,
  loading: PropTypes.bool,
  selectedCompany: ImmutablePropTypes.map.isRequired,
  emptyTableContent: PropTypes.element, // Content to display post-loading if rows contains no data
  serviceDescriptions: ImmutablePropTypes.map,
}

const mapStateToProps = (state, { services }) => {
  const selectedCompany = getSelectedCompany(state)
  return ({
    selectedCompany,
    serviceDescriptions: getServiceDescriptions(state),
  })
}

export default connect(mapStateToProps)(ServicesList)
