import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { ThemedPhoneInput, ThemedTextInput } from "../../components/Form/TextInput"
import {
  CONTACT_ROLES,
  contactsSaveContact, hasErrorSelector,
  isSavingSelector
} from "../../redux/modules/contacts"
import { Button } from "../../components/Form"
import { device } from "../../components/Responsive/Responsive"
import { Field, Formik } from "formik"
import { min2Max100, validEmail } from "../validators"
import InputError from "../../components/Form/InputError"
import { connect } from "react-redux"
import { getSelectedCompany } from "../../redux/modules/companies"
import StyledSelectField from "../../components/Form/StyledSelectField"

export const CONTACT_FORM_NAME = "settingsContacts"

const SettingsContactSaveButton = styled(Button)`

    @media ${device.large} {
        margin-top: 16px;
    }
`

const SettingsContactFormParent = styled.div`

    border: 1px solid ${({ theme }) => theme.borderColor};
    padding: 30px;
    width: calc(100% - 62px);


    @media ${device.large} {
        padding: 16px 0px 16px 0px;
        width: 100%;
        border: none;
    }
`

const Row = styled.div`
    height: auto;
    position: relative;
    margin-bottom: 8px;
`
const RowLabel = styled.label`
    display: inline-block;
    width: 200px;
    font-family: 'Museo-Sans', 'sans-serif';
    font-weight: 300;
    color: #575757;
    font-size: 17px;
    height: 38px;
    line-height: 38px;

    @media ${device.large} {
        display: block;
        width: 100%;
    }
`

const RowField = styled(ThemedTextInput)`
    width: 495px;

    @media ${device.large} {
        width: calc(100% - 30px);
    }
`

const SelectField = styled(StyledSelectField)`
    display: inline-block;
    width: 495px;

    @media ${device.large} {
        width: calc(100% - 30px);
    }
`

const ContactPhoneNumber = styled(ThemedPhoneInput)`
    width: 495px;

    @media ${device.large} {
        width: 100%;
    }
`

const TextField = ({ name, errors, ...rest }) => (
  <>
    <RowField name={name} type="text" {...rest} />
    { errors[name] && <span><InputError>{errors[name]}</InputError></span> }
  </>
);

const ContactForm = ({ company, hasError, initialValues, isSaving, saveContact }) => {
  return (<Formik
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize={true}
      initialValues={initialValues}
      validate={(values) => {
        const errors = {};

        if (!values.firstName) {
          errors.firstName = "Please enter a First Name.";
        } else if (!min2Max100(values.firstName)) {
          errors.firstName = "First name should be 2-100 characters.";
        }

        if (!values.lastName) {
          errors.lastName = "Please enter a Last Name.";
        } else if (!min2Max100(values.lastName)) {
          errors.lastName = "Last name should be 2-100 characters.";
        }

        if (!values.email) {
          errors.email = "Please enter an email.";
        } else if (!validEmail(values.email)) {
          errors.email = ", ThemedTextInputEmail address is invalid";
        }

        if (!values.phoneNumber) {
          errors.phoneNumber = "Please enter a Phone number.";
        }

        if(isNaN(parseInt(values.roleId))) {
          errors.roleId = 'Please select a valid role'
        }

        return errors;
      }}
      onSubmit={(values) => {
        return saveContact(company.get('id'), values)
      }}
    >
      {({errors, values, setFieldValue, isSubmitting, resetForm, handleSubmit}) => {
        const [wasSaving, setWasSaving] = useState()
        useEffect(() => {
          if (!hasError && (isSaving === false && wasSaving === true)) {
            resetForm()
          }

          setWasSaving(isSaving)
        }, [isSaving])
        return (
          <>
            <SettingsContactFormParent>
              <Row>
                <RowLabel>First name</RowLabel>
                <Field
                  name="firstName"
                  errors={errors}
                  as={TextField}
                />
              </Row>
              <Row>
                <RowLabel>Last name</RowLabel>
                <Field
                  name="lastName"
                  errors={errors}
                  as={TextField}
                />
              </Row>
              <Row>
                <RowLabel>Role</RowLabel>
                <Field
                  name="roleId"
                  as={SelectField}
                  errors={errors}
                  options={Object.keys(CONTACT_ROLES)
                    .sort((a, b) => CONTACT_ROLES[a] > CONTACT_ROLES[b])
                    .map(key => ({ label: CONTACT_ROLES[key], value: key }))
                  }
                  onChange={(value) => setFieldValue('roleId', value)}
                />
                { errors['roleId'] && <span><InputError>{errors['roleId']}</InputError></span> }
              </Row>
              <Row>
                <RowLabel>Email</RowLabel>
                <Field
                  name="email"
                  as={TextField}
                  errors={errors}
                />
              </Row>
              <Row>
                <RowLabel>Phone</RowLabel>
                <Field
                  component={ContactPhoneNumber}
                  value={values.phoneNumber}
                  onChange={(value) => setFieldValue('phoneNumber', value)}
                />
                { errors['phoneNumber'] && <span><InputError>{errors['phoneNumber']}</InputError></span> }
              </Row>
              <div style={{ textAlign: "right" }}>
                <SettingsContactSaveButton disabled={isSaving || isSubmitting} type="submit" onClick={handleSubmit}>Save</SettingsContactSaveButton>
              </div>
            </SettingsContactFormParent>
          </>)}}
    </Formik>
    )
}

ContactForm.propTypes = {
  saveContact: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  initialValues: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
}

const mapDispatchToProps = (dispatch) => ({
  saveContact: (companyId, values) => dispatch(contactsSaveContact(companyId, values)),
});

const mapStateToProps = (state) => ({
  isSaving: isSavingSelector(state),
  hasError: hasErrorSelector(state),
  company: getSelectedCompany(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm)
