import React from "react"
import { subscribe, unsubscribe } from "pusher-redux"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import { REFRESH_ALL } from "../../config/constants"
import { PUSHER_EVENT, pusherSubscribed, pusherUnsubscribed } from "../../redux/modules/pusher"

class PusherToolbarContainer extends React.Component {
  componentDidMount() {
    this.subscribe()
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  clickHandler = () => {
    const { dispatch } = this.props
    dispatch({ type: REFRESH_ALL })
  }

  subscribe = () => {
    subscribe("company-refresh", "client-refresh", PUSHER_EVENT)
    subscribe("company-notification", "client-notification", PUSHER_EVENT)

    const { dispatch } = this.props
    dispatch(pusherSubscribed())
  }

  unsubscribe = () => {
    unsubscribe("company-refresh", "client-notification", PUSHER_EVENT)
    unsubscribe("company-notification", "client-refresh", PUSHER_EVENT)

    const { dispatch } = this.props
    dispatch(pusherUnsubscribed())
  }

  render() {
    // const { isApiActive, style } = this.props

    return null

    /* return (
      <PusherToolbar style={style} onClick={this.clickHandler} isActive={isApiActive} />
    ) */
  }
}

PusherToolbarContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isApiActive: PropTypes.bool.isRequired,
  style: PropTypes.object,
}

function mapStateToProps(state) {
  return {
    isApiActive: state.getIn(["api", "isActive"]) > 0,
  }
}

export default connect(mapStateToProps)(PusherToolbarContainer)
