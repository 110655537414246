import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const PlainButton = styled.button`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  border: none !important;
  background: none !important;
  font-size: 16px;
  cursor: pointer;
  span {
    margin-left: 9px;
    color: ${({ theme, active }) => !active ? theme.disabledColor : 'inherit'};
  }
  svg {
    path {
      stroke: ${({ theme, active }) => !active ? theme.disabledColor : theme.primaryColor} !important;
    }
  }
  &:hover {
    span, svg {
      ${({ active }) => !active ? `filter: brightness(90%)` : ''};
    }
  }
`

const IconAndLabelLink = ({ icon, children, onClick, active }) => {
  return (
    <PlainButton onClick={onClick} active={active}>{icon}<span>{children}</span></PlainButton>
  )
}

IconAndLabelLink.propTypes = {
  icon: PropTypes.node.isRequired,
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default IconAndLabelLink