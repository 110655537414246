import {
  IntroCol,
  IntroHeadline,
  IntroRow,
  IntroWrapper,
  MaxWidthWrapper,
  Section,
  TopHeader
} from "../StyledComponents"
import React from "react"
import InfoCard from "../InfoCard"

export default () => {
  return (
    <Section background="transparent">
      <MaxWidthWrapper>
        <IntroRow>
          <IntroCol>
            <IntroWrapper centered={true}>
              <TopHeader><h4>Business Benefits</h4></TopHeader>
              <IntroHeadline><h1>Increase your appointments and your business</h1></IntroHeadline>
              <p>Drive new clients to your salon with Aveda's convenient online booking tool.</p>
            </IntroWrapper>
          </IntroCol>
        </IntroRow>

        <IntroRow gutter="narrow">
          <IntroCol gutter="narrow">
            <InfoCard
              headline={'New clients'}
              icon={'https://images.prismic.io/flossie-production/1bae196c-0598-4f97-bb79-f8b884f63734_new-clients-icon.png?auto=compress%2Cformat&w=800&h=800&fit=max'}
              content={'Customers can easily find your salon and book a service.'}
            />
          </IntroCol>
          <IntroCol gutter="narrow">
            <InfoCard
              headline={'Free promo'}
              icon={'https://images.prismic.io/flossie-production/05ba5d40-e0d5-4a19-bbc8-cc7415fda741_promo-icon.png?auto=compress%2Cformat&w=800&h=800&fit=max'}
              content={'No cost to you, Aveda can promote salon appointments via their marketing channels.'}
            />
          </IntroCol>
          <IntroCol gutter="narrow">
            <InfoCard
              headline={'No admin'}
              icon={'https://images.prismic.io/flossie-production/aaead64d-0dc9-4a80-b499-b45e9f851ea7_admin-icon.png?auto=compress%2Cformat&w=800&h=800&fit=max'}
              content={'Hassle free admin, appointments are added directly to your appointment calendar.'}
            />
          </IntroCol>
          <IntroCol gutter="narrow">
            <InfoCard
              headline={'Premium'}
              icon={'https://images.prismic.io/flossie-production/0e17785b-f458-429a-b7d8-cb2fa8d522d9_feedback-icon.png?auto=compress%2Cformat&w=800&h=800&fit=max'}
              content={'Alignment with premium retailing of services.'}
            />
          </IntroCol>
        </IntroRow>
      </MaxWidthWrapper>
    </Section>
  )
}