import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import ImmutablePropTypes from "react-immutable-proptypes"
import Immutable, { List, Map } from "immutable"
import styled from "styled-components"

import {
  isServicesLoading,
  servicesSaveService,
  getFormattedPrice,
  getServiceError,
  isServicesSaving,
  getServiceDescriptions,
  getAllServicesSlugMap,
  getServiceImage,
} from "../../redux/modules/services"
import ServiceForm, { EXISTING_PACKAGE, NEW_PACKAGE } from "../../forms/ServiceEdit/ServiceForm"
import FormWrapper from "../../components/Wrappers/FormWrapper"
import { getSelectedCompany } from "../../redux/modules/companies"
import { getCompanyIdInCookie } from "../../redux/modules/auth"
import Tabs from "../../components/Navigation/Tabs"
import NavigateTab from "../../components/Navigation/Tabs/NavigateTab"
import { PuzzleIcon } from "../../components/Icons/Icons"
import { H2 } from "../../components/Typography"
import EmployeePricing from "../../forms/ServiceEdit/EmployeePricing"
import LoadingIcon from "../../components/Loading/LoadingIcon"
import InlineError from "../../components/Form/InlineError"
import { useLoaderData, useLocation, useNavigate, useParams } from "react-router-dom"

const ServiceHeading = styled.div`
  text-align: center;
  padding: 30px 0px;
  svg {
    margin-bottom: 10px;
  }
`

const ServiceEditContainer = ({
  allServicesBySlug = Immutable.Map(), dispatchServicesSaveService, loading, innerServices, selectedCompany = null, errorMessage = null, isSaving, defaultPriceFieldValue = null, serviceDescriptions = null, serviceDescriptionFieldValue = null,
  browser
}) => {

  const { serviceSlug, companySlug, serviceSection } = useParams();
  const { companyId } = useLoaderData()
  const location = useLocation();
  const navigate = useNavigate();

  let service = null
  if (!loading) {
    if (serviceSlug && allServicesBySlug && allServicesBySlug.has(serviceSlug)) {
      service = allServicesBySlug.get(serviceSlug)
      if (service && service.get("externalType") === "package") {
        if (service.get("externalId") === "-1") {
          // it is a package created from scratch
          service = service.set("packageType", NEW_PACKAGE)
        } else {
          // it is linked to an external package
          service = service.set("packageType", EXISTING_PACKAGE)
        }
      }
    }
  }

  const title = serviceSlug ? (service && service.getIn(['serviceDescription', 'name'])) ?? "Configure Service" : "New Service"

  const backLinkLabel = browser.greaterThan.medium ? "Back to Services" : "Back"
  const backLinkPath = location.state?.backlink ?? "/settings/services"

  useEffect(() => {
    const allCompaniesIsSelected = !selectedCompany && !companyId
    const newCompanySelected = selectedCompany && selectedCompany.get("slug") !== companySlug
    if (allCompaniesIsSelected || newCompanySelected) {
      // User selected another company or company slug in url doesn't exist
      // We redirect to services page
      navigate(backLinkPath)
    }
  }, [companyId])

  const onSubmitEmployeePricingForm = ({ employeeId, price }) => {
    //Find the existing employee
    let employee = service.getIn(['employees', employeeId])
    if (employee) {
      dispatchServicesSaveService(service.setIn(
        ['employees', employeeId],
        employee
          .set('price', price)
          .set('formattedPrice', getFormattedPrice(price, null, selectedCompany))
      ))
    }
  }

  const onSubmitServiceForm = (values) => {

    if (!service || !Map.isMap(service)) {
      return
    }

    const externalId = values.externalType === 'service' || values.packageType === EXISTING_PACKAGE ?
      values.externalServiceId
      : '-1'

    service = service.set('externalType', values.externalType)
      .set('externalId', externalId)
      .set('visible', values.visible)
      .set('servicePackageId', '')
      .delete('externalName')
      .delete('externalCategoryId')

    if (service.get('externalType') === 'service') {
      service = service
        .set('externalId', values.externalServiceId)
        .set('externalName', values.externalName)
        .set('externalCategoryId', values.externalCategoryId)
        .set('servicePackageId', '')
    } else if (
      service.get('externalType') === 'package'
      && service.get('externalId') === '-1'
    ) {
      service = service.set('servicePackageId', new List(values.innerPackageServices ?? []))
    }

    dispatchServicesSaveService(service)
  }

  const serviceDescriptionId = serviceDescriptionFieldValue ? serviceDescriptionFieldValue.get("id") : null
  
  const serviceDetailsPathname = `/settings/services/${companySlug}/${serviceSlug}/details`
  const serviceStylistPathname = `/settings/services/${companySlug}/${serviceSlug}/employees`
  return (
    <>
      <FormWrapper
        title={title}
        backLinkLabel={backLinkLabel}
        backLinkPath={backLinkPath}
      >
        <Tabs size="sm" align="left">
          <NavigateTab to={serviceDetailsPathname} key={serviceDetailsPathname}>Match Service</NavigateTab>
          <NavigateTab to={serviceStylistPathname} key={serviceStylistPathname}>Stylist Pricing</NavigateTab>
        </Tabs>
        {loading && <div style={{ padding: 30, textAlign: "center"}}><LoadingIcon /></div>}
        {(!loading && !service) && <InlineError align="left" error="Service not found" />}
        {!loading && service && serviceSection === 'details' && (
          <>
            <ServiceHeading>
              <PuzzleIcon />
              <H2>Match the Aveda Service with Your Salon Service</H2>
            </ServiceHeading>
            <ServiceForm
              serviceName={serviceDescriptionFieldValue?.get("name")}
              serviceImage={getServiceImage(service)}
              selectedCompany={selectedCompany}
              serviceId={service.get("id")}
              initialValues={service.toJS()}
              serviceDescriptionId={serviceDescriptionId}
              serviceDescriptions={serviceDescriptions}
              loading={loading || isSaving}
              onSubmit={onSubmitServiceForm}
              innerServices={innerServices}
              errorMessage={errorMessage}
            />
          </>
        )}
        {!loading && service && serviceSection === 'employees' && (
          <>
            <div style={{ height: 30 }} />
            <EmployeePricing 
              service={service}
              defaultPriceFieldValue={defaultPriceFieldValue}
              selectedCompany={selectedCompany}
              onSubmit={onSubmitEmployeePricingForm}
              loading={loading || isSaving}
              errorMessage={errorMessage}
            />
          </>
        )}
      </FormWrapper>
    </>
  )
}

ServiceEditContainer.propTypes = {
  service: ImmutablePropTypes.map,
  loading: PropTypes.bool.isRequired,
  dispatchServicesSaveService: PropTypes.func.isRequired, // action to save/update service
  innerServices: ImmutablePropTypes.list.isRequired,
  selectedCompany: ImmutablePropTypes.map,
  errorMessage: PropTypes.string,
  isSaving: PropTypes.bool.isRequired,
  serviceDescriptions: ImmutablePropTypes.map,
  allServicesBySlug: ImmutablePropTypes.map,
  browser: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {

  const innerServices = new List()

  return ({
    loading: isServicesLoading(state),
    errorMessage: getServiceError(state),
    innerServices,
    // get the global selected company
    selectedCompany: getSelectedCompany(state),
    // get the current service if any
    allServicesBySlug: getAllServicesSlugMap(state),
    isSaving: isServicesSaving(state),
    serviceDescriptions: getServiceDescriptions(state),
    browser: state.get("browser"),
  })
}

const mapDispatchToProps = dispatch => ({
  dispatchServicesSaveService: service => dispatch(servicesSaveService(service)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ServiceEditContainer)
