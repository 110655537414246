import styled from "styled-components"
import { SearchIcon } from "../Icons/Icons"
import React from "react"
import { ThemedTextInput } from "./TextInput"
import PropTypes from "prop-types"
import TextArea from "./TextArea"

const SearchInputContainer = styled.div`
  position: relative;
  svg {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
  input {
    padding-left: 40px !important;
    ::placeholder {
      opacity: 0.5;
    }
  }
`
const SearchInput = (props) => (
  <SearchInputContainer>
    <SearchIcon />
    <ThemedTextInput {...props} />
  </SearchInputContainer>
)

export default SearchInput