import styled from "styled-components"
import Label from "./Label"
import { ThemedTextInput } from "./TextInput"
import React from "react"

const RadioContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  input[type="radio"] {
    accent-color: ${({ theme }) => theme.primaryColor};
    min-width: auto;
    height: 18px;
    width: 18px;
    margin: 0;
  }
  label {
    margin-bottom: 0 !important;
    margin-left: 8px;
    font-size: 15px;
  }
`

const ThemedRadioInput = ({ id, label, ...rest}) => {
  return (
    <RadioContainer>
      <ThemedTextInput type="radio" id={id} {...rest} />
      {label && <Label htmlFor={id}>{label}</Label>}
    </RadioContainer>
  )
}

export default ThemedRadioInput