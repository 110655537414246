import React from "react"
import Responsive from "react-responsive"


export const ResponsiveMobile = props => <Responsive {...props} maxWidth={767} />
export const ResponsiveDefault = props => <Responsive {...props} minWidth={768} />

const size = {
  mobile: "767px",
  tablet: "768px",
  desktop: "2560px",
}

export const device = {
  mobile: `(max-width: ${size.mobile})`,
  tablet: `(min-width: ${size.tablet})`,
  desktop: `(min-width: ${size.desktop})`,

  // Default breakpoints from https://github.com/AlecAivazis/redux-responsive added to ease
  // new settings layouts. Setup above is a non-functional mess and should be migrated to use
  // default (or some other appropriate set of values) values ongoing
  extraSmall: "(max-width: 480px)",
  small: "(max-width: 768px)",
  medium: "(max-width: 992px)",
  large: "(max-width: 1200px)",
}
