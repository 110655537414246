import React from "react"
import PropTypes from "prop-types"
import { PrismicRichText } from "@prismicio/react"
import MainContent from "../../components/Wrappers/MainContent"

const SimplePage = ({ data }) => {
  if (!data.body) return null

  return (
    data
      .body.filter(slice => slice.slice_type === "text_section")
      .map((slice, index) => (
        <MainContent key={`${slice.slice_type}${index}`}>
          <PrismicRichText field={slice.primary.text_content} />
        </MainContent>
      ))
  )
}

SimplePage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default SimplePage
