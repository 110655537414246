import { List, Map } from "immutable"
import { BULK_INSERT } from "./helpers"

const REPLACE_USER = "REPLACE_USER"

export function usersReplaceUser(u) {
  return {
    type: REPLACE_USER,
    id: u.id,
    email: u.username,
    firstName: u.first_name,
    lastName: u.last_name,
    phoneNumber: u.phone_number,
    isVip: u.vip,
  }
}
export function usersReplace(id, email, firstName, lastName, phoneNumber, isVip) {
  return {
    type: REPLACE_USER,
    id,
    email,
    firstName,
    lastName,
    phoneNumber,
    isVip,
  }
}

const initialUserState = Map({
  id: "0",
  email: "",
  firstName: "",
  lastName: "",
  phoneNumber: "",
  isVip: false,
})

function user(state = initialUserState, action) {
  switch (action.type) {
    case REPLACE_USER:
      return state.merge({
        id: action.id,
        email: action.email,
        firstName: action.firstName,
        lastName: action.lastName,
        phoneNumber: action.phoneNumber,
        isVip: action.isVip,
      })
    default:
      return state
  }
}

const initialState = Map({
  ids: List(),
})

export default function users(state = initialState, action) {
  switch (action.type) {
    case BULK_INSERT:
      if (action.users) {
        let s = state
        action.users.forEach((u) => {
          const ids = s.get("ids")
          s = s.merge({
            [u.id]: user(s.get(u.id), usersReplaceUser(u)),
            ids: ids.indexOf(u.id) === -1 ? ids.push(u.id) : ids,
          })
        })
        return s
      }
      return state

    case REPLACE_USER: {
      const u = state.get(action.id)
      const ids = state.get("ids")
      return state.merge({
        [action.id]: user(u, action),
        ids: ids.indexOf(action.id) === -1 ? ids.push(action.id) : ids,
      })
    }
    default:
      return state
  }
}
