import { useState, useEffect } from "react"


import PropTypes from "prop-types"

import { useIntercom } from "react-use-intercom"
import { useCookies } from "react-cookie"

const WAS_SHOWN_COOKIE_NAME='intercomWasShown';

const IntercomContainer = ({ intercomUser }) => {
  const {
    boot, show
  } = useIntercom()

  const [cookies, setCookie] = useCookies([WAS_SHOWN_COOKIE_NAME]);

  const [booted, setBooted] = useState(false)

  useEffect(() => {
    if (!booted) {
      boot(intercomUser)

      if (typeof cookies !== 'object' || !Object.keys(cookies).includes(WAS_SHOWN_COOKIE_NAME)) {
        setCookie(WAS_SHOWN_COOKIE_NAME, '1');
        show()
      }

      setBooted(true)
    }
  })

  return null
}

IntercomContainer.propTypes = {
  intercomUser: PropTypes.object.isRequired,
}

export default IntercomContainer
