import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { device, ResponsiveDefault } from "../Responsive/Responsive"

import { ListTitleContainer, ListTitleText } from "./ListWrapper"
import { useNavigate } from "react-router-dom"


const Parent = styled.div`
    @media ${device.tablet}, ${device.desktop}  {
        padding: 0px 50px;
    }

    @media ${device.mobile} {
        padding: 20px 0px;
    }
`

const Content = styled.div`
  background-color: #fff;
  margin: 0px 0px 20px 0px;
  width: calc(100% - 60px);
  border: 1px solid #ececec;
  padding: 30px;

  @media ${device.mobile} {
      margin: 0px 20px;
      width: calc(100% - 100px);
  }
`

const BackLink = styled.div`
    position: absolute;
    right: 0;
    top: 36px;
`

const NavigationBar = styled.div`
  margin-bottom: 1rem;
`

const ContentWrapper = ({ title = "", children, backLink = false, backLinkUrl = null, navigation = null, style = {} }) => {

  const navigate = useNavigate()

  const backClick = () => navigate(backLinkUrl ? backLinkUrl : -1)

  return (
    <Parent style={{ ...style }}>
      {title
        && (
          <ResponsiveDefault>
            <div style={{ position: "relative", marginBottom: 39 }}>
              { backLink && backLink.length > 0
                && (
                  <BackLink
                    className="BackLink"
                    role="button"
                    tabIndex={0}
                    onClick={backClick}
                    onKeyUp={(evt) => {
                      if (evt.key === "Enter") {
                        backClick()
                      }
                    }}
                  >
                    {backLink}
                  </BackLink>
                )
              }
              <ListTitleContainer>
                <ListTitleText className="MainTitle">{title}</ListTitleText>
              </ListTitleContainer>
            </div>
          </ResponsiveDefault>
        )
      }
      {navigation && (
        <NavigationBar>{navigation}</NavigationBar>
      )}
      <Content>
        {children}
      </Content>
    </Parent>
  )
}

ContentWrapper.propTypes = {
  backLink: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  backLinkUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  title: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node.isRequired,
  navigation: PropTypes.oneOfType([PropTypes.element, PropTypes.array]), // can be used for breadcrumbs navigation
}

export default ContentWrapper
