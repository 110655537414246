import React from "react"
import PropTypes from "prop-types"
import ReactTooltip from "react-tooltip"
import styled, { withTheme } from "styled-components"

import { QuestionIcon } from "../Icons/Icons"

const TooltipIcon = styled(QuestionIcon)`
    width: 18px;
    height: 18px;
    cursor: pointer;
    align-self: center;
`

const StyledReactTooltip = styled(ReactTooltip)`
    max-width: 250px;
    span {
      font-weight: bold;
    }
`


const TooltipWrapper = styled.div`
    display: flex;
    position: relative;
`

const Tooltip = ({
  children, id, theme, className = '', style = {}
}) => (
  <TooltipWrapper>
    <TooltipIcon style={style} className={className} data-tip data-for={id} />
    <StyledReactTooltip id={id} effect="solid" multiline color={theme.primaryContrastColor} backgroundColor={theme.primaryColor}>
      {children}
    </StyledReactTooltip>
  </TooltipWrapper>
)


Tooltip.propTypes = {
  id: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
}

export default withTheme(Tooltip)
