import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Thumbor from "thumbor"

import { THUMBOR_KEY, THUMBOR_URL } from "../../config/constants"

const LogoCompany = styled.img`
    border-radius: 100%;
    border: 2px solid #ececec;
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
`

const CompanyAvatar = ({ logoUrl = null, size = 40, ...rest }) => {
  let formattedLogoUrl

  const thumbor = new Thumbor(THUMBOR_KEY, THUMBOR_URL)

  /* Getting a higher quality image from thumbor then downsizing in the browser with
        CSS usually gets better results with tiny 54x54 images like this */
  if (logoUrl) {
    formattedLogoUrl = thumbor.setImagePath(logoUrl.replace("cropped_", ""))
      .resize(256, 256)
      .filter("quality(95)")
      .buildUrl()
  }

  if (formattedLogoUrl) {
    return <LogoCompany alt="" src={formattedLogoUrl} size={size} {...rest} />
  }
  return null
}

CompanyAvatar.propTypes = {
  logoUrl: PropTypes.string,
  size: PropTypes.number, // This value will be used for height and width
}

export default CompanyAvatar
