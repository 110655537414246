import { Map } from "immutable"
import { createSelector } from "reselect"

export const API_ACTIVE = "API_ACTIVE"

export const apiActive = isActive => ({
  type: API_ACTIVE,
  isActive,
})

const initialApiState = Map({
  isActive: 0,
})

const api = (state = initialApiState, action) => {
  switch (action.type) {
    case API_ACTIVE: {
      const isActive = state.get("isActive") + (action.isActive ? 1 : -1)
      return state.merge({
        isActive,
      })
    }
    default:
      return state
  }
}

export default api

const getApi = state => state.get("api")

export const isApiActive = createSelector(
  getApi,
  apiState => apiState.get("isActive") > 0,
)
