import React from "react"
import Immutable, { Map } from "immutable"
import PropTypes from "prop-types"
import ImmutablePropTypes from "react-immutable-proptypes"
import { connect } from "react-redux"

import { SettingsContactsForm } from "../../forms"
import SidebarWithContent from "../../components/Wrappers/SidebarWithContent"
import { H1 } from "../../components/Typography"
import { Navigate } from "react-router-dom"
import { getSelectedCompany } from "../../redux/modules/companies"

const ContactsContainer = ({ company = null, contactsFormValues = undefined, saveContact }) => {
  if (!company) return <Navigate to={'/settings'} />

  const onContactsSubmit = () => {
    saveContact(company.get("id"), contactsFormValues)
  }

  if (!company) return null

  return (
    <SidebarWithContent
      sidebarContent={(<>
        <H1>
          Contacts
        </H1>
        <p>This is your contact list, enter your contacts for your salon, for internal use only. They are not viewable publicly on your salon profile.<br/><br/>Keep these up to date so we can get in touch with you if we need to.</p>
      </>)}
    >
      <SettingsContactsForm
        onSubmit={onContactsSubmit}
      />
    </SidebarWithContent>
  )
}

ContactsContainer.propTypes = {
  contactsFormValues: ImmutablePropTypes.map,
  company: ImmutablePropTypes.map, // provided by parent
  currentContactId: PropTypes.number,
}

const mapStateToProps = state => {
  const contacts = state.get("contacts")

  return ({
    currentContactId: contacts.get("currentContactId"),
    company: getSelectedCompany(state),
  })
}

export default connect(mapStateToProps)(ContactsContainer)
