import React from "react"
import { connect } from "react-redux"
import styled from "styled-components"
import PropTypes from "prop-types"

import DateRangePicker from "../DateRangePicker/DateRangePicker"
import { device } from "../../components/Responsive/Responsive"
import { TextInput, Button } from "../../components/Form"
import { Field, Formik } from "formik"

const ReportsContainer = styled.div`
    display: block;
`

const RowLabel = styled.label`
    display: inline-block;
    font-face: 'Museo-Sans', 'sans-serif';
    color: #575757;
    font-size: 17px;
`

const DateRangeLabel = styled(RowLabel)`
    font-weight: 300;
    margin-bottom: 30px;

    @media ${device.mobile} {
        margin-left: 14px;
    }

`

const RecipientLabel = styled(RowLabel)`
    font-weight: 300;
    display: block;
    line-height: 40px;

    @media ${device.desktop}, ${device.tablet} {
        float: left;
    }
`


const RecipientField = styled(TextInput)`

    @media ${device.desktop}, ${device.tablet} {
        width: 300px !important;
        float: left;
        margin-left: 8px;
        margin-right: 8px;
    }

    @media ${device.mobile} {
        width: calc(100% - 32px) !important;
    }
`

const ReportsFormParent = styled.form`
    background-color: #fff;
    display: block;
    padding: 0px;

      border: 1px solid #ececec;

    @media ${device.desktop}, ${device.tablet} {
        width: 730px;
    }
    @media ${device.mobile} {
        width: auto;
    }

`
const ReportsFormTop = styled.div`
    border-bottom: 1px solid #ececec;
    @media ${device.mobile} {
        padding: 30px 0px;

    }
    @media ${device.tablet}, ${device.desktop} {
        padding: 30px 36px;
    }
`

const ReportsFormBottom = styled.div`

    @media ${device.mobile} {
        padding: 30px 18px;
    }
    @media ${device.desktop}, ${device.tablet} {
        padding: 30px 36px;
        height: 40px;
    }
`
const ReportsFormButtonParent = styled.div`

    text-align: right;

    @media ${device.mobile} {
        padding: 0px 18px 18px 18px;
    }
    @media ${device.desktop}, ${device.tablet} {
        padding: 0px 36px 36px 36px;
    }
`

const SendReportButton = styled(Button)`
    @media ${device.mobile} {
        width: 100%;
    }
`

const ReportsDescription = styled.p`
    @media ${device.tablet}, ${device.desktop} {
        margin: 0px 0px 20px 0px;
        padding: 0;
    }

    @media ${device.mobile} {
        margin: 10px 0px;
        padding: 0px 20px;
    }
`

const ReportsForm = ({ description, browser, initialValues, onSubmit }) => {
  const numberOfMonths = (browser.lessThan.tablet) ? 1 : 2
  const disabledDays = { after: new Date() }
  const dayPickerClass = (browser.lessThan.tablet) ? "DayPicker-Compact" : "DayPicker-Expanded"

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ handleSubmit, errors, setFieldValue }) => (
        <ReportsContainer>
          {description && <ReportsDescription>{description}</ReportsDescription>}
          <ReportsFormParent onSubmit={handleSubmit}>
            <ReportsFormTop>
              <DateRangeLabel>Select Date Range</DateRangeLabel>
              <DateRangePicker
                name="dateRange"
                onChange={(value) => setFieldValue('dateRange', value)}
                className={dayPickerClass}
                disabledDays={disabledDays}
                initialValues={initialValues.dateRange}
                numberOfMonths={numberOfMonths}
              />
            </ReportsFormTop>
            <ReportsFormBottom>
              <RecipientLabel>Recipient</RecipientLabel>
              <Field
                name="recipient"
                as={RecipientField}
                type="email"
                className="EmailField"
              />
            </ReportsFormBottom>

            <ReportsFormButtonParent>
              <SendReportButton type="submit" disabled={Object.keys(errors) > 0}>Send Report</SendReportButton>
            </ReportsFormButtonParent>
          </ReportsFormParent>

        </ReportsContainer>
      )}
    </Formik>
  )
}

ReportsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    dateRange: PropTypes.shape({
      from: PropTypes.object.isRequired,
      to: PropTypes.object.isRequired,
    }).isRequired,
    recipient: PropTypes.string.isRequired,
  }),
  browser: PropTypes.object.isRequired,
  description: PropTypes.string,
}

const mapStateToProps = state => ({
  browser: state.get("browser"),
})

export default connect(mapStateToProps)(ReportsForm)
