import * as prismicH from '@prismicio/helpers'
import Immutable from "immutable"

import { createSelector } from "reselect"

import { FAQS } from "../../config/constants"
import PrismicContent from "../../../dist/all_prismic_data.json"

// ACTIONS
export const loadAllFaqs = () => ({
  type: "LOAD_ALL_FAQS",
})

export const searchFaqs = query => ({
  type: "SEARCH_FAQS",
  payload: { query },
})

const hasFaqs = PrismicContent && PrismicContent[FAQS] && PrismicContent[FAQS].length > 0
const getFaqsContent = () => PrismicContent && PrismicContent[FAQS]

// REDUCER
export const initialState = Immutable.fromJS({
  faqTopics: null,
  searchResults: null,
  searchQuery: null,
  showFaqs: hasFaqs, // if there is at least 1 faq in prismic for process.env.DOMAIN then this is set to true
})

export default function employeesReducer(state = initialState, action) {
  switch (action.type) {
    case "LOAD_ALL_FAQS":
      return state.merge({ faqTopics: getFaqsContent().filter(c => c.faqs && c.faqs.length > 0) })
    case "SEARCH_FAQS":
      const { query } = action.payload
      // split query by words
      let searchWords = query.split(" ")
      // remove short words (< 3 letters)
      searchWords = searchWords.filter(w => w.length > 3)
      const allFaqs = state.get("faqTopics")
      if (searchWords.length > 0) {
        // we only perform a search if there are words bigger than 3 letters
        let results = []
        allFaqs.forEach((topic) => {
          const faqs = topic.faqs.filter((faq) => {
            if (faq.type !== "faq") {
              return false
            }
            let includesWords = false
            const { question, answer } = faq.data
            const plainTextAnswer = prismicH.asText(answer)
            searchWords.forEach((w) => {
              const lowerCasedWord = w.toLowerCase()
              if (question.toLowerCase().includes(lowerCasedWord) || plainTextAnswer.toLowerCase().includes(lowerCasedWord)) {
                includesWords = true
              }
            })
            return includesWords
          })
          results = results.concat(faqs)
        })

        return state.merge({
          searchQuery: query,
          searchResults: results,
        })
      }
      return state
    default:
      return state
  }
}

// SELECTORS
export const getFaqsData = state => state.get("faqs")

export const getFaqsTopic = createSelector(
  getFaqsData,
  data => data.get("faqTopics"),
)

export const getSearchResults = createSelector(
  getFaqsData,
  data => data.get("searchResults"),
)

export const getShowFaqs = createSelector(
  getFaqsData,
  data => data.get("showFaqs"),
)
