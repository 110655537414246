/* eslint-disable camelcase, no-restricted-syntax */
import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import ImmutablePropTypes from "react-immutable-proptypes"

import {
  getSelectedCompany,
} from "../../redux/modules/companies"
import { Navigate, Outlet, useLocation } from "react-router-dom"
import MasterContainer from "../Master/MasterContainer"
import Settings from "../../components/Settings/Settings"

const SettingsMasterPage = ({ company = null, ...rest }) => {

  const routeLocation = useLocation();

  if (null === company && routeLocation.pathname !== '/settings') {
    return <Navigate to={'/settings'}/>
  } else if (null !== company && routeLocation.pathname === '/settings') {
    return <Navigate to={'/settings/profile'}/>
  }

  return (
    <Settings tabsDisabled={!company}>
      <Outlet />
    </Settings>
  )
}

SettingsMasterPage.propTypes = {
  company: ImmutablePropTypes.map,
}

const mapStateToProps = state => ({
  company: getSelectedCompany(state),
})

export default connect(mapStateToProps)(SettingsMasterPage)