import React, { useState } from "react"
import styled, {withTheme} from "styled-components"
import Select, { components } from "react-select"
import { reactSelectStyleUtils } from "./SelectBox/SelectUtils"
import LoadingIcon from "../Loading/LoadingIcon"
import PropTypes from "prop-types"
import InputError from "./InputError"

const StyledSelect = styled(Select)`
    .Select-control {
      background: white;
      border-radius: 25px !important;
      /* padding: 10px 15px !important; */
      height: 42px;
      font-size: 15px;
      outline: none;
      border: 1px solid ${({ theme }) => theme.borderColor};
      box-sizing: border-box;
    }

    .Select-value-label {
      display: block;
      line-height: 42px;
      margin-left: 8px;
    }

    .Select-placeholder {
      height: 42px;
      line-height: 42px;
      padding: 0px 22px;
    }

    .Select-input {
      height: 42px;
      line-height: 42px;
      padding: 0px 22px;
    }

    .Select-value span {
      overflow: hidden;
      margin-right: 20px;
    }
`

const StyledSelectField = ({ name, theme, isLoading = false, field, children, options, onChange, errors = {}, ...props }) => {
  // Turn the selected options into a value (or array of values)
  const handleChange = (selectedOptions) => {
    if (typeof onChange === "function") {
      const value =
        Array.isArray(selectedOptions) ?
          selectedOptions.map((option) => option.value)
          : selectedOptions.value
      onChange(value)
    }
  }

  const findMatchingOptionAllowingForGroups = (option, value) => {
    let found = undefined;
    // If this is a group parent
    if (Array.isArray(option.options)) {
      found = option.options.find(option => value === option.value)
    } else {
      found = option.value === value ? option : undefined
    }
    return found
  }

  // Convert value(s) passed in, into option(s)
  const valueFromOptions = (value) => {
    let v = undefined;

    if (props.isMulti) {
      v = value
        .map((eachValue) => options.find((option) => findMatchingOptionAllowingForGroups(option, eachValue)))
        .filter((eachOption) => eachOption)
    } else {
      for (let eachOption in options) {
        const found = findMatchingOptionAllowingForGroups(options[eachOption], value);

        if (found) {
          v = found
        }
      }
    }

    return v
  }

  return true === isLoading ?
    <div><LoadingIcon /></div>
    : (
      <>
        <StyledSelect
          styles={{
            ...reactSelectStyleUtils(theme),
            control: base => ({
              ...base,
              fontSize: "15px",
              fontWeight: "700",
              paddingLeft: "5px",
              boxShadow: "none",
              borderRadius: "25px",
              borderColor: `${theme.borderColor} !important`,
              color: `${theme.labelDarkColor} !important`,
              cursor: "pointer",
            }),
          }}
          name={name}
          {...props}
          options={options}
          value={valueFromOptions(props.value)}
          onChange={handleChange}
        />
        { errors[name] && <span><InputError>{errors[name]}</InputError></span> }
      </>
    )
}

StyledSelectField.propTypes = {
  isLoading: PropTypes.bool,
}

export default withTheme(StyledSelectField)
