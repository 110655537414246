import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import ImmutablePropTypes from "react-immutable-proptypes"
import moment from "moment-timezone"
import styled from "styled-components"
import ReportsForm from "../../forms/Reports/ReportsForm"
import { getCompanyIds } from "../../redux/modules/companies"
import { preferencesSendReport } from "../../redux/modules/preferences"
import { getAuthApiUser } from "../../redux/modules/auth"
import FormWrapper from "../../components/Wrappers/FormWrapper"
import MobileNavigation from "../../components/Home/MobileNavigation"

const ReportWrapper = styled.div`
`

const ReportsContainer = ({ apiUser, companyIds, sendReport }) => {
  const onSubmit = (values) => {
    sendReport(companyIds.toJS(), values)
  }

  const getInitialValues = () => {
    /* Range is between the most recent Thursday and the one before that */
    const to = moment() // new Date()
    while (to.format("dddd") !== "Thursday") to.subtract(1, "days")

    const from = moment(to).subtract(1, "weeks")

    return ({
      dateRange: {
        from: from.toDate(),
        to: to.toDate(),
      },
      recipient: apiUser,
    })
  }

  const title = "Booking Report"


  return (
    <React.Fragment>
      <MobileNavigation title={title} />
      <ReportWrapper>
        <FormWrapper title={title}>
          <ReportsForm
            description="Export a report of your bookings. Select the date range you require, and it will be emailed to you."
            initialValues={getInitialValues()}
            onSubmit={(values) => onSubmit(values)}
          />
        </FormWrapper>
      </ReportWrapper>
    </React.Fragment>
  )
}

ReportsContainer.propTypes = {
  companyIds: ImmutablePropTypes.list.isRequired,
  sendReport: PropTypes.func.isRequired,
  apiUser: PropTypes.string.isRequired,
}

const mapDispatchToProps = dispatch => ({
  sendReport: (companyIds, values) => dispatch(preferencesSendReport(companyIds, values)),
})
const mapStateToProps = state => ({
  companyIds: getCompanyIds(state),
  apiUser: getAuthApiUser(state),
})
export default connect(mapStateToProps, mapDispatchToProps)(ReportsContainer)
