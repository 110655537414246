import styled from "styled-components"

export const ServiceEditIcon = styled.div`
  display: none;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
`

export const ServiceName = styled.div`
  font-weight: bold;
  font-size: 18px;
  color: #575757;
`
