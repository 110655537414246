import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import styled from "styled-components"

import {
  BookingIcon,
  CupOfTeaIcon,
} from "../../components/Icons/Icons"
import { device } from "../../components/Responsive/Responsive"
import { getNewBookingsInboxCount, getCompaniesPendingApprovalCount } from "../../redux/modules/inbox"
import { Button } from "../../components/Form"
import { useNavigate } from "react-router-dom"

const CupOfTea = styled(CupOfTeaIcon)`
    fill: #f067a6;
    flex-grow: 0;
    flex-shrink: 0;

    position: absolute;
    top: 50%;
    left: 20px;
    margin-top: -12px;
`
const PinkBookingIcon = styled(BookingIcon)`
    width: 32px;
    height: 32px;
    fill: #f067a6;
    flex-grow: 0;
    flex-shrink: 0;

    position: absolute;
    top: 50%;
    left: 20px;
    margin-top: -16px;

    @media ${device.mobile} {
      top: 16px;
      margin-top: 0;
    }
`

const Inbox = styled.div`

    border: 1px solid #ececec;
    border-bottom: none;

    @media ${device.mobile} {
        margin-left: 0px;
        margin-right: 0px;
    }
`

const InboxRow = styled.div`
    min-width: 600px;
    min-height: 40px;
    padding: 20px 0px;
    align-items: center;
    display: flex;
    flex-direction: row;
    background-color: #ffffff;
    border-bottom: 1px solid #ececec;
    color: #575757;
    font-weight: 300;
    font-size: 1.2em;
    cursor: pointer;
    outline: 0;
    position: relative;

    @media ${device.mobile} {
      display: block;
      min-width: 0px;
    }
`

const BookingEmpty = styled.div`
    margin-left: 20px;
    flex: 0.8;

    padding-left: 52px;
`

const BookingCount = styled.div`
    margin-left: 20px;
    flex: 1;

    padding-left: 52px;

    @media ${device.mobile} {
      margin-bottom: 8px;
      margin-top: 2px;
    }
`

const ViewButton = styled.div`
    text-align: center;
    margin-right: 20px;

    @media ${device.mobile} {
      margin-right: 0px;
      margin-top: 16px;
    }
`


const InboxContainer = ({ browser, newBookingsInboxCount = 0, companiesPendingApprovalCount = 0 }) => {
  const navigate = useNavigate();

  const onChat = () => {
    Intercom("show");
  }

  const onClick = (id) => {
    switch (id) {
      case "bookings": {
        navigate('/bookings');
        break
      }
      case "settings": {
        navigate('/settings/profile');
        break
      }
      default:
        break
    }
  }

  const showInboxRow = (message, onClick) => (
    <InboxRow
      role="button"
      tabIndex={0}
      onClick={onClick}
      onKeyUp={(evt) => {
        if (evt.key === "Enter") {
          onClick()
        }
      }}
    >
      <BookingCount>
        <PinkBookingIcon />
        {message}
      </BookingCount>
      <ViewButton>
        <Button>Click to view</Button>
      </ViewButton>
    </InboxRow>
  )

  // Don't render until there's data to display
  if (newBookingsInboxCount === null || companiesPendingApprovalCount === null) {
    return null
  }

  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      width: "100%",
    }}
    >
      <p
        style={{
          paddingLeft: browser.is.mobile ? 10 : 0,
        }}

        className="MainTitle"
      >
        Inbox
      </p>
      <Inbox>
        {(newBookingsInboxCount === 0 && companiesPendingApprovalCount === 0) && (
          <InboxRow>
            <BookingEmpty>
              <CupOfTea />
              You’re up to date! There’s nothing to action right now.
            </BookingEmpty>
          </InboxRow>
        )}
        {companiesPendingApprovalCount && companiesPendingApprovalCount !== 0
          ? showInboxRow(
            `You have ${companiesPendingApprovalCount === 1 ? "a salon that requires" : "new salons that require"} approval`,
            onClick.bind(null, "settings"),
          ) : null
        }
        {newBookingsInboxCount && newBookingsInboxCount !== 0
          ? showInboxRow(
            `You have ${newBookingsInboxCount} new upcoming booking${newBookingsInboxCount === 1 ? "" : "s"}`,
            onClick.bind(null, "bookings"),
          ) : null
        }
      </Inbox>
    </div>
  )
}

InboxContainer.propTypes = {
  browser: PropTypes.object.isRequired,
  newBookingsInboxCount: PropTypes.number,
  companiesPendingApprovalCount: PropTypes.number,
}

const mapStateToProps = state => ({
  browser: state.get("browser"),
  newBookingsInboxCount: getNewBookingsInboxCount(state),
  companiesPendingApprovalCount: getCompaniesPendingApprovalCount(state),
})

export default connect(mapStateToProps)(InboxContainer)
