import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const Column = styled.div`
  max-width: ${({ theme }) => theme.maxWidthColumn};
  margin: 0 auto;
  position: relative;
`

const WelcomeHeader = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  top: -120px;
`

const SingleColumnPage = ({ children }) => (
  <Column>
    <WelcomeHeader>
      <h2 style={{
        textAlign: "center",
        marginBottom: 6
      }}
      >
        Welcome to the
      </h2>
      <h1 style={{
        textAlign: "center",
        marginTop: 0,
        marginBottom: 60
      }}
      >
        Flossie Salon Admin
      </h1>
    </WelcomeHeader>
    {children}
  </Column>
)

SingleColumnPage.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
}

export default SingleColumnPage
