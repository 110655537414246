import styled from "styled-components"

// this error is for input specific errors, usually at the bottom of the input
const InputError = styled.div`
    padding-top: 2px;
    padding-left: 10px;
    color: ${({ theme }) => theme.errorColor};
`

export default InputError
