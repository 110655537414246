import styled from "styled-components"

import { device } from "../Responsive/Responsive"

export SelectBox from "./SelectBox"
export Button from "./Button"
export TextInput from "./TextInput"
export RadioButton from "./RadioButton"
export CopyTextInput from "./CopyTextInput"
export Checkbox from "./Checkbox"
export Label from "./Label"
export InlineError from "./InlineError" // this error is for general errors, usually at the bottom of a form.
export InnerFormSection from "./InnerFormSection"
export SearchableItemsSelect from "./SearchableItemsSelect"
export StyledSelectField from "./StyledSelectField"
export TextareaAutosizeRedux from "./TextareaAutosizeRedux"

export const FormRow = styled.div`

  text-align: ${({ centered }) => (centered ? "center" : "left")};


  display: flex;
  flex-direction: ${({ $labelTop }) => $labelTop ? "column" : "row" };
  column-gap: 1rem;
  align-items: ${({ $align }) => ($align ? $align : "center")};
  margin-top: 8px;

  label {
    display: block;
    width: ${({ $width }) => $width !== "auto" ? '130px' : 'auto'};
    position: relative;
    color: ${({ theme }) => theme.labelDarkColor};
    margin-bottom: ${({ $labelTop }) => $labelTop ? "10px" : "0" };
  }

  a {
    color: ${({ theme }) => theme.primaryColor};
    font-weight: bold;
    font-size: 16px;
  }
  & > div {
    flex: ${({ fullWidth }) => (fullWidth ? 1 : 0.6)};
  }
  & + & {
    margin-top: 1.5rem;
  }
  @media ${device.large} {
      display: block;
      margin-top: 16px;

      label {
        display: block;
        margin-bottom: 4px;
      }
  }

  /* Tooltip positioning */
  & > label + div > div svg {
      margin-left: 8px;
      margin-right: -34px;

  }

  @media ${device.large} {

      & > label + div > div svg {
          margin-right: 0px;
      }
  }
`
