import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import FaqsContentHoc from "../../hocs/FaqsContentHoc"
import SearchInput from "../../components/Faqs/SearchInput"
import { QuestionLink } from "../../components/Faqs/FaqLink"
import ContainerWithSideBar from "../../components/Faqs/ContainerWithSideBar"
import Header from "../../components/Faqs/Header"
import { useNavigate, useParams } from "react-router-dom"

const Title = styled.h1`
  text-align: center;
  flex: 1;
`

const FaqSearchResults = ({ searchResults = null, faqTopics = [], searchFaqs }) => {

  const params = useParams();
  const navigate = useNavigate();
  const query = params.query?.trim() ?? ''

  React.useEffect(() => {
    if (faqTopics) {
      if (query !== "") {
        searchFaqs(query)
      } else {
        navigate('/help/faqs');
      }
    }
  }, [query, faqTopics])

  return (
    <div>
      <Header><Title>Search Results</Title></Header>
      <SearchInput query={query} />
      <ContainerWithSideBar topics={faqTopics}>
        <div>
          {searchResults && <span>{`${searchResults.length} Questions`}</span>}
          {searchResults && searchResults.map(faq => <QuestionLink key={faq.slug} topic={faqTopics.find(t => t.faqs.find(f => f.id === faq.id))} faq={faq} size="h2" />)}
        </div>
      </ContainerWithSideBar>
    </div>
  )
}

FaqSearchResults.propTypes = {
  faqTopics: PropTypes.array,
  searchResults: PropTypes.array,
  searchFaqs: PropTypes.func.isRequired,
}

export default FaqsContentHoc(FaqSearchResults)
