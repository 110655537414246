import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { connect } from "react-redux"

import { device } from "../../components/Responsive/Responsive"
import { getContactLinks } from "../../redux/modules/contacts"

const HelpContactList = styled.ul`
    margin: 0;
    padding: 0;
`

const HelpContactItem = styled.li`
    list-style-type: none;
    display: block;
    font-size: 15px;
    color: #ef609f;
    margin-bottom: 4px;
`

const HelpContactChat = styled(HelpContactItem)`
    cursor: pointer;
`

const HelpContactLink = styled.a`
    text-decoration: none;
    color: #ef609f;
`

const HelpTitle = styled.div`
    font-family: 'Museo-Sans','sans-serif';
    font-weight: 500;
    font-size: 17px;
    color #575757;
    margin-bottom: 47px;

    @media ${device.mobile} {
        margin-bottom: 18px;
    }
`


const HelpContactPage = ({ contactLinks = [] }) => {
  const onChat = () => {
    Intercom("show")
  }

  return (
    <React.Fragment>
      <HelpTitle>Contact us</HelpTitle>
      <HelpContactList>
        <HelpContactChat onClick={onChat}>&raquo; Chat with us</HelpContactChat>
        {contactLinks.map((link) => {
          const label = link.link_label
          const { url, target } = link.link_url
          return (
            <HelpContactItem key={label}>
              <HelpContactLink href={url} target={target}>
                &raquo;
                {" "}
                {link.link_label}
              </HelpContactLink>
            </HelpContactItem>
          )
        })}
      </HelpContactList>
    </React.Fragment>
  )
}


HelpContactPage.propTypes = {
  contactLinks: PropTypes.array,
}

const mapStateToProps = state => ({
  contactLinks: getContactLinks(state),
})

export default connect(mapStateToProps)(HelpContactPage)
