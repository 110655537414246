import { Map } from "immutable"
import moment from "moment-timezone"
import { createSelector } from "reselect"

export const PREFERENCES_SAVE_PREFERENCE = "PREFERENCES_SAVE_PREFERENCE"
export const PREFERENCES_SEND_REPORT = "PREFERENCES_SEND_REPORT" // TODO move to own module
const PREFERENCES_REPLACE_PREFERENCE = "PREFERENCES_REPLACE_PREFERENCE"
const PREFERENCES_SAVE_PREFERENCE_START = "PREFERENCES_SAVE_PREFERENCE_START"
const PREFERENCES_SAVE_PREFERENCE_SUCCESS = "PREFERENCES_SAVE_PREFERENCE_SUCCESS"
const PREFERENCES_SAVE_PREFERENCE_FAIL = "PREFERENCES_SAVE_PREFERENCE_FAIL"
const COOKIE_OK = "COOKIE_OK"
const COOKIE_DECLINE = "COOKIE_DECLINE"

export const preferencesSendReport = (companyIds, { recipient, dateRange: { from, to }}) => ({
  type: PREFERENCES_SEND_REPORT,
  company_id: companyIds.join(","),
  date_from: moment(from).format("YYYY-MM-DD"),
  date_to: moment(to).format("YYYY-MM-DD"),
  email: recipient,
});

export const preferencesReplacePreference = (key, value) => ({
  type: PREFERENCES_REPLACE_PREFERENCE,
  key,
  value,
})

export const preferencesSavePreference = (key, value) => ({
  type: PREFERENCES_SAVE_PREFERENCE,
  [key]: value,
})

export function preferencesSavePreferenceStart() {
  return {
    type: PREFERENCES_SAVE_PREFERENCE_START,
  }
}

export function preferencesSavePreferenceSuccess() {
  return {
    type: PREFERENCES_SAVE_PREFERENCE_SUCCESS,
  }
}

export function preferencesSavePreferenceFail(error) {
  return {
    type: PREFERENCES_SAVE_PREFERENCE_FAIL,
    error,
  }
}

export const cookieOk = () => ({ type: COOKIE_OK })
export const cookieDecline = () => ({ type: COOKIE_DECLINE })


const initialPreferencesState = Map({
  enquiryNotification: "0",
  isSaving: false,
  error: null,
  cookieOk: false,
  cookieDecline: false,
})


export default function preferences(state = initialPreferencesState, action) {
  switch (action.type) {
    case PREFERENCES_REPLACE_PREFERENCE:
      return state.merge({
        [action.key]: action.value,
      })
    case PREFERENCES_SAVE_PREFERENCE: {
      const key = action.key === "enquiry_notification" ? "enquiryNotification" : undefined
      return key ? state.merge({
        [key]: action.value,
      }) : state
    }
    case PREFERENCES_SAVE_PREFERENCE_START:
      return state.merge({
        isSaving: true,
      })
    case PREFERENCES_SAVE_PREFERENCE_SUCCESS:
      return state.merge({
        isSaving: false,
      })
    case PREFERENCES_SAVE_PREFERENCE_FAIL:
      return state.merge({
        isSaving: false,
        error: action.error,
      })
    case "COOKIE_OK":
      return state
        .set("cookieOk", true)
        .set("cookieDecline", false)
    case "COOKIE_DECLINE":
      return state
        .set("cookieDecline", true)
        .set("cookieOk", false)
    default:
      return state
  }
}

const getPreferences = state => state.get("preferences")

export const areCookiesAllowed = createSelector(
  getPreferences,
  preferences => preferences.get("cookieOk")
)
export const areCookiesDeclined = createSelector(
  getPreferences,
  preferences => preferences.get("cookieDecline")
)
