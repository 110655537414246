import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { device } from "../Responsive/Responsive"

/*
* This is the main container of all pages. Here we define only the padding we want.
*/
const Padding = styled.div`
    padding: 3rem 4rem 3rem 4rem;
    @media ${device.mobile} {
        padding: 2rem 0 0 0;
    }
`

const PaddingContent = ({ children }) => (
  <Padding>
    {children}
  </Padding>
)

PaddingContent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
}

export default PaddingContent
