import React, { createContext } from "react"
import { AnalyticsBrowser } from "@segment/analytics-next"

import { FRANCHISE_LIST } from '../config/constants'
// Send all franchise IDs to segment, only segment requirement is that it be a string that
// groups like events together
const FRANCHISE_ID_LIST = FRANCHISE_LIST.map(franchise => franchise.id).join(",")

export const segmentAnalytics = AnalyticsBrowser.load({ writeKey: SEGMENT_WRITE_KEY })

export const SegmentContext = createContext(segmentAnalytics)

const defaultTrackProperties = { groupId: FRANCHISE_ID_LIST }

const basicTrackEvent = (eventName, properties = {}) => segmentAnalytics.track(eventName, { ...defaultTrackProperties, ...properties })
export const trackLogin = () => basicTrackEvent('User Login')
export const trackLogout = () => basicTrackEvent('User Logout')
export const trackSetSelectedBookingId = (bookingId) => segmentAnalytics.track('Booking View', { bookingId })
export const identify = (userId) => segmentAnalytics.identify(userId, { groupId: FRANCHISE_ID_LIST })
export const group = () => segmentAnalytics.group(FRANCHISE_ID_LIST)

export default ({ children }) => <SegmentContext.Provider value={segmentAnalytics}>{children}</SegmentContext.Provider>