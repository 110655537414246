import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const ThemedButton = styled.button`
  background-color: ${({ theme, outlined, backgroundColor }) => {
    if (backgroundColor) return backgroundColor
    if (outlined) return theme.primaryContrastColor
    return theme.primaryColor
  }};
  color: ${({ theme, outlined }) => (outlined ? theme.primaryColor : theme.primaryContrastColor)};
  border: ${({ theme, outlined }) => (outlined ? `solid 2px ${theme.primaryColor}` : "none")};
  border-radius: ${({ theme }) => (theme.buttonBorderRadius || "25px")};
  padding: ${({ size }) => (size === "md" ? "14px 45px" : "10px 15px")};
  min-width: ${({ size }) => (size === "md" ? "200px" : "100px")};
  font-size: ${({ size }) => (size === "md" ? "16px" : "14px")};
  cursor: pointer;
  text-transform: ${({ uppercase }) => (uppercase ? "uppercase" : "none")};
  white-space: nowrap;
  &:disabled {
    background: ${({ theme }) => theme.disabledColor};
    cursor: not-allowed;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  ${({ theme }) => theme.buttonStyles};
`

const FlatButton = styled(ThemedButton)`
  border: none;
  background: transparent;
  text-decoration: underline;
  padding: 10px;
  color: ${({ theme }) => theme.primaryColor};
  &:disabled {
    color: ${({ theme }) => theme.disabledColor};
    background: transparent;
  }
`

/*
* Default Button: Background filled
* Outlined Button: (outlined=true) Transparent background borders filled
* Flat Button: (flat=true) Transparent background, no borders. (looks like a link)
*/
const Button = ({ children = undefined, flat = false, outlined = false, rounded = true, size = 'md', ...rest }) => (
  flat
    ? <FlatButton flat={flat} outlined={outlined} rounded={rounded} size={size} {...rest}>{children}</FlatButton>
    : <ThemedButton flat={flat} outlined={outlined} rounded={rounded} size={size} {...rest}>{children}</ThemedButton>
)

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  outlined: PropTypes.bool,
  flat: PropTypes.bool,
  rounded: PropTypes.bool,
  size: PropTypes.oneOf(["sm", "md"]),
}

export default Button
