import React from "react"
import PropTypes from "prop-types"
import { useLocation } from "react-router-dom"
import styled from "styled-components"

import { device } from "../Responsive/Responsive"

const ScrollContainer = styled.div`
  height: 100%;
  overflow-y: auto;

  @media ${device.mobile} {
    height: 100%;
  }
`
/**
 * This is the wrapper for the main content section.
 * Its a scrollable section which scrolls to top on every location change.
 */
const ScrollTop = (props) => {
  const { children } = props
  const ID = "scroll-container"
  const location = useLocation();

  React.useEffect(() => {
    // scrolls to top on every location change
    document.getElementById(ID).scrollTo(0, 0)
  }, [location])

  return <ScrollContainer id={ID}>{children}</ScrollContainer>
}

ScrollTop.propTypes = {
  children: PropTypes.array.isRequired,
}

export default ScrollTop
