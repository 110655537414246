import React from "react"
import PropTypes from "prop-types"
import ImmutablePropTypes from "react-immutable-proptypes"
import Immutable from "immutable"

import EditEmployeePricing from "./EditEmployeePricing"
import EmployeesList from "../../components/Employees/EmployeesList"
import {
  FormRow,
} from "../../components/Form"
import { H2, H3 } from "../../components/Typography"
import { getFormattedPrice, isServicesSaving, servicesSaveService } from "../../redux/modules/services"
import { connect } from "react-redux"
import { openConfirm } from "../../redux/modules/notifications"
import { Navigate, useParams } from "react-router-dom"
import rg4js from "raygun4js"

const validate = (values) => {
  const errors = {}
  const employees = values.get("employees")
  const newDefaultPrice = values.get("defaultPrice")
  if (employees && employees.count() > 0 && newDefaultPrice) {
    // check if new default price is higher than all employees price
    let validDefaultPrice = true
    employees.forEach((e) => {
      if (e.get("price") && parseFloat(e.get("price"), 10) > parseFloat(newDefaultPrice, 10)) {
        validDefaultPrice = false
      }
    })
    if (!validDefaultPrice) {
      errors.defaultPrice = "Default price must be highest price."
    }
  }
  return errors
}

const EmployeePricing = ({ defaultPricePlaceholder = 0, dispatchServicesSaveService, isSaving, onAddEmployee, selectedCompany, service, showConfirm, loading }) => {
  // const [selectedEmployee, setSelectedEmployee] = React.useState(null)

  const employees = service.get('employees').map((employee) => {
    if (!employee.get("price")) {
      employee = employee
        .set('price', service.get('defaultPrice'))
    }
    return employee
  }).toList()

  const params = useParams();

  const selectedEmployee = employees.find(employee => employee.get('slug') === params.employeeSlug)
  if (params.employeeSlug && !selectedEmployee) {
    rg4js('send', new Error(`Unable to find employee ${params.employeeSlug}`));
    return <Navigate to={'/settings/employees'}/>
  }

  const onSubmit = async ({ employeeId, price }) => {
    // Find the existing employee
    let employee = service.getIn(['employees', employeeId])

    if (employee) {
      service = service.setIn(
        ['employees', employeeId],
        employee
          .set('price', price)
          .set('formattedPrice', getFormattedPrice(price, null, selectedCompany))
      )

      if (parseFloat(price) > parseFloat(service.get('defaultPrice'))) {
        service = service.set('defaultPrice', parseFloat(price))

        showConfirm(
          null,
          "Default Price will be updated to match the new highest price. Do you want to continue?",
          servicesSaveService(service)
        )
      } else {
        dispatchServicesSaveService(service)
      }
    }
  }

  const onEditEmployeeSubmit = (newFormValues) => {
    // price of employee selected
    const employeePrice = newFormValues.employeePriceSelected

    const editEmployeePriceInForm = () => {
      // get id of employee selected
      const newEmployeeId = newFormValues.employeeIdSelected
      if (newFormValues.employees.keys.includes(newEmployeeId)) {
        let employee = newFormValues.employees[newEmployeeId]
        if (employeePrice) {
          employee = employee
            .set("price", employeePrice)
            .set("formattedPrice", getFormattedPrice(employeePrice, null, selectedCompany))
        } else {
          employee = employee.set("formattedPrice", getFormattedPrice(newFormValues.defaultPrice, null, selectedCompany, true))
        }
        newFormValues = newFormValues.setIn(["employees", newEmployeeId], employee)
      }
    }

    if (parseFloat(employeePrice) > parseFloat(newFormValues.defaultPrice)) {
      showConfirm(
        null,
        "Default Price will be updated to match the new highest price. Do you want to continue?",
        () => {
          newFormValues.defaultPrice = parseFloat(employeePrice)
          editEmployeePriceInForm()
          onSubmit(newFormValues)
        },
      )
    } else {
      editEmployeePriceInForm()
      onSubmit(newFormValues)
    }
  }

  const onRemoveEmployee = (employeeId) => {
    showConfirm(
      null,
      "Are you sure you want to remove this stylist from the service?",
      servicesSaveService(service.set('employees', service.get('employees').delete(employeeId)))
    )
  }

  const sortList = (a, b) => {
    // We order by desc name and by price asc
    if (a.get("name") > b.get("name")) {
      return 1
    }
    if (a.get("name") < b.get("name")) {
      return -1
    }
    const aPrice = a.get("price") ? parseFloat(a.get("price"), 10) : defaultPricePlaceholder
    const bPrice = b.get("price") ? parseFloat(b.get("price"), 10) : defaultPricePlaceholder
    if (aPrice > bPrice) {
      return -1
    }
    if (aPrice < bPrice) {
      return 1
    }
    return 0
  }

  return (
    <>
      {selectedEmployee ? (
          <div>
            <FormRow><H2>Edit Stylist Price</H2></FormRow>

            <EditEmployeePricing
              employees={employees}
              selectedEmployee={selectedEmployee}
              companyId={selectedCompany.get("id")}
              onAddEmployee={onAddEmployee}
              onSubmit={onSubmit}
              defaultPricePlaceholder={defaultPricePlaceholder}
              currencySymbol={selectedCompany ? selectedCompany.getIn(["defaultCurrency", "symbol"]) : null}
              currencyCode={selectedCompany ? selectedCompany.getIn(["defaultCurrency", "code"]) : null}
              submitted={isSaving}
              disabled={loading}
            />
          </div>
        ) : (
          <div>
            <H2>Set Stylist Pricing</H2>
            <p>The pricing is taken directly from your online booking system. Click to edit the stylist pricing below if
              required</p>
            <EmployeesList
              company={selectedCompany}
              employees={employees}
              sort={sortList}
              options={{
                showRole: true,
                showServicesCount: false,
                allowLink: false,
              }}
              handleRemove={onRemoveEmployee}
              // onEditClick={(employee) => setSelectedEmployee(employee)}
            />
          </div>
        )
      }
    </>
  )
}

EmployeePricing.propTypes = {
  defaultPricePlaceholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dispatchServicesSaveService: PropTypes.func.isRequired, // action to save/update service
  employees: ImmutablePropTypes.map,
  isSaving: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  onAddEmployee: PropTypes.func,
  selectedCompany: ImmutablePropTypes.map.isRequired,
  service: ImmutablePropTypes.map.isRequired,
  showConfirm: PropTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => ({
  showConfirm: (title, message, action) => dispatch(openConfirm(title, message, action)),
  dispatchServicesSaveService: service => dispatch(servicesSaveService(service)),
});

const mapStateToProps = state => ({
  isSaving: isServicesSaving(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeePricing)
