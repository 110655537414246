import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import moment from "moment-timezone"

import styled from "styled-components"

const Meridiem = styled.div`
    margin-left: 30px;
`
const TimeField = styled.input`
    border: none;
    border-bottom: 1px solid #979797;
    outline: none;
    width: 44px;
    text-align: right;
    height: 36px;
    padding: 0px 4px;
    font-family: 'Museo-Sans', 'sans-serif';
    font-weight: 500;
    font-size: 16px;
    color: #575757;
    line-height: 36px;
`

const MeridiemButton = styled.button`
    background-color: #ffffff;
    border: none;
    border-top: 1px solid #c6c6c6;
    border-bottom: 1px solid #c6c6c6;
    cursor: pointer;
    width: 55px;
    height: 36px;
    font-family: 'Museo-Sans', 'sans-serif';
    font-weight: 500;
    font-size: 16px;
    line-height: 33px;
    display: block;
    float: left;
    color: #f1f1f1;

    ${({ value, meridiem }) => {
      let style = '';
      if (value === 'AM') {
        style += `
          border-left: 1px solid #c6c6c6;
          border-radius: 6px 0px 0px 6px;
        `
      }

      if (value === 'PM') {
        style += `
          border-right: 1px solid #c6c6c6;
          border-radius: 0px 6px 6px 0px;
        `
      }

      if (value === meridiem) {
        style += `
          box-shadow: 1px 1px 2px #c7c7c7 inset, -1px -1px 2px #c7c7c7 inset;
          background-color: #eee;
          color: #ef609f;
        `;
      }

      return style
    }}
`

export const isValidTimepickerTime = (testValue) => /^[0-9]{1,2}:[0-9]{2} (AM|PM)$/.test(testValue);

const TimePicker = ({ field: { value }, onChange}) => {
  const [time, setTime] = useState('')
  const [meridiem, setMeridiem] = useState(null)
  const [onTimeChangeWithDebounceId, setOnTimeChangeWithDebounceId] = useState(null)


  useEffect(() => {
    if (isValidTimepickerTime(value)) {
      const date = moment(value, "hh:mm A")
      if (date.isValid()) {
        setTime(date.format('hh:mm'))
        setMeridiem(date.format('A'))
      }
    }
  }, [value])

  useEffect(() => {
      onChange(`${time} ${meridiem}`)
  }, [time, meridiem])

  const handleTimeChangeWithDebounce = (event) => {
    if (typeof onTimeChangeWithDebounceId === 'number') {
      clearTimeout(onTimeChangeWithDebounceId)
    }

    const newTimeValue = event.target.value
    //If it's valid, update immediately, otherwise give the user a chance to right their wrongs before annoying them with an error
    if (isValidTimepickerTime(`${newTimeValue} ${meridiem}`)) {
      setTime(newTimeValue)
    } else {
      setOnTimeChangeWithDebounceId(setTimeout(() => {
        setTime(newTimeValue)
      }, 1500))
    }
  }

  return (
    <div
      className="TimePicker"
      style={{
        display: "flex",
        flexFlow: "row",
      }}
    >
      <TimeField type="text" defaultValue={time} onChange={handleTimeChangeWithDebounce} />
      <Meridiem>
        <MeridiemButton onClick={() => setMeridiem('AM')} value="AM" meridiem={meridiem}>AM</MeridiemButton>
        <MeridiemButton onClick={() => setMeridiem('PM')} value="PM" meridiem={meridiem}>PM</MeridiemButton>
      </Meridiem>
    </div>
  )
}

TimePicker.propTypes = {
  field: PropTypes.shape({
    value: PropTypes.string.isRequired,
  }),
  onChange: PropTypes.func.isRequired,
}

export default TimePicker
