import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { GoogleReCaptcha } from "react-google-recaptcha-v3"
import { Field, useFormikContext } from "formik"
import styled from "styled-components"
import { FRANCHISE_LIST,  GOOGLE_RECAPTCHA_SITE_ID } from "../../config/constants"

import {
  Button,
  TextInput,
  Checkbox,
  FormRow
} from "../../components/Form"
import { device } from "../../components/Responsive/Responsive"
import {
  IntroCol, IntroRow, SecondarySection, Spacer, MaxWidthWrapper, TopHeader, IntroHeadline,
} from "./StyledComponents"
import { Formik } from "formik"
import { apiRegisterFranchise } from "../../api/api"
import { min2Max100, validEmail } from "../../forms/validators"
import StyledSelectField from "../../components/Form/StyledSelectField"
import { ThemedTextInput } from "../../components/Form/TextInput"
import Immutable from "immutable"
import { IntroWrapper } from "./StyledComponents"
import { useSearchParams } from "react-router-dom"

const FormCol = styled(IntroCol)`
    padding: ${({ padding }) => padding || "inherit"};
    button {
        min-width: 200px;
    }
    a {
        text-decoration: none;
    }
    @media ${device.small} {
        flex: 0 100%;
    }
`

const Logo = styled.img`
    max-width: 300px;
    margin: ${props => props.theme.parentPadding} auto 0;
    display: block;
`

const CheckboxCol = styled(FormCol)`
    display: flex;
    align-items: center;
    label {
        text-align: left;
        width: 100% !important;
        max-width: 100%;
    }
`

const TermsAndConditions = styled(CheckboxCol)`
    label {
        margin-left: 30px;
    }

    label + div {
        position: absolute;
    }
`

const MessageWrapper = styled.div`
    background-color: ${props => props.theme.primaryColor};
    padding: ${props => props.theme.parentPadding}
    ${props => props.theme.parentPaddingX2};

    * {
        color: white;
    }

    h4 {
        margin-top: 0px;
        margin-bottom: 10px;
    }
`

const forwardAsProp = Component => ({ as, ...props }) => (
  <Component
    forwardedAs={as}
    {...props}
  />
);

const SignUpFormField = forwardAsProp(styled(Field)`
    margin-top: 16px;
    width: 100%;
`)

const SuccessMessageWrapper = styled(MessageWrapper)`
    background: transparent;
    border: 3px solid ${props => props.theme.primaryColor};
    color: ${props => props.theme.headerLinkColor};
    p > a {
        color: ${props => props.theme.headerLinkColor};
    }
`

const ErrorMessage = ({ message }) => (
  <MessageWrapper>
    <h4>Error</h4>
    <p>{message}</p>
  </MessageWrapper>
)

const SuccessMessage = () => (
  <IntroCol>
    <FormRow centered style={{ justifyContent: "center" }}>
      <SuccessMessageWrapper>
        <p>Thank you for participating in this exclusive opportunity!</p>
        <p>You will receive an email shortly outlining the next steps towards getting your salon profile live on the Booking Site.</p>
        <p><a href={`https://${process.env.CONSUMER_SITE}`}>Explore the live site</a></p>
      </SuccessMessageWrapper>
    </FormRow>
  </IntroCol>
);

ErrorMessage.propTypes = {
  message: PropTypes.string,
}

const ResponsiveDetailsFieldsContainer = styled.div`
    display: grid;

    grid-template-columns: calc(50% - 8px) calc(50% - 8px);
    column-gap: 16px;

    @media ${device.mobile} {
        grid-template-columns: 100%;
    }
`

const CredentialsInstructionsHelpLink = styled.div`
    & a {
        text-decoration: none;
        color: var(--flossie-pink);
        font-weight: bold;
    }
    padding-top: 4px;
    padding-left: 17px;
`

const SignUpForm = ({
                      accountManagerPlaceholder = null, buttonBackgroundColour, headline,
                      privacyNoticeUrl = null, termsUrl = null, title
                    }) => {

  const [searchParams] = useSearchParams()

  // To test, can be removed in the future
  const forceSuccessMessage = searchParams.has('forceSuccess');
  const forceErrorMessage = searchParams.has('forceError');

  const [success, setSuccess] = useState(forceSuccessMessage)
  const [googleRecaptchaToken, setGoogleRecaptchaToken] = useState(null)
  const [errorMessage, setErrorMessage] = useState(forceErrorMessage ? 'Forced error' : null)
  const [isApiKeyRemotelyValidated, setIsApiKeyRemotelyValidated] = useState(false)

  const ApiDetailsInput = () => {

    const { values, errors, validateField } = useFormikContext();

    const [isCheckingApi, setIsCheckingApi ] = useState(false)
    const [apiCheckFails, setApiCheckFails] = useState(Immutable.List())

    useEffect(() => {
      validateField('apiKey')
    }, [values.apiKey])

    const checkApiKey = async () => {
      setIsCheckingApi(true)

      let result = false;
      try {
        await fetch(`https://${process.env.ZENOTI_API_HOSTNAME}/v1/centers?expand=working_hours`, {
          headers: {
            'Authorization': `apikey ${values.apiKey}`,
            'Content-Type': 'application/json'
          }
        }).then(response => {
          if (response.status !== 200) {
            throw Error('API Call failed')
          } else {
            setIsApiKeyRemotelyValidated(true)
          }
        })
      } catch (Error) {
        !keyHasPreviouslyFailedApiCheck() && setApiCheckFails(apiCheckFails.push(values.apiKey))
        await validateField('apiKey')
      }

      setIsCheckingApi(false)

      return result;
    }

    const keyHasPreviouslyFailedApiCheck = () => apiCheckFails.contains(values.apiKey)

    const canCheckRemote = () =>
      !isCheckingApi
      && values.apiKey.length > 0
      && !Object.keys(errors).includes('apiKey')
      && !keyHasPreviouslyFailedApiCheck()
      && !isApiKeyRemotelyValidated

    if (values.apiDetailsSelect !== 'Yes') {
      return;
    }

    return (
      <div>
        <div style={{ display: "flex", marginTop: '16px' }}>
          <Field
            as={ThemedTextInput}
            name="apiKey"
            disabled={isCheckingApi || isApiKeyRemotelyValidated}
            placeholder="Please enter your API key"
            type="text"
            validate={(value) => {
              if (apiCheckFails.contains(value) || (value !== '' && (!value || value.length <= 30))) {
                return 'Invalid API key'
              }
            }}
            errors={errors}
            style={{ flexGrow: 1, marginRight: '16px' }}
          />
          <Button onClick={checkApiKey} style={{ backgroundColor: isApiKeyRemotelyValidated ? "green" : null }} disabled={!canCheckRemote()} type="button">
            {
              isApiKeyRemotelyValidated ?
                'OK!'
                : Object.keys(errors).includes('apiKey') ?
                  'Invalid API key'
                  : 'Check'
            }
          </Button>
        </div>
        { keyHasPreviouslyFailedApiCheck() && (
          <div>
            There were issues connecting using this API key. If you believe it to be valid please contact support for assistance.
          </div>
        ) }
      </div>
    );
  };

  const credentialsInstructions = [
    {
      provider: 'zenoti',
      linkUrl: 'https://intercom.help/poweredbyflossie/en/articles/5125982-zenoti-api-generate-a-new-api-key',
      linkText: 'Click here to view instructions on how to obtain your Zenoti API credentials',
    }
  ];

  // Valid options for salon calendar system types
  const softwareTypes = {
    salonbiz: {
      name: 'SalonBiz'
    },
    zenoti: {},
    phorest: {},
    saloniq: {
      name: 'Salon IQ',
    },
    shortcutsonline: {
      name: 'Shortcuts / My Local Salon',
    },
    fresha: {},
    kitomba: {},
    premier3g: {
      name: 'Premier Software',
    },
    timely: {},
    boulevard: {},
    square: {},
    meevo: {},
    aura: {},
    other: {
      name: 'Other Booking System (please add to comments field below)'
    },
  }

  const ucFirst = (string) => string.charAt(0).toUpperCase() + string.slice(1);

  const ApiDetailsSelector = () => {
    const { values, errors, setFieldValue, initialValues } = useFormikContext();

    if (values.software !== 'zenoti') {
      return null;
    }

    const softwareProviderName = softwareTypes[values.software]?.name ?? ucFirst(values.software);

    const credentialsInstruction = credentialsInstructions.find((instruction) => instruction.provider === values.software)

    const apiDetailsSelectOptions = {
      'Yes': `Yes I am ready to enter my ${softwareProviderName} API key`,
      'No': 'No, I want to create my API key later'
    };

    return (
      <>
        <SignUpFormField
          name="apiDetailsSelect"
          as={StyledSelectField}
          errors={errors}
          isDisabled={isApiKeyRemotelyValidated}
          placeholder={`To connect ${softwareProviderName} to Flossie, you'll need to create and enter your ${softwareProviderName} API key. Are you ready to do that now?`}
          options={Object.entries(apiDetailsSelectOptions).map(([value, label]) => ({ label, value }))}
          onChange={(value) => {
            setFieldValue('apiDetailsSelect', value)
            setFieldValue('salonName', initialValues.salonName)
            setFieldValue('apiKey', initialValues.apiKey)
          }}
        />
        { credentialsInstruction !== null && <CredentialsInstructionsHelpLink><a href={credentialsInstruction.linkUrl} target="_blank">{credentialsInstruction.linkText}</a></CredentialsInstructionsHelpLink> }
      </>
    )
  };

  const WrapGoogleCaptcha = ({ children }) => {
    const handleCaptchaVerify = React.useCallback(async token => {
      if (!googleRecaptchaToken) {
        setGoogleRecaptchaToken(token)
      }
    }, [])

    return GOOGLE_RECAPTCHA_SITE_ID ?
      <><GoogleReCaptcha onVerify={handleCaptchaVerify} />{children}</> : children
  };

  const Form = () => (
    <WrapGoogleCaptcha>
      <IntroCol>
        <IntroWrapper centered>
          <TopHeader><h4>{title}</h4></TopHeader>
          <IntroHeadline><h1>{headline}</h1></IntroHeadline>
          <p>Simply fill out this form to get started.</p>
        </IntroWrapper>
        <Spacer />
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{
            accountManager: '',
            apiDetailsSelect: '',
            apiKey: '',
            avedaSignupReviewAgreement: true,
            comment: '',
            email: '',
            firstName: '',
            franchise_id: FRANCHISE_LIST.length === 1 ? FRANCHISE_LIST[0].id : "",
            lastName: '',
            phone: '',
            salonName: '',
            software: '',
            terms: false,
            website: '',
          }}
          onSubmit={(values) => {
            const registerParams = {
              aveda_signup_review_agreement: values.avedaSignupReviewAgreement,
              franchise_id: parseInt(values.franchiseId, 10),
              account_manager: values.accountManager,
              company: values.salonName,
              software: values.software,
              email: values.email,
              first_name: values.firstName,
              last_name: values.lastName,
              phone_number: values.phone,
              website_url: values.website,
              comment: values.comment,
              google_recaptcha_token: googleRecaptchaToken
            }

            if (values.apiKey) {
              registerParams['api_details'] = {
                key: values.apiKey
              }
            }

            apiRegisterFranchise(registerParams).then(res => {
              if (res.status === 200) {
                setSuccess(true)
              } else {
                setErrorMessage(res.data.message)
              }
            }).catch(() => {
              setErrorMessage("Sorry, signup failed please try again later.")
            })
          }}
          validate={(values) => {
            const errors = {};
            if (!values.franchiseId) {
              errors.franchiseId = "Please select your location.";
            }

            if (!values.software) {
              errors.software = "Please select a valid software.";
            }

            if (values.apiKey) {
              if (!isApiKeyRemotelyValidated) {
                errors.apiKey = 'Please check your API key'
              }
            } else {
              if (!values.salonName) {
                errors.salonName = "Please enter a salon name.";
              } else if (!min2Max100(values.salonName)) {
                errors.salonName = "Salon name should be 2-100 characters.";
              }
            }

            if (!values.firstName) {
              errors.firstName = "Please enter a First Name.";
            } else if (!min2Max100(values.firstName)) {
              errors.firstName = "First name should be 2-100 characters.";
            }

            if (!values.lastName) {
              errors.lastName = "Please enter a Last Name.";
            } else if (!min2Max100(values.lastName)) {
              errors.lastName = "Last name should be 2-100 characters.";
            }

            if (!values.email) {
              errors.email = "Please enter an email.";
            } else if (!validEmail(values.email)) {
              errors.email = "Email address is invalid";
            }

            if (!values.phone) {
              errors.phone = "Please enter a phone number.";
            } else {
              const validPhoneNumber = new RegExp('^\\+?[0-9\\-()\\s]\*$');
              if (!validPhoneNumber.test(values.phone)) {
                errors.phone = "Please enter a phone number consisting of hyphens and numbers";
              }
            }

            if (!values.website) {
              errors.website = "Please enter a Website.";
            }

            if (!values.accountManager) {
              errors.accountManager = 'Required';
            }

            if (!values.terms) {
              errors.terms = "Please agree to the terms and conditions & privacy policy to sign up."
            }

            return errors;
          }}
        >
          {({ errors, handleSubmit, setFieldValue, isSubmitting, values }) => {
            const textFieldProps = {
              as: TextInput,
              fullWidth: true,
              type: "text",
              errors,
            }

            return (
              <div id="SupForm">
                <FormRow style={{ flexDirection: 'column' }} fullWidth>
                  { FRANCHISE_LIST.length > 1
                    && (
                      <SignUpFormField
                        name="franchiseId"
                        as={StyledSelectField}
                        errors={errors}
                        placeholder="Please select your location"
                        options={ FRANCHISE_LIST.map(f => ({ label: f.name, value: f.id })) }
                        onChange={(value) => setFieldValue('franchiseId', value)}
                      />
                    )}

                  <SignUpFormField
                    name="software"
                    as={StyledSelectField}
                    errors={errors}
                    isDisabled={isApiKeyRemotelyValidated}
                    placeholder="Select your booking management software"
                    options={ Object.entries(softwareTypes).map(([ slug, options ]) => ({
                      value: slug,
                      label: options.name ? options.name : ucFirst(slug)
                    })) }
                    onChange={(value) => {
                      setFieldValue('software', value)
                      if (value !== 'zenoti') {
                        setFieldValue('apiDetailsSelect', '')
                      }
                    }}
                  />

                  { values.software && (
                    <>
                      <ApiDetailsSelector />
                      <ApiDetailsInput />

                      { (
                          (values.apiDetailsSelect === 'No' || values.software !== 'zenoti')
                          || values?.salonName.length > 0
                        ) &&
                        <>

                          {
                            // Only show salon name input if theyre not selecting from api options
                            values.apiKey.length <= 0 &&
                            <SignUpFormField
                              {...textFieldProps}
                              name="salonName"
                              placeholder="Your Salon Name"
                            />
                          }
                        </>
                      }
                    </>
                  )}
                  <SignUpFormField
                    {...textFieldProps}
                    name="firstName"
                    placeholder="Your First Name"
                  />
                  <SignUpFormField
                    {...textFieldProps}
                    name="lastName"
                    placeholder="Your Last Name"
                  />
                  <SignUpFormField
                    {...textFieldProps}
                    name="email"
                    placeholder="Your Contact Email"
                  />
                  <SignUpFormField
                    {...textFieldProps}
                    name="phone"
                    placeholder="Your Contact Number"
                  />
                  <SignUpFormField
                    {...textFieldProps}
                    name="website"
                    placeholder="Salon Website"
                  />
                  <SignUpFormField
                    {...textFieldProps}
                    name="accountManager"
                    placeholder={accountManagerPlaceholder || "SDP Full Name"}
                  />
                  <SignUpFormField
                    {...textFieldProps}
                    name="comment"
                    placeholder="Any questions or comments?"
                  />
                </FormRow>

                {privacyNoticeUrl && (
                  <FormRow style={{ marginBottom: "10px" }}>
                    <FormCol padding="0 5px">
                      <a href={`/${privacyNoticeUrl}`} target="_blank" rel="noopener noreferrer">
                        Privacy Notice
                      </a>
                    </FormCol>
                  </FormRow>
                )}

                <FormRow justifyContent="space-between" gutter="narrow">
                  <TermsAndConditions gutter="narrow">
                    <SignUpFormField
                      name="avedaSignupReviewAgreement"
                      as={Checkbox}
                      errors={errors}
                      fullWidth
                      label={(
                        <span>
                        By clicking, you are happy for an Aveda representative to review and approve your salon profile on your behalf. Please note that you will be able to make edits on an ongoing basis.
                      </span>
                      )}
                    />
                  </TermsAndConditions>
                </FormRow>

                <FormRow justifyContent="space-between" gutter="narrow">
                  <TermsAndConditions gutter="narrow">
                    {termsUrl && (
                      <SignUpFormField
                        name="terms"
                        as={Checkbox}
                        errors={errors}
                        fullWidth
                        label={(
                          <span>
                                By clicking, you are agreeing to the
                                <a href={`/${termsUrl}`} target="_blank" rel="noopener noreferrer">
                                  &nbsp;Salon Terms and Conditions.
                                </a>
                              </span>
                        )}
                      />
                    )}
                  </TermsAndConditions>
                  <FormCol gutter="narrow">
                    <Button uppercase disabled={isSubmitting} style={{ display: "block", marginLeft: "auto" }} type="submit" onClick={handleSubmit} size="sm" backgroundColor={buttonBackgroundColour}>
                      Sign up
                    </Button>
                  </FormCol>
                </FormRow>
              </div>
            )}}
        </Formik>
        <FormRow centered style={{ justifyContent: "center" }}>
          {errorMessage && <ErrorMessage message={errorMessage} />}
        </FormRow>
      </IntroCol>
    </WrapGoogleCaptcha>
  )

  return FRANCHISE_LIST.length <= 0 ? null : (
    <SecondarySection style={{ backgroundColor: '#ffffff' }}>
      <MaxWidthWrapper style={{ minWidth: '375px' }}>
        <IntroRow>
          { success ? <SuccessMessage /> : <Form /> }
        </IntroRow>
      </MaxWidthWrapper>
    </SecondarySection>
  )
}

SignUpForm.propTypes = {
  accountManagerPlaceholder: PropTypes.string,
  buttonBackgroundColour: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  privacyNoticeUrl: PropTypes.string,
  termsUrl: PropTypes.string,
  title: PropTypes.string.isRequired,
}

export default SignUpForm
