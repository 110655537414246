import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const Container = styled.div`
  position: absolute;
  top: 70px;
  left: 0px;
  height: calc(100vw - 70px);
  width: 100%;
`

const FullscreenWrapper = ({ children }) => (
  <Container>
    {children}
  </Container>
)

FullscreenWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
}

export default FullscreenWrapper
