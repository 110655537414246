import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { device, ResponsiveMobile, ResponsiveDefault } from "../Responsive/Responsive"
import { formatToMomentTimezone } from "../../timeFormat"

const Subtitle = styled.div`
    font-face: 'Museo-Sans', 'sans-serif';
    font-weight: 500;
    font-size: 16px;
    color: #000000;
    margin-bottom: 2px;
`

const CustomerVip = styled.div`
    font-face: 'Museo-Sans', 'sans-serif';
    font-weight: 300;
    font-size: 14px;
    color: #ef609f;
`
const Content = styled.div`
    font-face: 'Museo-Sans', 'sans-serif';
    font-weight: 300;
    font-size: 16px;
    color: #575757;
    margin-bottom: 6px;
`

const Parent = styled.div`

    @media ${device.tablet}, ${device.desktop} {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      >div {
        width: 32%;
      }
    }

    @media ${device.mobile} {
        width: 100%;
        padding: 0px;
    }
`

const BookingDetail = ({company, appointmentTime, customer, products = [], notes = undefined, employeeName = undefined, serviceDescription, servicePrice}) => {
  const renderCustomerDetails = () => {
    return (
      <React.Fragment>
        <Subtitle>
          Customer Details
        </Subtitle>
        <Content>
          <div>{customer.name}</div>

          {/* customer.isVip && (<CustomerVip>Flossie VIP</CustomerVip>) */}
          {customer.phoneNumber && (<div>{customer.phoneNumber}</div>)}
          {customer.email && (<div>{customer.email}</div>)}
        </Content>
      </React.Fragment>
    )
  }

  const renderServiceDetails = () => (
    <>
      <Subtitle>
        Service
      </Subtitle>
      <Content>{serviceDescription}</Content>
      <Subtitle>
        Price
      </Subtitle>
      <Content>{servicePrice}</Content>
      {(products.length > 0)
        && (
          <React.Fragment>
            <Subtitle>
              Products
            </Subtitle>
            <Content>{products}</Content>
          </React.Fragment>
        )
      }
    </>
  )

  const renderCompanyDetails = () => (
    <React.Fragment>
      <Subtitle>
        Salon
      </Subtitle>
      <Content>{company.name}</Content>
      {employeeName && (
        <React.Fragment>
          <Subtitle>
            Stylist Name
          </Subtitle>
          <Content>{employeeName}</Content>
        </React.Fragment>
      )}
      <Subtitle>
        Time
      </Subtitle>
      <Content>
        {formatToMomentTimezone(appointmentTime, "h:mma", company.timezone)}
        <br />
        {formatToMomentTimezone(appointmentTime, "ddd D MMMM YYYY", company.timezone)}
      </Content>
    </React.Fragment>
  )

  const renderNotes = () => notes && (
    <React.Fragment>
      <Subtitle>
        Notes
      </Subtitle>
      <Content>{notes}</Content>
    </React.Fragment>
  )

  return (
    <React.Fragment>
      <ResponsiveDefault>
        <Parent>
          <div>
            {renderCustomerDetails()}
          </div>
          <div>
            {renderCompanyDetails()}
          </div>
          <div>
            {renderServiceDetails()}
          </div>
          {notes
            && (
              <div style={{ width: "100%" }}>
                {renderNotes()}
              </div>
            )
          }
        </Parent>
      </ResponsiveDefault>
      <ResponsiveMobile>
        <Parent>
          {renderCustomerDetails()}
          {renderCompanyDetails()}
          {renderServiceDetails()}
          {renderNotes()}
        </Parent>
      </ResponsiveMobile>
    </React.Fragment>
  )
}

BookingDetail.propTypes = {
  customer: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  appointmentTime: PropTypes.number.isRequired,
  serviceDescription: PropTypes.string.isRequired,
  servicePrice: PropTypes.string.isRequired,
  products: PropTypes.array,
  notes: PropTypes.string, // booking notes
  employeeName: PropTypes.string,
}

export default BookingDetail
