/* eslint-disable camelcase */
import { List, Map } from "immutable"
import { createSelector } from "reselect"
import { BULK_INSERT } from "./helpers"
import { AUTH_LOGOUT } from "./auth"
import { getSelectedCompany } from "./companies"

const EMPLOYEES_REPLACE_EMPLOYEE = "EMPLOYEES_REPLACE_EMPLOYEE"
export const EMPLOYEES_SAVE_EMPLOYEE = "EMPLOYEES_SAVE_EMPLOYEE"
export const EMPLOYEES_SAVE_SUCCESS = "EMPLOYEES_SAVE_SUCCESS"
export const EMPLOYEES_SAVE_FAIL = "EMPLOYEES_SAVE_FAIL"
export const EMPLOYEES_DELETE_EMPLOYEE = "EMPLOYEES_DELETE_EMPLOYEE"
export const EMPLOYEES_DELETE_FAIL = "EMPLOYEES_DELETE_FAIL"
export const EMPLOYEES_START_LOADING = "EMPLOYEES_START_LOADING"
export const EMPLOYEES_LOADING_SUCCESS = "EMPLOYEES_LOADING_SUCCESS"
export const EMPLOYEES_LOADING_FAIL = "EMPLOYEES_LOADING_FAIL"
export const GET_CURRENT_EMPLOYEES = "GET_CURRENT_EMPLOYEES"

export const GET_EMPLOYEES_EXTERNAL_DATA = "GET_EMPLOYEES_EXTERNAL_DATA"
export const GET_EMPLOYEES_EXTERNAL_DATA_SUCCESS = "GET_EMPLOYEES_EXTERNAL_DATA_SUCCESS"
export const GET_EMPLOYEES_EXTERNAL_DATA_FAIL = "GET_EMPLOYEES_EXTERNAL_DATA_FAIL"

/*
* We fetch all employees external data at once.
* This will be used on the Adding/Update Employee Page for showing the ExternalEmployeeSelector
*/
export const getAllEmployeesExternalData = companyId => ({ type: GET_EMPLOYEES_EXTERNAL_DATA, company_id: companyId })

export function employeesStartLoading() {
  return {
    type: EMPLOYEES_START_LOADING,
  }
}

export function employeesLoadingSuccess() {
  return {
    type: EMPLOYEES_LOADING_SUCCESS,
  }
}

export function employeesLoadingFail(error) {
  return {
    type: EMPLOYEES_LOADING_FAIL,
    error,
  }
}

export const employeesSaveEmployee = c => ({
  type: EMPLOYEES_SAVE_EMPLOYEE,
  id: c.get("id"),
  slug: c.get("slug"),
  visible: c.get("visible"),
  company_id: c.get("companyId"),
  first_name: c.get("firstName"),
  last_name: c.get("lastName"),
  external_name: c.get("externalName"),
  external_id: c.get("externalId"),
  role: c.get("role"),
  service_id: c.get("serviceId") ? c.get("serviceId").toJS().toString() : null,
})

export function employeesDeleteEmployee(id) {
  return {
    type: EMPLOYEES_DELETE_EMPLOYEE,
    id,
  }
}

const employeesReplaceEmployee = (s) => {
  const name = `${s.first_name}${s.last_name ? ` ${s.last_name}` : ""}`
  return ({
    type: EMPLOYEES_REPLACE_EMPLOYEE,
    id: s.id,
    slug: s.slug,
    visible: s.visible,
    companyId: s.company_id,
    firstName: s.first_name,
    lastName: s.last_name,
    externalId: s.external_id,
    externalName: s.external_name,
    name,
    role: s.role,
    serviceId: List(s.service_ids),
    hasUpcomingBooking: s.has_upcoming_booking,
  })
}


const initialEmployeeState = Map({
  id: 0,
  slug: null,
  visible: false,
  companyId: 0,
  companySlug: "",
  name: "",
  firstName: "",
  lastName: "",
  externalId: "",
  externalName: "",
  role: "",
  isModified: false,
  serviceId: List(),
  hasUpcomingBooking: false,
})

const employee = (state = initialEmployeeState, action) => {
  switch (action.type) {
    case EMPLOYEES_SAVE_EMPLOYEE:
      return state.merge({
        firstName: action.first_name,
        slug: action.slug,
        visible: action.visible,
        lastName: action.last_name,
        externalId: action.external_id,
        externalName: action.external_name,
        name: `${action.first_name}${action.last_name ? ` ${action.last_name}` : ""}`,
        role: action.role,
        hasUpcomingBooking: action.has_upcoming_booking,
        isModified: false,
      })

    case EMPLOYEES_REPLACE_EMPLOYEE: {
      /* Retain service IDs in state if not specified in action */
      const serviceId = (action.serviceId && action.serviceId.count() > 0) ? action.serviceId : state.get("serviceId")
      return state.merge({
        id: action.id,
        slug: action.slug,
        visible: action.visible,
        companyId: action.companyId,
        companySlug: action.companySlug,
        name: action.name,
        externalId: action.externalId,
        externalName: action.externalName,
        firstName: action.firstName,
        lastName: action.lastName,
        serviceId,
        role: action.role,
        hasUpcomingBooking: action.hasUpcomingBooking,
      })
    }

    default:
      return state
  }
}

const initialEmployeesState = Map({
  ids: List(),
  loading: true, // current status of loading/saving/creating employees
  externalData: {
    all: Map(), // this will be a map with all the employees external data stored using the employee id as key { <ID>: {...}, ... }
    loading: false,
  },
})

const employees = (state = initialEmployeesState, action) => {
  switch (action.type) {
    case BULK_INSERT:
      if (action.employees) {
        let s = state
        action.employees.forEach((e) => {
          const ids = s.get("ids")
          s = s.merge({
            [e.id]: employee(s.get(e.id), employeesReplaceEmployee(e)),
            ids: ids.indexOf(e.id) === -1 ? ids.push(e.id) : ids,
          })
        })

        return s.set("loading", false)
      }
      if (action.employeesExternalData) {
        let s = state
        action.employeesExternalData.forEach((externalData) => {
          s = s.setIn(["externalData", "all", externalData.id], Map({ ...externalData, companyId: action.companyId }))
        })
        return s
      }
      return state

    case EMPLOYEES_DELETE_EMPLOYEE: {
      const ids = state.get("ids")
      return state.delete(action.id)
        .merge({
          ids: ids.delete(ids.indexOf(action.id)),
        })
    }
    case EMPLOYEES_SAVE_EMPLOYEE:
    case EMPLOYEES_REPLACE_EMPLOYEE: {
      if (action.id) {
        const ids = state.get("ids")
        const s = state.get(action.id)
        return state.merge({
          [action.id]: employee(s, action),
          ids: ids.indexOf(action.id) === -1 ? ids.push(action.id) : ids,
          loading: true,
        })
      }
      // If its a new employee (doesnt have an id yet) we dont add it to the store just yet.
      // after save in the sagas we fetch current employees, so that will add it to the redux store.
      return state.set("loading", true)
    }
    case EMPLOYEES_LOADING_SUCCESS:
    case EMPLOYEES_SAVE_SUCCESS:
    case EMPLOYEES_SAVE_FAIL:
      return state
        .set("loading", false)
        .set("error", action.error)
    case GET_CURRENT_EMPLOYEES: {
      return state.set("loading", true)
    }
    case EMPLOYEES_LOADING_FAIL: {
      return state.set("loading", false)
    }
    case GET_EMPLOYEES_EXTERNAL_DATA: {
      return state.setIn(["externalData", "loading"], true)
    }
    case GET_EMPLOYEES_EXTERNAL_DATA_SUCCESS:
    case GET_EMPLOYEES_EXTERNAL_DATA_FAIL: {
      return state.setIn(["externalData", "loading"], false)
    }
    case AUTH_LOGOUT:
      return initialEmployeesState
    default:
      return state
  }
}


export default employees

export const getEmployeesState = state => state.get("employees")

export const isEmployeesLoading = createSelector(
  getEmployeesState,
  employeesState => employeesState.get("loading"),
)

const getAllEmployees = state => state.getIn(["employees", "ids"])
  .map(id => state.getIn(["employees", id]))


/*
* All employees
* @returns a Immutable.map with <id>:<employee> key pairs.
* If you need an Immutable.list simply do getAllEmployeesMap(state).valueSeq()
*/
export const getAllEmployeesMap = createSelector(
  getAllEmployees,
  allEmployees => allEmployees.reduce((all, e) => all.set(e.get("id"), e), Map()),
)

/*
* All employees for the selected company.
* @returns a Immutable.map with <id>:<employee> key pairs.
* If you need an Immutable.list simply do getCompanyEmployees(state).valueSeq()
*/
export const getCompanyEmployees = (state) => {
  const companyId = state.getIn(["auth", "authCompanyId"])
  return getAllEmployees(state).reduce((all, e) => {
    if (e.get("companyId") === companyId) {
      return all.set(e.get("id"), e)
    }
    return all
  }, Map())
}

export const getEmployeeById = (state, id) => state.getIn(["employees", id])

export const getEmployeeError = createSelector(
  getEmployeesState,
  employeesState => employeesState.get("error"),
)

/*
* @returns Current status of loading all employees external data.
*/
export const isLoadingEmployeesExternalData = createSelector(
  getEmployeesState,
  employeesState => employeesState.getIn(["externalData", "loading"]),
)

/*
 * @returns immutable { <ID>: ... } external employees data
 */
export const employeesExternalData = createSelector(
  [
    (state) => state.get('employees'),
    getSelectedCompany,
  ],
  (employees, company) => employees.getIn(["externalData", "all"]).filter(e => e.get('companyId') === company.get('id'))
)

/*
* @returns Array with the external ids which have already an internal employee associated.
* Only evaluates the employees loaded in the redux store, so make sure to dispatch the corresponding actions
* to load that data.
*/
export const getExternalIdsAlreadyUsed = (state, companyId) => {
  const companyEmployees = getAllEmployees(state).filter(s => s.get("companyId") === companyId)
  const externalIdsAlreadyUsed = []
  companyEmployees.forEach(e => externalIdsAlreadyUsed.push(e.get("externalId")))
  return externalIdsAlreadyUsed
}
