import React from "react"
import styled from "styled-components"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"

import { Button } from "../Form"

const StyledCookieBanner = styled.div`
  z-index: 999;
  display: block;
  position: fixed;
  bottom: 0;
  font-size: 16px;
  width: calc(100% - 20px);
  color: #fff;
  padding: 20px 10px;
  background-color: rgba(0,0,0,0.8);
  left: 0;
`

const CookiesBanner = ({ cookieOk, cookieDecline }) => {
  const onCookieOk = () => {
    document.cookie = "cookiesok=true;path=/;Secure"
    cookieOk()
  }

  const onCookieDecline = () => {
    document.cookie = "cookiesok=false;path=/;Secure"
    cookieDecline()
  }

  return (
    <StyledCookieBanner>
      <div>
        <div style={{ fontSize: "20px", marginBottom: 10 }}>This website uses cookies</div>
        <div>
          We use third party cookies to personalise content and ads, to provide
          social media features and to analyse our traffic. We also share
          information about your use of our site with our social media, advertising
          and analytics partners who may combine it with other information that
          you&apos;ve provided to them or that they&apos;ve collected from your use of their
          service.
        </div>
        <div style={{ marginTop: 8 }}>
          For more information please refer to our
          {" "}
          <Link
            to="/help/privacy-policy"
            style={{ color: "#fff" }}
          >
            Privacy Policy
          </Link>
        </div>

        <div style={{ display: "flex", marginTop: 20 }}>

          <div style={{ flex: 1 }} />

          <Button
            onClick={onCookieDecline}
            style={{
              minWidth: 0,
              padding: "4px 8px",
              fontSize: 12,
              fontWeight: 700,
              border: "none",
              borderRadius: "4px",
              backgroundColor: "#777",
            }}
          >
            DECLINE
          </Button>

          <div style={{ width: 10 }} />

          <Button
            onClick={onCookieOk}
            style={{
              minWidth: 0,
              padding: "4px 8px",
              fontSize: 12,
              fontWeight: 700,
              border: "none",
              borderRadius: "4px",
            }}
          >
            ACCEPT COOKIES
          </Button>

          <div style={{ flex: 1 }} />
        </div>

      </div>
    </StyledCookieBanner>
  )
}

CookiesBanner.propTypes = {
  cookieOk: PropTypes.func.isRequired,
  cookieDecline: PropTypes.func.isRequired,
}

export default CookiesBanner
