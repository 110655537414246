import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import ImmutablePropTypes from "react-immutable-proptypes"
import { Field, Formik } from "formik"

import { Button, FormRow, TextInput } from "../../components/Form"
import EmployeeSelect from "../Employee/EmployeeSelect"
import InlineError from "../../components/Form/InlineError"
import styled from "styled-components"
import StyledSelectField from "../../components/Form/StyledSelectField"

const CancelButton = styled(Button)`
    background: ${({ theme }) => theme.disabledColor};
`

const EditEmployeePricing = ({ companyId, employees, currencyCode, currencySymbol, disabled, onCancel, onSubmit, submitted, errorMessage, ...props }) => {

  const [selectedEmployee, setSelectedEmployee] = useState(props.selectedEmployee)
  const [price, setPrice] = useState(props.selectedEmployee.get('price'))
  const [errors, setErrors] = useState({})

  const handleSubmit = () => {
    if (Object.keys(errors).length !== 0) {
      return;
    }

    // If nothing's changed, nothing need doin
    if (price === selectedEmployee.get('price')) {
      return onCancel();
    }

    onSubmit({
      employeeId: selectedEmployee.get('id'),
      price: price,
    })
  }

  useEffect(() => {
    const errors = {}
    if (!price || price <= 0) {
      errors.price = 'Invalid price'
    }
    setErrors(errors)
  }, [price])

  return (
    <>
      <FormRow $labelTop $align="left" fullWidth>
        <StyledSelectField
          label="Stylist Name"
          name="employeeId"
          disabled={disabled}
          value={selectedEmployee.get('id')}
          onChange={(value) => {
            const employee = employees.find((employee) => employee.get('id') === value)
            setSelectedEmployee(employee)
            setPrice(employee.get('price'))
          }}
          options={employees.map(employee => ({
            label: employee.get('name'),
            value: employee.get('id'),
          })).toJS()}
        />
      </FormRow>
      <FormRow $labelTop $align="left" $width="auto">
        <div style={{ maxWidth: "150px"}}>
          <TextInput
            type="number"
            label={`Stylist Price ${currencyCode && `(${currencyCode})`}`}
            name="price"
            errors={errors.price}
            prefix={currencySymbol}
            tooltipId="employee-pricing"
            value={price}
            onChange={(e) => {
              setPrice(e.target.value)
            }}
            bold
            disabled={disabled}
            tooltipContent={(
              <React.Fragment>
                <p>Select your employee, and add their price for this service or package.</p>
                <p>Make sure you have created your employee profiles before you attempt to add pricing!</p>
              </React.Fragment>
            )}
          />
        </div>
      </FormRow>
      <FormRow />
      <FormRow fullWidth>
        {submitted && <FormRow><InlineError error={errorMessage} /></FormRow>}
        <div style={{ textAlign: "right"}}>
          <CancelButton type="button" onClick={onCancel}>Cancel</CancelButton>
          <Button type="submit" onClick={handleSubmit} disabled={Object.keys(errors).length > 0}>
            Save Stylist Price
          </Button>
        </div>
      </FormRow>
    </>
  )
}

EditEmployeePricing.propTypes = {
  companyId: PropTypes.string.isRequired,
  currencySymbol: PropTypes.string.isRequired,
  currencyCode: PropTypes.string.isRequired,
  selectedEmployee: ImmutablePropTypes.map,
}

export default EditEmployeePricing
