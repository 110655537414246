import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { Button } from "../Form"

const PaginationContainer = styled.div`
  height: 34px;
`

const Pagination = ({ totalPages, onChange, currentPage }) => {
  const handlePageChange = (x) => {
    const newCurrentPage = currentPage + x
    onChange(newCurrentPage)
  }

  /* If there are no pages to display then render nothing */
  if (totalPages === 0) {
    return (<PaginationContainer />)
  }

  return (
    <PaginationContainer>
      <Button disabled={currentPage === 0} flat size="sm" onClick={() => handlePageChange(-1)}>Previous</Button>
      {`Current Page: ${currentPage + 1} | Total pages: ${totalPages}` }
      <Button disabled={currentPage === totalPages - 1} flat size="sm" onClick={() => handlePageChange(1)}>Next</Button>
    </PaginationContainer>
  )
}

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  totalPages: PropTypes.number.isRequired,
}

export default Pagination
