import React from "react"
import { useLocation } from "react-router-dom"
import PropTypes from "prop-types"

import NavigateTab from "../Navigation/Tabs/NavigateTab"
import MobileNavigation from "../Home/MobileNavigation"
import Tabs from "../Navigation/Tabs"
import MainContent from "../Wrappers/MainContent"

const Settings = ({ children, tabsDisabled = false }) => {
  return (
    <>
      <MobileNavigation title='Settings' backLinkOnly={false} />
      <Tabs>
        <NavigateTab key="profile" to={'/settings/profile'} disabled={tabsDisabled}>Profile</NavigateTab>
        <NavigateTab key="hours" to={'/settings/hours'} disabled={tabsDisabled}>Hours</NavigateTab>
        <NavigateTab key="contacts" to={'/settings/contacts'} disabled={tabsDisabled}>Contacts</NavigateTab>
        <NavigateTab key="stylists" to={'/settings/employees'} disabled={tabsDisabled}>Stylists</NavigateTab>
        <NavigateTab key="services" to={'/settings/services'} disabled={tabsDisabled}>Services</NavigateTab>
      </Tabs>
      <MainContent>
        {children}
      </MainContent>
    </>
  )
}

Settings.propTypes = {
  children: PropTypes.element.isRequired,
  tabsDisabled: PropTypes.bool,
}

export default Settings
