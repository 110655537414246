/* eslint-disable react/prop-types,no-unused-vars */
import React from "react"
import List from "react-virtualized/dist/es/List"
import Table from "react-virtualized/dist/es/Table"
import Column from "react-virtualized/dist/es/Table/Column"
import AutoSizer from "react-virtualized/dist/es/AutoSizer"
import CellMeasurerCache from "react-virtualized/dist/es/CellMeasurer/CellMeasurerCache"
import CellMeasurer from "react-virtualized/dist/es/CellMeasurer/CellMeasurer"
import PropTypes from "prop-types"

import { formatToMomentTimezone } from "../../timeFormat"
import { ResponsiveDefault, ResponsiveMobile } from "../Responsive/Responsive"
import {
  Bookings, BookingsRow, CustomerCell, ServiceCell
} from "../Wrappers/ListWrapper"

class BookingsList extends React.Component {
    cache = new CellMeasurerCache({
      defaultHeight: 100,
      fixedWidth: true,
    })

    componentDidUpdate() {
      this.cache.clearAll()
    }

    /* Renderer for mobile sized list */
    listRowRenderer = ({
      key, index, parent, style
    }) => {
      const { items, onClick } = this.props
      const item = items[index]
      const {
        rowType, time, customer, service, todo, type, timeZone, company
      } = item

      /* Return special date rows as required */
      if (rowType === "date") {
        const dateString = formatToMomentTimezone(time, "ddd D MMMM YYYY", timeZone)

        return (
          <CellMeasurer
            cache={this.cache}
            columnIndex={0}
            key={key}
            overscanRowCount={10}
            parent={parent}
            rowIndex={index}
          >

            <div style={style}>

              <div className="ReactVirtualized__Table__DateRow" style={{ borderBottom: "1px solid #ececec" }}>
                <div style={{ paddingLeft: "14px" }}>{dateString}</div>
              </div>

            </div>

          </CellMeasurer>
        )
      }

      /* Unsaved rows highlighted in unsightly pink */
      const highlightClass = (todo) ? "ReactVirtualized__Table__Highlight" : ""

      const dateString = formatToMomentTimezone(time, "h:mma", timeZone).toLowerCase()

      return (
        <CellMeasurer
          cache={this.cache}
          columnIndex={0}
          key={key}
          overscanRowCount={10}
          parent={parent}
          rowIndex={index}
        >
          <BookingsRow
            role="button"
            tabIndex={0}
            onClick={evt => onClick(item, evt)}
            onKeyUp={(evt) => {
              if (evt.key === "Enter") {
                onClick(item, evt)
              }
            }}
            style={style}
          >
            {/* Border and padding go here as they conflict with CellMeasurer */}
            <div style={{ borderBottom: "1px solid #ececec", padding: "14px 0px" }} className={highlightClass}>

              <div style={{ display: "inline-block", paddingLeft: 14 }}>

                <div style={{ position: "absolute", top: "16px", right: "12px" }}>{dateString}</div>
                <CustomerCell>{customer}</CustomerCell>
                <ServiceCell style={{ padding: "8px 0 0 0" }}>{company}</ServiceCell>
                <ServiceCell style={{ padding: "0 0 8px 0" }}>{service}</ServiceCell>
                <div className="ReactVirtualized__Table__row__TodoType">{type}</div>
              </div>
            </div>
          </BookingsRow>
        </CellMeasurer>
      )
    }


    tableRowRenderer = ({
      className,
      columns,
      index,
      key,
      onRowClick,
      onRowDoubleClick,
      onRowMouseOut,
      onRowMouseOver,
      onRowRightClick,
      rowData,
      style
    }) => {
      const a11yProps = {}

      if (
        onRowClick
            || onRowDoubleClick
            || onRowMouseOut
            || onRowMouseOver
            || onRowRightClick
      ) {
        a11yProps["aria-label"] = "row"
        a11yProps.tabIndex = 0

        if (onRowClick) {
          a11yProps.onClick = event => onRowClick({ event, index, rowData })
        }
        if (onRowDoubleClick) {
          a11yProps.onDoubleClick = event => onRowDoubleClick({ event, index, rowData })
        }
        if (onRowMouseOut) {
          a11yProps.onMouseOut = event => onRowMouseOut({ event, index, rowData })
        }
        if (onRowMouseOver) {
          a11yProps.onMouseOver = event => onRowMouseOver({ event, index, rowData })
        }
        if (onRowRightClick) {
          a11yProps.onContextMenu = event => onRowRightClick({ event, index, rowData })
        }
      }

      /* Return special date rows as required */
      if (rowData.rowType === "date") {
        const dateString = formatToMomentTimezone(rowData.time, "ddd D MMMM YYYY", rowData.timeZone)

        return (
          <div key={key} className={[className, "ReactVirtualized__Table__DateRow"].join(" ")} role="row" style={style}>
            <div style={{ width: "calc(100% - 32px)", paddingLeft: "32px", borderTop: "1px solid #ececec" }}>{dateString}</div>
          </div>
        )
      }

      /* Last appointment of the day has bottom border stripped */
      const lastClass = (rowData.last) ? "ReactVirtualized__Table__NoDivider" : ""

      /* Unsaved rows highlighted in unsightly pink */
      const highlightClass = (rowData.todo) ? "ReactVirtualized__Table__Highlight" : ""

      return (
        <div
          {...a11yProps}
          className={[className, lastClass, highlightClass].join(" ")}
          key={key}
          role="row"
          style={style}
        >
          {columns}
        </div>

      )
    }

    /* Wraps type in little grey box */
    typeCellRenderer = ({ cellData }) => {
      if (cellData == null) {
        return ""
      }
      return (
        <div className="ReactVirtualized__Table__row__TodoType">{cellData}</div>
      )
    }

    timeOfDayCellRenderer = ({ cellData, rowData }) => {
      const timeString = formatToMomentTimezone(cellData, "h:mma", rowData.timeZone)

      return (<div>{timeString.toLowerCase()}</div>)
    }

    onRowClick = ({ index }) => {
      const { items, onClick } = this.props
      const item = items[index]
      onClick(item)
    }

    render() {
      const { items } = this.props

      return (
        <Bookings>
          <ResponsiveDefault>
            <AutoSizer>
              {({ height, width }) => (
                <Table
                  disableHeader
                  height={height}
                  rowHeight={60}
                  rowGetter={({ index }) => items[index]}
                  rowCount={items.length}
                  width={width}
                  rowRenderer={this.tableRowRenderer}
                  onRowClick={this.onRowClick}
                >
                  <Column
                    dataKey="time"
                    label="Time"
                    headerClassName="light"
                    disableSort
                    width={48}
                    flexGrow={0}
                    cellRenderer={this.timeOfDayCellRenderer}
                  />

                  <Column
                    label="Customer"
                    dataKey="customer"
                    disableSort
                    width={60}
                    flexGrow={0.2}
                  />

                  <Column
                    label="Salon"
                    dataKey="company"
                    disableSort
                    width={60}
                    flexGrow={0.4}
                  />

                  <Column
                    label="Service"
                    dataKey="service"
                    disableSort
                    width={60}
                    flexGrow={1}
                  />

                  <Column
                    label="Status"
                    dataKey="type"
                    disableSort
                    width={60}
                    flexGrow={0.2}
                    headerClassName="light"
                    cellRenderer={this.typeCellRenderer}
                  />

                </Table>
              )}
            </AutoSizer>
          </ResponsiveDefault>
          <ResponsiveMobile>
            <AutoSizer>
              {({ height, width }) => (
                <List
                  deferredMeasurementCache={this.cache}
                  width={width}
                  height={height}
                  rowCount={items.length}
                  rowHeight={this.cache.rowHeight}
                  rowRenderer={this.listRowRenderer}
                />
              )}
            </AutoSizer>
          </ResponsiveMobile>
        </Bookings>
      )
    }
}

BookingsList.propTypes = {
  items: PropTypes.any.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default BookingsList
