import React from "react"
import Label from "./Label"
import InputError from "./InputError"
import { InputWrapper } from "./TextInput"
import styled from "styled-components"
import PropTypes from "prop-types"

const ThemedTextAreaInput = styled.textarea`
  background: white;
  border-radius: 25px;
  font-weight: 100 !important;
  padding: 10px 15px !important;
  min-width: 180px;
  width: 100%;
  font-size: 15px;
  font-family: 'Museo-Sans','sans-serif';
  outline: none;
  border: 1px solid ${({ theme }) => theme.borderColor};
  box-sizing: border-box;
`

export const TextArea = ({ error, id, label, ...rest }) => (
  <React.Fragment>
    {label && <Label htmlFor={id}>{label}</Label>}
    <InputWrapper>
      <ThemedTextAreaInput id={id} {...rest} />
      {error && <div><InputError>{error}</InputError></div>}
    </InputWrapper>
  </React.Fragment>
)

TextArea.propTypes = {
  error: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
}

export default TextArea
