import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const Error = styled.h6`
    color: ${({ theme }) => theme.errorColor};
    margin-top: ${({ theme }) => theme.parentPadding};
    text-align: ${({ $align }) => $align};
    font-size: 16px;
`

const InlineError = ({ error = null, style = {}, align = 'right' }) => {
  if (error) {
    return <Error style={style} $align={align}>{error}</Error>
  }
  return null
}

InlineError.propTypes = {
  error: PropTypes.string,
  style: PropTypes.object,
  align: PropTypes.oneOf(["left", "center", "right"])
}

export default InlineError
