/* eslint-disable import/no-unresolved,import/no-webpack-loader-syntax,react/prefer-stateless-function,react/no-multi-comp */
import React, { useState } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import styled, { withTheme } from "styled-components"

import { device } from "../Responsive/Responsive"
import {
  LoginToolbarContainer,
  NotificationContainer,
  PusherToolbarContainer,
} from "../../containers"
import { getNewBookingsInboxCount } from "../../redux/modules/inbox"
import SideBarMenu from "./SideBarMenu"
import { getAmountOfNotVisibleCompanies, getAmountOfVisibleCompanies } from "../../redux/modules/companies"
import { hasRole, isAuthenticated } from "../../redux/modules/auth"

import { API_ROLE_COMPANY } from "../../config/constants"
import CompanySelectContainer from "../../containers/CompanySelect/CompanySelectContainer"


const PBFDiv = styled.div`
  z-index: 1000;
  font-size: 9px;
  line-height: 10px;
  text-align: center;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  position: fixed;
  top: 0px;
  height: 10px;
  width: 114px;
  right: calc(50% - 57px);
  background-color: #ef609f;
  color: #fff;
  padding-top: 4px;
  padding-bottom: 4px;
  a {
    text-decoration: none;
    color: inherit;
  }
`

const PoweredByFlossie = () => (
  <PBFDiv>
    <a href="https://poweredbyflossie.com/" target="_blank" rel="noopener noreferrer">
      Powered by
      {" "}
      <span style={{ fontWeight: 500 }}>FLOSSIE</span>
    </a>
  </PBFDiv>
)

const Toolbar = styled.div`
    background-color: ${({ theme }) => theme.headerBg};
    align-items: center;
    display: flex;
    display-direction: row;
    padding: 0px ${({ theme }) => theme.parentPadding};
    box-sizing: border-box;
    position: fixed;
    z-index: 1000;

    @media ${device.tablet}, ${device.desktop} {

        top: 0;
        left: 0;
        height: 70px;
        width: 100vw;

    }

    @media ${device.mobile} {
        height: 53px;
        width: 100%;
        position: relative;
    }
`

const ToolbarShadow = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: transparent;
    height: 70px;
    z-index: 10;
    box-shadow: 0px 2px 4px #ececec;
    pointer-events: none;

    @media ${device.mobile} {
        height: 53px;
    }
`

const ThemedLogo = styled.img`
  width: auto;
  height: 56px;
  object-fit: contain;
  padding: 15px 0;
  box-sizing: border-box;
  @media ${device.mobile} {
      padding-left: 50px;
      width: auto;
      height: 46px;
      margin-top: 5px;
  }
`

const HeaderPortal = styled.div`
    display: none;

    @media ${device.mobile} {
        display: block;
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 1001;
        height: 0px;
        overflow: visible;
    }
`
const FooterPortal = styled.div`
    display: none;

    @media ${device.mobile} {
        display: block;
        width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        min-width: 320px;
    }
`
const OuterContainer = styled.div`
    background-color: #f7f7f7;
    height: ${({ hideSideBar }) => (hideSideBar ? "auto" : "100vh")};
    min-height: 100vh;
    width: 100%;
    font-weight: 300;
    font-size: 0.8em;
`
const PageWrap = styled.main`

    @media ${device.tablet}, ${device.desktop} {
        height: 100vh;
        padding-top: ${({ isAuthed }) => (isAuthed ? "70px" : 0)};
        padding-left: ${({ theme, isTabletOrDesktop, isAuthed }) => (isTabletOrDesktop && isAuthed ? `${theme.desktopSideBarWidth}` : 0)};
        box-sizing: border-box;
    }

    @media ${device.mobile} {
        height: ${({ hideSideBar }) => (hideSideBar ? "auto" : "calc(100vh - 53px)")};
        padding: 0;
    }
`

const ToolBarItem = styled.div`
  margin-left: ${({ isMobile }) => (isMobile ? "18px" : "32px")};
  >div, a {
    color: ${({ theme }) => theme.headerLinkColor} !important;
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
  }
`

const Home  = ({
                 newBookingsInboxCount=0, menuActive=undefined, hideSideBar=false,
                 children, isAuthed, isPublic, browser, theme, notVisibleCompanies,
                 visibleCompanies
               }) => {

  /* Track when menu opens/closes to selectively display flossie logo and the toolbar */
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const isMobile = browser.lessThan.tablet

  if (!isPublic && !isAuthed) {
    return null
  }

  return (

    <OuterContainer
      id="outer-container"
      hideSideBar={hideSideBar}
      className="tk-museo-sans"
    >

      {isAuthed && (<PusherToolbarContainer />)}

      {/* Custom navigation only present on mobile currently */}
      {isAuthed
        && (
          <HeaderPortal
            id="header-portal"
          />
        )
      }

      <React.Fragment>
        <Toolbar>
          <PoweredByFlossie />
          {/* {browser.lessThan.tablet &&
                        <div style={{ flex: '1' }}/>
                        } */}


          <div><ThemedLogo src={theme.logo} /></div>

          <div style={{ flex: "1", maxWidth: "100%" }} />


          {isAuthed && !isMobile && hasRole(API_ROLE_COMPANY) && (
            <CompanySelectContainer style={{ flex: 0 }} />
          )}

          {isAuthed && (
            <ToolBarItem className="clickable" isMobile={browser.lessThan.tablet}>
              <LoginToolbarContainer />
            </ToolBarItem>
          )}

          {!isMobile && isAuthed && hasRole(API_ROLE_COMPANY) && (
            <ToolBarItem className="clickable" isMobile={browser.lessThan.tablet}>
              <Link href to="/help/faqs">Help</Link>
            </ToolBarItem>
          )}

        </Toolbar>

        <ToolbarShadow />
      </React.Fragment>


      {/* Menu is only visible to logged in users */}
      {isAuthed && !hideSideBar && (
        <SideBarMenu
          menuActive={menuActive}
          isOpen={isMenuOpen}
          isMobile={isMobile}
          menuStateChanged={setIsMenuOpen}
          browser={browser}
          newUnsavedBookingCount={newBookingsInboxCount}
          notVisibleCompanies={notVisibleCompanies}
          visibleCompanies={visibleCompanies}
        />
      )}

      <PageWrap
        id="page-wrap"
        isAuthed={isAuthed}
        hideSideBar={hideSideBar}
        isTabletOrDesktop={browser.greaterThan.mobile}
      >
        <NotificationContainer>
          {children}
        </NotificationContainer>
      </PageWrap>

      {isAuthed
        && <FooterPortal id="footer-portal" />
      }
    </OuterContainer>
  )
}

Home.propTypes = {
  children: PropTypes.node.isRequired,
  isAuthed: PropTypes.bool.isRequired,
  browser: PropTypes.object.isRequired,
  newBookingsInboxCount: PropTypes.number,
  theme: PropTypes.object.isRequired,
  menuActive: PropTypes.string,
  hideSideBar: PropTypes.bool,
  notVisibleCompanies: PropTypes.number.isRequired,
  visibleCompanies: PropTypes.number.isRequired,
}

const mapStateToProps = state => ({
  browser: state.get("browser"),
  newBookingsInboxCount: getNewBookingsInboxCount(state),
  notVisibleCompanies: getAmountOfNotVisibleCompanies(state),
  visibleCompanies: getAmountOfVisibleCompanies(state),
  isAuthed: isAuthenticated(state),
})

export default connect(mapStateToProps)(withTheme(Home))
