import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { device } from "../Responsive/Responsive"

import LoadingIcon from "../Loading/LoadingIcon"

const LoadingWrapper = styled.div`
    margin-top: 15px;
    width: 100%;
    text-align: center;
`

const TableWrapper = styled.table`
    border-collapse: collapse;
    width: 100%;

    tr {
        border-bottom: solid 1px ${({ theme }) => theme.borderColor};
        border-left: solid 1px ${({ theme }) => theme.borderColor};
        border-right: solid 1px ${({ theme }) => theme.borderColor};
        color: ${({ theme }) => theme.labelDarkColor};
    }

    tr:first-child {
      border-bottom: solid 1px #ececec;
      border-left: solid 1px #ececec;
      border-right: solid 1px #ececec;
    }

    th {

        background-color: ${({ theme }) => theme.labelDarkColor};
        height: 45px;
        line-height: 45px;
        font-family: 'Museo-Sans', 'sans-serif';
        font-size: 14px;
        font-weight: 300;
        color: ${({ theme }) => theme.primaryContrastColor}};
        text-align: left;
        padding: 0;

        /*
        font-size: 14px;
        font-weight: 100;

        padding: 2rem 1rem;

        a {
            font-weight: bold;
            color: ${({ theme }) => theme.labelDarkColor};
            text-decoration: none;
        }*/
    }

    th:first-child {
      padding-left: 16px;
    }

    td {
      padding: 15px 0px;
      a {
        text-decoration: none;
        color: #575757;
      }
    }

    td:first-child {
      padding-left: 20px;
    }

    @media ${device.mobile} {

        th {
            font-size: 15px;
            font-weight: 100;
        }
    }
`

const Row = styled.tr`
  cursor: pointer;
  background-color: ${({ theme, $selected }) => ($selected ? theme.borderColor : "#fff")};
`

const Table = ({
  headers, rows = [], emptyTableContent = null, loading, onRowClick = () => {}
}) => {
  const renderRow = (element, row) => (
    <Row key={`row-${row.id}`} onClick={() => { onRowClick(row) }} $selected={row.selected}>
      {element}
    </Row>
  )

  return (

    <TableWrapper>
      <thead>
        <tr>
          {headers.map(header => <th key={`header-${header.key}`} style={{ textAlign: header.alignment ? header.alignment : "left", width: header.width ? header.width : "auto" }}>{header.label}</th>)}
        </tr>
      </thead>
      <tbody>
        {!loading && rows.map(row => (row.mobile ? renderRow(row.mobile, row) : renderRow(row.desktop, row)))}
        {!loading && rows.length === 0 && emptyTableContent && <tr><td colSpan={headers.length}>{emptyTableContent}</td></tr>}
        {loading && <tr><td colSpan={headers.length}><LoadingWrapper><LoadingIcon /></LoadingWrapper></td></tr>}
      </tbody>
    </TableWrapper>

  )
}

Table.propTypes = {
  headers: PropTypes.array.isRequired, // string array with headers titles
  rows: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // key used inside array.map render.
    desktop: PropTypes.oneOfType([PropTypes.array, PropTypes.element]), // Row to show on Desktop version. array when multiple columns. element when single column.
    mobile: PropTypes.oneOfType([PropTypes.array, PropTypes.element]), // (optional) Row to show in mobile. If empty we show desktop row in mobile.
  })),
  loading: PropTypes.bool.isRequired, // true: will show a loading icon on table body
  onRowClick: PropTypes.func,
  emptyTableContent: PropTypes.element, // Content to display post-loading if rows contains no data
}

export default Table
