import React from "react"
import PropTypes from "prop-types"
import { Link, useLocation, } from "react-router-dom"
import styled from "styled-components"

import { device } from "../../Responsive/Responsive"

const ThemedLink = styled(Link)`
    font-family: 'Museo-Sans','sans-serif';
    font-weight: bold;
    display: inline-block;
    color: ${({ theme }) => theme.labelDarkColor};
    opacity: ${({ $active }) => ($active ? 1 : 0.25)};
    text-decoration: none;
    border-bottom: ${({ $active, theme }) => ($active ? `solid 5px ${theme.primaryColor}` : "solid 5px #fff")};
    padding: 0 30px;
    white-space: nowrap;
    @media ${device.tablet}, ${device.desktop} {
        font-size: ${({ $size }) => $size === "sm" ? "18px" : "20px"};
        border-bottom: ${({ $active, theme }) => ($active ? `solid 5px ${theme.primaryColor}` : "")};
    }
    @media ${device.mobile} {
        width: auto;
        padding: 0 12px;
        font-size: 16px;
    }
    &[disabled] {
        pointer-events: none;
    }
`

/* Ternary operator required because active attribute has special meaning within browser JS */
const NavigateTab = ({
  to, children, disabled, size
}) => {
  const location = useLocation();
  // using $ before a prop name (transient prop) allows the styled component to use the prop but wont be passed to the dom element
  // https://styled-components.com/docs/api#transient-props
  return <ThemedLink to={to} $active={!disabled && location.pathname.startsWith(to) } disabled={disabled} $size={size}>{children}</ThemedLink>
}

NavigateTab.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  to: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(["sm", "lg"]),
}

export default NavigateTab
