import {
  IntroCol,
  IntroHeadline,
  IntroRow,
  IntroWrapper,
  MaxWidthWrapper,
  Section,
  TopHeader
} from "../StyledComponents"
import React from "react"
import InfoCard from "../InfoCard"
import Carousel from "../Carousel"
import iconService from "../../../../img/supplierCampaigns/Icon-Service.png"
import iconConnect from "../../../../img/supplierCampaigns/Icon-Connect.png"
import iconPromo from "../../../../img/supplierCampaigns/Icon-Promo.png"

export default () => {
  return (
    <Section background="transparent">
      <MaxWidthWrapper>
        <IntroRow>
          <IntroCol>
            <IntroWrapper centered={true}>
              <TopHeader><h4>Onboarding Process</h4></TopHeader>
              <p>Sign up, customize your service pricing and go live! It's as easy as a hair flip!</p>
            </IntroWrapper>
          </IntroCol>
        </IntroRow>

        <IntroRow gutter="narrow">
          <Carousel>
            <InfoCard
              headline="Sign Up"
              content="Fill in the form below to register your salon."
              icon={iconService}
              count={1}
            />
            <InfoCard
              headline="Confirm Profile"
              content="Your salon information will be pulled from your calendar. Receive access to the Flossie Admin to review!"
              icon={iconConnect}
              count={2}
            />
            <InfoCard
              headline="Approval & Go Live!"
              content="Hit the approval button in the Flossie Admin. Flossie will run test bookings to your calendar to make sure everything is working as it should be!"
              icon={iconPromo}
              count={3}
            />
          </Carousel>
        </IntroRow>
      </MaxWidthWrapper>
    </Section>
  )
}