import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import Pagination from "../../components/Pagination"
import * as paginationRedux from "../../redux/modules/pagination"

const PaginationContainer = ({
  changePage, handleChange, currentPage, ...rest
}) => {
  const onChange = (newCurrentPage) => {
    changePage(newCurrentPage) // updates current page in redux store
    handleChange(newCurrentPage) // calls the handle change from parent
  }
  return <Pagination onChange={onChange} currentPage={currentPage} {...rest} />
}

PaginationContainer.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  listName: PropTypes.string.isRequired, // name of the list to identify data in redux store
  changePage: PropTypes.func.isRequired, // action to dispatch on every page change so we update the page number in the redux store
  handleChange: PropTypes.func.isRequired, // callback from parent, it will be called after changing the page
}

const mapStateToProps = (state, { listName }) => {
  const pagination = paginationRedux.getPagination(state)
  return {
    currentPage: pagination.getIn([listName, "currentPage"]),
    totalPages: pagination.getIn([listName, "totalPages"]),
  }
}

const mapDispatchToProps = (dispatch, { listName }) => ({
  changePage: currentPage => dispatch(paginationRedux.changePage(listName, currentPage)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PaginationContainer)
