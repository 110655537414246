import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Link, useParams } from "react-router-dom"

import { CenterScreenWrapper, SingleColumnPage, ContentWrapper } from "../../components/Wrappers"
import ResetPasswordForm from "../../forms/ResetPassword/ResetPasswordForm"
import { isPasswordUpdated } from "../../redux/modules/auth"
import { Button } from "../../components/Form"

const ChangePasswordContainer = ({
  passwordUpdated
}) => {
  const { id } = useParams()

  return (
    <CenterScreenWrapper>
      <SingleColumnPage>
        {/* Layout changes after login so kludge in a negative margin to reposition centered content block */}
        <ContentWrapper style={{ marginTop: passwordUpdated ? -120 : 0 }}>
          {passwordUpdated ? (
            <div>
              <h3 style={{ fontSize: "16px", color: "#575757", marginTop: 0 }}>Password has been updated.</h3>
              <Link style={{ textDecoration: "none" }} href to="/"><Button style={{ margin: "0 auto", display: "block" }}>Go to Dashboard</Button></Link>
            </div>
          ) : (
            <ResetPasswordForm id={id} />
          )}
        </ContentWrapper>
      </SingleColumnPage>
    </CenterScreenWrapper>
  )
}

ChangePasswordContainer.propTypes = {
  passwordUpdated: PropTypes.bool.isRequired, // selector
}

const mapStateToProps = state => ({
  passwordUpdated: isPasswordUpdated(state),
})

export default connect(mapStateToProps)(ChangePasswordContainer)
