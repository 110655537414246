import React, { useEffect, useState } from "react"
import styled, { css } from "styled-components"
import { StyledSelectField } from "../../components/Form"
import { device } from "../../components/Responsive/Responsive"
import PropTypes from "prop-types"
import { BOOKING_HISTORY_TYPE_COMPLETE, BOOKING_HISTORY_TYPE_PENDING } from "../../redux/modules/bookings"
import { useNavigate, useParams } from "react-router-dom"

const FilterField = css`
    width: calc(100% - 16px);

    @media ${device.mobile} {
        width: calc(100% - 28px);
        margin: 0px 14px 8px 14px;
    }
`

const FilterSelectField = styled(StyledSelectField)`
  ${FilterField}
`

const FilterTextField = styled.input`
    ${FilterField}

    background: white;
    border-radius: 25px;
    font-weight: 100 !important;
    padding: 10px 15px !important;
    height: 42px;
    font-size: 15px;
    outline: none;
    border: 1px solid ${({ theme }) => theme.borderColor};
    box-sizing: border-box;

    @media ${device.mobile} {
        width: calc(100% - 30px);
    }
`

const BookingSearchFormParent = styled.form`
    display: flex;
    flex-direction: row;
    gap: 15px;
    justify-content: space-between;

    @media ${device.mobile} {
      margin-top: 8px;
      display: block;
    }

    & > div:first-child {
      flex: 0 0 calc((100%/2) - 8px);
    }
`

const BookingSearchForm = ({ handleChange }) => {
  const params = useParams()
  const navigate = useNavigate()

  const historyOptions = {
    pending: { label: "Pending", value: BOOKING_HISTORY_TYPE_PENDING },
    complete: { label: "Complete", value: BOOKING_HISTORY_TYPE_COMPLETE },
  };

  const selected = historyOptions[params.state ?? 'pending'];

  const [user, setUser] = useState('');

  useEffect(() => {
    handleChange({ user })
  }, [user])

  const getOptionByValue = (value) =>
    Object.entries(historyOptions).find((eachHistoryItem) => eachHistoryItem[1].value === value)[0]

  const onHistoryChange = (value) => navigate(`/bookings/${getOptionByValue(value)}`)

  return (
    <div>
      <BookingSearchFormParent>
        <FilterSelectField
          name="history"
          options={Object.values(historyOptions)}
          onChange={onHistoryChange}
          value={selected.value}
        />
        <FilterTextField
          name="user"
          component="input"
          placeholder="Enter customer name or email address"
          onChange={(event) => setUser(event.target.value)}
        />
      </BookingSearchFormParent>
    </div>
  )
}

BookingSearchForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
}

export default BookingSearchForm
