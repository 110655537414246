import { Map, List } from "immutable"

import { purge } from "./helpers"

import { REFRESH_STATISTICS } from "../../config/constants"

import { AUTH_LOGOUT } from "./auth"

export const STATISTICS_KEY_COMPANY = "company"
export const STATISTICS_KEY_FRANCHISE = "franchise"

const STATISTICS_REPLACE = "STATISTICS_REPLACE"
export const STATISTICS_START_LOADING = "STATISTICS_START_LOADING"
export const STATISTICS_LOADING_SUCCESS = "STATISTICS_LOADING_SUCCESS"
export const STATISTICS_LOADING_FAIL = "STATISTICS_LOADING_FAIL"
export const STATISTICS_UPDATE_RANGE = "STATISTICS_UPDATE_RANGE"
export const STATISTICS_UPDATE_COMPANY_NAME = "STATISTICS_UPDATE_COMPANY_NAME"
export const STATISTICS_DOWNLOAD = "STATISTICS_DOWNLOAD"

export function statisticsUpdateRange(key, range) {
  return {
    type: STATISTICS_UPDATE_RANGE,
    key,
    range,
  }
}

export function statisticsUpdateCompanyName(key, name) {
  return {
    type: STATISTICS_UPDATE_COMPANY_NAME,
    key,
    name,
  }
}


export function statisticsDownload(key, range = [null, null]) {
  return {
    type: STATISTICS_DOWNLOAD,
    key,
    range,
  }
}

export function statisticsRefresh(key, range = [null, null], companyId = null, companyName = null) {
  const action = {
    type: REFRESH_STATISTICS,
    key,
    range,
  }

  if (companyName) {
    action.companyName = companyName
  }

  // Both null and "0"
  if (companyId && companyId !== "0") {
    action.companyId = companyId
  }

  return action
}

export function statisticsReplace(key, currency, statistics, companies = null) {
  return {
    type: STATISTICS_REPLACE,
    key,
    currency,
    statistics,
    companies,
  }
}

export function statisticsStartLoading(key) {
  return {
    type: STATISTICS_START_LOADING,
    key,
  }
}

export function statisticsLoadingSuccess(key) {
  return {
    type: STATISTICS_LOADING_SUCCESS,
    key,
  }
}

export function statisticsLoadingFail(key, error) {
  return {
    type: STATISTICS_LOADING_FAIL,
    key,
    error,
  }
}

const emptyState = {
  isLoading: false,
  error: null,
  values: null,
  range: [null, null],
  currency: null,
  companyName: null,
  companyId: "0",
  companies: null,
}

const initialStatisticsState = Map({
  [STATISTICS_KEY_COMPANY]: Map(emptyState),
  [STATISTICS_KEY_FRANCHISE]: Map(emptyState)
})

export default function statistics(state = initialStatisticsState, action) {
  // The following code relies on action.key being present which is only true for statistics actions
  const validActions = [
    STATISTICS_REPLACE,
    STATISTICS_START_LOADING,
    STATISTICS_LOADING_SUCCESS,
    STATISTICS_LOADING_FAIL,
    STATISTICS_UPDATE_RANGE,
    STATISTICS_UPDATE_COMPANY_NAME,
    AUTH_LOGOUT
  ]

  if (validActions.indexOf(action.type) === -1) {
    return state
  }

  // Wipe all stats on logout
  if (action.type === AUTH_LOGOUT) {
    return initialStatisticsState
  }

  // Build state merge object
  const statistics = state.get(action.key)

  switch (action.type) {
    case STATISTICS_REPLACE: {
      const merge = statistics
        .set("values", Map(action.statistics))
        .set("currency", Map(action.currency))
        .set("companies", STATISTICS_KEY_FRANCHISE ? List(action.companies) : null)

      return state.set(action.key, merge)
    }

    case STATISTICS_START_LOADING: {
      const merge = statistics.set("isLoading", true)
      return state.set(action.key, merge)
    }
    case STATISTICS_LOADING_SUCCESS:
      const merge = statistics.set("isLoading", false)
      return purge(state)
        .set(action.key, merge)
    case STATISTICS_LOADING_FAIL: {
      const merge = statistics
        .set("isLoading", false)
        .set("error", action.error)
      return state.set(action.key, merge)
    }
    case STATISTICS_UPDATE_RANGE: {
      const merge = statistics.set("range", action.range)
      return state.set(action.key, merge)
    }
    case STATISTICS_UPDATE_COMPANY_NAME: {
      const merge = statistics.set("companyName", action.name)
      return state.set(action.key, merge)
    }
    default:
      return state
  }
}

export const getStatistics = (key, state) => state.getIn(["statistics", key, "values"])
export const getStatisticsRange = (key, state) => state.getIn(["statistics", key, "range"])
export const getStatisticsCompanyName = (key, state) => state.getIn(["statistics", key, "range"])
export const getStatisticsCurrency = (key, state) => state.getIn(["statistics", key, "currency"])
export const getStatisticsCompanies = (key, state) => state.getIn(["statistics", key, "companies"])
