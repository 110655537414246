import React from "react"
import PropTypes from "prop-types"

export default function Dashboard(props) {
  const { children } = props
  return (
    <div style={{ padding: "20px 20px 80px 20px" }}>
      {children}
    </div>
  )
}

Dashboard.propTypes = {
  children: PropTypes.node.isRequired,
}
