import React from "react"
import styled from "styled-components"

import { PencilIcon } from "../Icons/Icons"
import { ServiceEditIcon, ServiceName } from "./common"
import ServiceStatusBadge from "./StatusBadge"
import ServiceImage from "./Image"

const ServiceRow = styled.div`
  position: relative;
  display: flex;
  column-gap: 3rem;
  align-items: center;
  padding: 12px 0;
  border-bottom: solid 1px ${({ theme }) => theme.borderColor};
`

const ServiceRowView = ({ image, description, live }) => {
  return (
    <ServiceRow>
      <div><ServiceImage width={80} height={80} src={image} /></div>
      <div style={{ flex: 5 }} ><ServiceName>{description}</ServiceName></div>
      <div style={{ flex: 0.5 }}><ServiceStatusBadge live={live} /></div>
      <ServiceEditIcon style={{ flex: 1 }}><PencilIcon /></ServiceEditIcon>
      <div style={{ flex: 1 }} />
    </ServiceRow>
  )
}

export default ServiceRowView
