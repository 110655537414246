import { CONNECTED, DISCONNECTED } from "pusher-redux"
import { Map } from "immutable"

export const PUSHER_EVENT = "PUSHER_EVENT"
const PUSHER_SUBSCRIBED = "PUSHER_SUBSCRIBED"
const PUSHER_UNSUBSCRIBED = "PUSHER_UNSUBSCRIBED"

export function pusherSubscribed() {
  return {
    type: PUSHER_SUBSCRIBED,
  }
}

export function pusherUnsubscribed() {
  return {
    type: PUSHER_UNSUBSCRIBED,
  }
}

const initialPusherState = Map({
  isConnected: false,
  isSubscribed: false,
  lastEventAt: null,
})

export default function pusher(state = initialPusherState, action) {
  switch (action.type) {
    case PUSHER_EVENT:
      return state.merge({
        lastEventAt: Date.now(),
      })
    case CONNECTED:
      return state.merge({
        isConnected: true,
      })
    case DISCONNECTED:
      return state.merge({
        isConnected: false,
      })
    case PUSHER_SUBSCRIBED: {
      return state.merge({
        isSubscribed: true,
      })
    }
    case PUSHER_UNSUBSCRIBED: {
      return state.merge({
        isSubscribed: false,
      })
    }
    default:
      return state
  }
}
