import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

const StyledCarousel = styled(Carousel)`
  padding: 30px 0;
  .react-multi-carousel-dot {
    &:before {
      display: none;
    }
  }
  .react-multiple-carousel__arrow--left {
    left: calc(1% + 1px);
  }
  .react-multiple-carousel__arrow--right {
    right: calc(1% + 1px);
  }
  .react-multi-carousel-item:last-of-type {
    margin-bottom: 0.5rem;
  }
  .react-multi-carousel-item {
    &:before {
      display: none;
    }
  }
  .react-multi-carousel-dot button {
    border-color: ${props => props.theme.primaryColor};
  }
  .react-multi-carousel-dot--active button {
    background: ${props => props.theme.primaryColor};
  }
  .react-multi-carousel-track {
    margin: 0 auto;
  }

`

const defaultResponsiveValue = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

const MultiCarousel = ({
     responsive = defaultResponsiveValue,
     children
}) => {
  return (
    <div style={{ width: "100%" }}>
      <StyledCarousel
        showDots
        responsive={responsive}
        removeArrowOnDeviceType={["mobile", "tablet"]}
      >
        {children}
      </StyledCarousel>
    </div>
  )
}

MultiCarousel.propTypes = {
  responsive: PropTypes.object,
  children: PropTypes.array,
}

export default MultiCarousel
