import React from "react"
import styled from "styled-components"

const CenterCard = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    font-family: sans-serif;
    font-size: 32px;
    color: #f067a6;
    opacity: .4;
    transform: translate(-50%, -50%);
    text-align: center;
    justify-content: center;
`

const Message = styled.small`
    font-size: 16px;
    display: block;
    margin-top: 8px;
`

const NotFound = () => {
  return <CenterCard>
    404
    <Message>Oops... Sorry we couldn't find that page</Message>
  </CenterCard>
}

export default NotFound