import React, { useState } from "react"
import DayPicker from "../StyledDayPicker"
import PropTypes from "prop-types"
import moment from "moment-timezone"
import "react-day-picker/dist/style.css"

const DateRangePicker = ({ numberOfMonths = 2, disabledDays = {}, initialValues, onChange }) => {

  const [range, setRange] = useState(initialValues)

  const handleChange = (values) => {
    if (typeof onChange === 'function') {
      onChange(values)
    }
    setRange(values)
  }

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <DayPicker
        mode="range"
        defaultMonth={(range && range.from) || moment().toDate()}
        selected={range}
        onSelect={handleChange}
        disabled={disabledDays}
        toDate={moment.now()}
        numberOfMonths={window.screen.width < 769 ? 1 : 2}
        fromMonth={moment().subtract(numberOfMonths, 'months').toDate()}
      />
    </div>
  )
}

DateRangePicker.propTypes = {
  numberOfMonths: PropTypes.number,
  disabledDays: PropTypes.object,
  onChange: PropTypes.func,
  initialValues: PropTypes.shape({
    from: PropTypes.object.isRequired,
    to: PropTypes.object.isRequired,
  })
}

export default DateRangePicker