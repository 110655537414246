import { List, Map } from "immutable"
import { createSelector } from "reselect"
import { BULK_INSERT, purge } from "./helpers"
import { AUTH_LOGOUT } from "./auth"

const SERVICES_START_LOADING = "SERVICES_START_LOADING"
const SERVICES_LOADING_SUCCESS = "SERVICES_LOADING_SUCCESS"
const SERVICES_LOADING_FAIL = "SERVICES_LOADING_FAIL"
export const servicesStartLoading = () => ({ type: SERVICES_START_LOADING })
export const servicesLoadingSuccess = () => ({ type: SERVICES_LOADING_SUCCESS })
export const servicesLoadingFail = error => ({ type: SERVICES_LOADING_FAIL, error })

// used for api /company/service/new and /company/service/edit
const SINGLE_SERVICE_START_LOADING = "SINGLE_SERVICE_START_LOADING"
const SINGLE_SERVICE_LOADING_SUCCESS = "SINGLE_SERVICE_LOADING_SUCCESS"
export const SERVICES_SAVE_SERVICE = "SERVICES_SAVE_SERVICE"
const SERVICES_SAVE_SERVICE_SUCCESS = "SERVICES_SAVE_SERVICE_SUCCESS"
const SERVICES_SAVE_SERVICE_FAIL = "SERVICES_SAVE_SERVICE_FAIL"
export const servicesSaveService = service => ({
  type: SERVICES_SAVE_SERVICE,
  ...parseServiceToRequestFormat(service),
})
export const singleServiceStartLoading = () => ({ type: SINGLE_SERVICE_START_LOADING })
export const singleServiceLoadingSuccess = () => ({ type: SINGLE_SERVICE_LOADING_SUCCESS })
export const saveServiceSuccess = () => ({ type: SERVICES_SAVE_SERVICE_SUCCESS })
export const saveServiceFail = error => ({ type: SERVICES_SAVE_SERVICE_FAIL, error })

const SERVICES_SET_SELECTED_SERVICE_ID = "SERVICES_SET_SELECTED_SERVICE_ID"
export const servicesSetSelectedServiceId = id => ({ type: SERVICES_SET_SELECTED_SERVICE_ID, id })

export const SERVICES_DELETE_SERVICE = "SERVICES_DELETE_SERVICE"
const SERVICES_DELETE_SERVICE_SUCCESS = "SERVICES_DELETE_SERVICE_SUCCESS"
const SERVICES_DELETE_SERVICE_FAIL = "SERVICES_DELETE_SERVICE_FAIL"
export const servicesDeleteService = params => ({ type: SERVICES_DELETE_SERVICE, ...params })
export const servicesDeleteServiceSuccess = id => ({ type: SERVICES_DELETE_SERVICE_SUCCESS, id })
export const servicesDeleteServiceFail = error => ({ type: SERVICES_DELETE_SERVICE_FAIL, error })

// used for api /company/service/description/all
export const GET_SERVICES_DESCRIPTIONS = "GET_SERVICES_DESCRIPTIONS"
export const GET_SERVICES_DESCRIPTIONS_SUCCESS = "GET_SERVICES_DESCRIPTIONS_SUCCESS"
export const GET_SERVICES_DESCRIPTIONS_FAIL = "GET_SERVICES_DESCRIPTIONS_FAIL"
export const getAllServiceDescriptions = () => ({ type: GET_SERVICES_DESCRIPTIONS })

// used for api /company/service/external
export const GET_EXTERNAL_SERVICES = "GET_EXTERNAL_SERVICES"
export const GET_EXTERNAL_SERVICES_SUCCESS = "GET_EXTERNAL_SERVICES_SUCCESS"
export const GET_EXTERNAL_SERVICES_FAIL = "GET_EXTERNAL_SERVICES_FAIL"
export const getAllExternalServices = companyId => ({ type: GET_EXTERNAL_SERVICES, company_id: companyId })

// used for api /company/package/external
export const GET_EXTERNAL_PACKAGES = "GET_EXTERNAL_PACKAGES"
export const GET_EXTERNAL_PACKAGES_SUCCESS = "GET_EXTERNAL_PACKAGES_SUCCESS"
export const GET_EXTERNAL_PACKAGES_FAIL = "GET_EXTERNAL_PACKAGES_FAIL"
export const getAllExternalPackages = companyId => ({ type: GET_EXTERNAL_PACKAGES, company_id: companyId })

const GET_SERVICE_SUCCESS = "GET_SERVICE_SUCCESS"
export const getServiceSuccess = service => ({ type: GET_SERVICE_SUCCESS, service })

const initialServicesState = Map({
  ids: List(),
  prevIds: null,
  isLoading: true, // until a load has been completed at least once, services are loading
  isDeletingService: false,
  isSaving: false,
  error: null,
  selectedServiceid: "0",
  descriptions: {
    all: Map(), // data from /company/service/description/all
    loading: false,
  },
  externalService: {
    all: Map(), // data from /company/service/external,
    loading: false,
  },
  externalPackages: {
    all: Map(), // data from /company/package/external,
    loading: false,
  },
})

export default function servicesReducer(state = initialServicesState, action) {
  switch (action.type) {
    case BULK_INSERT:
      if (action.services) {
        let s = state
        action.services.forEach((srv) => {
          const ids = s.get("ids")
          s = s.merge({
            [srv.id]: parseServiceFromResponseFormat(srv),
            ids: ids.indexOf(srv.id) === -1 ? ids.push(srv.id) : ids,
          })
        })
        return s
      }
      if (action.serviceDescriptions) {
        let s = state
        action.serviceDescriptions.forEach((description) => {
          s = s.setIn(["descriptions", "all", description.id], Map(description))
        })
        return s
      }
      if (action.externalServices) {
        let s = state
        action.externalServices.forEach((externalService) => {
          s = s.setIn(["externalServices", "all", externalService.id], Map(externalService))
        })
        return s
      }

      if (action.externalPackages) {
        let s = state
        action.externalPackages.forEach((externalPackage) => {
          s = s.setIn(["externalPackages", "all", externalPackage.id], Map(externalPackage))
        })
        return s
      }
      return state

    case SERVICES_SET_SELECTED_SERVICE_ID:
      return state.merge({
        selectedServiceId: action.id,
      })
    case SERVICES_SAVE_SERVICE:
      return state.merge({
        isSaving: true,
        error: null,
      })
    case GET_SERVICE_SUCCESS: 
      return state.mergeIn([action.service.id], parseServiceFromResponseFormat(action.service))
    case SERVICES_SAVE_SERVICE_SUCCESS:
      return state.merge({
        isSaving: false,
        error: null,
      })
    case SERVICES_SAVE_SERVICE_FAIL:
      return state.merge({
        isSaving: false,
        error: action.error,
      })
    case SINGLE_SERVICE_START_LOADING: 
      return state.merge({
        isLoading: true,
      })
    case SINGLE_SERVICE_LOADING_SUCCESS: 
      return state.merge({
        isLoading: false,
      })
    case SERVICES_DELETE_SERVICE: {
      const ids = state.get("ids")
      return state.delete(action.id)
        .merge({
          ids: ids.delete(ids.indexOf(action.id)),
        })
        .set("isDeletingService", true)
    }
    case SERVICES_DELETE_SERVICE_SUCCESS: {
      return state.set("isDeletingService", false)
    }
    case SERVICES_DELETE_SERVICE_FAIL:
      return state
        .merge({ error: action.error })
        .set("isDeletingService", false)

    case SERVICES_START_LOADING: {
      const prevIds = state.get("prevIds")
      return state.merge({
        ids: List(),
        prevIds,
        isLoading: true,
      })
    }
    case SERVICES_LOADING_SUCCESS:
      return purge(state)
        .merge({
          isLoading: false,
        })
    case SERVICES_LOADING_FAIL: {
      const prevIds = state.get("prevIds")
      return state.merge({
        ids: prevIds,
        prevIds: null,
        isLoading: false,
        error: action.error,
      })
    }

    case GET_SERVICES_DESCRIPTIONS: {
      return state.setIn(["descriptions", "loading"], true)
    }
    case GET_SERVICES_DESCRIPTIONS_SUCCESS:
    case GET_SERVICES_DESCRIPTIONS_FAIL: {
      return state.setIn(["descriptions", "loading"], false)
    }

    case GET_EXTERNAL_SERVICES: {
      return state.setIn(["externalServices", "loading"], true)
    }
    case GET_EXTERNAL_SERVICES_SUCCESS:
    case GET_EXTERNAL_SERVICES_FAIL: {
      return state.setIn(["externalServices", "loading"], false)
    }
    case AUTH_LOGOUT:
      return initialServicesState
    default:
      return state
  }
}

export const getAllServices = state => state.get("services")

//TODO: Why we do this I don't know.. we should just have 1 array of service object. Not an array of service ID's
// that references a bunch of services, keyed at the same level as the array of ids 🤯
const servicesSelector = state => {
  const listOfIds = state.getIn(["services", "ids"])

  const length = listOfIds.size

  const mapOfServicesFromIds = listOfIds.map(id => state.getIn(["services", id]))

  return mapOfServicesFromIds
}

export const getAllServicesSlugMap = createSelector(
  servicesSelector,
  servicesList => servicesList.reduce((all, current) => all.set(current.get("slug"), current), Map()),
)

export const getServiceError = createSelector(
  getAllServices,
  services => services.get("error"),
)

export const companyServicesSelector = (state) => {
  const companyId = state.getIn(["auth", "authCompanyId"])
  return servicesSelector(state)
    .filter(s => s.get("companyId") === companyId)
}

export const getSelectedServiceId = state => state.getIn(["services", "selectedServiceId"])

export const getSelectedService = state => state.getIn(["services", getSelectedServiceId(state)])

export const getServiceDefaultPrice = (state, serviceId) => {
  const services = getAllServices(state)
  const service = services.get(serviceId)
  if (service && service.has("defaultPrice")) {
    if (Number.isNaN(parseFloat(service.get("defaultPrice"), 10))) {
      return "-"
    }
    const currency = service.has("currency") ? service.getIn(["currency", "symbol"]) : ""
    return `${currency}${parseFloat(service.get("defaultPrice"), 10)}`
  }
  return "-"
}

export const isServicesLoading = createSelector(
  getAllServices,
  services => services.get("isLoading"),
)

export const isServicesSaving = createSelector(
  getAllServices,
  services => services.get("isSaving"),
)

export const isServicesDescriptionsLoading = createSelector(
  getAllServices,
  services => services.getIn(["descriptions", "loading"]),
)

const groupedItemByCategory = items => (
  items.reduce((result, item) => {
    let groupedResult = result
    const categoryId = item.getIn(["category", "id"])
    if (!groupedResult.has(categoryId)) {
      // first item of this category, so we need to create the attribute for it.
      const categoryName = item.getIn(["category", "name"])
      groupedResult = groupedResult.set(categoryId, Map({ label: categoryName, options: Map() }))
    }
    return groupedResult.mergeIn([categoryId, "options", item.get("id")], item)
  }, Map())
)

export const getServiceDescriptions = createSelector(
  getAllServices,
  services => services.getIn(["descriptions", "all"]),
)

export const getServiceDescriptionsGroupedByCategory = createSelector(
  getServiceDescriptions,
  (serviceDescriptions) => {
    if (serviceDescriptions && serviceDescriptions.count() > 0) {
      return groupedItemByCategory(serviceDescriptions)
    }
    return Map()
  },
)

export const getExternalServices = createSelector(
  getAllServices,
  services => services.getIn(["externalServices", "all"]),
)
export const getExternalServicesGroupedByCategory = createSelector(
  getExternalServices,
  (externalServices) => {
    if (externalServices && externalServices.count() > 0) {
      return groupedItemByCategory(externalServices)
    }
    return Map()
  },
)

export const areExternalServicesLoading = createSelector(
  getAllServices,
  services => services.getIn(["externalServices", "loading"]),
)

export const getExternalPackages = createSelector(
  getAllServices,
  services => services.getIn(["externalPackages", "all"]),
)
export const areExternalPackagesLoading = createSelector(
  getAllServices,
  services => services.getIn(["externalPackages", "loading"]),
)

export const isDeletingService = createSelector(
  getAllServices,
  services => services.get("isDeletingService"),
)

export const getAllTypeService = createSelector(
  servicesSelector,
  services => services.filter(service => service.get("externalType") === "service"),
)

export const getAllTypeServiceGroupById = createSelector(
  getAllTypeService,
  services => services.reduce((all, current) => all.set(current.get("id"), current), Map()),
)

/**
 * @returns all services type 'services' for the given company id
 */
export const getCompanyServices = (companyId, state) => getAllTypeServiceGroupById(state).filter(service => service.get("companyId") === companyId)

export const getFormattedPrice = (value, service, company, isDefault) => {
  let symbol
  if (service && service.has("currency")) {
    symbol = service.getIn(["currency", "symbol"])
  } else if (company && company.has("defaultCurrency")) {
    symbol = company.getIn(["defaultCurrency", "symbol"])
  }
  const formattedPrice = `${symbol}${value ? parseFloat(value, 10) : 0}`
  return isDefault ? `${formattedPrice} (default)` : formattedPrice
}

export const parseServiceToRequestFormat = service => ({
  id: service.get("id"),
  visible: service.get("visible") ? 1 : 0,
  company_id: service.get("companyId"),
  default_price: service.get("defaultPrice"),
  duration: service.get("duration"),
  external_category_id: service.get("externalCategoryId"),
  external_id: service.get("externalId"),
  external_name: service.get("externalName"),
  external_type: service.get("externalType"),
  service_description_id: service.get("serviceDescription") ? service.getIn(["serviceDescription", "id"]) : undefined,
  service_package_id: service.get("servicePackageId") ? service.get("servicePackageId").toJS().toString() : undefined,
  ...parseServiceEmployeesToRequestFormat(service),
})

const parseServiceEmployeesToRequestFormat = (service) => {
  const employees = service.has("employees") ? service.get("employees").valueSeq() : null
  let parsedData = {}
  if (employees) {
    parsedData = Object.assign(parsedData, {
      employee_id: employees.map(e => e.get("id")).toJS().toString(),
    })
    const employeesWithPrices = employees.filter(e => e.get("price"))
    if (employeesWithPrices && employeesWithPrices.count() > 0) {
      parsedData = Object.assign(parsedData, {
        pricing_employee_id: employeesWithPrices.map(e => e.get("id")).toJS().toString(),
        pricing_price: employeesWithPrices.map(e => e.get("price")).toJS().toString(),
      })
    }
  }
  return parsedData
}

const parseServiceFromResponseFormat = service => Map({
  id: service.id,
  slug: service.slug,
  visible: service.visible,
  companyId: service.company_id || service.company.id,
  companySlug: service.company_slug || service.company.slug,
  defaultPrice: parseFloat(service.pricing.default.price, 10),
  duration: service.duration,
  externalCategoryId: service.external_category_id,
  externalId: service.external_id,
  externalName: service.external_name,
  externalType: service.external_type,
  serviceDescription: Map({
    id: service.service_description_id || service.description.id,
    name: service.description ? service.description.name : undefined,
    images: service.description ? service.description.images : undefined,
    category: service.description && service.description.category ? service.description.category : undefined
  }),
  currency: service.currency,
  images: service.images,
  hasUpcomingBooking: service.has_upcoming_booking,
  hasPackageParent: service.has_package_parent,
  servicePackageId: service.service_package ? List(service.service_package.map(packageItem => packageItem.child.id)) : undefined, // when external_type is package, this will contain the comma separated ids of the services associated with it. ORDER IN IT MATTERS, will determine position of the service in the package.

  employees: parseServiceEmployeesFromResponseFormat(service),
})

const parseServiceEmployeesFromResponseFormat = (service) => {
  const { employees } = service

  const defaultPrice = service.pricing.default

  let employeesMap = Map()
  if (employees && employees.length > 0) {
    employees.forEach((employee) => {
      const defaultFormattedPrice = getFormattedPrice(defaultPrice.price, Map(service))
      // 1. first assign default price to all employees
      employeesMap = employeesMap.set(
        employee.id,
        Map(employee)
          .set("name", `${employee.first_name}${employee.last_name ? ` ${employee.last_name}` : ""}`)
          .set("formattedPrice", `${defaultFormattedPrice}`),
      )
    })

    if (service.pricing && service.pricing.employees) {
      // 2. we override default price for employees which have their own prices
      service.pricing.employees.forEach(((priceData) => {
        if (priceData.employee_id && employeesMap.has(priceData.employee_id)) {
          employeesMap = employeesMap
            .setIn([priceData.employee_id, "price"], priceData.price)
            .setIn([priceData.employee_id, "formattedPrice"], getFormattedPrice(priceData.price, Map(service)))
        }
      }))
    }
  }

  return employeesMap
}

export const getServiceImage = (service) => {
  if (!service) {
    return null
  }
  const images = service.get("images")
  let galleryImage = images.find(image => image.placement === "gallery")
  if (!galleryImage) {
    const descriptionImages = service.getIn(["serviceDescription", "images"])

    if (descriptionImages) {
      galleryImage = descriptionImages.find(image => image.placement === "gallery")
    }
  }
  return galleryImage?.url
}
