import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"

import { device } from "../../components/Responsive/Responsive"
import { Button, TextInput, TextareaAutosizeRedux } from "../../components/Form"
import { Field, Formik } from "formik"
import BaseTextAreaAutosize from "react-textarea-autosize"
import { commonTextInputCss, ThemedTextInput } from "../../components/Form/TextInput"
import { isApiActive } from "../../redux/modules/api"
import { connect } from "react-redux"

const Row = styled.div`
    display: flex;
    height: auto;
    position: relative;
    margin-bottom: 8px;

    @media ${device.mobile} {
        display: block;
    }
`
const RowLabel = styled.label`
    display: inline-block;
    min-width: 200px;
    font-family: 'Museo-Sans', 'sans-serif';
    font-weight: 300;
    color: #575757;
    font-size: 17px;
    height: 100%;
    vertical-align: top;
    margin-top: 8px;

    @media ${device.mobile} {
        display: block;
        margin-top: 0px;
        margin-bottom: 10px;
    }
`

const commonFieldCss = css`
    flex: 1;

    @media ${device.mobile} {
        width: 100%;
    }
`

const TextField = styled(TextInput)`
    ${commonFieldCss}
`

const TextAreaField = styled(BaseTextAreaAutosize)`
    ${commonTextInputCss}
    ${commonFieldCss}
`

const SettingsProfileFormParent = styled.div`
    position: relative;
    padding: 0;
`

const SettingsProfileSaveButton = styled(Button)`
    margin-top: 22px;  // Standard 30px margin minus 8px bottom margin on field above

    @media ${device.mobile} {
        width: 100%;
    }
`

const SettingsProfileForm = ({ initialValues, onSubmit, className = null, isApiActive }) => {

  /**
   * Clean up undefined initial values
   */
  initialValues = {
    ...initialValues,
    ...{
      instagramUrl: initialValues.instagramUrl ?? '',
      facebookUrl: initialValues.facebookUrl ?? '',
      websiteUrl: initialValues.websiteUrl ?? '',
    }
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
    {({ handleSubmit, isSubmitting }) => (
      <div className={className}>
        <SettingsProfileFormParent>
          <Row>
            <RowLabel>Name</RowLabel>
            <Field
              as={TextField}
              name="name"
              type="text"
            />
          </Row>
          <Row>
            <RowLabel>Description</RowLabel>
            <Field
              as={TextAreaField}
              name="description"
              className={className}
            />
          </Row>
          <Row>
            <RowLabel>Address one</RowLabel>
            <Field
              as={TextField}
              name="addressOne"
              type="text"
            />
          </Row>
          <Row>
            <RowLabel>Address two</RowLabel>
            <Field
              as={TextField}
              name="addressTwo"
              type="text"
            />
          </Row>
          <Row style={{ marginBottom: 30 }}>
            <RowLabel>Post code</RowLabel>
            <Field
              as={TextField}
              style={{ maxWidth: "120px" }}
              name="postCode"
              type="text"
            />
          </Row>
          <Row>
            <RowLabel>Phone number</RowLabel>
            <Field
              as={TextField}
              style={{ maxWidth: "120px" }}
              name="phoneNumber"
              type="text"
            />
          </Row>
          <Row style={{ marginBottom: 30 }}>
            <RowLabel>Email address</RowLabel>
            <Field
              as={TextField}
              name="emailAddress"
              type="text"
            />
          </Row>
          <Row>
            <RowLabel>Website URL</RowLabel>
            <Field
              as={TextField}
              name="websiteUrl"
              type="text"
            />
          </Row>
          <Row>
            <RowLabel>Facebook URL</RowLabel>
            <Field
              as={TextField}
              name="facebookUrl"
              type="text"
            />
          </Row>
          <Row>
            <RowLabel>Instagram URL</RowLabel>
            <Field
              as={TextField}
              name="instagramUrl"
              type="text"
            />
          </Row>
          <div style={{ textAlign: "right" }}>
            <SettingsProfileSaveButton onClick={handleSubmit} type="submit" disabled={isSubmitting || isApiActive}>Save</SettingsProfileSaveButton>
          </div>
        </SettingsProfileFormParent>
      </div>
    )}
    </Formik>
  )
}

SettingsProfileForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  className: PropTypes.string,
  isApiActive: PropTypes.bool.isRequired
}

const mapStateToProps = (state) => ({
  isApiActive: isApiActive(state),
})

export default connect(mapStateToProps)(SettingsProfileForm)
