import React from "react"
import { applyMiddleware, compose, createStore } from "redux"
import { Provider as ReduxProvider } from "react-redux"
import createSagaMiddleware from "redux-saga"
import { combineReducers } from "redux-immutable"
import * as reducers from "../redux/modules"
import { createResponsiveStateReducer, responsiveStoreEnhancer } from "redux-responsive"
import { Map } from "immutable"
import thunk from "redux-thunk"
import rootSaga from "../sagas/sagas"

// immutable state
const initialState = Map()

// sagas
const sagaMiddleware = createSagaMiddleware()

// redux
// #const middlewares = [thunk, sagaMiddleware, companySelectorMiddleware]
const middlewares = [thunk, sagaMiddleware]

// redux responsive
const browserReducer = createResponsiveStateReducer({
  mobile: 767,
  tablet: 768,
  desktop: 2560,

  extraSmall: 480,
  small: 768,
  medium: 992,
  large: 1200,
})

export const store = createStore(combineReducers({
  ...reducers,
  browser: browserReducer,
}), initialState, compose(
  responsiveStoreEnhancer,
  applyMiddleware(...middlewares),
  window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true }) : f => f,
))

sagaMiddleware.run(rootSaga)

export default ({ children }) => <ReduxProvider store={store}>{children}</ReduxProvider>
