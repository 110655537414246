import React from "react"
import InputError from "./InputError"
import styled from "styled-components"
import PropTypes from "prop-types"

const RadioInputHidden = styled.input`
  display: none;
`

const RadioInputLabel = styled.label`
    color: ${({ theme }) => theme.labelDarkColor};
    border: solid 1px ${({ theme }) => theme.labelDarkColor};
    border-radius: ${({ theme }) => (theme.buttonBorderRadius || "25px")};
    padding: 12px 20px;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.5px;
    min-width: 80px;
    text-align: center;
    cursor: pointer;
    width: inherit !important;
    
    :has(input[type="radio"]:checked) {
        background: ${({ theme }) => theme.borderColor};
        border: none;
    }
`

const RadioButton = ({ field, form: { errors }, label, ...props }) => {
  return (
    <>
      <RadioInputLabel>
        <RadioInputHidden type="radio" {...field} {...props} />
        {label}
      </RadioInputLabel>
      {errors[field.name] && <div><InputError>{errors[field.name]}</InputError></div>}
    </>
  )
}

RadioButton.propTypes = {
  error: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
}

export default RadioButton