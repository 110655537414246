/* eslint-disable import/no-unresolved,import/no-webpack-loader-syntax,react/prefer-stateless-function,react/no-multi-comp */
import styled from "styled-components"
import Availabilities from "../../../svg/availabilities.svg"
import Booking from "../../../svg/booking.svg"
import Completed from "../../../svg/completed.svg"
import Success from "../../../svg/success.svg"
import Services from "../../../svg/services.svg"
import ListTodo from "../../../svg/list-todo.svg"
import ListDone from "../../../svg/list-done.svg"
import Comment from "../../../svg/comment.svg"
import Trash from "../../../svg/trash.svg"
import Edit from "../../../svg/edit.svg"
import Calendar from "../../../svg/calendar.svg"
import ArrowLeft from "../../../svg/arrow-left.svg"
import ArrowRight from "../../../svg/arrow-right.svg"
import Chevron from "../../../svg/chevron.svg"
import StarPink from "../../../svg/star_pink.svg"
import StarGray from "../../../svg/star_gray.svg"
import Refresh from "../../../svg/refresh.svg"
import Download from "../../../svg/download.svg"
import Copy from "../../../svg/copy.svg"
import Question from "../../../svg/question.svg"
import Gear from "../../../svg/gear.svg"
import Layers from "../../../svg/layers.svg"
import Info from "../../../svg/info.svg"
import Cuppa from "../../../svg/cuppa.svg"
import TrendU from "../../../svg/trend-up.svg"
import TrendD from "../../../svg/trend-down.svg"
import TrendF from "../../../svg/trend-flat.svg"
import Puzzle from "../../../svg/puzzle.svg"
import Close from "../../../svg/close.svg"
import Mosaic from "../../../svg/mosaic.svg"
import List from "../../../svg/list.svg"
import Pencil from "../../../svg/pencil.svg"
import Search from "../../../svg/search.svg"

export const TrendUpIcon = styled(TrendU)`
  width: 18px;
  height: 11px;
`
export const TrendDownIcon = styled(TrendD)`
  width: 18px;
  height: 11px;
`
export const TrendFlatIcon = styled(TrendF)`
  width: 16px;
  height: 16px;
`

export const InfoIcon = styled(Info)`
  width: 20px;
  height: 20px;
  fill: ${({ theme }) => theme.borderColor};
`

export const GearIcon = styled(Gear)`
  width: 20px;
  height: 20px;
  fill: ${({ theme }) => theme.primaryColor};
`

export const QuestionIcon = styled(Question)`
  width: 20px;
  height: 20px;
`

export const CopyIcon = styled(Copy)`
  width: 20px;
  height: 20px;
`
export const PinkStar = styled(StarPink)`
  width: 12px;
  height: 12px;
`
export const GrayStar = styled(StarGray)`
  width: 12px;
  height: 12px;
`
export const RefreshIcon = styled(Refresh)`
  width: 24px;
  height: 24px;
  fill: ${props => (props.selected ? "#f067a6" : "#f067a6")};
`
export const ChevronIcon = styled(Chevron)`
  width: 19px;
  height: 19px;
  fill: #ef609f;
  transform: rotate(90deg);
`
export const ArrowLeftIcon = styled(ArrowLeft)`
  width: 21px;
  height: 24px;
  fill: #ef609f;
`
export const ArrowRightIcon = styled(ArrowRight)`
  width: 21px;
  height: 24px;
  fill: #ef609f;
`
export const CalendarIcon = styled(Calendar)`
  width: 24px;
  height: 24px;
  fill: #ef609f;
`
export const AvailabilitiesIcon = styled(Availabilities)`
  width: 20px;
  height: 20px;
  fill: #ffffff;
`
export const TrashIcon = styled(Trash)`
  width: 30px;
  height: 30px;
  &:hover {
    circle {
      fill: ${({ theme }) => theme.labelDarkColor};
    }
    g {
      stroke: ${({ theme }) => theme.primaryContrastColor};
    }
  }
`
export const BookingIcon = styled(Booking)`
  width: 20px;
  height: 20px;
  fill: #f067a6;
`
export const CompletedIcon = styled(Completed)`
  width: 20px;
  height: 20px;
  fill: #ffffff;
`
export const SuccessIcon = styled(Success)`
  width: 20px;
  height: 20px;
`
export const EditIcon = styled(Edit)`
  width: 30px;
  height: 30px;
  &:hover {
    circle {
      fill: ${({ theme }) => theme.labelDarkColor};
    }
    g {
      stroke: ${({ theme }) => theme.primaryContrastColor};
    }
  }
`
export const ServicesIcon = styled(Services)`
  width: 20px;
  height: 20px;
  fill: #ffffff;
`

export const ResponseIcon = styled(Response)`
  width: 14px;
  height: 14px;
  fill: #868686;
`
export const ListTodoIcon = styled(ListTodo)`
  width: 20px;
  height: 20px;
  fill: #ffffff;
`
export const ListDoneIcon = styled(ListDone)`
  width: 20px;
  height: 20px;
  fill: #ffffff;
`
export const CommentIcon = styled(Comment)`
  width: 24px;
  height: 24px;
  fill: #000000;
`
export const DownloadIcon = styled(Download)`
  width: 20px;
  height: 20px;
  fill: #868686;
`

export const LayersIcon = styled(Layers)`
  width: 30px;
  height: 30px;
  fill: #868686;
`

export const CupOfTeaIcon = styled(Cuppa)`
  width: 39px;
  height: 24px;
`

export const MosaicIcon = styled(Mosaic)`
  width: 15px;
  height: 15px;
`

export const ListIcon = styled(List)`
  height: 15px;
`

export const PencilIcon = styled(Pencil)`
  width: 40px;
  height: 40px;
`

export const SearchIcon = styled(Search)`
  width: 20px;
  height: 20px;
`

export const PuzzleIcon = styled(Puzzle)`
  width: 40px;
  height: 40px;
`

export const CloseIcon = styled(Close)`
  width: 20px;
  height: 20px;
`
