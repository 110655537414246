import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"


const LabelLink = styled(Link)`
  display: flex;
  flex-direction: row;
  text-decoration: none;
  align-items: center;
  justify-content: space-between;
  color: ${({ $active }) => ($active ? "#fff" : "#848484")};
  outline: 0;
  margin-bottom: 25px;
  flex: 1;
  cursor: pointer;
  font-weight: normal;
  font-size: 16px;
  font-family: museo-sans, sans-serif;
  opacity: ${({ $active }) => ($active ? 1 : 0.8)};
  &:hover {
    opacity: 1;
  }
  >span {
    border-bottom: ${({ theme, $active }) => ($active ? `solid 1.3px ${theme.primaryColor}` : "none")};
  }
`

const NotificationBadge = styled.div`
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ef609f;
  text-align: right;
`


const MenuItem = ({
  id, label, className = '', menuActive = undefined, hasNotification = false, ...rest
}) =>
  <LabelLink
    id={id}
    role="button"
    tabIndex={0}
    $active={menuActive === id}
    className={className}
    { ...rest }
  >
    <span>{label}</span>
    {hasNotification ? <NotificationBadge /> : null}
  </LabelLink>

MenuItem.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  menuActive: PropTypes.string,
  hasNotification: PropTypes.bool,
}

export default MenuItem
