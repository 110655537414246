import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Link } from "react-router-dom"

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.headerLinkColor} !important;
  &:hover {
    color: #ef609f;
  }
  h4 {
    font-size: 17px;
    font-weight: 400;
  }
`

const getMarkup = (size, content) => {
  let markup
  switch (size) {
    case "h1":
      markup = <h1>{content}</h1>
      break
    case "h2":
      markup = <h2>{content}</h2>
      break
    case "h3":
      markup = <h3>{content}</h3>
      break
    default:
    case "h4":
      markup = <h4 style={{ fontWeight: 300 }}>{content}</h4>
  }
  return markup
}

const commonProps = {
  topic: PropTypes.object.isRequired,
  size: PropTypes.oneOf(["h1", "h2", "h3", "h4"]),
}

export const QuestionLink = ({ faq, topic, size }) => faq.data ?
  <StyledLink to={`/help/faqs/${topic.slug}/${faq.slug}`}>
    {getMarkup(size, faq.data.question)}
  </StyledLink>
  : null

QuestionLink.propTypes = {
  ...commonProps,
  faq: PropTypes.object.isRequired,
}

export const TopicLink = ({ topic, suffix = '', size }) =>
  <StyledLink to={`/help/faqs/${topic.slug}`}>
    { getMarkup(size, `${topic.label}${suffix}`) }
  </StyledLink>

TopicLink.propTypes = {
  ...commonProps,
  suffix: PropTypes.string, // sometimes we need to add 'FAQs' at the end of the title
}
