import React from "react"
import PropTypes from "prop-types"
import ImmutablePropTypes from "react-immutable-proptypes"
import { PrismicRichText } from '@prismicio/react' 

import styled from "styled-components"

const ChapterParent = styled.div`
  a {
    color: #ef609f;
  }
`

const Chapter = ({ title, items }) => (
  <ChapterParent>
    <PrismicRichText field={title.toJS()} />
    {items.map((item, i) => (
      <PrismicRichText key={i} field={item.get("content").toJS()} />
    ))}
  </ChapterParent>
)

Chapter.propTypes = {
  title: ImmutablePropTypes.list.isRequired,
  items: ImmutablePropTypes.list.isRequired,
}

export default Chapter
