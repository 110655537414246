import React from "react"

import { SIMPLE_PAGE } from "../config/constants"
import { MasterContainer } from "../containers"
import SimplePage from "../containers/SimplePage"
import prismicClient from "../prismicClient"

const getSimplePageRoutes = async () => {
  const simplePages = await prismicClient.getAllByType(SIMPLE_PAGE);

  if (simplePages && simplePages.length > 0) {
    return simplePages.filter(simplePage => simplePage.data.page_url)
      .map(simplePage => ({
          path: `/${simplePage.data.page_url}`,
          element: <MasterContainer isPublic><SimplePage data={simplePage.data} /></MasterContainer>
        })
      )
  }

  return []
}

export default async () => ([
  ...await getSimplePageRoutes()
])