import React from "react"
import ImmutablePropTypes from "react-immutable-proptypes"
import PropTypes from "prop-types"
import Select, { components } from "react-select"
import styled, { withTheme } from "styled-components"

import {
  LayersIcon,
} from "../Icons/Icons"

import { device } from "../Responsive/Responsive"
import CompanyAvatar from "./CompanyAvatar"
import { DownChevron, reactSelectStyleUtils } from "../Form/SelectBox/SelectUtils"

const CompanySelectWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    border: solid 1px ${({ theme }) => theme.borderColor};
    border-radius: 25px;
    padding: 1px;
    max-height: 48px;
    input {
        line-height: 25px;
    }
    @media ${device.mobile} {
        width: 100%;
        flex: 1;
    }
`

const ResponsiveSelect = styled(Select)`
    @media ${device.mobile} {
        width: 100%;
        flex: 1;
    }
    @media ${device.tablet}, ${device.desktop}  {
        width: 250px;
    }

    border: none;
    outline: 0;
    font-weight: 500;
    font-size: 16px;
    color: #575757;
    margin-left: 6px;
`

const CompanyAddress = styled.div`
    position: absolute;
    font-family: 'museo-sans',sans-serif;
    line-height: 1;
    font-size: 15px;
    font-weight: 300;
    color: #868686;
    height: 18px;

    @media ${device.tablet}, ${device.desktop}  {
        overflow: hidden;
        max-width: 200px;
    }
`

const LogoCompanyAll = styled.div`
    border-radius: 100%;
    border: 2px solid #ececec;
    width: 30px;
    height: 30px;
    padding: 5px;
`


const CompanySelect = ({ companies, selectedCompany = null, onCompanySelected, theme }) => {

  let options = []

  if (companies.length > 1) {
    // Only if user is admin of more than 1 company we add the option to select all companies.
    options = [{ value: "0", label: "All Salons" }]
  }

  options = options.concat(companies.map(company => ({
    value: company.id,
    label: company.name,
  })))

  const SingleValue = ({ children, ...rest }) => (
    <components.SingleValue {...rest}>
      {children}
      {selectedCompany && <CompanyAddress>{selectedCompany.get("addressOne")}</CompanyAddress>}
    </components.SingleValue>
  )
  SingleValue.propTypes = { children: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired }

  const selectedOption = options.filter(({ value }) => value === (selectedCompany ? selectedCompany.get("id") : 0))

  return (
    <CompanySelectWrapper>
      {selectedCompany && <CompanyAvatar size={40} alt="" logoUrl={selectedCompany.get("logoUrl")} />}

      {!selectedCompany && <LogoCompanyAll><LayersIcon /></LogoCompanyAll>}

      <ResponsiveSelect
        className="CompanySelectToolbar"
        options={options}
        autosize
        defaultValue={selectedCompany ? selectedOption : [options[0]]}
        clearable={false}
        placeholder="All Salons"
        styles={{
          ...reactSelectStyleUtils(theme),
          singleValue: base => ({
            ...base,
            height: "57px",
            lineHeight: "20px",
            paddingTop: "10px",
          }),
        }}
        onChange={onCompanySelected}
        value={selectedOption}
        components={{ SingleValue, DropdownIndicator: DownChevron }}
      />


    </CompanySelectWrapper>
  )
}

CompanySelect.propTypes = {
  onCompanySelected: PropTypes.func.isRequired,
  companies: PropTypes.array.isRequired,
  selectedCompany: ImmutablePropTypes.map,
  theme: PropTypes.object.isRequired,
}

export default withTheme(CompanySelect)
