import { List, Map } from "immutable"
import moment from "moment-timezone"
import { createSelector } from "reselect"

import { BULK_INSERT } from "./helpers"
import { PAGINATION_CHANGE_PAGE, getPagination } from "./pagination"
import { REFRESH_BOOKINGS } from "../../config/constants"

const BOOKINGS_REPLACE_ITEM = "BOOKINGS_REPLACE_ITEM"
const BOOKINGS_START_LOADING = "BOOKINGS_START_LOADING"
export const BOOKINGS_LOADING_SUCCESS = "BOOKINGS_LOADING_SUCCESS"
const BOOKINGS_LOADING_FAIL = "BOOKINGS_LOADING_FAIL"

export const BOOKINGS_REDEEM_BOOKING = "BOOKINGS_REDEEM_BOOKING"
const BOOKINGS_REDEEM_BOOKING_START = "BOOKINGS_REDEEM_BOOKING_START"
const BOOKINGS_REDEEM_BOOKING_SUCCESS = "BOOKINGS_REDEEM_BOOKING_SUCCESS"
const BOOKINGS_REDEEM_BOOKING_FAIL = "BOOKINGS_REDEEM_BOOKING_FAIL"
export const BOOKINGS_RESCHEDULE_BOOKING = "BOOKINGS_RESCHEDULE_BOOKING"
const BOOKINGS_RESCHEDULE_BOOKING_START = "BOOKINGS_RESCHEDULE_BOOKING_START"
const BOOKINGS_RESCHEDULE_BOOKING_SUCCESS = "BOOKINGS_RESCHEDULE_BOOKING_SUCCESS"
const BOOKINGS_RESCHEDULE_BOOKING_FAIL = "BOOKINGS_RESCHEDULE_BOOKING_FAIL"
export const BOOKINGS_CANCEL_BOOKING = "BOOKINGS_CANCEL_BOOKING"
const BOOKINGS_CANCEL_BOOKING_START = "BOOKINGS_CANCEL_BOOKING_START"
const BOOKINGS_CANCEL_BOOKING_SUCCESS = "BOOKINGS_CANCEL_BOOKING_SUCCESS"
const BOOKINGS_CANCEL_BOOKING_FAIL = "BOOKINGS_CANCEL_BOOKING_FAIL"
const BOOKINGS_SET_SELECTED_BOOKING_ID = "BOOKINGS_SET_SELECTED_BOOKING_ID"
const BOOKINGS_LOCAL_SAVE_SELECTED_BOOKING = "BOOKINGS_LOCAL_SAVE_SELECTED_BOOKING"
const BOOKINGS_LOCAL_RESCHEDULE_SELECTED_BOOKING = "BOOKINGS_LOCAL_RESCHEDULE_SELECTED_BOOKING"
const BOOKINGS_LOCAL_REDEEM_SELECTED_BOOKING = "BOOKINGS_LOCAL_REDEEM_SELECTED_BOOKING"
const BOOKINGS_LOCAL_CANCEL_SELECTED_BOOKING = "BOOKINGS_LOCAL_CANCEL_SELECTED_BOOKING"

export const BOOKING_CANCELLED_TYPE_AVAILABLE = 0
export const BOOKING_CANCELLED_TYPE_NOSHOW = 1
export const BOOKING_CANCELLED_TYPE_USER = 2
export const BOOKING_CANCELLED_TYPE_COMPANY = 3
export const BOOKING_HISTORY_TYPE_PENDING = 0;
export const BOOKING_HISTORY_TYPE_COMPLETE = 1

export const bookingsLocalSaveSelectedBooking = () => ({
  type: BOOKINGS_LOCAL_SAVE_SELECTED_BOOKING,
})
export const bookingsLocalCancelSelectedBooking = () => ({
  type: BOOKINGS_LOCAL_CANCEL_SELECTED_BOOKING,
})
export const bookingsLocalRescheduleSelectedBooking = appointmentTime => ({
  type: BOOKINGS_LOCAL_RESCHEDULE_SELECTED_BOOKING,
  appointmentTime,
})
export const bookingsSetSelectedBookingId = id => ({
  type: BOOKINGS_SET_SELECTED_BOOKING_ID,
  id,
})

/*
* Fetch all bookings with pagination.
* history: history=1 all complete bookings. history=0 all upcoming bookings.
* limit: Amount of bookings to return
* offset: Bookings to skip (for pagination purposes)
*/
export const refreshBookings = (history, company, user, limit, offset, page) => ({
  type: REFRESH_BOOKINGS,
  payload: {
    history, company, user, limit, offset, page
  },
})
export const bookingsLoadingFail = error => ({
  type: BOOKINGS_LOADING_FAIL,
  payload: { error },
})
export const bookingsLoadingSuccess = (bookingsArray, page, count, limit) => ({
  type: BOOKINGS_LOADING_SUCCESS,
  payload: {
    bookingsArray, page, count, limit
  },
})


export function bookingsCancelBooking(id, cancelledType) {
  return {
    type: BOOKINGS_CANCEL_BOOKING,
    id,
    cancelledType
  }
}

export function bookingsCancelBookingStart() {
  return {
    type: BOOKINGS_CANCEL_BOOKING_START,
  }
}

export function bookingsCancelBookingSuccess() {
  return {
    type: BOOKINGS_CANCEL_BOOKING_SUCCESS,
  }
}

export function bookingsCancelBookingFail(error) {
  return {
    type: BOOKINGS_CANCEL_BOOKING_FAIL,
    error,
  }
}


export function bookingsRescheduleBooking(id, appointmentTime, companyTimezone, employeeId) {
  return {
    type: BOOKINGS_RESCHEDULE_BOOKING,
    id,
    appointmentTime,
    companyTimezone,
    employeeId,
  }
}

export function bookingsRescheduleBookingStart() {
  return {
    type: BOOKINGS_RESCHEDULE_BOOKING_START,
  }
}

export function bookingsRescheduleBookingSuccess({ id: bookingId, appointmentTime, employeeId }) {
  return {
    type: BOOKINGS_RESCHEDULE_BOOKING_SUCCESS,
    bookingId,
    appointmentTime,
    employeeId
  }
}

export function bookingsRescheduleBookingFail(error) {
  return {
    type: BOOKINGS_RESCHEDULE_BOOKING_FAIL,
    error,
  }
}

export function bookingsStartLoading() {
  return {
    type: BOOKINGS_START_LOADING,
  }
}

function getBookingType(values) {
  if (!values.saved) {
    return "New"
  }

  if (values.cancelledType === BOOKING_CANCELLED_TYPE_NOSHOW) {
    return "No Show"
  }

  if (values.cancelledType === BOOKING_CANCELLED_TYPE_USER || values.cancelledType === BOOKING_CANCELLED_TYPE_COMPANY) {
    return "Cancelled"
  }

  // Implied that the only cancelledType left is BOOKING_CANCELLED_TYPE_AVAILABLE from here
  if (moment(values.appointmentTime).isBefore()) {
    return "Complete"
  }

  return "Upcoming"
}

function getBookingTodo(values) {
  if (!values.saved) {
    return true
  }

  if (values.cancelledType === BOOKING_CANCELLED_TYPE_AVAILABLE && moment(values.appointmentTime).isBefore()) {
    return true
  }

  return false
}

const initialBookingState = Map({
  id: 0,
  userId: 0,
  companyId: 0,
  responseId: 0,
  serviceId: 0,
  employeeId: 0,
  price: 0,
  appointmentTime: Date.now(),
  cancelledType: BOOKING_CANCELLED_TYPE_AVAILABLE,
  saved: false,
  productIds: [],
  employeeIds: [],
  notes: null,
  pages: {},
  type: null,
  todo: false,
  currency: null,
})

function booking(state = initialBookingState, action) {
  switch (action.type) {
    case BOOKINGS_LOCAL_RESCHEDULE_SELECTED_BOOKING:
      return state.merge({
        appointmentTime: action.appointmentTime,
        saved: true,
      })
    case BOOKINGS_LOCAL_CANCEL_SELECTED_BOOKING:
      return state.merge({
        cancelledType: BOOKING_CANCELLED_TYPE_COMPANY,
      })
    case BOOKINGS_LOCAL_SAVE_SELECTED_BOOKING:
      return state.merge({
        saved: true,
      })
    case BOOKINGS_REPLACE_ITEM: {
      return state.merge({
        id: action.id,
        userId: action.userId,
        companyId: action.companyId,
        responseId: action.responseId,
        serviceId: action.serviceId,
        employeeId: action.employeeId,
        appointmentTime: action.appointmentTime,
        cancelledType: action.cancelledType,
        saved: action.saved,
        productIds: action.productIds,
        employeeIds: action.employeeIds,
        notes: action.notes,
        price: action.price,
        currency: action.currency,
        type: getBookingType(action),
        todo: getBookingTodo(action),
      })
    }
    default:
      return state
  }
}

function bookingsReplaceBooking(bookng) {
  const productIds = []

  if (bookng.products) {
    for (let i = 0; i < bookng.products.length; i += 1) {
      productIds.push(bookng.products[i].product.id)
    }
  }

  const employeeIds = []

  if (bookng.service.employees) {
    for (let i = 0; i < bookng.service.employees.length; i += 1) {
      employeeIds.push(bookng.service.employees[i].id)
    }
  }

  return {
    type: BOOKINGS_REPLACE_ITEM,
    id: bookng.id,
    userId: bookng.user_profile.id,
    companyId: bookng.company.id,
    responseId: bookng.response ? bookng.response.id : 0,
    serviceId: bookng.service.id,
    employeeId: (bookng.employee) ? bookng.employee.id : 0,
    appointmentTime: Date.parse(bookng.appointment_time),
    cancelledType: bookng.cancelled_type,
    saved: bookng.appointment_saved,
    productIds,
    employeeIds,
    notes: bookng.notes,
    currency: bookng.order.currency,
    price: parseFloat(bookng.price_paid),
  }
}

const initialBookingsState = Map({
  ids: List(),
  prevIds: null,
  isLoading: true,
  isSaving: false,
  isRescheduling: false,
  isCancelling: false,
  error: null,
  selectedBookingId: null,
  notes: null,
  pages: Map(),
  currentPage: 0,
})

export default function bookings(state = initialBookingsState, action) {
  switch (action.type) {
    case BULK_INSERT:
      if (action.bookings) {
        let s = state
        action.bookings.forEach((b) => {
          const ids = s.get("ids")
          s = s.merge({
            [b.id]: booking(s.get(b.id), bookingsReplaceBooking(b)),
            ids: ids.indexOf(b.id) === -1 ? ids.push(b.id) : ids,
          })
        })
        return s
      }
      return state

    case BOOKINGS_LOCAL_RESCHEDULE_SELECTED_BOOKING:
    case BOOKINGS_LOCAL_CANCEL_SELECTED_BOOKING:
    case BOOKINGS_LOCAL_SAVE_SELECTED_BOOKING: {
      const selectedBookingId = state.get("selectedBookingId")
      if (!selectedBookingId) {
        return state
      }
      const b = state.get(selectedBookingId)
      return state.merge({
        [selectedBookingId]: booking(b, action),
      })
    }
    case BOOKINGS_SET_SELECTED_BOOKING_ID:
      return state.merge({
        selectedBookingId: action.id,
      })
    case BOOKINGS_REPLACE_ITEM: {
      const ids = state.get("ids")
      const b = state.get(action.id)
      return state.merge({
        [action.id]: booking(b, action),
        ids: ids.push(action.id),
      })
    }
    /* case BOOKINGS_START_LOADING: {
            const prevIds = state.get('prevIds')
            return state.merge({
                ids: List(),
                prevIds,
                isLoading: true,
            })
        }
        case BOOKINGS_LOADING_SUCCESS:
            return purge(state)
                .merge({
                    isLoading: false,
                })
        case BOOKINGS_LOADING_FAIL: {
            const prevIds = state.get('prevIds')
            return state.merge({
                ids: prevIds,
                prevIds: null,
                isLoading: false,
                error: action.error,
            })
        } */
    case BOOKINGS_CANCEL_BOOKING_START:
      return state.merge({
        isCancelling: true,
      })
    case BOOKINGS_CANCEL_BOOKING_SUCCESS:
      return state.merge({
        isCancelling: false,
      })
    case BOOKINGS_CANCEL_BOOKING_FAIL:
      return state.merge({
        isCancelling: false,
        error: action.error,
      })
    case BOOKINGS_RESCHEDULE_BOOKING_START:
      return state.merge({
        isRescheduling: true,
      })
    case BOOKINGS_RESCHEDULE_BOOKING_SUCCESS:
      return state.merge({
        isRescheduling: false,
        [action.bookingId]: state.get(action.bookingId).merge({
          employeeId: parseInt(action.employeeId) === 0 ? 0 : String(action.employeeId),
          appointmentTime: action.appointmentTime,
        }),
      })
    case BOOKINGS_RESCHEDULE_BOOKING_FAIL:
      return state.merge({
        isRescheduling: false,
        error: action.error,
      })
    case PAGINATION_CHANGE_PAGE: {
      return state.merge({
        isLoading: true,
      })
    }
    case BOOKINGS_START_LOADING: {
      return state.merge({
        isLoading: true,
      })
    }
    case BOOKINGS_LOADING_FAIL: {
      return state.merge({
        isLoading: false,
        error: action.error,
      })
    }
    case BOOKINGS_LOADING_SUCCESS: {
      const { page, bookingsArray } = action.payload
      return state.setIn(["pages", page], List(bookingsArray.map(b => b.id))).merge({
        isLoading: false,
      })
    }
    default:
      return state
  }
}

// selectors
const getBookingRoot = state => state.get("bookings")

const bookingsSelector = state => state.getIn(["bookings", "ids"])
  .map(id => state.getIn(["bookings", id]))

/* Bookings where the appointment time is in the future */
export const newCompanyUpcomingBookingsSelector = createSelector([bookingsSelector], bookngs => bookngs.filter(b => (moment(b.get("appointmentTime")).isAfter() && b.get("cancelledType") === BOOKING_CANCELLED_TYPE_AVAILABLE)))

/* Bookings where the appointment time is in the past */
export const newCompanyCompletedBookingsSelector = createSelector([bookingsSelector], bookngs => bookngs.filter(b => (moment(b.get("appointmentTime")).isBefore() && b.get("cancelledType") === BOOKING_CANCELLED_TYPE_AVAILABLE)))

export const getSelectedBookingId = state => state.getIn(["bookings", "selectedBookingId"])

export const getSelectedBooking = state => state.getIn(["bookings", getSelectedBookingId(state)])

export const getAllPagesFetched = createSelector(
  getBookingRoot,
  root => root.get("pages"),
)


export const getBookingsLoading = state => state.getIn(["bookings", "isLoading"])

export const getCurrentPageBookings = createSelector(
  [
    getBookingRoot,
    (state) => getPagination(state),
    getAllPagesFetched
  ],
  (root, pagination, pagesFetched) => {
    const currentPage = pagination.has("bookings") ? pagination.getIn(["bookings", "currentPage"]) : 0
    return (
      pagesFetched.has(currentPage)
        ? pagesFetched.get(currentPage).map(bookingId => root.get(bookingId)).filter(b => b)
        : List()
    )
  },
)
