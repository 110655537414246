import React from "react"
import {SuccessIcon} from "../Icons/Icons"
import PropTypes from "prop-types"

const ToastMessage = ({ message }) => {
  return (
    <div style={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      height: "100%",
    }}
    >
      <SuccessIcon
        style={{
          marginLeft: "24px",
          width: "2.5em",
          height: "2.5em",
        }}
      />
      <div
        style={{
          fontFamily: "Museo-Sans",
          fontWeight: "500",
          fontSize: "1.2em",
          marginLeft: "14px",
        }}
      >
        {message}
      </div>
    </div>
  )
}

ToastMessage.propTypes = {
  message: PropTypes.string.isRequired,
}

export default ToastMessage