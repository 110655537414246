import React, { useEffect } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import ImmutablePropTypes from "react-immutable-proptypes"
import styled from "styled-components"

import Stats from "../../components/Stats/Stats"
import {
  getStatistics,
  getStatisticsCurrency,
  statisticsRefresh,
} from "../../redux/modules/statistics"
import { getSelectedCompanyId } from "../../redux/modules/auth"

import { device } from "../../components/Responsive/Responsive"
import StatsForm from "../../forms/Stats/StatsForm"

import { DEFAULT_LOCALE } from "../../config/constants"

import { STATISTICS_KEY_FRANCHISE } from "../../redux/modules/statistics"

const StatsBox = styled.div`
    background-color: #fff;
    border: 1px solid #ececec;
    @media ${device.mobile} {
        margin-left: 0px;
        margin-right: 0px;
    }
`

const StatsData = styled.div`

    display: flex;
    flex-wrap: wrap;

    @media ${device.mobile} {
        padding-left: 20px;

        & > div {
            width: 50%;
            text-align: left;
        }
    }

    @media ${device.tablet}, ${device.desktop} {



        padding: 0px 20px 40px 20px;

        /* 5 columns on first row */
        & > div {
            width: 20%;
            text-align: center;
        }

        & > div > div > div:first-child {
            font-size: 48px;
        }

        /* 6 columns on all subsequent rows */
        & > div + div + div + div + div + div {
            width: 16%;
            margin-top: 40px;
        }

        & > div + div + div + div + div + div > div > div:first-child {
            font-size: 36px;
        }
    }


`


const StatsContainer  = ({ browser,
                           statistics = undefined,
                           selectedCompanyId = null,
                           statisticsRefresh, type, currency,
                           title
                         }) => {
  useEffect(() => {
    statisticsRefresh(type, null, selectedCompanyId)
  }, [])

  const getRepeatBookingsStatistics = () => {
    const values = {
      total: "0",
      change: "N/C",
      trend: "flat",
    }

    const bookings = statistics.get("bookings")
    const bookingsRepeat = statistics.get("bookings_repeat")

    let percent = bookingsRepeat.current / bookings.current * 100
    const change = bookingsRepeat.current - bookingsRepeat.prev

    if (Number.isNaN(percent)) {
      percent = 0
    }

    values.total = `${Math.round(percent)}%`

    if (change !== 0) {
      let changePercent = Math.round(change / bookingsRepeat.prev * 100)

      if (changePercent === Infinity) {
        changePercent = 100
      }

      values.trend = (change > 0) ? "up" : "down"
      values.change = `${Math.abs(changePercent)}%`
    }

    return values
  }

  const getNewCustomersStatistics = () => {

    const values = {
      total: "0",
      change: "N/C",
      trend: "flat",
    }

    const newCustomers = (type !== STATISTICS_KEY_FRANCHISE) ? statistics.get("new_customers") : statistics.get("new_customers_from_new_users")
    const repeatCustomers = (type !== STATISTICS_KEY_FRANCHISE) ? statistics.get("repeat_customers") : statistics.get("repeat_customers_from_new_users")

    let percent = newCustomers.current / (repeatCustomers.current + newCustomers.current) * 100
    const change = newCustomers.current - newCustomers.prev

    if (Number.isNaN(percent)) {
      percent = 0
    }

    values.total = `${Math.round(percent)}%`

    if (change !== 0) {
      let changePercent = Math.round(change / newCustomers.prev * 100)

      if (changePercent === Infinity) {
        changePercent = 100
      }

      values.trend = (change > 0) ? "up" : "down"
      values.change = `${Math.abs(changePercent)}%`
    }

    return values
  }

  const getBookingsStatistics = () => {

    const values = {
      total: "0",
      change: "N/C",
      trend: "flat",
    }

    const bookings = statistics.get("bookings")

    const change = bookings.current - bookings.prev

    values.total = `${bookings.current.toLocaleString(DEFAULT_LOCALE)}`

    if (change !== 0) {
      values.trend = (change > 0) ? "up" : "down"
      values.change = `${Math.abs(change).toLocaleString(DEFAULT_LOCALE)}`
    }

    return values
  }

  const getRevenueStatistics = () => {

    const values = {
      total: "0",
      change: "N/C",
      trend: "flat",
    }

    const revenue = statistics.get("revenue")

    const change = revenue.current - revenue.prev

    values.total = `${currency.get("symbol")}${Math.round(revenue.current).toLocaleString(DEFAULT_LOCALE)}`

    if (change !== 0) {
      let changePercent = Math.round(change / revenue.prev * 100)

      if (changePercent === Infinity) {
        changePercent = 100
      }

      values.trend = (change > 0) ? "up" : "down"
      values.change = `${Math.abs(changePercent)}%`
    }

    return values
  }

  const getAverageOrderSizeStatistics = () => {
    const values = {
      total: "0",
      change: "N/C",
      trend: "flat",
    }

    const revenue = statistics.get("revenue")
    const bookings = statistics.get("bookings")

    const current = (bookings.current > 0) ? revenue.current / bookings.current : 0
    const prev = (bookings.prev > 0) ? revenue.prev / bookings.prev : 0

    const change = current - prev

    values.total = `${currency.get("symbol")}${Math.round(current)}`

    if (change !== 0) {
      const changePercent = (prev === 0 || current === 0) ? 100 : Math.round(change / prev * 100)

      values.trend = (change > 0) ? "up" : "down"
      values.change = `${Math.abs(changePercent)}%`
    }

    return values
  }

  const getVisibleCompaniesStatistics = () => {
    if (type !== STATISTICS_KEY_FRANCHISE) {
      return null
    }

    return { total: statistics.get("visible_companies").current, change: "100%", trend: "up" }
  }

  const getConversionRateStatistics = () => {
    if (type !== STATISTICS_KEY_FRANCHISE) {
      return null
    }

    const values = {
      total: "0",
      change: "N/C",
      trend: "flat",
    }

    const users = statistics.get("unique_users")
    const bookings = statistics.get("bookings")

    let current = bookings.current / users.current * 100

    if (Number.isNaN(current)) {
      current = 0
    }

    if (current === Infinity) {
      current = 100
    }

    let prev = bookings.prev / users.prev * 100

    if (Number.isNaN(prev)) {
      prev = 0
    }

    if (current === Infinity) {
      prev = 100
    }

    const change = current - prev

    values.total = `${current.toFixed(2)}%`

    if (change !== 0) {
      let changePercent = Math.round(change / prev * 100)

      if (changePercent === Infinity || Number.isNaN(changePercent)) {
        changePercent = 100
      }

      values.trend = (change > 0) ? "up" : "down"
      values.change = `${Math.abs(changePercent)}%`
    }

    return values
  }

  const getColorAverageOrderSizeStatistics = () => {

    if (type !== STATISTICS_KEY_FRANCHISE) {
      return null
    }

    const values = {
      total: "0",
      change: "N/C",
      trend: "flat",
    }

    const revenue = statistics.get("revenue_colour")
    const bookings = statistics.get("bookings_colour")

    const current = (bookings.current > 0) ? revenue.current / bookings.current : 0
    const prev = (bookings.prev > 0) ? revenue.prev / bookings.prev : 0

    values.total = `${currency.get("symbol")}${Math.round(current)}`

    const change = current - prev

    if (change !== 0) {
      const changePercent = (prev === 0 || current === 0) ? 100 : Math.round(change / prev * 100)

      values.trend = (change > 0) ? "up" : "down"
      values.change = `${Math.abs(changePercent)}%`
    }

    return values
  }

  const getOtherAverageOrderSizeStatistics = () => {
    if (type !== STATISTICS_KEY_FRANCHISE) {
      return null
    }

    const values = {
      total: "0",
      change: "N/C",
      trend: "flat",
    }

    const revenueTotal = statistics.get("revenue")
    const revenueColour = statistics.get("revenue_colour")
    const bookingsTotal = statistics.get("bookings")
    const bookingsColour = statistics.get("bookings_colour")

    const revenue = {}

    Object.keys(revenueTotal).forEach((key) => {
      revenue[key] = revenueTotal[key] - revenueColour[key]
    })

    const bookings = {}

    Object.keys(bookingsTotal).forEach((key) => {
      bookings[key] = bookingsTotal[key] - bookingsColour[key]
    })

    const current = (bookings.current > 0) ? revenue.current / bookings.current : 0
    const prev = (bookings.prev > 0) ? revenue.prev / bookings.prev : 0

    values.total = `${currency.get("symbol")}${Math.round(current)}`

    const change = current - prev

    if (change !== 0) {
      const changePercent = (prev === 0 || current === 0) ? 100 : Math.round(change / prev * 100)

      values.trend = (change > 0) ? "up" : "down"
      values.change = `${Math.abs(changePercent)}%`
    }

    return values
  }

  const getTotalPageViewsStatistics = () => {
    if (type !== STATISTICS_KEY_FRANCHISE) {
      return null
    }

    const values = {
      total: "0",
      change: "N/C",
      trend: "flat",
    }

    const views = statistics.get("total_views")

    const { current, prev } = views

    const change = current - prev

    values.total = `${views.current.toLocaleString(DEFAULT_LOCALE)}`

    if (change !== 0) {
      const changePercent = (prev === 0 || current === 0) ? 100 : Math.round(change / prev * 100)

      values.trend = (change > 0) ? "up" : "down"
      values.change = `${Math.abs(changePercent)}%`
    }

    return values
  }

  const getTotalUniqueUsersStatistics = () => {
    if (type !== STATISTICS_KEY_FRANCHISE) {
      return null
    }

    const values = {
      total: "0",
      change: "N/C",
      trend: "flat",
    }

    const users = statistics.get("unique_users")

    const { current, prev } = users

    const change = current - prev

    values.total = `${users.current.toLocaleString(DEFAULT_LOCALE)}`

    if (change !== 0) {
      const changePercent = (prev === 0 || current === 0) ? 100 : Math.round(change / prev * 100)

      values.trend = (change > 0) ? "up" : "down"
      values.change = `${Math.abs(changePercent)}%`
    }

    return values
  }

  if (!statistics) {
    return null
  }

  const newCustomers = getNewCustomersStatistics();
  const bookings = getBookingsStatistics();
  const revenue = getRevenueStatistics();
  const averageOrderSize = getAverageOrderSizeStatistics();
  const visibleCompanies = getVisibleCompaniesStatistics();
  const conversionRate = getConversionRateStatistics();
  const colorAverageOrderSize = getColorAverageOrderSizeStatistics();
  const otherAverageOrderSize = getOtherAverageOrderSizeStatistics();
  const totalPageViews = getTotalPageViewsStatistics();
  const uniqueUsers = getTotalUniqueUsersStatistics();
  const repeatBookings = getRepeatBookingsStatistics();

  return (
    <div>
      <p
        style={{
          paddingLeft: browser.is.mobile ? 10 : 0,
        }}
        className="MainTitle"
      >
        {title}
      </p>
      <StatsBox>

        <StatsForm type={type} />

        <StatsData>

          {visibleCompanies && (
            <Stats
              total={visibleCompanies.total}
              label="Salons Live On Booking Site"
              change={visibleCompanies.change}
              trend={visibleCompanies.trend}
            />
          )}

          <Stats
            total={bookings.total}
            label="Total Bookings"
            change={bookings.change}
            trend={bookings.trend}
          />

          {repeatBookings && (
            <Stats
              total={repeatBookings.total}
              label="Repeat Bookings"
              change={repeatBookings.change}
              trend={repeatBookings.trend}
            />
          )}

          <Stats
            total={revenue.total}
            label="Total Service Revenue"
            change={revenue.change}
            trend={revenue.trend}
          />

          {conversionRate && (
            <Stats
              total={conversionRate.total}
              label="Conversion Rate"
              change={conversionRate.change}
              trend={conversionRate.trend}
            />
          )}

          <Stats
            total={averageOrderSize.total}
            label="AOV"
            change={averageOrderSize.change}
            trend={averageOrderSize.trend}
          />

          {otherAverageOrderSize && (
            <Stats
              total={otherAverageOrderSize.total}
              label="Other AOV"
              change={otherAverageOrderSize.change}
              trend={otherAverageOrderSize.trend}
            />
          )}

          {colorAverageOrderSize && (
            <Stats
              total={colorAverageOrderSize.total}
              label="Color AOV"
              change={colorAverageOrderSize.change}
              trend={colorAverageOrderSize.trend}
            />
          )}

          <Stats
            total={newCustomers.total}
            label="New Customer To Salon"
            change={newCustomers.change}
            trend={newCustomers.trend}
          />

          {totalPageViews && (
            <Stats
              total={totalPageViews.total}
              label="Total Page Views"
              change={totalPageViews.change}
              trend={totalPageViews.trend}
            />
          )}

          {uniqueUsers && (
            <Stats
              total={uniqueUsers.total}
              label="Total Unique Visitors"
              change={uniqueUsers.change}
              trend={uniqueUsers.trend}
            />
          )}
        </StatsData>
      </StatsBox>
    </div>
  )
}

StatsContainer.propTypes = {
  statistics: ImmutablePropTypes.map,
  currency: ImmutablePropTypes.map,
  browser: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  statisticsRefresh: PropTypes.func.isRequired,
  selectedCompanyId: PropTypes.string,
}

const mapStateToProps = (state, ownProps) => ({
  statistics: getStatistics(ownProps.type, state),
  currency: getStatisticsCurrency(ownProps.type, state),
  selectedCompanyId: getSelectedCompanyId(state),
  browser: state.get("browser"),
})

const mapDispatchToProps = dispatch => ({
  statisticsRefresh: (key, dateRange, companyId, companyName) => dispatch(statisticsRefresh(key, dateRange, companyId, companyName)),
})

export default connect(mapStateToProps, mapDispatchToProps)(StatsContainer)
